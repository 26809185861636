@mixin clearfix {
  &::after {
    clear: both;
    content: "";
    display: table;
  }
}

@mixin add-to-links() {
  list-style: none;
  display: flex;
  padding: 0;
  li {
    padding-right: 1em;
  }
  @media #{$small-only} {
    .inner {
      span {
        display: none;
      }
    }
  }
}

@mixin material-icons {
  font-family: "Material Icons";
  text-rendering: optimizelegibility;
  font-feature-settings: "liga";
  font-style: normal;
  text-transform: none;
  line-height: 1;
  display: block;
  overflow: hidden;
  -moz-osx-font-smoothing: grayscale;
}

@mixin btn-grey($bg: #6E6E6E, $color: #fff, $amount: 5%) {
  display: inline-block;
  background: $bg;
  padding: 0.75em 1em;
  border: 0;
  width: 100%;
  text-align: center;
  font-size: 1.2rem;
  font-weight: lighter;
  color: $color;
  margin: 1rem 0;
  &:hover {
    cursor: pointer;
    background: darken($bg, $amount);
  }
}

@mixin btn-cart($bg, $color, $amount: 1%) {
  background: $bg;
  padding: 0.5em 1em;
  border: 0;
  width: 100%;
  text-align: center;
  font-size: 1.2rem;
  font-weight: lighter;
  color: $color;
  margin-bottom: 1rem;
  margin-top: 1rem;
  i.material-icons {
    position: relative;
    top: 0.25em;
    padding-bottom: 0.25em;
  }
  &:hover {
    cursor: pointer;
    background: darken($bg, $amount);
  }
}

@mixin h2-with-line {
  // <div class="title">
  //    <h2><span class="center">Title Of The Day</span></h2>
  // </div>
  position: relative;
  background: $title-bg-color;
  &:before {
    content: "";
    position: absolute;
    background: $title-bg-color;
    width: 1em;
    height:1em;
    left:calc(50% - 0.5em);
    bottom: -0.5em;
    transform: rotate(45deg);
    z-index: 1
  }
  h2 {
    position: relative;
    text-align: center;
    font-size: 1rem;
    margin-bottom: 0.5rem;
    text-transform:uppercase;
    .center {
      background: $title-bg-color;
      padding: 0 2rem;
    }
  }
}

@mixin price-align($justify-content) {
  padding-left: 0;
  @if $justify-content == center {
    @media #{$large-up} {
      padding-left: 0.5rem;
    }
  }
}

@mixin grid-price-box($direction: row, $justify-content: center) {
  .price-box {
    display: flex;
    width: 100%;
    justify-content: $justify-content;
    flex-direction: column;
    align-items: $justify-content;
    height: auto;
    @media #{$large-up} {
      height: 1.8rem;
      flex-direction: $direction;
    }
    .price {
      margin: 0;
      font-size: 1rem;
      font-weight: lighter;
    }
    .price-label {
      display: none;
      color: $price-color;
    }
    .regular-price {
      margin: 0;
      color: $price-color;
    }
    .old-price {
      color: $old-price-color;
      order: 5;
      margin: 0;
      .price {
        text-decoration: line-through;
        @include price-align($justify-content);
      }
    }
    .special-price {
      margin: 0;
      color: $special-price-color;
      font-weight: bold;
      order: 1;
    }
    .price-from {
      margin: 0;
      font-size: 1rem;
      color: $price-color;
      .price-label{
        text-transform: uppercase;
        display: inline;
      }
    }
    .price-to {
      margin: 0;
      display: none;
      color: $price-color;
    }
    .minimal-price {
      margin: 0;
      color: $price-color;
    }
    .minimal-price-link {
      @include price-align($justify-content);
      .label {
        display: none;
      }
    }
    .map-link {
      margin: 0;
      font-size: 1rem;
      order: 10;
      @include price-align($justify-content);
    }
    // End Price Box
  }
}
