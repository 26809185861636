@charset "UTF-8";
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* TOOLTIP */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Typography */
/* Shadows */
/* Animations */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* TOOLTIP */
@import url("https://fonts.googleapis.com/css?family=Comfortaa");
.mdl-animation--default {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.mdl-animation--fast-out-slow-in {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.mdl-animation--linear-out-slow-in {
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}

.mdl-animation--fast-out-linear-in {
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* TOOLTIP */
.mdl-badge {
  position: relative;
  white-space: nowrap;
  margin-right: 24px;
}

.mdl-badge:not([data-badge]) {
  margin-right: auto;
}

.mdl-badge[data-badge]:after {
  content: attr(data-badge);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  position: absolute;
  top: -11px;
  right: -24px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 600;
  font-size: 12px;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: rgb(255,64,129);
  color: rgb(255,255,255);
}

.mdl-button .mdl-badge[data-badge]:after {
  top: -10px;
  right: -5px;
}

.mdl-badge.mdl-badge--no-background[data-badge]:after {
  color: rgb(255,64,129);
  background: rgba(255,255,255,0.2);
  box-shadow: 0 0 1px gray;
}

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* TOOLTIP */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Typography */
/* Shadows */
/* Animations */
.mdl-button {
  background: transparent;
  border: none;
  border-radius: 2px;
  color: rgb(0,0,0);
  position: relative;
  height: 36px;
  min-width: 64px;
  padding: 0 8px;
  display: inline-block;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1;
  letter-spacing: 0;
  overflow: hidden;
  will-change: box-shadow, transform;
  transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1), color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  outline: none;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  line-height: 36px;
  vertical-align: middle;
}

.mdl-button::-moz-focus-inner {
  border: 0;
}

.mdl-button:hover {
  background-color: rgba(158,158,158, 0.20);
}

.mdl-button:focus:not(:active) {
  background-color: rgba(0,0,0, 0.12);
}

.mdl-button:active {
  background-color: rgba(158,158,158, 0.40);
}

.mdl-button.mdl-button--colored {
  color: rgb(34,34,34);
}

.mdl-button.mdl-button--colored:focus:not(:active) {
  background-color: rgba(0,0,0, 0.12);
}

input.mdl-button[type="submit"] {
  -webkit-appearance: none;
}

.mdl-button--raised {
  background: rgba(158,158,158, 0.20);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.mdl-button--raised:active {
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
  background-color: rgba(158,158,158, 0.40);
}

.mdl-button--raised:focus:not(:active) {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.18), 0 8px 16px rgba(0, 0, 0, 0.36);
  background-color: rgba(158,158,158, 0.40);
}

.mdl-button--raised.mdl-button--colored {
  background: rgb(34,34,34);
  color: rgb(255,255,255);
}

.mdl-button--raised.mdl-button--colored:hover {
  background-color: rgb(34,34,34);
}

.mdl-button--raised.mdl-button--colored:active {
  background-color: rgb(34,34,34);
}

.mdl-button--raised.mdl-button--colored:focus:not(:active) {
  background-color: rgb(34,34,34);
}

.mdl-button--raised.mdl-button--colored .mdl-ripple {
  background: rgb(255,255,255);
}

.mdl-button--fab {
  border-radius: 50%;
  font-size: 24px;
  height: 56px;
  margin: auto;
  min-width: 56px;
  width: 56px;
  padding: 0;
  overflow: hidden;
  background: rgba(158,158,158, 0.20);
  box-shadow: 0 1px 1.5px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.24);
  position: relative;
  line-height: normal;
}

.mdl-button--fab .material-icons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-12px, -12px);
  line-height: 24px;
  width: 24px;
}

.mdl-button--fab.mdl-button--mini-fab {
  height: 40px;
  min-width: 40px;
  width: 40px;
}

.mdl-button--fab .mdl-button__ripple-container {
  border-radius: 50%;
  -webkit-mask-image: -webkit-radial-gradient(circle, white, black);
}

.mdl-button--fab:active {
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
  background-color: rgba(158,158,158, 0.40);
}

.mdl-button--fab:focus:not(:active) {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.18), 0 8px 16px rgba(0, 0, 0, 0.36);
  background-color: rgba(158,158,158, 0.40);
}

.mdl-button--fab.mdl-button--colored {
  background: rgb(255,64,129);
  color: rgb(255,255,255);
}

.mdl-button--fab.mdl-button--colored:hover {
  background-color: rgb(255,64,129);
}

.mdl-button--fab.mdl-button--colored:focus:not(:active) {
  background-color: rgb(255,64,129);
}

.mdl-button--fab.mdl-button--colored:active {
  background-color: rgb(255,64,129);
}

.mdl-button--fab.mdl-button--colored .mdl-ripple {
  background: rgb(255,255,255);
}

.mdl-button--icon {
  border-radius: 50%;
  font-size: 24px;
  height: 32px;
  margin-left: 0;
  margin-right: 0;
  min-width: 32px;
  width: 32px;
  padding: 0;
  overflow: hidden;
  color: inherit;
  line-height: normal;
}

.mdl-button--icon .material-icons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-12px, -12px);
  line-height: 24px;
  width: 24px;
}

.mdl-button--icon.mdl-button--mini-icon {
  height: 24px;
  min-width: 24px;
  width: 24px;
}

.mdl-button--icon.mdl-button--mini-icon .material-icons {
  top: 0px;
  left: 0px;
}

.mdl-button--icon .mdl-button__ripple-container {
  border-radius: 50%;
  -webkit-mask-image: -webkit-radial-gradient(circle, white, black);
}

.mdl-button__ripple-container {
  display: block;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
  z-index: 0;
  overflow: hidden;
}

.mdl-button[disabled] .mdl-button__ripple-container .mdl-ripple,
.mdl-button.mdl-button--disabled .mdl-button__ripple-container .mdl-ripple {
  background-color: transparent;
}

.mdl-button--primary.mdl-button--primary {
  color: rgb(34,34,34);
}

.mdl-button--primary.mdl-button--primary .mdl-ripple {
  background: rgb(255,255,255);
}

.mdl-button--primary.mdl-button--primary.mdl-button--raised, .mdl-button--primary.mdl-button--primary.mdl-button--fab {
  color: rgb(255,255,255);
  background-color: rgb(34,34,34);
}

.mdl-button--accent.mdl-button--accent {
  color: rgb(255,64,129);
}

.mdl-button--accent.mdl-button--accent .mdl-ripple {
  background: rgb(255,255,255);
}

.mdl-button--accent.mdl-button--accent.mdl-button--raised, .mdl-button--accent.mdl-button--accent.mdl-button--fab {
  color: rgb(255,255,255);
  background-color: rgb(255,64,129);
}

.mdl-button[disabled][disabled], .mdl-button.mdl-button--disabled.mdl-button--disabled {
  color: rgba(0,0,0, 0.26);
  cursor: auto;
  background-color: transparent;
}

.mdl-button--fab[disabled][disabled], .mdl-button--fab.mdl-button--disabled.mdl-button--disabled {
  background-color: rgba(0,0,0, 0.12);
  color: rgba(0,0,0, 0.26);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.mdl-button--raised[disabled][disabled], .mdl-button--raised.mdl-button--disabled.mdl-button--disabled {
  background-color: rgba(0,0,0, 0.12);
  color: rgba(0,0,0, 0.26);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.mdl-button--colored[disabled][disabled], .mdl-button--colored.mdl-button--disabled.mdl-button--disabled {
  color: rgba(0,0,0, 0.26);
}

.mdl-button .material-icons {
  vertical-align: middle;
}

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* TOOLTIP */
.mdl-card {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 400;
  min-height: 200px;
  overflow: hidden;
  width: 330px;
  z-index: 1;
  position: relative;
  background: rgb(255,255,255);
  border-radius: 2px;
  box-sizing: border-box;
}

.mdl-card__media {
  background-color: rgb(255,64,129);
  background-repeat: repeat;
  background-position: 50% 50%;
  background-size: cover;
  background-origin: padding-box;
  background-attachment: scroll;
  box-sizing: border-box;
}

.mdl-card__title {
  align-items: center;
  color: rgb(0,0,0);
  display: block;
  display: flex;
  justify-content: stretch;
  line-height: normal;
  padding: 16px 16px;
  perspective-origin: 165px 56px;
  transform-origin: 165px 56px;
  box-sizing: border-box;
}

.mdl-card__title.mdl-card--border {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.mdl-card__title-text {
  align-self: flex-end;
  color: inherit;
  display: block;
  display: flex;
  font-size: 24px;
  font-weight: 300;
  line-height: normal;
  overflow: hidden;
  transform-origin: 149px 48px;
  margin: 0;
}

.mdl-card__subtitle-text {
  font-size: 14px;
  color: grey;
  margin: 0;
}

.mdl-card__supporting-text {
  color: rgba(0,0,0, 0.54);
  font-size: 13px;
  line-height: 18px;
  overflow: hidden;
  padding: 16px 16px;
  width: 90%;
}

.mdl-card__actions {
  font-size: 16px;
  line-height: normal;
  width: 100%;
  background-color: transparent;
  padding: 8px;
  box-sizing: border-box;
}

.mdl-card__actions.mdl-card--border {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.mdl-card--expand {
  flex-grow: 1;
}

.mdl-card__menu {
  position: absolute;
  right: 16px;
  top: 16px;
}

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* TOOLTIP */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Typography */
/* Shadows */
/* Animations */
.mdl-checkbox {
  position: relative;
  z-index: 1;
  vertical-align: middle;
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
  height: 24px;
  margin: 0;
  padding: 0;
}

.mdl-checkbox.is-upgraded {
  padding-left: 24px;
}

.mdl-checkbox__input {
  line-height: 24px;
}

.mdl-checkbox.is-upgraded .mdl-checkbox__input {
  position: absolute;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
  opacity: 0;
  -ms-appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: none;
}

.mdl-checkbox__box-outline {
  position: absolute;
  top: 3px;
  left: 0;
  display: inline-block;
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  margin: 0;
  cursor: pointer;
  overflow: hidden;
  border: 2px solid rgba(0,0,0, 0.54);
  border-radius: 2px;
  z-index: 2;
}

.mdl-checkbox.is-checked .mdl-checkbox__box-outline {
  border: 2px solid rgb(34,34,34);
}

.mdl-checkbox.is-disabled .mdl-checkbox__box-outline {
  border: 2px solid rgba(0,0,0, 0.26);
  cursor: auto;
}

.mdl-checkbox__focus-helper {
  position: absolute;
  top: 3px;
  left: 0;
  display: inline-block;
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: transparent;
}

.mdl-checkbox.is-focused .mdl-checkbox__focus-helper {
  box-shadow: 0 0 0px 8px rgba(0, 0, 0, 0.1);
  background-color: rgba(0, 0, 0, 0.1);
}

.mdl-checkbox.is-focused.is-checked .mdl-checkbox__focus-helper {
  box-shadow: 0 0 0px 8px rgba(34,34,34, 0.26);
  background-color: rgba(34,34,34, 0.26);
}

.mdl-checkbox__tick-outline {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  mask: url("/images/tick-mask.svg?embed");
  background: transparent;
  transition-duration: 0.28s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: background;
}

.mdl-checkbox.is-checked .mdl-checkbox__tick-outline {
  background: rgb(34,34,34) url("/images/tick.svg?embed");
}

.mdl-checkbox.is-checked.is-disabled .mdl-checkbox__tick-outline {
  background: rgba(0,0,0, 0.26) url("/images/tick.svg?embed");
}

.mdl-checkbox__label {
  position: relative;
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
}

.mdl-checkbox.is-disabled .mdl-checkbox__label {
  color: rgba(0,0,0, 0.26);
  cursor: auto;
}

.mdl-checkbox__ripple-container {
  position: absolute;
  z-index: 2;
  top: -6px;
  left: -10px;
  box-sizing: border-box;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  cursor: pointer;
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(circle, white, black);
}

.mdl-checkbox__ripple-container .mdl-ripple {
  background: rgb(34,34,34);
}

.mdl-checkbox.is-disabled .mdl-checkbox__ripple-container {
  cursor: auto;
}

.mdl-checkbox.is-disabled .mdl-checkbox__ripple-container .mdl-ripple {
  background: transparent;
}

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* TOOLTIP */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Typography */
/* Shadows */
/* Animations */
.mdl-data-table {
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-collapse: collapse;
  white-space: nowrap;
  font-size: 13px;
  background-color: rgb(255,255,255);
}

.mdl-data-table thead {
  padding-bottom: 3px;
}

.mdl-data-table thead .mdl-data-table__select {
  margin-top: 0;
}

.mdl-data-table tbody tr {
  position: relative;
  height: 48px;
  transition-duration: 0.28s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: background-color;
}

.mdl-data-table tbody tr.is-selected {
  background-color: #e0e0e0;
}

.mdl-data-table tbody tr:hover {
  /*background-color: $data-table-hover-color;*/
}

.mdl-data-table td, .mdl-data-table th {
  padding: 0 18px 0 18px;
  text-align: right;
}

.mdl-data-table td:first-of-type, .mdl-data-table th:first-of-type {
  padding-left: 24px;
}

.mdl-data-table td:last-of-type, .mdl-data-table th:last-of-type {
  padding-right: 24px;
}

.mdl-data-table td {
  position: relative;
  vertical-align: top;
  height: 48px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding-top: 12px;
  box-sizing: border-box;
}

.mdl-data-table td .mdl-data-table__select {
  vertical-align: top;
  position: absolute;
  left: 24px;
}

.mdl-data-table th {
  position: relative;
  vertical-align: bottom;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: bold;
  line-height: 24px;
  letter-spacing: 0;
  height: 48px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.54);
  padding-bottom: 8px;
  box-sizing: border-box;
}

.mdl-data-table th .mdl-data-table__select {
  position: absolute;
  bottom: 8px;
  left: 24px;
}

.mdl-data-table__select {
  width: 16px;
}

.mdl-data-table__cell--non-numeric.mdl-data-table__cell--non-numeric {
  text-align: left;
}

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* TOOLTIP */
.mdl-mini-footer {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding: 32px 16px;
  color: rgb(158,158,158);
  background-color: rgb(66,66,66);
}

.mdl-mini-footer:after {
  content: '';
  display: block;
}

.mdl-mini-footer .mdl-logo {
  line-height: 36px;
}

.mdl-mini-footer--link-list,
.mdl-mini-footer__link-list {
  display: flex;
  flex-flow: row nowrap;
  list-style: none;
  margin: 0;
  padding: 0;
}

.mdl-mini-footer--link-list li,
.mdl-mini-footer__link-list li {
  margin-bottom: 0;
  margin-right: 16px;
}

@media screen and (min-width: 760px) {
  .mdl-mini-footer--link-list li,
  .mdl-mini-footer__link-list li {
    line-height: 36px;
  }
}

.mdl-mini-footer--link-list a,
.mdl-mini-footer__link-list a {
  color: inherit;
  text-decoration: none;
  white-space: nowrap;
}

.mdl-mini-footer--left-section,
.mdl-mini-footer__left-section {
  display: inline-block;
  order: 0;
}

.mdl-mini-footer--right-section,
.mdl-mini-footer__right-section {
  display: inline-block;
  order: 1;
}

.mdl-mini-footer--social-btn,
.mdl-mini-footer__social-btn {
  width: 36px;
  height: 36px;
  padding: 0;
  margin: 0;
  background-color: rgb(158,158,158);
  border: none;
}

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* TOOLTIP */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Typography */
/* Shadows */
/* Animations */
.mdl-mega-footer {
  padding: 16px 40px;
  color: rgb(158,158,158);
  background-color: rgb(66,66,66);
}

.mdl-mega-footer--top-section:after,
.mdl-mega-footer--middle-section:after,
.mdl-mega-footer--bottom-section:after,
.mdl-mega-footer__top-section:after,
.mdl-mega-footer__middle-section:after,
.mdl-mega-footer__bottom-section:after {
  content: '';
  display: block;
  clear: both;
}

.mdl-mega-footer--left-section,
.mdl-mega-footer__left-section {
  margin-bottom: 16px;
}

.mdl-mega-footer--right-section,
.mdl-mega-footer__right-section {
  margin-bottom: 16px;
}

.mdl-mega-footer--right-section a,
.mdl-mega-footer__right-section a {
  display: block;
  margin-bottom: 16px;
  color: inherit;
  text-decoration: none;
}

@media screen and (min-width: 760px) {
  .mdl-mega-footer--left-section,
  .mdl-mega-footer__left-section {
    float: left;
  }
  .mdl-mega-footer--right-section,
  .mdl-mega-footer__right-section {
    float: right;
  }
  .mdl-mega-footer--right-section a,
  .mdl-mega-footer__right-section a {
    display: inline-block;
    margin-left: 16px;
    line-height: 36px;
    vertical-align: middle;
  }
}

.mdl-mega-footer--social-btn,
.mdl-mega-footer__social-btn {
  width: 36px;
  height: 36px;
  padding: 0;
  margin: 0;
  background-color: rgb(158,158,158);
  border: none;
}

.mdl-mega-footer--drop-down-section,
.mdl-mega-footer__drop-down-section {
  display: block;
  position: relative;
}

@media screen and (min-width: 760px) {
  .mdl-mega-footer--drop-down-section,
  .mdl-mega-footer__drop-down-section {
    width: 33%;
  }
  .mdl-mega-footer--drop-down-section:nth-child(1),
  .mdl-mega-footer--drop-down-section:nth-child(2),
  .mdl-mega-footer__drop-down-section:nth-child(1),
  .mdl-mega-footer__drop-down-section:nth-child(2) {
    float: left;
  }
  .mdl-mega-footer--drop-down-section:nth-child(3),
  .mdl-mega-footer__drop-down-section:nth-child(3) {
    float: right;
  }
  .mdl-mega-footer--drop-down-section:nth-child(3):after,
  .mdl-mega-footer__drop-down-section:nth-child(3):after {
    clear: right;
  }
  .mdl-mega-footer--drop-down-section:nth-child(4),
  .mdl-mega-footer__drop-down-section:nth-child(4) {
    clear: right;
    float: right;
  }
  .mdl-mega-footer--middle-section:after,
  .mdl-mega-footer__middle-section:after {
    content: '';
    display: block;
    clear: both;
  }
  .mdl-mega-footer--bottom-section,
  .mdl-mega-footer__bottom-section {
    padding-top: 0;
  }
}

@media screen and (min-width: 1024px) {
  .mdl-mega-footer--drop-down-section,
  .mdl-mega-footer--drop-down-section:nth-child(3),
  .mdl-mega-footer--drop-down-section:nth-child(4),
  .mdl-mega-footer__drop-down-section,
  .mdl-mega-footer__drop-down-section:nth-child(3),
  .mdl-mega-footer__drop-down-section:nth-child(4) {
    width: 24%;
    float: left;
  }
}

.mdl-mega-footer--heading-checkbox,
.mdl-mega-footer__heading-checkbox {
  position: absolute;
  width: 100%;
  height: 55.8px;
  padding: 32px;
  margin: 0;
  margin-top: -16px;
  cursor: pointer;
  z-index: 1;
  opacity: 0;
}

.mdl-mega-footer--heading-checkbox ~ .mdl-mega-footer--heading:after,
.mdl-mega-footer--heading-checkbox ~ .mdl-mega-footer__heading:after,
.mdl-mega-footer__heading-checkbox ~ .mdl-mega-footer--heading:after,
.mdl-mega-footer__heading-checkbox ~ .mdl-mega-footer__heading:after {
  font-family: 'Material Icons';
  content: '\E5CE';
}

.mdl-mega-footer--heading-checkbox:checked ~ ul,
.mdl-mega-footer__heading-checkbox:checked ~ ul {
  display: none;
}

.mdl-mega-footer--heading-checkbox:checked ~ .mdl-mega-footer--heading:after,
.mdl-mega-footer--heading-checkbox:checked ~ .mdl-mega-footer__heading:after,
.mdl-mega-footer__heading-checkbox:checked ~ .mdl-mega-footer--heading:after,
.mdl-mega-footer__heading-checkbox:checked ~ .mdl-mega-footer__heading:after {
  font-family: 'Material Icons';
  content: '\E5CF';
}

.mdl-mega-footer--heading,
.mdl-mega-footer__heading {
  position: relative;
  width: 100%;
  padding-right: 39.8px;
  margin-bottom: 16px;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 23.8px;
  font-weight: 500;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: rgb(224,224,224);
}

.mdl-mega-footer--heading:after,
.mdl-mega-footer__heading:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 23.8px;
  height: 23.8px;
  background-size: cover;
}

.mdl-mega-footer--link-list,
.mdl-mega-footer__link-list {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-bottom: 32px;
}

.mdl-mega-footer--link-list:after,
.mdl-mega-footer__link-list:after {
  clear: both;
  display: block;
  content: '';
}

.mdl-mega-footer--link-list li,
.mdl-mega-footer__link-list li {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  line-height: 20px;
}

.mdl-mega-footer--link-list a,
.mdl-mega-footer__link-list a {
  color: inherit;
  text-decoration: none;
  white-space: nowrap;
}

@media screen and (min-width: 760px) {
  .mdl-mega-footer--heading-checkbox,
  .mdl-mega-footer__heading-checkbox {
    display: none;
  }
  .mdl-mega-footer--heading-checkbox ~ .mdl-mega-footer--heading:after,
  .mdl-mega-footer--heading-checkbox ~ .mdl-mega-footer__heading:after,
  .mdl-mega-footer__heading-checkbox ~ .mdl-mega-footer--heading:after,
  .mdl-mega-footer__heading-checkbox ~ .mdl-mega-footer__heading:after {
    background-image: none;
  }
  .mdl-mega-footer--heading-checkbox:checked ~ ul,
  .mdl-mega-footer__heading-checkbox:checked ~ ul {
    display: block;
  }
  .mdl-mega-footer--heading-checkbox:checked ~ .mdl-mega-footer--heading:after,
  .mdl-mega-footer--heading-checkbox:checked ~ .mdl-mega-footer__heading:after,
  .mdl-mega-footer__heading-checkbox:checked ~ .mdl-mega-footer--heading:after,
  .mdl-mega-footer__heading-checkbox:checked ~ .mdl-mega-footer__heading:after {
    content: '';
  }
}

.mdl-mega-footer--bottom-section,
.mdl-mega-footer__bottom-section {
  padding-top: 16px;
  margin-bottom: 16px;
}

.mdl-logo {
  margin-bottom: 16px;
  color: white;
}

.mdl-mega-footer--bottom-section .mdl-mega-footer--link-list li,
.mdl-mega-footer__bottom-section .mdl-mega-footer__link-list li {
  float: left;
  margin-bottom: 0;
  margin-right: 16px;
}

@media screen and (min-width: 760px) {
  .mdl-logo {
    float: left;
    margin-bottom: 0;
    margin-right: 16px;
  }
}

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*
* NOTE: Some rules here are applied using duplicate selectors.
* This is on purpose to increase their specificity when applied.
* For example: `.mdl-cell--1-col-phone.mdl-cell--1-col-phone`
*/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* TOOLTIP */
.mdl-grid {
  display: flex;
  flex-flow: row wrap;
  margin: 0 auto 0 auto;
  align-items: stretch;
}

.mdl-grid.mdl-grid--no-spacing {
  padding: 0;
}

.mdl-cell {
  box-sizing: border-box;
}

.mdl-cell--top {
  align-self: flex-start;
}

.mdl-cell--middle {
  align-self: center;
}

.mdl-cell--bottom {
  align-self: flex-end;
}

.mdl-cell--stretch {
  align-self: stretch;
}

.mdl-grid.mdl-grid--no-spacing > .mdl-cell {
  margin: 0;
}

@media (max-width: 767px) {
  .mdl-grid {
    padding: -10px;
  }
  .mdl-cell {
    margin: 0px;
    width: calc(100% - 0px);
  }
  .mdl-grid--no-spacing > .mdl-cell {
    width: 100%;
  }
  .mdl-cell--hide-phone {
    display: none !important;
  }
  .mdl-cell--1-col,
  .mdl-cell--1-col-phone.mdl-cell--1-col-phone {
    width: calc(8.33333% - 0px);
  }
  .mdl-grid--no-spacing > .mdl-cell--1-col, .mdl-grid--no-spacing >
  .mdl-cell--1-col-phone.mdl-cell--1-col-phone {
    width: 8.33333%;
  }
  .mdl-cell--2-col,
  .mdl-cell--2-col-phone.mdl-cell--2-col-phone {
    width: calc(16.66667% - 0px);
  }
  .mdl-grid--no-spacing > .mdl-cell--2-col, .mdl-grid--no-spacing >
  .mdl-cell--2-col-phone.mdl-cell--2-col-phone {
    width: 16.66667%;
  }
  .mdl-cell--3-col,
  .mdl-cell--3-col-phone.mdl-cell--3-col-phone {
    width: calc(25% - 0px);
  }
  .mdl-grid--no-spacing > .mdl-cell--3-col, .mdl-grid--no-spacing >
  .mdl-cell--3-col-phone.mdl-cell--3-col-phone {
    width: 25%;
  }
  .mdl-cell--4-col,
  .mdl-cell--4-col-phone.mdl-cell--4-col-phone {
    width: calc(33.33333% - 0px);
  }
  .mdl-grid--no-spacing > .mdl-cell--4-col, .mdl-grid--no-spacing >
  .mdl-cell--4-col-phone.mdl-cell--4-col-phone {
    width: 33.33333%;
  }
  .mdl-cell--5-col,
  .mdl-cell--5-col-phone.mdl-cell--5-col-phone {
    width: calc(41.66667% - 0px);
  }
  .mdl-grid--no-spacing > .mdl-cell--5-col, .mdl-grid--no-spacing >
  .mdl-cell--5-col-phone.mdl-cell--5-col-phone {
    width: 41.66667%;
  }
  .mdl-cell--6-col,
  .mdl-cell--6-col-phone.mdl-cell--6-col-phone {
    width: calc(50% - 0px);
  }
  .mdl-grid--no-spacing > .mdl-cell--6-col, .mdl-grid--no-spacing >
  .mdl-cell--6-col-phone.mdl-cell--6-col-phone {
    width: 50%;
  }
  .mdl-cell--7-col,
  .mdl-cell--7-col-phone.mdl-cell--7-col-phone {
    width: calc(58.33333% - 0px);
  }
  .mdl-grid--no-spacing > .mdl-cell--7-col, .mdl-grid--no-spacing >
  .mdl-cell--7-col-phone.mdl-cell--7-col-phone {
    width: 58.33333%;
  }
  .mdl-cell--8-col,
  .mdl-cell--8-col-phone.mdl-cell--8-col-phone {
    width: calc(66.66667% - 0px);
  }
  .mdl-grid--no-spacing > .mdl-cell--8-col, .mdl-grid--no-spacing >
  .mdl-cell--8-col-phone.mdl-cell--8-col-phone {
    width: 66.66667%;
  }
  .mdl-cell--9-col,
  .mdl-cell--9-col-phone.mdl-cell--9-col-phone {
    width: calc(75% - 0px);
  }
  .mdl-grid--no-spacing > .mdl-cell--9-col, .mdl-grid--no-spacing >
  .mdl-cell--9-col-phone.mdl-cell--9-col-phone {
    width: 75%;
  }
  .mdl-cell--10-col,
  .mdl-cell--10-col-phone.mdl-cell--10-col-phone {
    width: calc(83.33333% - 0px);
  }
  .mdl-grid--no-spacing > .mdl-cell--10-col, .mdl-grid--no-spacing >
  .mdl-cell--10-col-phone.mdl-cell--10-col-phone {
    width: 83.33333%;
  }
  .mdl-cell--11-col,
  .mdl-cell--11-col-phone.mdl-cell--11-col-phone {
    width: calc(91.66667% - 0px);
  }
  .mdl-grid--no-spacing > .mdl-cell--11-col, .mdl-grid--no-spacing >
  .mdl-cell--11-col-phone.mdl-cell--11-col-phone {
    width: 91.66667%;
  }
  .mdl-cell--12-col,
  .mdl-cell--12-col-phone.mdl-cell--12-col-phone {
    width: calc(100% - 0px);
  }
  .mdl-grid--no-spacing > .mdl-cell--12-col, .mdl-grid--no-spacing >
  .mdl-cell--12-col-phone.mdl-cell--12-col-phone {
    width: 100%;
  }
  .mdl-cell--1-offset,
  .mdl-cell--1-offset-phone.mdl-cell--1-offset-phone {
    margin-left: calc(8.33333% + 0px);
  }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--1-offset, .mdl-grid.mdl-grid--no-spacing >
  .mdl-cell--1-offset-phone.mdl-cell--1-offset-phone {
    margin-left: 8.33333%;
  }
  .mdl-cell--2-offset,
  .mdl-cell--2-offset-phone.mdl-cell--2-offset-phone {
    margin-left: calc(16.66667% + 0px);
  }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--2-offset, .mdl-grid.mdl-grid--no-spacing >
  .mdl-cell--2-offset-phone.mdl-cell--2-offset-phone {
    margin-left: 16.66667%;
  }
  .mdl-cell--3-offset,
  .mdl-cell--3-offset-phone.mdl-cell--3-offset-phone {
    margin-left: calc(25% + 0px);
  }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--3-offset, .mdl-grid.mdl-grid--no-spacing >
  .mdl-cell--3-offset-phone.mdl-cell--3-offset-phone {
    margin-left: 25%;
  }
  .mdl-cell--4-offset,
  .mdl-cell--4-offset-phone.mdl-cell--4-offset-phone {
    margin-left: calc(33.33333% + 0px);
  }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--4-offset, .mdl-grid.mdl-grid--no-spacing >
  .mdl-cell--4-offset-phone.mdl-cell--4-offset-phone {
    margin-left: 33.33333%;
  }
  .mdl-cell--5-offset,
  .mdl-cell--5-offset-phone.mdl-cell--5-offset-phone {
    margin-left: calc(41.66667% + 0px);
  }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--5-offset, .mdl-grid.mdl-grid--no-spacing >
  .mdl-cell--5-offset-phone.mdl-cell--5-offset-phone {
    margin-left: 41.66667%;
  }
  .mdl-cell--6-offset,
  .mdl-cell--6-offset-phone.mdl-cell--6-offset-phone {
    margin-left: calc(50% + 0px);
  }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--6-offset, .mdl-grid.mdl-grid--no-spacing >
  .mdl-cell--6-offset-phone.mdl-cell--6-offset-phone {
    margin-left: 50%;
  }
  .mdl-cell--7-offset,
  .mdl-cell--7-offset-phone.mdl-cell--7-offset-phone {
    margin-left: calc(58.33333% + 0px);
  }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--7-offset, .mdl-grid.mdl-grid--no-spacing >
  .mdl-cell--7-offset-phone.mdl-cell--7-offset-phone {
    margin-left: 58.33333%;
  }
  .mdl-cell--8-offset,
  .mdl-cell--8-offset-phone.mdl-cell--8-offset-phone {
    margin-left: calc(66.66667% + 0px);
  }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--8-offset, .mdl-grid.mdl-grid--no-spacing >
  .mdl-cell--8-offset-phone.mdl-cell--8-offset-phone {
    margin-left: 66.66667%;
  }
  .mdl-cell--9-offset,
  .mdl-cell--9-offset-phone.mdl-cell--9-offset-phone {
    margin-left: calc(75% + 0px);
  }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--9-offset, .mdl-grid.mdl-grid--no-spacing >
  .mdl-cell--9-offset-phone.mdl-cell--9-offset-phone {
    margin-left: 75%;
  }
  .mdl-cell--10-offset,
  .mdl-cell--10-offset-phone.mdl-cell--10-offset-phone {
    margin-left: calc(83.33333% + 0px);
  }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--10-offset, .mdl-grid.mdl-grid--no-spacing >
  .mdl-cell--10-offset-phone.mdl-cell--10-offset-phone {
    margin-left: 83.33333%;
  }
  .mdl-cell--11-offset,
  .mdl-cell--11-offset-phone.mdl-cell--11-offset-phone {
    margin-left: calc(91.66667% + 0px);
  }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--11-offset, .mdl-grid.mdl-grid--no-spacing >
  .mdl-cell--11-offset-phone.mdl-cell--11-offset-phone {
    margin-left: 91.66667%;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .mdl-grid {
    padding: -10px;
  }
  .mdl-cell {
    margin: 0px;
    width: calc(100% - 0px);
  }
  .mdl-grid--no-spacing > .mdl-cell {
    width: 100%;
  }
  .mdl-cell--hide-tablet {
    display: none !important;
  }
  .mdl-cell--1-col,
  .mdl-cell--1-col-tablet.mdl-cell--1-col-tablet {
    width: calc(8.33333% - 0px);
  }
  .mdl-grid--no-spacing > .mdl-cell--1-col, .mdl-grid--no-spacing >
  .mdl-cell--1-col-tablet.mdl-cell--1-col-tablet {
    width: 8.33333%;
  }
  .mdl-cell--2-col,
  .mdl-cell--2-col-tablet.mdl-cell--2-col-tablet {
    width: calc(16.66667% - 0px);
  }
  .mdl-grid--no-spacing > .mdl-cell--2-col, .mdl-grid--no-spacing >
  .mdl-cell--2-col-tablet.mdl-cell--2-col-tablet {
    width: 16.66667%;
  }
  .mdl-cell--3-col,
  .mdl-cell--3-col-tablet.mdl-cell--3-col-tablet {
    width: calc(25% - 0px);
  }
  .mdl-grid--no-spacing > .mdl-cell--3-col, .mdl-grid--no-spacing >
  .mdl-cell--3-col-tablet.mdl-cell--3-col-tablet {
    width: 25%;
  }
  .mdl-cell--4-col,
  .mdl-cell--4-col-tablet.mdl-cell--4-col-tablet {
    width: calc(33.33333% - 0px);
  }
  .mdl-grid--no-spacing > .mdl-cell--4-col, .mdl-grid--no-spacing >
  .mdl-cell--4-col-tablet.mdl-cell--4-col-tablet {
    width: 33.33333%;
  }
  .mdl-cell--5-col,
  .mdl-cell--5-col-tablet.mdl-cell--5-col-tablet {
    width: calc(41.66667% - 0px);
  }
  .mdl-grid--no-spacing > .mdl-cell--5-col, .mdl-grid--no-spacing >
  .mdl-cell--5-col-tablet.mdl-cell--5-col-tablet {
    width: 41.66667%;
  }
  .mdl-cell--6-col,
  .mdl-cell--6-col-tablet.mdl-cell--6-col-tablet {
    width: calc(50% - 0px);
  }
  .mdl-grid--no-spacing > .mdl-cell--6-col, .mdl-grid--no-spacing >
  .mdl-cell--6-col-tablet.mdl-cell--6-col-tablet {
    width: 50%;
  }
  .mdl-cell--7-col,
  .mdl-cell--7-col-tablet.mdl-cell--7-col-tablet {
    width: calc(58.33333% - 0px);
  }
  .mdl-grid--no-spacing > .mdl-cell--7-col, .mdl-grid--no-spacing >
  .mdl-cell--7-col-tablet.mdl-cell--7-col-tablet {
    width: 58.33333%;
  }
  .mdl-cell--8-col,
  .mdl-cell--8-col-tablet.mdl-cell--8-col-tablet {
    width: calc(66.66667% - 0px);
  }
  .mdl-grid--no-spacing > .mdl-cell--8-col, .mdl-grid--no-spacing >
  .mdl-cell--8-col-tablet.mdl-cell--8-col-tablet {
    width: 66.66667%;
  }
  .mdl-cell--9-col,
  .mdl-cell--9-col-tablet.mdl-cell--9-col-tablet {
    width: calc(75% - 0px);
  }
  .mdl-grid--no-spacing > .mdl-cell--9-col, .mdl-grid--no-spacing >
  .mdl-cell--9-col-tablet.mdl-cell--9-col-tablet {
    width: 75%;
  }
  .mdl-cell--10-col,
  .mdl-cell--10-col-tablet.mdl-cell--10-col-tablet {
    width: calc(83.33333% - 0px);
  }
  .mdl-grid--no-spacing > .mdl-cell--10-col, .mdl-grid--no-spacing >
  .mdl-cell--10-col-tablet.mdl-cell--10-col-tablet {
    width: 83.33333%;
  }
  .mdl-cell--11-col,
  .mdl-cell--11-col-tablet.mdl-cell--11-col-tablet {
    width: calc(91.66667% - 0px);
  }
  .mdl-grid--no-spacing > .mdl-cell--11-col, .mdl-grid--no-spacing >
  .mdl-cell--11-col-tablet.mdl-cell--11-col-tablet {
    width: 91.66667%;
  }
  .mdl-cell--12-col,
  .mdl-cell--12-col-tablet.mdl-cell--12-col-tablet {
    width: calc(100% - 0px);
  }
  .mdl-grid--no-spacing > .mdl-cell--12-col, .mdl-grid--no-spacing >
  .mdl-cell--12-col-tablet.mdl-cell--12-col-tablet {
    width: 100%;
  }
  .mdl-cell--1-offset,
  .mdl-cell--1-offset-tablet.mdl-cell--1-offset-tablet {
    margin-left: calc(8.33333% + 0px);
  }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--1-offset, .mdl-grid.mdl-grid--no-spacing >
  .mdl-cell--1-offset-tablet.mdl-cell--1-offset-tablet {
    margin-left: 8.33333%;
  }
  .mdl-cell--2-offset,
  .mdl-cell--2-offset-tablet.mdl-cell--2-offset-tablet {
    margin-left: calc(16.66667% + 0px);
  }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--2-offset, .mdl-grid.mdl-grid--no-spacing >
  .mdl-cell--2-offset-tablet.mdl-cell--2-offset-tablet {
    margin-left: 16.66667%;
  }
  .mdl-cell--3-offset,
  .mdl-cell--3-offset-tablet.mdl-cell--3-offset-tablet {
    margin-left: calc(25% + 0px);
  }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--3-offset, .mdl-grid.mdl-grid--no-spacing >
  .mdl-cell--3-offset-tablet.mdl-cell--3-offset-tablet {
    margin-left: 25%;
  }
  .mdl-cell--4-offset,
  .mdl-cell--4-offset-tablet.mdl-cell--4-offset-tablet {
    margin-left: calc(33.33333% + 0px);
  }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--4-offset, .mdl-grid.mdl-grid--no-spacing >
  .mdl-cell--4-offset-tablet.mdl-cell--4-offset-tablet {
    margin-left: 33.33333%;
  }
  .mdl-cell--5-offset,
  .mdl-cell--5-offset-tablet.mdl-cell--5-offset-tablet {
    margin-left: calc(41.66667% + 0px);
  }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--5-offset, .mdl-grid.mdl-grid--no-spacing >
  .mdl-cell--5-offset-tablet.mdl-cell--5-offset-tablet {
    margin-left: 41.66667%;
  }
  .mdl-cell--6-offset,
  .mdl-cell--6-offset-tablet.mdl-cell--6-offset-tablet {
    margin-left: calc(50% + 0px);
  }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--6-offset, .mdl-grid.mdl-grid--no-spacing >
  .mdl-cell--6-offset-tablet.mdl-cell--6-offset-tablet {
    margin-left: 50%;
  }
  .mdl-cell--7-offset,
  .mdl-cell--7-offset-tablet.mdl-cell--7-offset-tablet {
    margin-left: calc(58.33333% + 0px);
  }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--7-offset, .mdl-grid.mdl-grid--no-spacing >
  .mdl-cell--7-offset-tablet.mdl-cell--7-offset-tablet {
    margin-left: 58.33333%;
  }
  .mdl-cell--8-offset,
  .mdl-cell--8-offset-tablet.mdl-cell--8-offset-tablet {
    margin-left: calc(66.66667% + 0px);
  }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--8-offset, .mdl-grid.mdl-grid--no-spacing >
  .mdl-cell--8-offset-tablet.mdl-cell--8-offset-tablet {
    margin-left: 66.66667%;
  }
  .mdl-cell--9-offset,
  .mdl-cell--9-offset-tablet.mdl-cell--9-offset-tablet {
    margin-left: calc(75% + 0px);
  }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--9-offset, .mdl-grid.mdl-grid--no-spacing >
  .mdl-cell--9-offset-tablet.mdl-cell--9-offset-tablet {
    margin-left: 75%;
  }
  .mdl-cell--10-offset,
  .mdl-cell--10-offset-tablet.mdl-cell--10-offset-tablet {
    margin-left: calc(83.33333% + 0px);
  }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--10-offset, .mdl-grid.mdl-grid--no-spacing >
  .mdl-cell--10-offset-tablet.mdl-cell--10-offset-tablet {
    margin-left: 83.33333%;
  }
  .mdl-cell--11-offset,
  .mdl-cell--11-offset-tablet.mdl-cell--11-offset-tablet {
    margin-left: calc(91.66667% + 0px);
  }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--11-offset, .mdl-grid.mdl-grid--no-spacing >
  .mdl-cell--11-offset-tablet.mdl-cell--11-offset-tablet {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1025px) {
  .mdl-grid {
    padding: -10px;
  }
  .mdl-cell {
    margin: 0px;
    width: calc(100% - 0px);
  }
  .mdl-grid--no-spacing > .mdl-cell {
    width: 100%;
  }
  .mdl-cell--hide-desktop {
    display: none !important;
  }
  .mdl-cell--1-col,
  .mdl-cell--1-col-desktop.mdl-cell--1-col-desktop {
    width: calc(8.33333% - 0px);
  }
  .mdl-grid--no-spacing > .mdl-cell--1-col, .mdl-grid--no-spacing >
  .mdl-cell--1-col-desktop.mdl-cell--1-col-desktop {
    width: 8.33333%;
  }
  .mdl-cell--2-col,
  .mdl-cell--2-col-desktop.mdl-cell--2-col-desktop {
    width: calc(16.66667% - 0px);
  }
  .mdl-grid--no-spacing > .mdl-cell--2-col, .mdl-grid--no-spacing >
  .mdl-cell--2-col-desktop.mdl-cell--2-col-desktop {
    width: 16.66667%;
  }
  .mdl-cell--3-col,
  .mdl-cell--3-col-desktop.mdl-cell--3-col-desktop {
    width: calc(25% - 0px);
  }
  .mdl-grid--no-spacing > .mdl-cell--3-col, .mdl-grid--no-spacing >
  .mdl-cell--3-col-desktop.mdl-cell--3-col-desktop {
    width: 25%;
  }
  .mdl-cell--4-col,
  .mdl-cell--4-col-desktop.mdl-cell--4-col-desktop {
    width: calc(33.33333% - 0px);
  }
  .mdl-grid--no-spacing > .mdl-cell--4-col, .mdl-grid--no-spacing >
  .mdl-cell--4-col-desktop.mdl-cell--4-col-desktop {
    width: 33.33333%;
  }
  .mdl-cell--5-col,
  .mdl-cell--5-col-desktop.mdl-cell--5-col-desktop {
    width: calc(41.66667% - 0px);
  }
  .mdl-grid--no-spacing > .mdl-cell--5-col, .mdl-grid--no-spacing >
  .mdl-cell--5-col-desktop.mdl-cell--5-col-desktop {
    width: 41.66667%;
  }
  .mdl-cell--6-col,
  .mdl-cell--6-col-desktop.mdl-cell--6-col-desktop {
    width: calc(50% - 0px);
  }
  .mdl-grid--no-spacing > .mdl-cell--6-col, .mdl-grid--no-spacing >
  .mdl-cell--6-col-desktop.mdl-cell--6-col-desktop {
    width: 50%;
  }
  .mdl-cell--7-col,
  .mdl-cell--7-col-desktop.mdl-cell--7-col-desktop {
    width: calc(58.33333% - 0px);
  }
  .mdl-grid--no-spacing > .mdl-cell--7-col, .mdl-grid--no-spacing >
  .mdl-cell--7-col-desktop.mdl-cell--7-col-desktop {
    width: 58.33333%;
  }
  .mdl-cell--8-col,
  .mdl-cell--8-col-desktop.mdl-cell--8-col-desktop {
    width: calc(66.66667% - 0px);
  }
  .mdl-grid--no-spacing > .mdl-cell--8-col, .mdl-grid--no-spacing >
  .mdl-cell--8-col-desktop.mdl-cell--8-col-desktop {
    width: 66.66667%;
  }
  .mdl-cell--9-col,
  .mdl-cell--9-col-desktop.mdl-cell--9-col-desktop {
    width: calc(75% - 0px);
  }
  .mdl-grid--no-spacing > .mdl-cell--9-col, .mdl-grid--no-spacing >
  .mdl-cell--9-col-desktop.mdl-cell--9-col-desktop {
    width: 75%;
  }
  .mdl-cell--10-col,
  .mdl-cell--10-col-desktop.mdl-cell--10-col-desktop {
    width: calc(83.33333% - 0px);
  }
  .mdl-grid--no-spacing > .mdl-cell--10-col, .mdl-grid--no-spacing >
  .mdl-cell--10-col-desktop.mdl-cell--10-col-desktop {
    width: 83.33333%;
  }
  .mdl-cell--11-col,
  .mdl-cell--11-col-desktop.mdl-cell--11-col-desktop {
    width: calc(91.66667% - 0px);
  }
  .mdl-grid--no-spacing > .mdl-cell--11-col, .mdl-grid--no-spacing >
  .mdl-cell--11-col-desktop.mdl-cell--11-col-desktop {
    width: 91.66667%;
  }
  .mdl-cell--12-col,
  .mdl-cell--12-col-desktop.mdl-cell--12-col-desktop {
    width: calc(100% - 0px);
  }
  .mdl-grid--no-spacing > .mdl-cell--12-col, .mdl-grid--no-spacing >
  .mdl-cell--12-col-desktop.mdl-cell--12-col-desktop {
    width: 100%;
  }
  .mdl-cell--1-offset,
  .mdl-cell--1-offset-desktop.mdl-cell--1-offset-desktop {
    margin-left: calc(8.33333% + 0px);
  }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--1-offset, .mdl-grid.mdl-grid--no-spacing >
  .mdl-cell--1-offset-desktop.mdl-cell--1-offset-desktop {
    margin-left: 8.33333%;
  }
  .mdl-cell--2-offset,
  .mdl-cell--2-offset-desktop.mdl-cell--2-offset-desktop {
    margin-left: calc(16.66667% + 0px);
  }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--2-offset, .mdl-grid.mdl-grid--no-spacing >
  .mdl-cell--2-offset-desktop.mdl-cell--2-offset-desktop {
    margin-left: 16.66667%;
  }
  .mdl-cell--3-offset,
  .mdl-cell--3-offset-desktop.mdl-cell--3-offset-desktop {
    margin-left: calc(25% + 0px);
  }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--3-offset, .mdl-grid.mdl-grid--no-spacing >
  .mdl-cell--3-offset-desktop.mdl-cell--3-offset-desktop {
    margin-left: 25%;
  }
  .mdl-cell--4-offset,
  .mdl-cell--4-offset-desktop.mdl-cell--4-offset-desktop {
    margin-left: calc(33.33333% + 0px);
  }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--4-offset, .mdl-grid.mdl-grid--no-spacing >
  .mdl-cell--4-offset-desktop.mdl-cell--4-offset-desktop {
    margin-left: 33.33333%;
  }
  .mdl-cell--5-offset,
  .mdl-cell--5-offset-desktop.mdl-cell--5-offset-desktop {
    margin-left: calc(41.66667% + 0px);
  }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--5-offset, .mdl-grid.mdl-grid--no-spacing >
  .mdl-cell--5-offset-desktop.mdl-cell--5-offset-desktop {
    margin-left: 41.66667%;
  }
  .mdl-cell--6-offset,
  .mdl-cell--6-offset-desktop.mdl-cell--6-offset-desktop {
    margin-left: calc(50% + 0px);
  }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--6-offset, .mdl-grid.mdl-grid--no-spacing >
  .mdl-cell--6-offset-desktop.mdl-cell--6-offset-desktop {
    margin-left: 50%;
  }
  .mdl-cell--7-offset,
  .mdl-cell--7-offset-desktop.mdl-cell--7-offset-desktop {
    margin-left: calc(58.33333% + 0px);
  }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--7-offset, .mdl-grid.mdl-grid--no-spacing >
  .mdl-cell--7-offset-desktop.mdl-cell--7-offset-desktop {
    margin-left: 58.33333%;
  }
  .mdl-cell--8-offset,
  .mdl-cell--8-offset-desktop.mdl-cell--8-offset-desktop {
    margin-left: calc(66.66667% + 0px);
  }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--8-offset, .mdl-grid.mdl-grid--no-spacing >
  .mdl-cell--8-offset-desktop.mdl-cell--8-offset-desktop {
    margin-left: 66.66667%;
  }
  .mdl-cell--9-offset,
  .mdl-cell--9-offset-desktop.mdl-cell--9-offset-desktop {
    margin-left: calc(75% + 0px);
  }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--9-offset, .mdl-grid.mdl-grid--no-spacing >
  .mdl-cell--9-offset-desktop.mdl-cell--9-offset-desktop {
    margin-left: 75%;
  }
  .mdl-cell--10-offset,
  .mdl-cell--10-offset-desktop.mdl-cell--10-offset-desktop {
    margin-left: calc(83.33333% + 0px);
  }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--10-offset, .mdl-grid.mdl-grid--no-spacing >
  .mdl-cell--10-offset-desktop.mdl-cell--10-offset-desktop {
    margin-left: 83.33333%;
  }
  .mdl-cell--11-offset,
  .mdl-cell--11-offset-desktop.mdl-cell--11-offset-desktop {
    margin-left: calc(91.66667% + 0px);
  }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--11-offset, .mdl-grid.mdl-grid--no-spacing >
  .mdl-cell--11-offset-desktop.mdl-cell--11-offset-desktop {
    margin-left: 91.66667%;
  }
}

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* TOOLTIP */
.mdl-icon-toggle {
  position: relative;
  z-index: 1;
  vertical-align: middle;
  display: inline-block;
  height: 32px;
  margin: 0;
  padding: 0;
}

.mdl-icon-toggle__input {
  line-height: 32px;
}

.mdl-icon-toggle.is-upgraded .mdl-icon-toggle__input {
  position: absolute;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
  opacity: 0;
  -ms-appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: none;
}

.mdl-icon-toggle__label {
  display: inline-block;
  position: relative;
  cursor: pointer;
  height: 32px;
  width: 32px;
  min-width: 32px;
  color: rgb(97,97,97);
  border-radius: 50%;
  padding: 0;
  margin-left: 0;
  margin-right: 0;
  text-align: center;
  background-color: transparent;
  will-change: background-color;
  transition: background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1), color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.mdl-icon-toggle__label.material-icons {
  line-height: 32px;
  font-size: 24px;
}

.mdl-icon-toggle.is-checked .mdl-icon-toggle__label {
  color: rgb(34,34,34);
}

.mdl-icon-toggle.is-disabled .mdl-icon-toggle__label {
  color: rgba(0,0,0, 0.26);
  cursor: auto;
  transition: none;
}

.mdl-icon-toggle.is-focused .mdl-icon-toggle__label {
  background-color: rgba(0,0,0, 0.12);
}

.mdl-icon-toggle.is-focused.is-checked .mdl-icon-toggle__label {
  background-color: rgba(34,34,34, 0.26);
}

.mdl-icon-toggle__ripple-container {
  position: absolute;
  z-index: 2;
  top: -2px;
  left: -2px;
  box-sizing: border-box;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  cursor: pointer;
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(circle, white, black);
}

.mdl-icon-toggle__ripple-container .mdl-ripple {
  background: rgb(97,97,97);
}

.mdl-icon-toggle.is-disabled .mdl-icon-toggle__ripple-container {
  cursor: auto;
}

.mdl-icon-toggle.is-disabled .mdl-icon-toggle__ripple-container .mdl-ripple {
  background: transparent;
}

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* TOOLTIP */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Typography */
/* Shadows */
/* Animations */
.mdl-navigation {
  display: flex;
  flex-wrap: nowrap;
  box-sizing: border-box;
}

.mdl-navigation__link {
  color: rgb(66,66,66);
  text-decoration: none;
  font-weight: 500;
  font-size: 13px;
  margin: 0;
}

.mdl-navigation__link .material-icons {
  vertical-align: middle;
}

.mdl-layout {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  -webkit-overflow-scrolling: touch;
}

.mdl-layout.is-small-screen .mdl-layout--large-screen-only {
  display: none;
}

.mdl-layout:not(.is-small-screen) .mdl-layout--small-screen-only {
  display: none;
}

.mdl-layout__container {
  position: absolute;
  width: 100%;
  height: 100%;
}

.mdl-layout-title {
  display: block;
  position: relative;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.02em;
  font-weight: 400;
  box-sizing: border-box;
}

.mdl-layout-spacer {
  flex-grow: 1;
}

.mdl-layout__drawer {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 240px;
  height: 100%;
  max-height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  border-right: 1px solid rgb(224,224,224);
  background: rgb(250,250,250);
  transform: translateX(-250px);
  transform-style: preserve-3d;
  will-change: transform;
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: transform;
  color: rgb(66,66,66);
  overflow: visible;
  overflow-y: auto;
  z-index: 5;
}

.mdl-layout__drawer.is-visible {
  transform: translateX(0);
}

.mdl-layout__drawer > * {
  flex-shrink: 0;
}

.mdl-layout__drawer > .mdl-layout-title {
  line-height: 64px;
  padding-left: 40px;
}

@media screen and (max-width: 1024px) {
  .mdl-layout__drawer > .mdl-layout-title {
    line-height: 56px;
    padding-left: 16px;
  }
}

.mdl-layout__drawer .mdl-navigation {
  flex-direction: column;
  align-items: stretch;
  padding-top: 16px;
}

.mdl-layout__drawer .mdl-navigation .mdl-navigation__link {
  display: block;
  flex-shrink: 0;
  padding: 16px 40px;
  margin: 0;
  color: #757575;
}

@media screen and (max-width: 1024px) {
  .mdl-layout__drawer .mdl-navigation .mdl-navigation__link {
    padding: 16px 16px;
  }
}

.mdl-layout__drawer .mdl-navigation .mdl-navigation__link:hover {
  background-color: rgb(224,224,224);
}

.mdl-layout__drawer .mdl-navigation .mdl-navigation__link--current {
  background-color: rgb(0,0,0);
  color: rgb(34,34,34);
}

@media screen and (min-width: 1025px) {
  .mdl-layout--fixed-drawer > .mdl-layout__drawer {
    transform: translateX(0);
  }
}

.mdl-layout__drawer-button {
  display: block;
  position: absolute;
  height: 48px;
  width: 48px;
  border: 0;
  flex-shrink: 0;
  overflow: hidden;
  text-align: center;
  cursor: pointer;
  font-size: 26px;
  line-height: 50px;
  font-family: Helvetica, Arial, sans-serif;
  margin: 10px 12px;
  top: 0;
  left: 0;
  color: rgb(255,255,255);
  z-index: 4;
}

.mdl-layout__header .mdl-layout__drawer-button {
  position: absolute;
  color: rgb(255,255,255);
  background-color: inherit;
}

@media screen and (max-width: 1024px) {
  .mdl-layout__header .mdl-layout__drawer-button {
    margin: 4px;
  }
}

@media screen and (max-width: 1024px) {
  .mdl-layout__drawer-button {
    margin: 4px;
    color: rgba(0, 0, 0, 0.5);
  }
}

@media screen and (min-width: 1025px) {
  .mdl-layout--fixed-drawer > .mdl-layout__drawer-button {
    display: none;
  }
}

.mdl-layout__header {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  box-sizing: border-box;
  flex-shrink: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  border: none;
  min-height: 64px;
  max-height: 1000px;
  z-index: 3;
  background-color: rgb(34,34,34);
  color: rgb(255,255,255);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: max-height, box-shadow;
}

@media screen and (max-width: 1024px) {
  .mdl-layout__header {
    min-height: 56px;
  }
}

.mdl-layout--fixed-drawer:not(.is-small-screen) > .mdl-layout__header {
  margin-left: 240px;
  width: calc(100% - 240px);
}

@media screen and (min-width: 1024px) {
  .mdl-layout--fixed-drawer > .mdl-layout__header .mdl-layout__header-row {
    padding-left: 40px;
  }
}

.mdl-layout__header > .mdl-layout-icon {
  position: absolute;
  left: 40px;
  top: 16px;
  height: 32px;
  width: 32px;
  overflow: hidden;
  z-index: 3;
  display: block;
}

@media screen and (max-width: 1024px) {
  .mdl-layout__header > .mdl-layout-icon {
    left: 16px;
    top: 12px;
  }
}

.mdl-layout.has-drawer .mdl-layout__header > .mdl-layout-icon {
  display: none;
}

.mdl-layout__header.is-compact {
  max-height: 64px;
}

@media screen and (max-width: 1024px) {
  .mdl-layout__header.is-compact {
    max-height: 56px;
  }
}

.mdl-layout__header.is-compact.has-tabs {
  height: 112px;
}

@media screen and (max-width: 1024px) {
  .mdl-layout__header.is-compact.has-tabs {
    min-height: 104px;
  }
}

@media screen and (max-width: 1024px) {
  .mdl-layout__header {
    display: none;
  }
  .mdl-layout--fixed-header > .mdl-layout__header {
    display: flex;
  }
}

.mdl-layout__header--transparent.mdl-layout__header--transparent {
  background-color: transparent;
  box-shadow: none;
}

.mdl-layout__header--seamed {
  box-shadow: none;
}

.mdl-layout__header--scroll {
  box-shadow: none;
}

.mdl-layout__header--waterfall {
  box-shadow: none;
  overflow: hidden;
}

.mdl-layout__header--waterfall.is-casting-shadow {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.mdl-layout__header-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  flex-shrink: 0;
  box-sizing: border-box;
  align-self: stretch;
  align-items: center;
  height: 64px;
  margin: 0;
  padding: 0 40px 0 80px;
}

@media screen and (max-width: 1024px) {
  .mdl-layout__header-row {
    height: 56px;
    padding: 0 16px 0 72px;
  }
}

.mdl-layout__header-row > * {
  flex-shrink: 0;
}

.mdl-layout__header--scroll .mdl-layout__header-row {
  width: 100%;
}

.mdl-layout__header-row .mdl-navigation {
  margin: 0;
  padding: 0;
  height: 64px;
  flex-direction: row;
  align-items: center;
}

@media screen and (max-width: 1024px) {
  .mdl-layout__header-row .mdl-navigation {
    height: 56px;
  }
}

.mdl-layout__header-row .mdl-navigation__link {
  display: block;
  color: rgb(255,255,255);
  line-height: 64px;
  padding: 0 24px;
}

@media screen and (max-width: 1024px) {
  .mdl-layout__header-row .mdl-navigation__link {
    line-height: 56px;
    padding: 0 16px;
  }
}

.mdl-layout__obfuscator {
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 4;
  visibility: hidden;
  transition-property: background-color;
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.mdl-layout__drawer.is-visible ~ .mdl-layout__obfuscator {
  background-color: rgba(0, 0, 0, 0.5);
  visibility: visible;
}

.mdl-layout__content {
  -ms-flex: 0 1 auto;
  display: inline-block;
  overflow-y: auto;
  overflow-x: hidden;
  flex-grow: 1;
  z-index: 1;
  -webkit-overflow-scrolling: touch;
}

.mdl-layout--fixed-drawer > .mdl-layout__content {
  margin-left: 240px;
}

.mdl-layout__container.has-scrolling-header .mdl-layout__content {
  overflow: visible;
}

@media screen and (max-width: 1024px) {
  .mdl-layout--fixed-drawer > .mdl-layout__content {
    margin-left: 0;
  }
  .mdl-layout__container.has-scrolling-header .mdl-layout__content {
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.mdl-layout__tab-bar {
  height: 96px;
  margin: 0;
  width: calc(100% - 112px);
  padding: 0 0 0 56px;
  display: flex;
  background-color: rgb(34,34,34);
  overflow-y: hidden;
  overflow-x: scroll;
}

.mdl-layout__tab-bar::-webkit-scrollbar {
  display: none;
}

@media screen and (max-width: 1024px) {
  .mdl-layout__tab-bar {
    width: calc(100% - 60px);
    padding: 0 0 0 60px;
  }
}

.mdl-layout--fixed-tabs .mdl-layout__tab-bar {
  padding: 0;
  overflow: hidden;
  width: 100%;
}

.mdl-layout__tab-bar-container {
  position: relative;
  height: 48px;
  width: 100%;
  border: none;
  margin: 0;
  z-index: 2;
  flex-grow: 0;
  flex-shrink: 0;
  overflow: hidden;
}

.mdl-layout__container > .mdl-layout__tab-bar-container {
  position: absolute;
  top: 0;
  left: 0;
}

.mdl-layout__tab-bar-button {
  display: inline-block;
  position: absolute;
  top: 0;
  height: 48px;
  width: 56px;
  z-index: 4;
  text-align: center;
  background-color: rgb(34,34,34);
  color: transparent;
  cursor: pointer;
  user-select: none;
}

@media screen and (max-width: 1024px) {
  .mdl-layout__tab-bar-button {
    display: none;
    width: 60px;
  }
}

.mdl-layout--fixed-tabs .mdl-layout__tab-bar-button {
  display: none;
}

.mdl-layout__tab-bar-button .material-icons {
  line-height: 48px;
}

.mdl-layout__tab-bar-button.is-active {
  color: rgb(255,255,255);
}

.mdl-layout__tab-bar-left-button {
  left: 0;
}

.mdl-layout__tab-bar-right-button {
  right: 0;
}

.mdl-layout__tab {
  margin: 0;
  border: none;
  padding: 0 24px 0 24px;
  float: left;
  position: relative;
  display: block;
  flex-grow: 0;
  flex-shrink: 0;
  text-decoration: none;
  height: 48px;
  line-height: 48px;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  color: rgba(255,255,255, 0.6);
  overflow: hidden;
}

@media screen and (max-width: 1024px) {
  .mdl-layout__tab {
    padding: 0 12px 0 12px;
  }
}

.mdl-layout--fixed-tabs .mdl-layout__tab {
  float: none;
  flex-grow: 1;
  padding: 0;
}

.mdl-layout.is-upgraded .mdl-layout__tab.is-active {
  color: rgb(255,255,255);
}

.mdl-layout.is-upgraded .mdl-layout__tab.is-active::after {
  height: 2px;
  width: 100%;
  display: block;
  content: " ";
  bottom: 0;
  left: 0;
  position: absolute;
  background: rgb(255,64,129);
  animation: border-expand 0.2s cubic-bezier(0.4, 0, 0.4, 1) 0.01s alternate forwards;
  transition: all 1s cubic-bezier(0.4, 0, 1, 1);
}

.mdl-layout__tab .mdl-layout__tab-ripple-container {
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  overflow: hidden;
}

.mdl-layout__tab .mdl-layout__tab-ripple-container .mdl-ripple {
  background-color: rgb(255,255,255);
}

.mdl-layout__tab-panel {
  display: block;
}

.mdl-layout.is-upgraded .mdl-layout__tab-panel {
  display: none;
}

.mdl-layout.is-upgraded .mdl-layout__tab-panel.is-active {
  display: block;
}

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* TOOLTIP */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Typography */
/* Shadows */
/* Animations */
.mdl-menu__container {
  display: block;
  margin: 0;
  padding: 0;
  border: none;
  position: absolute;
  overflow: visible;
  height: 0;
  width: 0;
  visibility: hidden;
  z-index: -1;
}

.mdl-menu__container.is-visible, .mdl-menu__container.is-animating {
  z-index: 999;
  visibility: visible;
}

.mdl-menu__outline {
  display: block;
  background: rgb(255,255,255);
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 2px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  opacity: 0;
  transform: scale(0);
  transform-origin: 0 0;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  will-change: transform;
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  z-index: -1;
}

.mdl-menu__container.is-visible .mdl-menu__outline {
  opacity: 1;
  transform: scale(1);
  z-index: 999;
}

.mdl-menu__outline.mdl-menu--bottom-right {
  transform-origin: 100% 0;
}

.mdl-menu__outline.mdl-menu--top-left {
  transform-origin: 0 100%;
}

.mdl-menu__outline.mdl-menu--top-right {
  transform-origin: 100% 100%;
}

.mdl-menu {
  position: absolute;
  list-style: none;
  top: 0;
  left: 0;
  height: auto;
  width: auto;
  min-width: 124px;
  padding: 8px 0;
  margin: 0;
  opacity: 0;
  clip: rect(0 0 0 0);
  z-index: -1;
}

.mdl-menu__container.is-visible .mdl-menu {
  opacity: 1;
  z-index: 999;
}

.mdl-menu.is-animating {
  transition: opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1), clip 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.mdl-menu.mdl-menu--bottom-right {
  left: auto;
  right: 0;
}

.mdl-menu.mdl-menu--top-left {
  top: auto;
  bottom: 0;
}

.mdl-menu.mdl-menu--top-right {
  top: auto;
  left: auto;
  bottom: 0;
  right: 0;
}

.mdl-menu.mdl-menu--unaligned {
  top: auto;
  left: auto;
}

.mdl-menu__item {
  display: block;
  border: none;
  color: rgba(0,0,0, 0.87);
  background-color: transparent;
  text-align: left;
  margin: 0;
  padding: 0 16px;
  outline-color: rgb(189,189,189);
  position: relative;
  overflow: hidden;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-decoration: none;
  cursor: pointer;
  height: 48px;
  line-height: 48px;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  user-select: none;
}

.mdl-menu__container.is-visible .mdl-menu__item {
  opacity: 1;
}

.mdl-menu__item::-moz-focus-inner {
  border: 0;
}

.mdl-menu__item[disabled] {
  color: rgb(189,189,189);
  background-color: transparent;
  cursor: auto;
}

.mdl-menu__item[disabled]:hover {
  background-color: transparent;
}

.mdl-menu__item[disabled]:focus {
  background-color: transparent;
}

.mdl-menu__item[disabled] .mdl-ripple {
  background: transparent;
}

.mdl-menu__item:hover {
  background-color: rgb(238,238,238);
}

.mdl-menu__item:focus {
  outline: none;
  background-color: rgb(238,238,238);
}

.mdl-menu__item:active {
  background-color: rgb(224,224,224);
}

.mdl-menu__item--ripple-container {
  display: block;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
  z-index: 0;
  overflow: hidden;
}

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* TOOLTIP */
.mdl-color-text--red {
  color: rgb(244,67,54) !important;
}

.mdl-color--red {
  background-color: rgb(244,67,54) !important;
}

.mdl-color-text--red-50 {
  color: rgb(255,235,238) !important;
}

.mdl-color--red-50 {
  background-color: rgb(255,235,238) !important;
}

.mdl-color-text--red-100 {
  color: rgb(255,205,210) !important;
}

.mdl-color--red-100 {
  background-color: rgb(255,205,210) !important;
}

.mdl-color-text--red-200 {
  color: rgb(239,154,154) !important;
}

.mdl-color--red-200 {
  background-color: rgb(239,154,154) !important;
}

.mdl-color-text--red-300 {
  color: rgb(229,115,115) !important;
}

.mdl-color--red-300 {
  background-color: rgb(229,115,115) !important;
}

.mdl-color-text--red-400 {
  color: rgb(239,83,80) !important;
}

.mdl-color--red-400 {
  background-color: rgb(239,83,80) !important;
}

.mdl-color-text--red-500 {
  color: rgb(244,67,54) !important;
}

.mdl-color--red-500 {
  background-color: rgb(244,67,54) !important;
}

.mdl-color-text--red-600 {
  color: rgb(229,57,53) !important;
}

.mdl-color--red-600 {
  background-color: rgb(229,57,53) !important;
}

.mdl-color-text--red-700 {
  color: rgb(211,47,47) !important;
}

.mdl-color--red-700 {
  background-color: rgb(211,47,47) !important;
}

.mdl-color-text--red-800 {
  color: rgb(198,40,40) !important;
}

.mdl-color--red-800 {
  background-color: rgb(198,40,40) !important;
}

.mdl-color-text--red-900 {
  color: rgb(183,28,28) !important;
}

.mdl-color--red-900 {
  background-color: rgb(183,28,28) !important;
}

.mdl-color-text--red-A100 {
  color: rgb(255,138,128) !important;
}

.mdl-color--red-A100 {
  background-color: rgb(255,138,128) !important;
}

.mdl-color-text--red-A200 {
  color: rgb(255,82,82) !important;
}

.mdl-color--red-A200 {
  background-color: rgb(255,82,82) !important;
}

.mdl-color-text--red-A400 {
  color: rgb(255,23,68) !important;
}

.mdl-color--red-A400 {
  background-color: rgb(255,23,68) !important;
}

.mdl-color-text--red-A700 {
  color: rgb(213,0,0) !important;
}

.mdl-color--red-A700 {
  background-color: rgb(213,0,0) !important;
}

.mdl-color-text--pink {
  color: rgb(233,30,99) !important;
}

.mdl-color--pink {
  background-color: rgb(233,30,99) !important;
}

.mdl-color-text--pink-50 {
  color: rgb(252,228,236) !important;
}

.mdl-color--pink-50 {
  background-color: rgb(252,228,236) !important;
}

.mdl-color-text--pink-100 {
  color: rgb(248,187,208) !important;
}

.mdl-color--pink-100 {
  background-color: rgb(248,187,208) !important;
}

.mdl-color-text--pink-200 {
  color: rgb(244,143,177) !important;
}

.mdl-color--pink-200 {
  background-color: rgb(244,143,177) !important;
}

.mdl-color-text--pink-300 {
  color: rgb(240,98,146) !important;
}

.mdl-color--pink-300 {
  background-color: rgb(240,98,146) !important;
}

.mdl-color-text--pink-400 {
  color: rgb(236,64,122) !important;
}

.mdl-color--pink-400 {
  background-color: rgb(236,64,122) !important;
}

.mdl-color-text--pink-500 {
  color: rgb(233,30,99) !important;
}

.mdl-color--pink-500 {
  background-color: rgb(233,30,99) !important;
}

.mdl-color-text--pink-600 {
  color: rgb(216,27,96) !important;
}

.mdl-color--pink-600 {
  background-color: rgb(216,27,96) !important;
}

.mdl-color-text--pink-700 {
  color: rgb(194,24,91) !important;
}

.mdl-color--pink-700 {
  background-color: rgb(194,24,91) !important;
}

.mdl-color-text--pink-800 {
  color: rgb(173,20,87) !important;
}

.mdl-color--pink-800 {
  background-color: rgb(173,20,87) !important;
}

.mdl-color-text--pink-900 {
  color: rgb(136,14,79) !important;
}

.mdl-color--pink-900 {
  background-color: rgb(136,14,79) !important;
}

.mdl-color-text--pink-A100 {
  color: rgb(255,128,171) !important;
}

.mdl-color--pink-A100 {
  background-color: rgb(255,128,171) !important;
}

.mdl-color-text--pink-A200 {
  color: rgb(255,64,129) !important;
}

.mdl-color--pink-A200 {
  background-color: rgb(255,64,129) !important;
}

.mdl-color-text--pink-A400 {
  color: rgb(245,0,87) !important;
}

.mdl-color--pink-A400 {
  background-color: rgb(245,0,87) !important;
}

.mdl-color-text--pink-A700 {
  color: rgb(197,17,98) !important;
}

.mdl-color--pink-A700 {
  background-color: rgb(197,17,98) !important;
}

.mdl-color-text--purple {
  color: rgb(156,39,176) !important;
}

.mdl-color--purple {
  background-color: rgb(156,39,176) !important;
}

.mdl-color-text--purple-50 {
  color: rgb(243,229,245) !important;
}

.mdl-color--purple-50 {
  background-color: rgb(243,229,245) !important;
}

.mdl-color-text--purple-100 {
  color: rgb(225,190,231) !important;
}

.mdl-color--purple-100 {
  background-color: rgb(225,190,231) !important;
}

.mdl-color-text--purple-200 {
  color: rgb(206,147,216) !important;
}

.mdl-color--purple-200 {
  background-color: rgb(206,147,216) !important;
}

.mdl-color-text--purple-300 {
  color: rgb(186,104,200) !important;
}

.mdl-color--purple-300 {
  background-color: rgb(186,104,200) !important;
}

.mdl-color-text--purple-400 {
  color: rgb(171,71,188) !important;
}

.mdl-color--purple-400 {
  background-color: rgb(171,71,188) !important;
}

.mdl-color-text--purple-500 {
  color: rgb(156,39,176) !important;
}

.mdl-color--purple-500 {
  background-color: rgb(156,39,176) !important;
}

.mdl-color-text--purple-600 {
  color: rgb(142,36,170) !important;
}

.mdl-color--purple-600 {
  background-color: rgb(142,36,170) !important;
}

.mdl-color-text--purple-700 {
  color: rgb(123,31,162) !important;
}

.mdl-color--purple-700 {
  background-color: rgb(123,31,162) !important;
}

.mdl-color-text--purple-800 {
  color: rgb(106,27,154) !important;
}

.mdl-color--purple-800 {
  background-color: rgb(106,27,154) !important;
}

.mdl-color-text--purple-900 {
  color: rgb(74,20,140) !important;
}

.mdl-color--purple-900 {
  background-color: rgb(74,20,140) !important;
}

.mdl-color-text--purple-A100 {
  color: rgb(234,128,252) !important;
}

.mdl-color--purple-A100 {
  background-color: rgb(234,128,252) !important;
}

.mdl-color-text--purple-A200 {
  color: rgb(224,64,251) !important;
}

.mdl-color--purple-A200 {
  background-color: rgb(224,64,251) !important;
}

.mdl-color-text--purple-A400 {
  color: rgb(213,0,249) !important;
}

.mdl-color--purple-A400 {
  background-color: rgb(213,0,249) !important;
}

.mdl-color-text--purple-A700 {
  color: rgb(170,0,255) !important;
}

.mdl-color--purple-A700 {
  background-color: rgb(170,0,255) !important;
}

.mdl-color-text--deep-purple {
  color: rgb(103,58,183) !important;
}

.mdl-color--deep-purple {
  background-color: rgb(103,58,183) !important;
}

.mdl-color-text--deep-purple-50 {
  color: rgb(237,231,246) !important;
}

.mdl-color--deep-purple-50 {
  background-color: rgb(237,231,246) !important;
}

.mdl-color-text--deep-purple-100 {
  color: rgb(209,196,233) !important;
}

.mdl-color--deep-purple-100 {
  background-color: rgb(209,196,233) !important;
}

.mdl-color-text--deep-purple-200 {
  color: rgb(179,157,219) !important;
}

.mdl-color--deep-purple-200 {
  background-color: rgb(179,157,219) !important;
}

.mdl-color-text--deep-purple-300 {
  color: rgb(149,117,205) !important;
}

.mdl-color--deep-purple-300 {
  background-color: rgb(149,117,205) !important;
}

.mdl-color-text--deep-purple-400 {
  color: rgb(126,87,194) !important;
}

.mdl-color--deep-purple-400 {
  background-color: rgb(126,87,194) !important;
}

.mdl-color-text--deep-purple-500 {
  color: rgb(103,58,183) !important;
}

.mdl-color--deep-purple-500 {
  background-color: rgb(103,58,183) !important;
}

.mdl-color-text--deep-purple-600 {
  color: rgb(94,53,177) !important;
}

.mdl-color--deep-purple-600 {
  background-color: rgb(94,53,177) !important;
}

.mdl-color-text--deep-purple-700 {
  color: rgb(81,45,168) !important;
}

.mdl-color--deep-purple-700 {
  background-color: rgb(81,45,168) !important;
}

.mdl-color-text--deep-purple-800 {
  color: rgb(69,39,160) !important;
}

.mdl-color--deep-purple-800 {
  background-color: rgb(69,39,160) !important;
}

.mdl-color-text--deep-purple-900 {
  color: rgb(49,27,146) !important;
}

.mdl-color--deep-purple-900 {
  background-color: rgb(49,27,146) !important;
}

.mdl-color-text--deep-purple-A100 {
  color: rgb(179,136,255) !important;
}

.mdl-color--deep-purple-A100 {
  background-color: rgb(179,136,255) !important;
}

.mdl-color-text--deep-purple-A200 {
  color: rgb(124,77,255) !important;
}

.mdl-color--deep-purple-A200 {
  background-color: rgb(124,77,255) !important;
}

.mdl-color-text--deep-purple-A400 {
  color: rgb(101,31,255) !important;
}

.mdl-color--deep-purple-A400 {
  background-color: rgb(101,31,255) !important;
}

.mdl-color-text--deep-purple-A700 {
  color: rgb(98,0,234) !important;
}

.mdl-color--deep-purple-A700 {
  background-color: rgb(98,0,234) !important;
}

.mdl-color-text--indigo {
  color: rgb(63,81,181) !important;
}

.mdl-color--indigo {
  background-color: rgb(63,81,181) !important;
}

.mdl-color-text--indigo-50 {
  color: rgb(232,234,246) !important;
}

.mdl-color--indigo-50 {
  background-color: rgb(232,234,246) !important;
}

.mdl-color-text--indigo-100 {
  color: rgb(197,202,233) !important;
}

.mdl-color--indigo-100 {
  background-color: rgb(197,202,233) !important;
}

.mdl-color-text--indigo-200 {
  color: rgb(159,168,218) !important;
}

.mdl-color--indigo-200 {
  background-color: rgb(159,168,218) !important;
}

.mdl-color-text--indigo-300 {
  color: rgb(121,134,203) !important;
}

.mdl-color--indigo-300 {
  background-color: rgb(121,134,203) !important;
}

.mdl-color-text--indigo-400 {
  color: rgb(92,107,192) !important;
}

.mdl-color--indigo-400 {
  background-color: rgb(92,107,192) !important;
}

.mdl-color-text--indigo-500 {
  color: rgb(63,81,181) !important;
}

.mdl-color--indigo-500 {
  background-color: rgb(63,81,181) !important;
}

.mdl-color-text--indigo-600 {
  color: rgb(57,73,171) !important;
}

.mdl-color--indigo-600 {
  background-color: rgb(57,73,171) !important;
}

.mdl-color-text--indigo-700 {
  color: rgb(48,63,159) !important;
}

.mdl-color--indigo-700 {
  background-color: rgb(48,63,159) !important;
}

.mdl-color-text--indigo-800 {
  color: rgb(40,53,147) !important;
}

.mdl-color--indigo-800 {
  background-color: rgb(40,53,147) !important;
}

.mdl-color-text--indigo-900 {
  color: rgb(26,35,126) !important;
}

.mdl-color--indigo-900 {
  background-color: rgb(26,35,126) !important;
}

.mdl-color-text--indigo-A100 {
  color: rgb(140,158,255) !important;
}

.mdl-color--indigo-A100 {
  background-color: rgb(140,158,255) !important;
}

.mdl-color-text--indigo-A200 {
  color: rgb(83,109,254) !important;
}

.mdl-color--indigo-A200 {
  background-color: rgb(83,109,254) !important;
}

.mdl-color-text--indigo-A400 {
  color: rgb(61,90,254) !important;
}

.mdl-color--indigo-A400 {
  background-color: rgb(61,90,254) !important;
}

.mdl-color-text--indigo-A700 {
  color: rgb(48,79,254) !important;
}

.mdl-color--indigo-A700 {
  background-color: rgb(48,79,254) !important;
}

.mdl-color-text--blue {
  color: rgb(33,150,243) !important;
}

.mdl-color--blue {
  background-color: rgb(33,150,243) !important;
}

.mdl-color-text--blue-50 {
  color: rgb(227,242,253) !important;
}

.mdl-color--blue-50 {
  background-color: rgb(227,242,253) !important;
}

.mdl-color-text--blue-100 {
  color: rgb(187,222,251) !important;
}

.mdl-color--blue-100 {
  background-color: rgb(187,222,251) !important;
}

.mdl-color-text--blue-200 {
  color: rgb(144,202,249) !important;
}

.mdl-color--blue-200 {
  background-color: rgb(144,202,249) !important;
}

.mdl-color-text--blue-300 {
  color: rgb(100,181,246) !important;
}

.mdl-color--blue-300 {
  background-color: rgb(100,181,246) !important;
}

.mdl-color-text--blue-400 {
  color: rgb(66,165,245) !important;
}

.mdl-color--blue-400 {
  background-color: rgb(66,165,245) !important;
}

.mdl-color-text--blue-500 {
  color: rgb(33,150,243) !important;
}

.mdl-color--blue-500 {
  background-color: rgb(33,150,243) !important;
}

.mdl-color-text--blue-600 {
  color: rgb(30,136,229) !important;
}

.mdl-color--blue-600 {
  background-color: rgb(30,136,229) !important;
}

.mdl-color-text--blue-700 {
  color: rgb(25,118,210) !important;
}

.mdl-color--blue-700 {
  background-color: rgb(25,118,210) !important;
}

.mdl-color-text--blue-800 {
  color: rgb(21,101,192) !important;
}

.mdl-color--blue-800 {
  background-color: rgb(21,101,192) !important;
}

.mdl-color-text--blue-900 {
  color: rgb(13,71,161) !important;
}

.mdl-color--blue-900 {
  background-color: rgb(13,71,161) !important;
}

.mdl-color-text--blue-A100 {
  color: rgb(130,177,255) !important;
}

.mdl-color--blue-A100 {
  background-color: rgb(130,177,255) !important;
}

.mdl-color-text--blue-A200 {
  color: rgb(68,138,255) !important;
}

.mdl-color--blue-A200 {
  background-color: rgb(68,138,255) !important;
}

.mdl-color-text--blue-A400 {
  color: rgb(41,121,255) !important;
}

.mdl-color--blue-A400 {
  background-color: rgb(41,121,255) !important;
}

.mdl-color-text--blue-A700 {
  color: rgb(41,98,255) !important;
}

.mdl-color--blue-A700 {
  background-color: rgb(41,98,255) !important;
}

.mdl-color-text--light-blue {
  color: rgb(3,169,244) !important;
}

.mdl-color--light-blue {
  background-color: rgb(3,169,244) !important;
}

.mdl-color-text--light-blue-50 {
  color: rgb(225,245,254) !important;
}

.mdl-color--light-blue-50 {
  background-color: rgb(225,245,254) !important;
}

.mdl-color-text--light-blue-100 {
  color: rgb(179,229,252) !important;
}

.mdl-color--light-blue-100 {
  background-color: rgb(179,229,252) !important;
}

.mdl-color-text--light-blue-200 {
  color: rgb(129,212,250) !important;
}

.mdl-color--light-blue-200 {
  background-color: rgb(129,212,250) !important;
}

.mdl-color-text--light-blue-300 {
  color: rgb(79,195,247) !important;
}

.mdl-color--light-blue-300 {
  background-color: rgb(79,195,247) !important;
}

.mdl-color-text--light-blue-400 {
  color: rgb(41,182,246) !important;
}

.mdl-color--light-blue-400 {
  background-color: rgb(41,182,246) !important;
}

.mdl-color-text--light-blue-500 {
  color: rgb(3,169,244) !important;
}

.mdl-color--light-blue-500 {
  background-color: rgb(3,169,244) !important;
}

.mdl-color-text--light-blue-600 {
  color: rgb(3,155,229) !important;
}

.mdl-color--light-blue-600 {
  background-color: rgb(3,155,229) !important;
}

.mdl-color-text--light-blue-700 {
  color: rgb(2,136,209) !important;
}

.mdl-color--light-blue-700 {
  background-color: rgb(2,136,209) !important;
}

.mdl-color-text--light-blue-800 {
  color: rgb(2,119,189) !important;
}

.mdl-color--light-blue-800 {
  background-color: rgb(2,119,189) !important;
}

.mdl-color-text--light-blue-900 {
  color: rgb(1,87,155) !important;
}

.mdl-color--light-blue-900 {
  background-color: rgb(1,87,155) !important;
}

.mdl-color-text--light-blue-A100 {
  color: rgb(128,216,255) !important;
}

.mdl-color--light-blue-A100 {
  background-color: rgb(128,216,255) !important;
}

.mdl-color-text--light-blue-A200 {
  color: rgb(64,196,255) !important;
}

.mdl-color--light-blue-A200 {
  background-color: rgb(64,196,255) !important;
}

.mdl-color-text--light-blue-A400 {
  color: rgb(0,176,255) !important;
}

.mdl-color--light-blue-A400 {
  background-color: rgb(0,176,255) !important;
}

.mdl-color-text--light-blue-A700 {
  color: rgb(0,145,234) !important;
}

.mdl-color--light-blue-A700 {
  background-color: rgb(0,145,234) !important;
}

.mdl-color-text--cyan {
  color: rgb(0,188,212) !important;
}

.mdl-color--cyan {
  background-color: rgb(0,188,212) !important;
}

.mdl-color-text--cyan-50 {
  color: rgb(224,247,250) !important;
}

.mdl-color--cyan-50 {
  background-color: rgb(224,247,250) !important;
}

.mdl-color-text--cyan-100 {
  color: rgb(178,235,242) !important;
}

.mdl-color--cyan-100 {
  background-color: rgb(178,235,242) !important;
}

.mdl-color-text--cyan-200 {
  color: rgb(128,222,234) !important;
}

.mdl-color--cyan-200 {
  background-color: rgb(128,222,234) !important;
}

.mdl-color-text--cyan-300 {
  color: rgb(77,208,225) !important;
}

.mdl-color--cyan-300 {
  background-color: rgb(77,208,225) !important;
}

.mdl-color-text--cyan-400 {
  color: rgb(38,198,218) !important;
}

.mdl-color--cyan-400 {
  background-color: rgb(38,198,218) !important;
}

.mdl-color-text--cyan-500 {
  color: rgb(0,188,212) !important;
}

.mdl-color--cyan-500 {
  background-color: rgb(0,188,212) !important;
}

.mdl-color-text--cyan-600 {
  color: rgb(0,172,193) !important;
}

.mdl-color--cyan-600 {
  background-color: rgb(0,172,193) !important;
}

.mdl-color-text--cyan-700 {
  color: rgb(0,151,167) !important;
}

.mdl-color--cyan-700 {
  background-color: rgb(0,151,167) !important;
}

.mdl-color-text--cyan-800 {
  color: rgb(0,131,143) !important;
}

.mdl-color--cyan-800 {
  background-color: rgb(0,131,143) !important;
}

.mdl-color-text--cyan-900 {
  color: rgb(0,96,100) !important;
}

.mdl-color--cyan-900 {
  background-color: rgb(0,96,100) !important;
}

.mdl-color-text--cyan-A100 {
  color: rgb(132,255,255) !important;
}

.mdl-color--cyan-A100 {
  background-color: rgb(132,255,255) !important;
}

.mdl-color-text--cyan-A200 {
  color: rgb(24,255,255) !important;
}

.mdl-color--cyan-A200 {
  background-color: rgb(24,255,255) !important;
}

.mdl-color-text--cyan-A400 {
  color: rgb(0,229,255) !important;
}

.mdl-color--cyan-A400 {
  background-color: rgb(0,229,255) !important;
}

.mdl-color-text--cyan-A700 {
  color: rgb(0,184,212) !important;
}

.mdl-color--cyan-A700 {
  background-color: rgb(0,184,212) !important;
}

.mdl-color-text--teal {
  color: rgb(0,150,136) !important;
}

.mdl-color--teal {
  background-color: rgb(0,150,136) !important;
}

.mdl-color-text--teal-50 {
  color: rgb(224,242,241) !important;
}

.mdl-color--teal-50 {
  background-color: rgb(224,242,241) !important;
}

.mdl-color-text--teal-100 {
  color: rgb(178,223,219) !important;
}

.mdl-color--teal-100 {
  background-color: rgb(178,223,219) !important;
}

.mdl-color-text--teal-200 {
  color: rgb(128,203,196) !important;
}

.mdl-color--teal-200 {
  background-color: rgb(128,203,196) !important;
}

.mdl-color-text--teal-300 {
  color: rgb(77,182,172) !important;
}

.mdl-color--teal-300 {
  background-color: rgb(77,182,172) !important;
}

.mdl-color-text--teal-400 {
  color: rgb(38,166,154) !important;
}

.mdl-color--teal-400 {
  background-color: rgb(38,166,154) !important;
}

.mdl-color-text--teal-500 {
  color: rgb(0,150,136) !important;
}

.mdl-color--teal-500 {
  background-color: rgb(0,150,136) !important;
}

.mdl-color-text--teal-600 {
  color: rgb(0,137,123) !important;
}

.mdl-color--teal-600 {
  background-color: rgb(0,137,123) !important;
}

.mdl-color-text--teal-700 {
  color: rgb(0,121,107) !important;
}

.mdl-color--teal-700 {
  background-color: rgb(0,121,107) !important;
}

.mdl-color-text--teal-800 {
  color: rgb(0,105,92) !important;
}

.mdl-color--teal-800 {
  background-color: rgb(0,105,92) !important;
}

.mdl-color-text--teal-900 {
  color: rgb(0,77,64) !important;
}

.mdl-color--teal-900 {
  background-color: rgb(0,77,64) !important;
}

.mdl-color-text--teal-A100 {
  color: rgb(167,255,235) !important;
}

.mdl-color--teal-A100 {
  background-color: rgb(167,255,235) !important;
}

.mdl-color-text--teal-A200 {
  color: rgb(100,255,218) !important;
}

.mdl-color--teal-A200 {
  background-color: rgb(100,255,218) !important;
}

.mdl-color-text--teal-A400 {
  color: rgb(29,233,182) !important;
}

.mdl-color--teal-A400 {
  background-color: rgb(29,233,182) !important;
}

.mdl-color-text--teal-A700 {
  color: rgb(0,191,165) !important;
}

.mdl-color--teal-A700 {
  background-color: rgb(0,191,165) !important;
}

.mdl-color-text--green {
  color: rgb(76,175,80) !important;
}

.mdl-color--green {
  background-color: rgb(76,175,80) !important;
}

.mdl-color-text--green-50 {
  color: rgb(232,245,233) !important;
}

.mdl-color--green-50 {
  background-color: rgb(232,245,233) !important;
}

.mdl-color-text--green-100 {
  color: rgb(200,230,201) !important;
}

.mdl-color--green-100 {
  background-color: rgb(200,230,201) !important;
}

.mdl-color-text--green-200 {
  color: rgb(165,214,167) !important;
}

.mdl-color--green-200 {
  background-color: rgb(165,214,167) !important;
}

.mdl-color-text--green-300 {
  color: rgb(129,199,132) !important;
}

.mdl-color--green-300 {
  background-color: rgb(129,199,132) !important;
}

.mdl-color-text--green-400 {
  color: rgb(102,187,106) !important;
}

.mdl-color--green-400 {
  background-color: rgb(102,187,106) !important;
}

.mdl-color-text--green-500 {
  color: rgb(76,175,80) !important;
}

.mdl-color--green-500 {
  background-color: rgb(76,175,80) !important;
}

.mdl-color-text--green-600 {
  color: rgb(67,160,71) !important;
}

.mdl-color--green-600 {
  background-color: rgb(67,160,71) !important;
}

.mdl-color-text--green-700 {
  color: rgb(56,142,60) !important;
}

.mdl-color--green-700 {
  background-color: rgb(56,142,60) !important;
}

.mdl-color-text--green-800 {
  color: rgb(46,125,50) !important;
}

.mdl-color--green-800 {
  background-color: rgb(46,125,50) !important;
}

.mdl-color-text--green-900 {
  color: rgb(27,94,32) !important;
}

.mdl-color--green-900 {
  background-color: rgb(27,94,32) !important;
}

.mdl-color-text--green-A100 {
  color: rgb(185,246,202) !important;
}

.mdl-color--green-A100 {
  background-color: rgb(185,246,202) !important;
}

.mdl-color-text--green-A200 {
  color: rgb(105,240,174) !important;
}

.mdl-color--green-A200 {
  background-color: rgb(105,240,174) !important;
}

.mdl-color-text--green-A400 {
  color: rgb(0,230,118) !important;
}

.mdl-color--green-A400 {
  background-color: rgb(0,230,118) !important;
}

.mdl-color-text--green-A700 {
  color: rgb(0,200,83) !important;
}

.mdl-color--green-A700 {
  background-color: rgb(0,200,83) !important;
}

.mdl-color-text--light-green {
  color: rgb(139,195,74) !important;
}

.mdl-color--light-green {
  background-color: rgb(139,195,74) !important;
}

.mdl-color-text--light-green-50 {
  color: rgb(241,248,233) !important;
}

.mdl-color--light-green-50 {
  background-color: rgb(241,248,233) !important;
}

.mdl-color-text--light-green-100 {
  color: rgb(220,237,200) !important;
}

.mdl-color--light-green-100 {
  background-color: rgb(220,237,200) !important;
}

.mdl-color-text--light-green-200 {
  color: rgb(197,225,165) !important;
}

.mdl-color--light-green-200 {
  background-color: rgb(197,225,165) !important;
}

.mdl-color-text--light-green-300 {
  color: rgb(174,213,129) !important;
}

.mdl-color--light-green-300 {
  background-color: rgb(174,213,129) !important;
}

.mdl-color-text--light-green-400 {
  color: rgb(156,204,101) !important;
}

.mdl-color--light-green-400 {
  background-color: rgb(156,204,101) !important;
}

.mdl-color-text--light-green-500 {
  color: rgb(139,195,74) !important;
}

.mdl-color--light-green-500 {
  background-color: rgb(139,195,74) !important;
}

.mdl-color-text--light-green-600 {
  color: rgb(124,179,66) !important;
}

.mdl-color--light-green-600 {
  background-color: rgb(124,179,66) !important;
}

.mdl-color-text--light-green-700 {
  color: rgb(104,159,56) !important;
}

.mdl-color--light-green-700 {
  background-color: rgb(104,159,56) !important;
}

.mdl-color-text--light-green-800 {
  color: rgb(85,139,47) !important;
}

.mdl-color--light-green-800 {
  background-color: rgb(85,139,47) !important;
}

.mdl-color-text--light-green-900 {
  color: rgb(51,105,30) !important;
}

.mdl-color--light-green-900 {
  background-color: rgb(51,105,30) !important;
}

.mdl-color-text--light-green-A100 {
  color: rgb(204,255,144) !important;
}

.mdl-color--light-green-A100 {
  background-color: rgb(204,255,144) !important;
}

.mdl-color-text--light-green-A200 {
  color: rgb(178,255,89) !important;
}

.mdl-color--light-green-A200 {
  background-color: rgb(178,255,89) !important;
}

.mdl-color-text--light-green-A400 {
  color: rgb(118,255,3) !important;
}

.mdl-color--light-green-A400 {
  background-color: rgb(118,255,3) !important;
}

.mdl-color-text--light-green-A700 {
  color: rgb(100,221,23) !important;
}

.mdl-color--light-green-A700 {
  background-color: rgb(100,221,23) !important;
}

.mdl-color-text--lime {
  color: rgb(205,220,57) !important;
}

.mdl-color--lime {
  background-color: rgb(205,220,57) !important;
}

.mdl-color-text--lime-50 {
  color: rgb(249,251,231) !important;
}

.mdl-color--lime-50 {
  background-color: rgb(249,251,231) !important;
}

.mdl-color-text--lime-100 {
  color: rgb(240,244,195) !important;
}

.mdl-color--lime-100 {
  background-color: rgb(240,244,195) !important;
}

.mdl-color-text--lime-200 {
  color: rgb(230,238,156) !important;
}

.mdl-color--lime-200 {
  background-color: rgb(230,238,156) !important;
}

.mdl-color-text--lime-300 {
  color: rgb(220,231,117) !important;
}

.mdl-color--lime-300 {
  background-color: rgb(220,231,117) !important;
}

.mdl-color-text--lime-400 {
  color: rgb(212,225,87) !important;
}

.mdl-color--lime-400 {
  background-color: rgb(212,225,87) !important;
}

.mdl-color-text--lime-500 {
  color: rgb(205,220,57) !important;
}

.mdl-color--lime-500 {
  background-color: rgb(205,220,57) !important;
}

.mdl-color-text--lime-600 {
  color: rgb(192,202,51) !important;
}

.mdl-color--lime-600 {
  background-color: rgb(192,202,51) !important;
}

.mdl-color-text--lime-700 {
  color: rgb(175,180,43) !important;
}

.mdl-color--lime-700 {
  background-color: rgb(175,180,43) !important;
}

.mdl-color-text--lime-800 {
  color: rgb(158,157,36) !important;
}

.mdl-color--lime-800 {
  background-color: rgb(158,157,36) !important;
}

.mdl-color-text--lime-900 {
  color: rgb(130,119,23) !important;
}

.mdl-color--lime-900 {
  background-color: rgb(130,119,23) !important;
}

.mdl-color-text--lime-A100 {
  color: rgb(244,255,129) !important;
}

.mdl-color--lime-A100 {
  background-color: rgb(244,255,129) !important;
}

.mdl-color-text--lime-A200 {
  color: rgb(238,255,65) !important;
}

.mdl-color--lime-A200 {
  background-color: rgb(238,255,65) !important;
}

.mdl-color-text--lime-A400 {
  color: rgb(198,255,0) !important;
}

.mdl-color--lime-A400 {
  background-color: rgb(198,255,0) !important;
}

.mdl-color-text--lime-A700 {
  color: rgb(174,234,0) !important;
}

.mdl-color--lime-A700 {
  background-color: rgb(174,234,0) !important;
}

.mdl-color-text--yellow {
  color: rgb(255,235,59) !important;
}

.mdl-color--yellow {
  background-color: rgb(255,235,59) !important;
}

.mdl-color-text--yellow-50 {
  color: rgb(255,253,231) !important;
}

.mdl-color--yellow-50 {
  background-color: rgb(255,253,231) !important;
}

.mdl-color-text--yellow-100 {
  color: rgb(255,249,196) !important;
}

.mdl-color--yellow-100 {
  background-color: rgb(255,249,196) !important;
}

.mdl-color-text--yellow-200 {
  color: rgb(255,245,157) !important;
}

.mdl-color--yellow-200 {
  background-color: rgb(255,245,157) !important;
}

.mdl-color-text--yellow-300 {
  color: rgb(255,241,118) !important;
}

.mdl-color--yellow-300 {
  background-color: rgb(255,241,118) !important;
}

.mdl-color-text--yellow-400 {
  color: rgb(255,238,88) !important;
}

.mdl-color--yellow-400 {
  background-color: rgb(255,238,88) !important;
}

.mdl-color-text--yellow-500 {
  color: rgb(255,235,59) !important;
}

.mdl-color--yellow-500 {
  background-color: rgb(255,235,59) !important;
}

.mdl-color-text--yellow-600 {
  color: rgb(253,216,53) !important;
}

.mdl-color--yellow-600 {
  background-color: rgb(253,216,53) !important;
}

.mdl-color-text--yellow-700 {
  color: rgb(251,192,45) !important;
}

.mdl-color--yellow-700 {
  background-color: rgb(251,192,45) !important;
}

.mdl-color-text--yellow-800 {
  color: rgb(249,168,37) !important;
}

.mdl-color--yellow-800 {
  background-color: rgb(249,168,37) !important;
}

.mdl-color-text--yellow-900 {
  color: rgb(245,127,23) !important;
}

.mdl-color--yellow-900 {
  background-color: rgb(245,127,23) !important;
}

.mdl-color-text--yellow-A100 {
  color: rgb(255,255,141) !important;
}

.mdl-color--yellow-A100 {
  background-color: rgb(255,255,141) !important;
}

.mdl-color-text--yellow-A200 {
  color: rgb(255,255,0) !important;
}

.mdl-color--yellow-A200 {
  background-color: rgb(255,255,0) !important;
}

.mdl-color-text--yellow-A400 {
  color: rgb(255,234,0) !important;
}

.mdl-color--yellow-A400 {
  background-color: rgb(255,234,0) !important;
}

.mdl-color-text--yellow-A700 {
  color: rgb(255,214,0) !important;
}

.mdl-color--yellow-A700 {
  background-color: rgb(255,214,0) !important;
}

.mdl-color-text--amber {
  color: rgb(255,193,7) !important;
}

.mdl-color--amber {
  background-color: rgb(255,193,7) !important;
}

.mdl-color-text--amber-50 {
  color: rgb(255,248,225) !important;
}

.mdl-color--amber-50 {
  background-color: rgb(255,248,225) !important;
}

.mdl-color-text--amber-100 {
  color: rgb(255,236,179) !important;
}

.mdl-color--amber-100 {
  background-color: rgb(255,236,179) !important;
}

.mdl-color-text--amber-200 {
  color: rgb(255,224,130) !important;
}

.mdl-color--amber-200 {
  background-color: rgb(255,224,130) !important;
}

.mdl-color-text--amber-300 {
  color: rgb(255,213,79) !important;
}

.mdl-color--amber-300 {
  background-color: rgb(255,213,79) !important;
}

.mdl-color-text--amber-400 {
  color: rgb(255,202,40) !important;
}

.mdl-color--amber-400 {
  background-color: rgb(255,202,40) !important;
}

.mdl-color-text--amber-500 {
  color: rgb(255,193,7) !important;
}

.mdl-color--amber-500 {
  background-color: rgb(255,193,7) !important;
}

.mdl-color-text--amber-600 {
  color: rgb(255,179,0) !important;
}

.mdl-color--amber-600 {
  background-color: rgb(255,179,0) !important;
}

.mdl-color-text--amber-700 {
  color: rgb(255,160,0) !important;
}

.mdl-color--amber-700 {
  background-color: rgb(255,160,0) !important;
}

.mdl-color-text--amber-800 {
  color: rgb(255,143,0) !important;
}

.mdl-color--amber-800 {
  background-color: rgb(255,143,0) !important;
}

.mdl-color-text--amber-900 {
  color: rgb(255,111,0) !important;
}

.mdl-color--amber-900 {
  background-color: rgb(255,111,0) !important;
}

.mdl-color-text--amber-A100 {
  color: rgb(255,229,127) !important;
}

.mdl-color--amber-A100 {
  background-color: rgb(255,229,127) !important;
}

.mdl-color-text--amber-A200 {
  color: rgb(255,215,64) !important;
}

.mdl-color--amber-A200 {
  background-color: rgb(255,215,64) !important;
}

.mdl-color-text--amber-A400 {
  color: rgb(255,196,0) !important;
}

.mdl-color--amber-A400 {
  background-color: rgb(255,196,0) !important;
}

.mdl-color-text--amber-A700 {
  color: rgb(255,171,0) !important;
}

.mdl-color--amber-A700 {
  background-color: rgb(255,171,0) !important;
}

.mdl-color-text--orange {
  color: rgb(255,152,0) !important;
}

.mdl-color--orange {
  background-color: rgb(255,152,0) !important;
}

.mdl-color-text--orange-50 {
  color: rgb(255,243,224) !important;
}

.mdl-color--orange-50 {
  background-color: rgb(255,243,224) !important;
}

.mdl-color-text--orange-100 {
  color: rgb(255,224,178) !important;
}

.mdl-color--orange-100 {
  background-color: rgb(255,224,178) !important;
}

.mdl-color-text--orange-200 {
  color: rgb(255,204,128) !important;
}

.mdl-color--orange-200 {
  background-color: rgb(255,204,128) !important;
}

.mdl-color-text--orange-300 {
  color: rgb(255,183,77) !important;
}

.mdl-color--orange-300 {
  background-color: rgb(255,183,77) !important;
}

.mdl-color-text--orange-400 {
  color: rgb(255,167,38) !important;
}

.mdl-color--orange-400 {
  background-color: rgb(255,167,38) !important;
}

.mdl-color-text--orange-500 {
  color: rgb(255,152,0) !important;
}

.mdl-color--orange-500 {
  background-color: rgb(255,152,0) !important;
}

.mdl-color-text--orange-600 {
  color: rgb(251,140,0) !important;
}

.mdl-color--orange-600 {
  background-color: rgb(251,140,0) !important;
}

.mdl-color-text--orange-700 {
  color: rgb(245,124,0) !important;
}

.mdl-color--orange-700 {
  background-color: rgb(245,124,0) !important;
}

.mdl-color-text--orange-800 {
  color: rgb(239,108,0) !important;
}

.mdl-color--orange-800 {
  background-color: rgb(239,108,0) !important;
}

.mdl-color-text--orange-900 {
  color: rgb(230,81,0) !important;
}

.mdl-color--orange-900 {
  background-color: rgb(230,81,0) !important;
}

.mdl-color-text--orange-A100 {
  color: rgb(255,209,128) !important;
}

.mdl-color--orange-A100 {
  background-color: rgb(255,209,128) !important;
}

.mdl-color-text--orange-A200 {
  color: rgb(255,171,64) !important;
}

.mdl-color--orange-A200 {
  background-color: rgb(255,171,64) !important;
}

.mdl-color-text--orange-A400 {
  color: rgb(255,145,0) !important;
}

.mdl-color--orange-A400 {
  background-color: rgb(255,145,0) !important;
}

.mdl-color-text--orange-A700 {
  color: rgb(255,109,0) !important;
}

.mdl-color--orange-A700 {
  background-color: rgb(255,109,0) !important;
}

.mdl-color-text--deep-orange {
  color: rgb(255,87,34) !important;
}

.mdl-color--deep-orange {
  background-color: rgb(255,87,34) !important;
}

.mdl-color-text--deep-orange-50 {
  color: rgb(251,233,231) !important;
}

.mdl-color--deep-orange-50 {
  background-color: rgb(251,233,231) !important;
}

.mdl-color-text--deep-orange-100 {
  color: rgb(255,204,188) !important;
}

.mdl-color--deep-orange-100 {
  background-color: rgb(255,204,188) !important;
}

.mdl-color-text--deep-orange-200 {
  color: rgb(255,171,145) !important;
}

.mdl-color--deep-orange-200 {
  background-color: rgb(255,171,145) !important;
}

.mdl-color-text--deep-orange-300 {
  color: rgb(255,138,101) !important;
}

.mdl-color--deep-orange-300 {
  background-color: rgb(255,138,101) !important;
}

.mdl-color-text--deep-orange-400 {
  color: rgb(255,112,67) !important;
}

.mdl-color--deep-orange-400 {
  background-color: rgb(255,112,67) !important;
}

.mdl-color-text--deep-orange-500 {
  color: rgb(255,87,34) !important;
}

.mdl-color--deep-orange-500 {
  background-color: rgb(255,87,34) !important;
}

.mdl-color-text--deep-orange-600 {
  color: rgb(244,81,30) !important;
}

.mdl-color--deep-orange-600 {
  background-color: rgb(244,81,30) !important;
}

.mdl-color-text--deep-orange-700 {
  color: rgb(230,74,25) !important;
}

.mdl-color--deep-orange-700 {
  background-color: rgb(230,74,25) !important;
}

.mdl-color-text--deep-orange-800 {
  color: rgb(216,67,21) !important;
}

.mdl-color--deep-orange-800 {
  background-color: rgb(216,67,21) !important;
}

.mdl-color-text--deep-orange-900 {
  color: rgb(191,54,12) !important;
}

.mdl-color--deep-orange-900 {
  background-color: rgb(191,54,12) !important;
}

.mdl-color-text--deep-orange-A100 {
  color: rgb(255,158,128) !important;
}

.mdl-color--deep-orange-A100 {
  background-color: rgb(255,158,128) !important;
}

.mdl-color-text--deep-orange-A200 {
  color: rgb(255,110,64) !important;
}

.mdl-color--deep-orange-A200 {
  background-color: rgb(255,110,64) !important;
}

.mdl-color-text--deep-orange-A400 {
  color: rgb(255,61,0) !important;
}

.mdl-color--deep-orange-A400 {
  background-color: rgb(255,61,0) !important;
}

.mdl-color-text--deep-orange-A700 {
  color: rgb(221,44,0) !important;
}

.mdl-color--deep-orange-A700 {
  background-color: rgb(221,44,0) !important;
}

.mdl-color-text--brown {
  color: rgb(121,85,72) !important;
}

.mdl-color--brown {
  background-color: rgb(121,85,72) !important;
}

.mdl-color-text--brown-50 {
  color: rgb(239,235,233) !important;
}

.mdl-color--brown-50 {
  background-color: rgb(239,235,233) !important;
}

.mdl-color-text--brown-100 {
  color: rgb(215,204,200) !important;
}

.mdl-color--brown-100 {
  background-color: rgb(215,204,200) !important;
}

.mdl-color-text--brown-200 {
  color: rgb(188,170,164) !important;
}

.mdl-color--brown-200 {
  background-color: rgb(188,170,164) !important;
}

.mdl-color-text--brown-300 {
  color: rgb(161,136,127) !important;
}

.mdl-color--brown-300 {
  background-color: rgb(161,136,127) !important;
}

.mdl-color-text--brown-400 {
  color: rgb(141,110,99) !important;
}

.mdl-color--brown-400 {
  background-color: rgb(141,110,99) !important;
}

.mdl-color-text--brown-500 {
  color: rgb(121,85,72) !important;
}

.mdl-color--brown-500 {
  background-color: rgb(121,85,72) !important;
}

.mdl-color-text--brown-600 {
  color: rgb(109,76,65) !important;
}

.mdl-color--brown-600 {
  background-color: rgb(109,76,65) !important;
}

.mdl-color-text--brown-700 {
  color: rgb(93,64,55) !important;
}

.mdl-color--brown-700 {
  background-color: rgb(93,64,55) !important;
}

.mdl-color-text--brown-800 {
  color: rgb(78,52,46) !important;
}

.mdl-color--brown-800 {
  background-color: rgb(78,52,46) !important;
}

.mdl-color-text--brown-900 {
  color: rgb(62,39,35) !important;
}

.mdl-color--brown-900 {
  background-color: rgb(62,39,35) !important;
}

.mdl-color-text--grey {
  color: rgb(158,158,158) !important;
}

.mdl-color--grey {
  background-color: rgb(158,158,158) !important;
}

.mdl-color-text--grey-50 {
  color: rgb(250,250,250) !important;
}

.mdl-color--grey-50 {
  background-color: rgb(250,250,250) !important;
}

.mdl-color-text--grey-100 {
  color: rgb(245,245,245) !important;
}

.mdl-color--grey-100 {
  background-color: rgb(245,245,245) !important;
}

.mdl-color-text--grey-200 {
  color: rgb(238,238,238) !important;
}

.mdl-color--grey-200 {
  background-color: rgb(238,238,238) !important;
}

.mdl-color-text--grey-300 {
  color: rgb(224,224,224) !important;
}

.mdl-color--grey-300 {
  background-color: rgb(224,224,224) !important;
}

.mdl-color-text--grey-400 {
  color: rgb(189,189,189) !important;
}

.mdl-color--grey-400 {
  background-color: rgb(189,189,189) !important;
}

.mdl-color-text--grey-500 {
  color: rgb(158,158,158) !important;
}

.mdl-color--grey-500 {
  background-color: rgb(158,158,158) !important;
}

.mdl-color-text--grey-600 {
  color: rgb(117,117,117) !important;
}

.mdl-color--grey-600 {
  background-color: rgb(117,117,117) !important;
}

.mdl-color-text--grey-700 {
  color: rgb(97,97,97) !important;
}

.mdl-color--grey-700 {
  background-color: rgb(97,97,97) !important;
}

.mdl-color-text--grey-800 {
  color: rgb(66,66,66) !important;
}

.mdl-color--grey-800 {
  background-color: rgb(66,66,66) !important;
}

.mdl-color-text--grey-900 {
  color: rgb(33,33,33) !important;
}

.mdl-color--grey-900 {
  background-color: rgb(33,33,33) !important;
}

.mdl-color-text--blue-grey {
  color: rgb(96,125,139) !important;
}

.mdl-color--blue-grey {
  background-color: rgb(96,125,139) !important;
}

.mdl-color-text--blue-grey-50 {
  color: rgb(236,239,241) !important;
}

.mdl-color--blue-grey-50 {
  background-color: rgb(236,239,241) !important;
}

.mdl-color-text--blue-grey-100 {
  color: rgb(207,216,220) !important;
}

.mdl-color--blue-grey-100 {
  background-color: rgb(207,216,220) !important;
}

.mdl-color-text--blue-grey-200 {
  color: rgb(176,190,197) !important;
}

.mdl-color--blue-grey-200 {
  background-color: rgb(176,190,197) !important;
}

.mdl-color-text--blue-grey-300 {
  color: rgb(144,164,174) !important;
}

.mdl-color--blue-grey-300 {
  background-color: rgb(144,164,174) !important;
}

.mdl-color-text--blue-grey-400 {
  color: rgb(120,144,156) !important;
}

.mdl-color--blue-grey-400 {
  background-color: rgb(120,144,156) !important;
}

.mdl-color-text--blue-grey-500 {
  color: rgb(96,125,139) !important;
}

.mdl-color--blue-grey-500 {
  background-color: rgb(96,125,139) !important;
}

.mdl-color-text--blue-grey-600 {
  color: rgb(84,110,122) !important;
}

.mdl-color--blue-grey-600 {
  background-color: rgb(84,110,122) !important;
}

.mdl-color-text--blue-grey-700 {
  color: rgb(69,90,100) !important;
}

.mdl-color--blue-grey-700 {
  background-color: rgb(69,90,100) !important;
}

.mdl-color-text--blue-grey-800 {
  color: rgb(55,71,79) !important;
}

.mdl-color--blue-grey-800 {
  background-color: rgb(55,71,79) !important;
}

.mdl-color-text--blue-grey-900 {
  color: rgb(38,50,56) !important;
}

.mdl-color--blue-grey-900 {
  background-color: rgb(38,50,56) !important;
}

.mdl-color--black {
  background-color: rgb(0,0,0) !important;
}

.mdl-color-text--black {
  color: rgb(0,0,0) !important;
}

.mdl-color--white {
  background-color: rgb(255,255,255) !important;
}

.mdl-color-text--white {
  color: rgb(255,255,255) !important;
}

.mdl-color--primary {
  background-color: rgb(34,34,34) !important;
}

.mdl-color--primary-contrast {
  background-color: rgb(255,255,255) !important;
}

.mdl-color--primary-dark {
  background-color: rgb(48,63,159) !important;
}

.mdl-color--accent {
  background-color: rgb(255,64,129) !important;
}

.mdl-color--accent-contrast {
  background-color: rgb(255,255,255) !important;
}

.mdl-color-text--primary {
  color: rgb(34,34,34) !important;
}

.mdl-color-text--primary-contrast {
  color: rgb(255,255,255) !important;
}

.mdl-color-text--primary-dark {
  color: rgb(48,63,159) !important;
}

.mdl-color-text--accent {
  color: rgb(255,64,129) !important;
}

.mdl-color-text--accent-contrast {
  color: rgb(255,255,255) !important;
}

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* TOOLTIP */
.mdl-progress {
  display: block;
  position: relative;
  height: 4px;
  width: 500px;
}

.mdl-progress > .bar {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 0%;
  transition: width 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.mdl-progress > .progressbar {
  background-color: rgb(34,34,34);
  z-index: 1;
  left: 0;
}

.mdl-progress > .bufferbar {
  background-image: linear-gradient(to right, rgba(255,255,255, 0.7), rgba(255,255,255, 0.7)), linear-gradient(to right, rgb(34,34,34), rgb(34,34,34));
  z-index: 0;
  left: 0;
}

.mdl-progress > .auxbar {
  right: 0;
}

@supports (-webkit-appearance: none) {
  .mdl-progress:not(.mdl-progress__indeterminate):not(.mdl-progress__indeterminate) > .auxbar {
    background-image: linear-gradient(to right, rgba(255,255,255, 0.7), rgba(255,255,255, 0.7)), linear-gradient(to right, rgb(34,34,34), rgb(34,34,34));
    mask: url("/images/buffer.svg?embed");
  }
}

.mdl-progress:not(.mdl-progress__indeterminate) > .auxbar {
  background-image: linear-gradient(to right, rgba(255,255,255, 0.9), rgba(255,255,255, 0.9)), linear-gradient(to right, rgb(34,34,34), rgb(34,34,34));
}

.mdl-progress.mdl-progress__indeterminate > .bar1 {
  background-color: rgb(34,34,34);
  animation-name: indeterminate1;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.mdl-progress.mdl-progress__indeterminate > .bar3 {
  background-image: none;
  background-color: rgb(34,34,34);
  animation-name: indeterminate2;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes indeterminate1 {
  0% {
    left: 0%;
    width: 0%;
  }
  50% {
    left: 25%;
    width: 75%;
  }
  75% {
    left: 100%;
    width: 0%;
  }
}

@keyframes indeterminate2 {
  0% {
    left: 0%;
    width: 0%;
  }
  50% {
    left: 0%;
    width: 0%;
  }
  75% {
    left: 0%;
    width: 25%;
  }
  100% {
    left: 100%;
    width: 0%;
  }
}

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* TOOLTIP */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Typography */
/* Shadows */
/* Animations */
.mdl-radio {
  position: relative;
  font-size: 16px;
  line-height: 24px;
  display: inline-block;
  box-sizing: border-box;
  margin: 0;
  padding-left: 0;
}

.mdl-radio.is-upgraded {
  padding-left: 24px;
}

.mdl-radio__button {
  line-height: 24px;
}

.mdl-radio.is-upgraded .mdl-radio__button {
  position: absolute;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
  opacity: 0;
  -ms-appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: none;
}

.mdl-radio__outer-circle {
  position: absolute;
  top: 4px;
  left: 0;
  display: inline-block;
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  margin: 0;
  cursor: pointer;
  border: 2px solid rgba(0,0,0, 0.54);
  border-radius: 50%;
  z-index: 2;
}

.mdl-radio.is-checked .mdl-radio__outer-circle {
  border: 2px solid rgb(34,34,34);
}

.mdl-radio.is-disabled .mdl-radio__outer-circle {
  border: 2px solid rgba(0,0,0, 0.26);
  cursor: auto;
}

.mdl-radio__inner-circle {
  position: absolute;
  z-index: 1;
  margin: 0;
  top: 8px;
  left: 4px;
  box-sizing: border-box;
  width: 8px;
  height: 8px;
  cursor: pointer;
  transition-duration: 0.28s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: transform;
  transform: scale3d(0, 0, 0);
  border-radius: 50%;
  background: rgb(34,34,34);
}

.mdl-radio.is-checked .mdl-radio__inner-circle {
  transform: scale3d(1, 1, 1);
}

.mdl-radio.is-disabled .mdl-radio__inner-circle {
  background: rgba(0,0,0, 0.26);
  cursor: auto;
}

.mdl-radio.is-focused .mdl-radio__inner-circle {
  box-shadow: 0 0 0px 10px rgba(0, 0, 0, 0.1);
}

.mdl-radio__label {
  cursor: pointer;
}

.mdl-radio.is-disabled .mdl-radio__label {
  color: rgba(0,0,0, 0.26);
  cursor: auto;
}

.mdl-radio__ripple-container {
  position: absolute;
  z-index: 2;
  top: -9px;
  left: -13px;
  box-sizing: border-box;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  cursor: pointer;
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(circle, white, black);
}

.mdl-radio__ripple-container .mdl-ripple {
  background: rgb(34,34,34);
}

.mdl-radio.is-disabled .mdl-radio__ripple-container {
  cursor: auto;
}

.mdl-radio.is-disabled .mdl-radio__ripple-container .mdl-ripple {
  background: transparent;
}

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* TOOLTIP */
/*
 * What follows is the result of much research on cross-browser styling.
 * Credit left inline and big thanks to Nicolas Gallagher, Jonathan Neal,
 * Kroc Camen, and the H5BP dev community and team.
 */
/* ==========================================================================
   Base styles: opinionated defaults
   ========================================================================== */
html {
  color: rgba(0,0,0, 0.87);
  font-size: 1em;
  line-height: 1.4;
}

/*
 * Remove text-shadow in selection highlight:
 * https://twitter.com/miketaylr/status/12228805301
 *
 * These selection rule sets have to be separate.
 * Customize the background color to match your design.
 */
::selection {
  background: #b3d4fc;
  text-shadow: none;
}

/*
 * A better looking default horizontal rule
 */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

/*
 * Remove the gap between audio, canvas, iframes,
 * images, videos and the bottom of their containers:
 * https://github.com/h5bp/html5-boilerplate/issues/440
 */
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

/*
 * Remove default fieldset styles.
 */
fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

/*
 * Allow only vertical resizing of textareas.
 */
textarea {
  resize: vertical;
}

/* ==========================================================================
   Browser Upgrade Prompt
   ========================================================================== */
.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

/* ==========================================================================
   Author's custom styles
   ========================================================================== */
/* ==========================================================================
   Helper classes
   ========================================================================== */
/*
 * Hide visually and from screen readers:
 */
.hidden {
  display: none !important;
}

/*
 * Hide only visually, but have it available for screen readers:
 * http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */
.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

/*
 * Extends the .visuallyhidden class to allow the element
 * to be focusable when navigated to via the keyboard:
 * https://www.drupal.org/node/897638
 */
.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

/*
 * Hide visually and from screen readers, but maintain layout
 */
.invisible {
  visibility: hidden;
}

/*
 * Clearfix: contain floats
 *
 * For modern browsers
 * 1. The space content is one way to avoid an Opera bug when the
 *    `contenteditable` attribute is included anywhere else in the document.
 *    Otherwise it causes space to appear at the top and bottom of elements
 *    that receive the `clearfix` class.
 * 2. The use of `table` rather than `block` is only necessary if using
 *    `:before` to contain the top-margins of child elements.
 */
.clearfix:before, #header .header-content:before,
.clearfix:after,
#header .header-content:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}

.clearfix:after, #header .header-content:after {
  clear: both;
}

/* ==========================================================================
   EXAMPLE Media Queries for Responsive Design.
   These examples override the primary ('mobile first') styles.
   Modify as content requires.
   ========================================================================== */
/* ==========================================================================
   Print styles.
   Inlined to avoid the additional HTTP request:
   http://www.phpied.com/delay-loading-your-print-css/
   ========================================================================== */
@media print {
  *,
  *:before,
  *:after,
  *:first-letter,
  *:first-line {
    background: transparent !important;
    color: #000 !important;
    /* Black prints faster: http://www.sanbeiji.com/archives/953 */
    box-shadow: none !important;
    text-shadow: none !important;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: " (" attr(href) ")";
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  /*
     * Don't show links that are fragment identifiers,
     * or use the `javascript:` pseudo protocol
     */
  a[href^="#"]:after,
  a[href^="javascript:"]:after {
    content: "";
  }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  /*
     * Printing Tables:
     * http://css-discuss.incutio.com/wiki/Printing_Tables
     */
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
}

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Remove the unwanted box around FAB buttons */
/* More info: http://goo.gl/IPwKi */
a, .mdl-accordion, .mdl-button, .mdl-card, .mdl-checkbox, .mdl-dropdown-menu,
.mdl-icon-toggle, .mdl-item, .mdl-radio, .mdl-slider, .mdl-switch, .mdl-tabs__tab, .mdl-tabs .tab-polarcore {
  -webkit-tap-highlight-color: transparent;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* TOOLTIP */
/*
 * What follows is the result of much research on cross-browser styling.
 * Credit left inline and big thanks to Nicolas Gallagher, Jonathan Neal,
 * Kroc Camen, and the H5BP dev community and team.
 */
/* ==========================================================================
   Base styles: opinionated defaults
   ========================================================================== */
html {
  color: rgba(0,0,0, 0.87);
  font-size: 1em;
  line-height: 1.4;
}

/*
 * Remove text-shadow in selection highlight:
 * https://twitter.com/miketaylr/status/12228805301
 *
 * These selection rule sets have to be separate.
 * Customize the background color to match your design.
 */
::selection {
  background: #b3d4fc;
  text-shadow: none;
}

/*
 * A better looking default horizontal rule
 */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

/*
 * Remove the gap between audio, canvas, iframes,
 * images, videos and the bottom of their containers:
 * https://github.com/h5bp/html5-boilerplate/issues/440
 */
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

/*
 * Remove default fieldset styles.
 */
fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

/*
 * Allow only vertical resizing of textareas.
 */
textarea {
  resize: vertical;
}

/* ==========================================================================
   Browser Upgrade Prompt
   ========================================================================== */
.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

/* ==========================================================================
   Author's custom styles
   ========================================================================== */
/* ==========================================================================
   Helper classes
   ========================================================================== */
/*
 * Hide visually and from screen readers:
 */
.hidden {
  display: none !important;
}

/*
 * Hide only visually, but have it available for screen readers:
 * http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */
.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

/*
 * Extends the .visuallyhidden class to allow the element
 * to be focusable when navigated to via the keyboard:
 * https://www.drupal.org/node/897638
 */
.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

/*
 * Hide visually and from screen readers, but maintain layout
 */
.invisible {
  visibility: hidden;
}

/*
 * Clearfix: contain floats
 *
 * For modern browsers
 * 1. The space content is one way to avoid an Opera bug when the
 *    `contenteditable` attribute is included anywhere else in the document.
 *    Otherwise it causes space to appear at the top and bottom of elements
 *    that receive the `clearfix` class.
 * 2. The use of `table` rather than `block` is only necessary if using
 *    `:before` to contain the top-margins of child elements.
 */
.clearfix:before, #header .header-content:before,
.clearfix:after,
#header .header-content:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}

.clearfix:after, #header .header-content:after {
  clear: both;
}

/* ==========================================================================
   EXAMPLE Media Queries for Responsive Design.
   These examples override the primary ('mobile first') styles.
   Modify as content requires.
   ========================================================================== */
/* ==========================================================================
   Print styles.
   Inlined to avoid the additional HTTP request:
   http://www.phpied.com/delay-loading-your-print-css/
   ========================================================================== */
@media print {
  *,
  *:before,
  *:after,
  *:first-letter,
  *:first-line {
    background: transparent !important;
    color: #000 !important;
    /* Black prints faster: http://www.sanbeiji.com/archives/953 */
    box-shadow: none !important;
    text-shadow: none !important;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: " (" attr(href) ")";
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  /*
     * Don't show links that are fragment identifiers,
     * or use the `javascript:` pseudo protocol
     */
  a[href^="#"]:after,
  a[href^="javascript:"]:after {
    content: "";
  }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  /*
     * Printing Tables:
     * http://css-discuss.incutio.com/wiki/Printing_Tables
     */
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
}

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Remove the unwanted box around FAB buttons */
/* More info: http://goo.gl/IPwKi */
a, .mdl-accordion, .mdl-button, .mdl-card, .mdl-checkbox, .mdl-dropdown-menu,
.mdl-icon-toggle, .mdl-item, .mdl-radio, .mdl-slider, .mdl-switch, .mdl-tabs__tab, .mdl-tabs .tab-polarcore {
  -webkit-tap-highlight-color: transparent;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

/*
 * Make html take up the entire screen
 * Then set touch-action to avoid touch delay on mobile IE
 */
html {
  width: 100%;
  height: 100%;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

/*
* Make body take up the entire screen
* Remove body margin so layout containers don't cause extra overflow.
*/
body {
  width: 100%;
  min-height: 100%;
  margin: 0;
}

/*
 * Main display reset for IE support.
 * Source: http://weblog.west-wind.com/posts/2015/Jan/12/main-HTML5-Tag-not-working-in-Internet-Explorer-91011
 */
main {
  display: block;
}

/*
* Apply no display to elements with the hidden attribute.
* IE 9 and 10 support.
*/
*[hidden] {
  display: none !important;
}

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* TOOLTIP */
.mdl-ripple {
  background: rgb(0,0,0);
  border-radius: 50%;
  height: 50px;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  transform: translate(-50%, -50%);
  width: 50px;
  overflow: hidden;
}

.mdl-ripple.is-animating {
  transition: transform 0.3s cubic-bezier(0, 0, 0.2, 1), width 0.3s cubic-bezier(0, 0, 0.2, 1), height 0.3s cubic-bezier(0, 0, 0.2, 1), opacity 0.6s cubic-bezier(0, 0, 0.2, 1);
}

.mdl-ripple.is-visible {
  opacity: 0.3;
}

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* TOOLTIP */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Typography */
/* Shadows */
/* Animations */
.mdl-shadow--2dp, body.sticky--dirty .header {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.mdl-shadow--3dp, .footer-container .back-to-top__icon {
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 1px 8px 0 rgba(0, 0, 0, 0.12);
}

.mdl-shadow--4dp {
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
}

.mdl-shadow--6dp {
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
}

.mdl-shadow--8dp {
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
}

.mdl-shadow--16dp {
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* TOOLTIP */
_:-ms-input-placeholder, :root .mdl-slider.mdl-slider.is-upgraded {
  -ms-appearance: none;
  height: 32px;
  margin: 0;
}

.mdl-slider {
  width: calc(100% - 40px);
  margin: 0 20px;
}

.mdl-slider.is-upgraded {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: 2px;
  background: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  outline: 0;
  padding: 0;
  color: rgb(34,34,34);
  align-self: center;
  z-index: 1;
  cursor: pointer;
  /**************************** Tracks ****************************/
  /**************************** Thumbs ****************************/
  /**************************** 0-value ****************************/
  /**************************** Disabled ****************************/
}

.mdl-slider.is-upgraded::-moz-focus-outer {
  border: 0;
}

.mdl-slider.is-upgraded::-ms-tooltip {
  display: none;
}

.mdl-slider.is-upgraded::-webkit-slider-runnable-track {
  background: transparent;
}

.mdl-slider.is-upgraded::-moz-range-track {
  background: transparent;
  border: none;
}

.mdl-slider.is-upgraded::-ms-track {
  background: none;
  color: transparent;
  height: 2px;
  width: 100%;
  border: none;
}

.mdl-slider.is-upgraded::-ms-fill-lower {
  padding: 0;
  background: linear-gradient(to right, transparent, transparent 16px, rgb(34,34,34) 16px, rgb(34,34,34) 0);
}

.mdl-slider.is-upgraded::-ms-fill-upper {
  padding: 0;
  background: linear-gradient(to left, transparent, transparent 16px, rgba(0,0,0, 0.26) 16px, rgba(0,0,0, 0.26) 0);
}

.mdl-slider.is-upgraded::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 12px;
  height: 12px;
  box-sizing: border-box;
  border-radius: 50%;
  background: rgb(34,34,34);
  border: none;
  transition: transform 0.18s cubic-bezier(0.4, 0, 0.2, 1), border 0.18s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.18s cubic-bezier(0.4, 0, 0.2, 1), background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
}

.mdl-slider.is-upgraded::-moz-range-thumb {
  -moz-appearance: none;
  width: 12px;
  height: 12px;
  box-sizing: border-box;
  border-radius: 50%;
  background-image: none;
  background: rgb(34,34,34);
  border: none;
}

.mdl-slider.is-upgraded:focus:not(:active)::-webkit-slider-thumb {
  box-shadow: 0 0 0 10px rgba(34,34,34, 0.26);
}

.mdl-slider.is-upgraded:focus:not(:active)::-moz-range-thumb {
  box-shadow: 0 0 0 10px rgba(34,34,34, 0.26);
}

.mdl-slider.is-upgraded:active::-webkit-slider-thumb {
  background-image: none;
  background: rgb(34,34,34);
  transform: scale(1.5);
}

.mdl-slider.is-upgraded:active::-moz-range-thumb {
  background-image: none;
  background: rgb(34,34,34);
  transform: scale(1.5);
}

.mdl-slider.is-upgraded::-ms-thumb {
  width: 32px;
  height: 32px;
  border: none;
  border-radius: 50%;
  background: rgb(34,34,34);
  transform: scale(0.375);
  transition: transform 0.18s cubic-bezier(0.4, 0, 0.2, 1), background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
}

.mdl-slider.is-upgraded:focus:not(:active)::-ms-thumb {
  background: radial-gradient(circle closest-side, rgb(34,34,34) 0%, rgb(34,34,34) 37.5%, rgba(34,34,34, 0.26) 37.5%, rgba(34,34,34, 0.26) 100%);
  transform: scale(1);
}

.mdl-slider.is-upgraded:active::-ms-thumb {
  background: rgb(34,34,34);
  transform: scale(0.5625);
}

.mdl-slider.is-upgraded.is-lowest-value::-webkit-slider-thumb {
  border: 2px solid rgba(0,0,0, 0.26);
  background: transparent;
}

.mdl-slider.is-upgraded.is-lowest-value::-moz-range-thumb {
  border: 2px solid rgba(0,0,0, 0.26);
  background: transparent;
}

.mdl-slider.is-upgraded.is-lowest-value ~
.mdl-slider__background-flex > .mdl-slider__background-upper {
  left: 6px;
}

.mdl-slider.is-upgraded.is-lowest-value:focus:not(:active)::-webkit-slider-thumb {
  box-shadow: 0 0 0 10px rgba(0,0,0, 0.12);
  background: rgba(0,0,0, 0.12);
}

.mdl-slider.is-upgraded.is-lowest-value:focus:not(:active)::-moz-range-thumb {
  box-shadow: 0 0 0 10px rgba(0,0,0, 0.12);
  background: rgba(0,0,0, 0.12);
}

.mdl-slider.is-upgraded.is-lowest-value:active::-webkit-slider-thumb {
  border: 1.6px solid rgba(0,0,0, 0.26);
  transform: scale(1.5);
}

.mdl-slider.is-upgraded.is-lowest-value:active ~
.mdl-slider__background-flex > .mdl-slider__background-upper {
  left: 9px;
}

.mdl-slider.is-upgraded.is-lowest-value:active::-moz-range-thumb {
  border: 1.5px solid rgba(0,0,0, 0.26);
  transform: scale(1.5);
}

.mdl-slider.is-upgraded.is-lowest-value::-ms-thumb {
  background: radial-gradient(circle closest-side, transparent 0%, transparent 66.67%, rgba(0,0,0, 0.26) 66.67%, rgba(0,0,0, 0.26) 100%);
}

.mdl-slider.is-upgraded.is-lowest-value:focus:not(:active)::-ms-thumb {
  background: radial-gradient(circle closest-side, rgba(0,0,0, 0.12) 0%, rgba(0,0,0, 0.12) 25%, rgba(0,0,0, 0.26) 25%, rgba(0,0,0, 0.26) 37.5%, rgba(0,0,0, 0.12) 37.5%, rgba(0,0,0, 0.12) 100%);
  transform: scale(1);
}

.mdl-slider.is-upgraded.is-lowest-value:active::-ms-thumb {
  transform: scale(0.5625);
  background: radial-gradient(circle closest-side, transparent 0%, transparent 77.78%, rgba(0,0,0, 0.26) 77.78%, rgba(0,0,0, 0.26) 100%);
}

.mdl-slider.is-upgraded.is-lowest-value::-ms-fill-lower {
  background: transparent;
}

.mdl-slider.is-upgraded.is-lowest-value::-ms-fill-upper {
  margin-left: 6px;
}

.mdl-slider.is-upgraded.is-lowest-value:active::-ms-fill-upper {
  margin-left: 9px;
}

.mdl-slider.is-upgraded:disabled:focus::-webkit-slider-thumb, .mdl-slider.is-upgraded:disabled:active::-webkit-slider-thumb, .mdl-slider.is-upgraded:disabled::-webkit-slider-thumb {
  transform: scale(0.667);
  background: rgba(0,0,0, 0.26);
}

.mdl-slider.is-upgraded:disabled:focus::-moz-range-thumb, .mdl-slider.is-upgraded:disabled:active::-moz-range-thumb, .mdl-slider.is-upgraded:disabled::-moz-range-thumb {
  transform: scale(0.667);
  background: rgba(0,0,0, 0.26);
}

.mdl-slider.is-upgraded:disabled ~
.mdl-slider__background-flex > .mdl-slider__background-lower {
  background-color: rgba(0,0,0, 0.26);
  left: -6px;
}

.mdl-slider.is-upgraded:disabled ~
.mdl-slider__background-flex > .mdl-slider__background-upper {
  left: 6px;
}

.mdl-slider.is-upgraded.is-lowest-value:disabled:focus::-webkit-slider-thumb, .mdl-slider.is-upgraded.is-lowest-value:disabled:active::-webkit-slider-thumb, .mdl-slider.is-upgraded.is-lowest-value:disabled::-webkit-slider-thumb {
  border: 3px solid rgba(0,0,0, 0.26);
  background: transparent;
  transform: scale(0.667);
}

.mdl-slider.is-upgraded.is-lowest-value:disabled:focus::-moz-range-thumb, .mdl-slider.is-upgraded.is-lowest-value:disabled:active::-moz-range-thumb, .mdl-slider.is-upgraded.is-lowest-value:disabled::-moz-range-thumb {
  border: 3px solid rgba(0,0,0, 0.26);
  background: transparent;
  transform: scale(0.667);
}

.mdl-slider.is-upgraded.is-lowest-value:disabled:active ~
.mdl-slider__background-flex > .mdl-slider__background-upper {
  left: 6px;
}

.mdl-slider.is-upgraded:disabled:focus::-ms-thumb, .mdl-slider.is-upgraded:disabled:active::-ms-thumb, .mdl-slider.is-upgraded:disabled::-ms-thumb {
  transform: scale(0.25);
  background: rgba(0,0,0, 0.26);
}

.mdl-slider.is-upgraded.is-lowest-value:disabled:focus::-ms-thumb, .mdl-slider.is-upgraded.is-lowest-value:disabled:active::-ms-thumb, .mdl-slider.is-upgraded.is-lowest-value:disabled::-ms-thumb {
  transform: scale(0.25);
  background: radial-gradient(circle closest-side, transparent 0%, transparent 50%, rgba(0,0,0, 0.26) 50%, rgba(0,0,0, 0.26) 100%);
}

.mdl-slider.is-upgraded:disabled::-ms-fill-lower {
  margin-right: 6px;
  background: linear-gradient(to right, transparent, transparent 25px, rgba(0,0,0, 0.26) 25px, rgba(0,0,0, 0.26) 0);
}

.mdl-slider.is-upgraded:disabled::-ms-fill-upper {
  margin-left: 6px;
}

.mdl-slider.is-upgraded.is-lowest-value:disabled:active::-ms-fill-upper {
  margin-left: 6px;
}

.mdl-slider__ie-container {
  height: 18px;
  overflow: visible;
  border: none;
  margin: none;
  padding: none;
}

.mdl-slider__container {
  height: 18px;
  position: relative;
  background: none;
  display: flex;
  flex-direction: row;
}

.mdl-slider__background-flex {
  background: transparent;
  position: absolute;
  height: 2px;
  width: calc(100% - 52px);
  top: 50%;
  left: 0;
  margin: 0 26px;
  display: flex;
  overflow: hidden;
  border: 0;
  padding: 0;
  transform: translate(0, -1px);
}

.mdl-slider__background-lower {
  background: rgb(34,34,34);
  flex: 0;
  position: relative;
  border: 0;
  padding: 0;
}

.mdl-slider__background-upper {
  background: rgba(0,0,0, 0.26);
  flex: 0;
  position: relative;
  border: 0;
  padding: 0;
  transition: left 0.18s cubic-bezier(0.4, 0, 0.2, 1);
}

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* TOOLTIP */
.mdl-spinner {
  display: inline-block;
  position: relative;
  width: 28px;
  height: 28px;
}

.mdl-spinner:not(.is-upgraded).is-active:after {
  content: "Loading...";
}

.mdl-spinner.is-upgraded.is-active {
  animation: mdl-spinner__container-rotate 1568.23529ms linear infinite;
}

@keyframes mdl-spinner__container-rotate {
  to {
    transform: rotate(360deg);
  }
}

.mdl-spinner__layer {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.mdl-spinner__layer-1 {
  border-color: rgb(66,165,245);
}

.mdl-spinner--single-color .mdl-spinner__layer-1 {
  border-color: rgb(34,34,34);
}

.mdl-spinner.is-active .mdl-spinner__layer-1 {
  animation: mdl-spinner__fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, mdl-spinner__layer-1-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

.mdl-spinner__layer-2 {
  border-color: rgb(244,67,54);
}

.mdl-spinner--single-color .mdl-spinner__layer-2 {
  border-color: rgb(34,34,34);
}

.mdl-spinner.is-active .mdl-spinner__layer-2 {
  animation: mdl-spinner__fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, mdl-spinner__layer-2-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

.mdl-spinner__layer-3 {
  border-color: rgb(253,216,53);
}

.mdl-spinner--single-color .mdl-spinner__layer-3 {
  border-color: rgb(34,34,34);
}

.mdl-spinner.is-active .mdl-spinner__layer-3 {
  animation: mdl-spinner__fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, mdl-spinner__layer-3-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

.mdl-spinner__layer-4 {
  border-color: rgb(76,175,80);
}

.mdl-spinner--single-color .mdl-spinner__layer-4 {
  border-color: rgb(34,34,34);
}

.mdl-spinner.is-active .mdl-spinner__layer-4 {
  animation: mdl-spinner__fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, mdl-spinner__layer-4-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

@keyframes mdl-spinner__fill-unfill-rotate {
  12.5% {
    transform: rotate(135deg);
  }
  25% {
    transform: rotate(270deg);
  }
  37.5% {
    transform: rotate(405deg);
  }
  50% {
    transform: rotate(540deg);
  }
  62.5% {
    transform: rotate(675deg);
  }
  75% {
    transform: rotate(810deg);
  }
  87.5% {
    transform: rotate(945deg);
  }
  to {
    transform: rotate(1080deg);
  }
}

/**
* HACK: Even though the intention is to have the current .mdl-spinner__layer-N
* at `opacity: 1`, we set it to `opacity: 0.99` instead since this forces Chrome
* to do proper subpixel rendering for the elements being animated. This is
* especially visible in Chrome 39 on Ubuntu 14.04. See:
*
* - https://github.com/Polymer/paper-spinner/issues/9
* - https://code.google.com/p/chromium/issues/detail?id=436255
*/
@keyframes mdl-spinner__layer-1-fade-in-out {
  from {
    opacity: 0.99;
  }
  25% {
    opacity: 0.99;
  }
  26% {
    opacity: 0;
  }
  89% {
    opacity: 0;
  }
  90% {
    opacity: 0.99;
  }
  100% {
    opacity: 0.99;
  }
}

@keyframes mdl-spinner__layer-2-fade-in-out {
  from {
    opacity: 0;
  }
  15% {
    opacity: 0;
  }
  25% {
    opacity: 0.99;
  }
  50% {
    opacity: 0.99;
  }
  51% {
    opacity: 0;
  }
}

@keyframes mdl-spinner__layer-3-fade-in-out {
  from {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  50% {
    opacity: 0.99;
  }
  75% {
    opacity: 0.99;
  }
  76% {
    opacity: 0;
  }
}

@keyframes mdl-spinner__layer-4-fade-in-out {
  from {
    opacity: 0;
  }
  65% {
    opacity: 0;
  }
  75% {
    opacity: 0.99;
  }
  90% {
    opacity: 0.99;
  }
  100% {
    opacity: 0;
  }
}

/**
* Patch the gap that appear between the two adjacent
* div.mdl-spinner__circle-clipper while the spinner is rotating
* (appears on Chrome 38, Safari 7.1, and IE 11).
*
* Update: the gap no longer appears on Chrome when .mdl-spinner__layer-N's
* opacity is 0.99, but still does on Safari and IE.
*/
.mdl-spinner__gap-patch {
  position: absolute;
  box-sizing: border-box;
  top: 0;
  left: 45%;
  width: 10%;
  height: 100%;
  overflow: hidden;
  border-color: inherit;
}

.mdl-spinner__gap-patch .mdl-spinner__circle {
  width: 1000%;
  left: -450%;
}

.mdl-spinner__circle-clipper {
  display: inline-block;
  position: relative;
  width: 50%;
  height: 100%;
  overflow: hidden;
  border-color: inherit;
}

.mdl-spinner__circle-clipper .mdl-spinner__circle {
  width: 200%;
}

.mdl-spinner__circle {
  box-sizing: border-box;
  height: 100%;
  border-width: 3px;
  border-style: solid;
  border-color: inherit;
  border-bottom-color: transparent !important;
  border-radius: 50%;
  animation: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.mdl-spinner__left .mdl-spinner__circle {
  border-right-color: transparent !important;
  transform: rotate(129deg);
}

.mdl-spinner.is-active .mdl-spinner__left .mdl-spinner__circle {
  animation: mdl-spinner__left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

.mdl-spinner__right .mdl-spinner__circle {
  left: -100%;
  border-left-color: transparent !important;
  transform: rotate(-129deg);
}

.mdl-spinner.is-active .mdl-spinner__right .mdl-spinner__circle {
  animation: mdl-spinner__right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

@keyframes mdl-spinner__left-spin {
  from {
    transform: rotate(130deg);
  }
  50% {
    transform: rotate(-5deg);
  }
  to {
    transform: rotate(130deg);
  }
}

@keyframes mdl-spinner__right-spin {
  from {
    transform: rotate(-130deg);
  }
  50% {
    transform: rotate(5deg);
  }
  to {
    transform: rotate(-130deg);
  }
}

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* TOOLTIP */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Typography */
/* Shadows */
/* Animations */
.mdl-switch {
  position: relative;
  z-index: 1;
  vertical-align: middle;
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
  height: 24px;
  margin: 0;
  padding: 0;
  overflow: visible;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.mdl-switch.is-upgraded {
  padding-left: 28px;
}

.mdl-switch__input {
  line-height: 24px;
}

.mdl-switch.is-upgraded .mdl-switch__input {
  position: absolute;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
  opacity: 0;
  -ms-appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: none;
}

.mdl-switch__track {
  background: rgba(0,0,0, 0.26);
  position: absolute;
  left: 0;
  top: 5px;
  height: 14px;
  width: 36px;
  border-radius: 14px;
  cursor: pointer;
}

.mdl-switch.is-checked .mdl-switch__track {
  background: rgba(34,34,34, 0.5);
}

.mdl-switch.is-disabled .mdl-switch__track {
  background: rgba(0,0,0, 0.12);
  cursor: auto;
}

.mdl-switch__thumb {
  background: rgb(250,250,250);
  position: absolute;
  left: 0;
  top: 2px;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  transition-duration: 0.28s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: left;
}

.mdl-switch.is-checked .mdl-switch__thumb {
  background: rgb(34,34,34);
  left: 16px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 1px 8px 0 rgba(0, 0, 0, 0.12);
}

.mdl-switch.is-disabled .mdl-switch__thumb {
  background: rgb(189,189,189);
  cursor: auto;
}

.mdl-switch__focus-helper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-4px, -4px);
  display: inline-block;
  box-sizing: border-box;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: transparent;
}

.mdl-switch.is-focused .mdl-switch__focus-helper {
  box-shadow: 0 0 0px 20px rgba(0, 0, 0, 0.1);
  background-color: rgba(0, 0, 0, 0.1);
}

.mdl-switch.is-focused.is-checked .mdl-switch__focus-helper {
  box-shadow: 0 0 0px 20px rgba(34,34,34, 0.26);
  background-color: rgba(34,34,34, 0.26);
}

.mdl-switch__label {
  position: relative;
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  left: 24px;
}

.mdl-switch.is-disabled .mdl-switch__label {
  color: rgb(189,189,189);
  cursor: auto;
}

.mdl-switch__ripple-container {
  position: absolute;
  z-index: 2;
  top: -12px;
  left: -14px;
  box-sizing: border-box;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  cursor: pointer;
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(circle, white, black);
  transition-duration: 0.40s;
  transition-timing-function: step-end;
  transition-property: left;
}

.mdl-switch__ripple-container .mdl-ripple {
  background: rgb(34,34,34);
}

.mdl-switch.is-disabled .mdl-switch__ripple-container {
  cursor: auto;
}

.mdl-switch.is-disabled .mdl-switch__ripple-container .mdl-ripple {
  background: transparent;
}

.mdl-switch.is-checked .mdl-switch__ripple-container {
  cursor: auto;
  left: 2px;
}

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* TOOLTIP */
.mdl-tabs {
  display: block;
  width: 100%;
}

.mdl-tabs__tab-bar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: space-between;
  align-items: flex-start;
  height: 48px;
  padding: 0 0 0 0;
  margin: 0;
  border-bottom: 1px solid rgb(224,224,224);
}

.mdl-tabs__tab, .mdl-tabs .tab-polarcore {
  margin: 0;
  border: none;
  padding: 0 24px 0 24px;
  float: left;
  position: relative;
  display: block;
  text-decoration: none;
  height: 48px;
  line-height: 48px;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  color: rgba(0,0,0, 0.54);
  overflow: hidden;
}

.mdl-tabs.is-upgraded .mdl-tabs__tab.is-active, .mdl-tabs.is-upgraded .is-active.tab-polarcore {
  color: rgba(0,0,0, 0.87);
}

.mdl-tabs.is-upgraded .mdl-tabs__tab.is-active:after, .mdl-tabs.is-upgraded .is-active.tab-polarcore:after {
  height: 2px;
  width: 100%;
  display: block;
  content: " ";
  bottom: 0px;
  left: 0px;
  position: absolute;
  background: rgb(34,34,34);
  animation: border-expand 0.2s cubic-bezier(0.4, 0, 0.4, 1) 0.01s alternate forwards;
  transition: all 1s cubic-bezier(0.4, 0, 1, 1);
}

.mdl-tabs__tab .mdl-tabs__ripple-container, .mdl-tabs .tab-polarcore .mdl-tabs__ripple-container {
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0px;
  top: 0px;
  z-index: 1;
  overflow: hidden;
}

.mdl-tabs__tab .mdl-tabs__ripple-container .mdl-ripple, .mdl-tabs .tab-polarcore .mdl-tabs__ripple-container .mdl-ripple {
  background: rgb(34,34,34);
}

.mdl-tabs__panel {
  display: block;
}

.mdl-tabs.is-upgraded .mdl-tabs__panel {
  display: none;
}

.mdl-tabs.is-upgraded .mdl-tabs__panel.is-active {
  display: block;
}

@keyframes border-expand {
  0% {
    opacity: 0;
    width: 0;
  }
  100% {
    opacity: 1;
    width: 100%;
  }
}

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* TOOLTIP */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Typography */
/* Shadows */
/* Animations */
.mdl-textfield {
  position: relative;
  font-size: 16px;
  display: inline-block;
  box-sizing: border-box;
  width: 300px;
  max-width: 100%;
  margin: 0;
  padding: 20px 0;
}

.mdl-textfield .mdl-button {
  position: absolute;
  bottom: 20px;
}

.mdl-textfield--align-right {
  text-align: right;
}

.mdl-textfield--full-width {
  width: 100%;
}

.mdl-textfield--expandable {
  min-width: 32px;
  width: auto;
  min-height: 32px;
}

.mdl-textfield__input {
  border: none;
  border-bottom: 1px solid rgba(0,0,0, 0.12);
  display: block;
  font-size: 16px;
  margin: 0;
  padding: 4px 0;
  width: 100%;
  background: none;
  text-align: left;
  color: inherit;
}

.mdl-textfield.is-focused .mdl-textfield__input {
  outline: none;
}

.mdl-textfield.is-invalid .mdl-textfield__input {
  border-color: rgb(222, 50, 38);
  box-shadow: none;
}

.mdl-textfield.is-disabled .mdl-textfield__input {
  background-color: transparent;
  border-bottom: 1px dotted rgba(0,0,0, 0.12);
  color: rgba(0,0,0, 0.26);
}

.mdl-textfield textarea.mdl-textfield__input {
  display: block;
}

.mdl-textfield__label {
  bottom: 0;
  color: rgba(0,0,0, 0.26);
  font-size: 16px;
  left: 0;
  right: 0;
  pointer-events: none;
  position: absolute;
  display: block;
  top: 24px;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-align: left;
}

.mdl-textfield.is-dirty .mdl-textfield__label {
  visibility: hidden;
}

.mdl-textfield--floating-label .mdl-textfield__label {
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.mdl-textfield.is-disabled.is-disabled .mdl-textfield__label {
  color: rgba(0,0,0, 0.26);
}

.mdl-textfield--floating-label.is-focused .mdl-textfield__label,
.mdl-textfield--floating-label.is-dirty .mdl-textfield__label {
  color: rgb(34,34,34);
  font-size: 12px;
  top: 4px;
  visibility: visible;
}

.mdl-textfield--floating-label.is-focused .mdl-textfield__expandable-holder .mdl-textfield__label,
.mdl-textfield--floating-label.is-dirty .mdl-textfield__expandable-holder .mdl-textfield__label {
  top: -16px;
}

.mdl-textfield--floating-label.is-invalid .mdl-textfield__label {
  color: rgb(222, 50, 38);
  font-size: 12px;
}

.mdl-textfield__label:after {
  background-color: rgb(34,34,34);
  bottom: 20px;
  content: '';
  height: 2px;
  left: 45%;
  position: absolute;
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  visibility: hidden;
  width: 10px;
}

.mdl-textfield.is-focused .mdl-textfield__label:after {
  left: 0;
  visibility: visible;
  width: 100%;
}

.mdl-textfield.is-invalid .mdl-textfield__label:after {
  background-color: rgb(222, 50, 38);
}

.mdl-textfield__error {
  color: rgb(222, 50, 38);
  position: absolute;
  font-size: 12px;
  margin-top: 3px;
  visibility: hidden;
  display: block;
}

.mdl-textfield.is-invalid .mdl-textfield__error {
  visibility: visible;
}

.mdl-textfield__expandable-holder {
  display: inline-block;
  position: relative;
  margin-left: 32px;
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  display: inline-block;
  max-width: 0.1px;
}

.mdl-textfield.is-focused .mdl-textfield__expandable-holder, .mdl-textfield.is-dirty .mdl-textfield__expandable-holder {
  max-width: 600px;
}

.mdl-textfield__expandable-holder .mdl-textfield__label:after {
  bottom: 0;
}

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* TOOLTIP */
.mdl-tooltip {
  transform: scale(0);
  transform-origin: top center;
  will-change: transform;
  z-index: 999;
  background: rgba(97,97,97, 0.9);
  border-radius: 2px;
  color: rgb(255,255,255);
  display: inline-block;
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  max-width: 170px;
  position: fixed;
  top: -500px;
  left: -500px;
  padding: 8px;
  text-align: center;
}

.mdl-tooltip.is-active {
  animation: pulse 200ms cubic-bezier(0, 0, 0.2, 1) forwards;
}

.mdl-tooltip--large {
  line-height: 14px;
  font-size: 14px;
  padding: 16px;
}

@keyframes pulse {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  50% {
    transform: scale(0.99);
  }
  100% {
    transform: scale(1);
    opacity: 1;
    visibility: visible;
  }
}

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* TOOLTIP */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Typography */
/* Shadows */
/* Animations */
html, body {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 100;
  line-height: 20px;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  padding: 0;
}

/**
  * Styles for HTML elements
  */
h1 small, h2 small, h3 small, h4 small, h5 small, h6 small {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 56px;
  font-weight: 400;
  line-height: 1.35;
  letter-spacing: -0.02em;
  opacity: 0.54;
  font-size: 0.6em;
}

h1 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 56px;
  font-weight: 400;
  line-height: 1.35;
  letter-spacing: -0.02em;
  margin-top: 24px;
  margin-bottom: 24px;
}

h2 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 45px;
  font-weight: 400;
  line-height: 48px;
  margin-top: 24px;
  margin-bottom: 24px;
}

h3 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 34px;
  font-weight: 400;
  line-height: 40px;
  margin-top: 24px;
  margin-bottom: 24px;
}

h4 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  -moz-osx-font-smoothing: grayscale;
  margin-top: 24px;
  margin-bottom: 16px;
}

h5 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.02em;
  margin-top: 24px;
  margin-bottom: 16px;
}

h6 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.04em;
  margin-top: 24px;
  margin-bottom: 16px;
}

p {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  margin-bottom: 16px;
}

a {
  color: #222;
  font-weight: 500;
}

blockquote {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  position: relative;
  font-size: 24px;
  font-weight: 300;
  font-style: italic;
  line-height: 1.35;
  letter-spacing: 0.08em;
}

blockquote:before {
  position: absolute;
  left: -0.5em;
  content: '“';
}

blockquote:after {
  content: '”';
  margin-left: -0.05em;
}

mark {
  background-color: #f4ff81;
}

dt {
  font-weight: 700;
}

address {
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0;
  font-style: normal;
}

ul, ol {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
}

/**
 * Class Name Styles
 */
.mdl-typography--display-4 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 112px;
  font-weight: 300;
  line-height: 1;
  letter-spacing: -0.04em;
}

.mdl-typography--display-4-color-contrast {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 112px;
  font-weight: 300;
  line-height: 1;
  letter-spacing: -0.04em;
  opacity: 0.54;
}

.mdl-typography--display-3 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 56px;
  font-weight: 400;
  line-height: 1.35;
  letter-spacing: -0.02em;
}

.mdl-typography--display-3-color-contrast {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 56px;
  font-weight: 400;
  line-height: 1.35;
  letter-spacing: -0.02em;
  opacity: 0.54;
}

.mdl-typography--display-2 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 45px;
  font-weight: 400;
  line-height: 48px;
}

.mdl-typography--display-2-color-contrast {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 45px;
  font-weight: 400;
  line-height: 48px;
  opacity: 0.54;
}

.mdl-typography--display-1 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 34px;
  font-weight: 400;
  line-height: 40px;
}

.mdl-typography--display-1-color-contrast {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 34px;
  font-weight: 400;
  line-height: 40px;
  opacity: 0.54;
}

.mdl-typography--headline {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  -moz-osx-font-smoothing: grayscale;
}

.mdl-typography--headline-color-contrast {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  -moz-osx-font-smoothing: grayscale;
  opacity: 0.87;
}

.mdl-typography--title {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.02em;
}

.mdl-typography--title-color-contrast {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.02em;
  opacity: 0.87;
}

.mdl-typography--subhead {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.04em;
}

.mdl-typography--subhead-color-contrast {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.04em;
  opacity: 0.87;
}

.mdl-typography--body-2 {
  font-size: 14px;
  font-weight: bold;
  line-height: 24px;
  letter-spacing: 0;
}

.mdl-typography--body-2-color-contrast {
  font-size: 14px;
  font-weight: bold;
  line-height: 24px;
  letter-spacing: 0;
  opacity: 0.87;
}

.mdl-typography--body-1 {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
}

.mdl-typography--body-1-color-contrast {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  opacity: 0.87;
}

.mdl-typography--body-2-force-preferred-font {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
}

.mdl-typography--body-2-force-preferred-font-color-contrast {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
  opacity: 0.87;
}

.mdl-typography--body-1-force-preferred-font {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
}

.mdl-typography--body-1-force-preferred-font-color-contrast {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  opacity: 0.87;
}

.mdl-typography--caption {
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0;
}

.mdl-typography--caption-force-preferred-font {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0;
}

.mdl-typography--caption-color-contrast {
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0;
  opacity: 0.54;
}

.mdl-typography--caption-force-preferred-font-color-contrast {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0;
  opacity: 0.54;
}

.mdl-typography--menu {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0;
}

.mdl-typography--menu-color-contrast {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0;
  opacity: 0.87;
}

.mdl-typography--button {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1;
  letter-spacing: 0;
}

.mdl-typography--button-color-contrast {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1;
  letter-spacing: 0;
  opacity: 0.87;
}

.mdl-typography--text-left {
  text-align: left;
}

.mdl-typography--text-right {
  text-align: right;
}

.mdl-typography--text-center {
  text-align: center;
}

.mdl-typography--text-justify {
  text-align: justify;
}

.mdl-typography--text-nowrap {
  white-space: nowrap;
}

.mdl-typography--text-lowercase {
  text-transform: lowercase;
}

.mdl-typography--text-uppercase {
  text-transform: uppercase;
}

.mdl-typography--text-capitalize {
  text-transform: capitalize;
}

.mdl-typography--font-thin {
  font-weight: 200 !important;
}

.mdl-typography--font-light {
  font-weight: 300 !important;
}

.mdl-typography--font-regular {
  font-weight: 400 !important;
}

.mdl-typography--font-medium {
  font-weight: 500 !important;
}

.mdl-typography--font-bold {
  font-weight: 700 !important;
}

.mdl-typography--font-black {
  font-weight: 900 !important;
}

/* GLOBALS - _globals.scss */
/* GENERAL - _general.scss */
* {
  box-sizing: border-box;
}

body {
  background: #FFF;
}

.bg-white {
  background: #fff;
}

.page {
  flex-direction: column;
  display: flex;
  min-height: 100vh;
}

.mdl-layout__content {
  z-index: initial;
}

.mdl-layout__obfuscator {
  z-index: 140;
}

.main-container {
  flex: 1 1 auto;
  position: relative;
  width: 100%;
  /*background: $main-container-bg-color;*/
}

@media only screen and (min-width: 768px) {
  .main-container {
    padding: 0 1rem;
  }
}

@media only screen and (min-width: 1025px) {
  .main-container {
    padding: 0;
  }
}

.content-width {
  max-width: 100%;
  margin: 0 auto;
}

ul, ol, li, dd, dt {
  list-style: none;
  margin: 0;
  padding: 0;
}

/*main button, .button {
  background: $button-bg-color;
  color: $button-text-color;
  padding: 1em;
  border: none;
  outline: none;
  text-transform: uppercase;
  border:1px solid black;
  font-size: .8em;
  font-weight: 400
  margin:0;
  &:hover {
    transition: ease 0.2s background;
    cursor: pointer;
    background: transparent;
    color:#000;
    border-color:#000;
  }
}*/
main button, .button {
  background: transparent;
  color: #000;
  padding: 11px;
  border: none;
  outline: none;
  text-transform: uppercase;
  border: 1px solid black;
  font-size: .8em;
  font-weight: 400;
  margin: 0;
}

main button:hover, .button:hover {
  transition: ease 0.2s background;
  cursor: pointer;
  background: #eaff00;
  color: #000;
  border-color: #000;
}

img {
  max-width: 100%;
  height: auto;
}

.col-wrapper {
  position: relative;
  clear: both;
}

a.button, a.link-wishlist, a.link-compare,
a.link-learn {
  text-decoration: none;
}

a {
  text-decoration: none;
  color: #222;
}

a:hover {
  color: black;
}

.polar-language-box {
  position: relative;
  z-index: 110;
  margin-top: 2px;
}

.polar-language-box .polar-language-button {
  font-size: 0.9rem;
  margin-top: 0.5em;
  color: #9E9E9E;
  vertical-align: center;
}

.polar-language-box .polar-language-button::after {
  content: ' \25BE ';
}

.polar-language-box .polar-language-list {
  display: none;
  position: absolute;
  background: white;
  border: 1px solid #EEEEEE;
}

.polar-language-box .polar-language-list .polar-language-flex {
  display: flex;
  flex-direction: column;
}

.polar-language-box .polar-language-list .polar-language-flex li:hover {
  background: #f2f2f2;
}

.messages {
  display: inline-block;
  vertical-align: bottom;
  font-weight: 400;
  margin: auto;
}

.messages > li {
  background: #FAFAFA;
  color: #424242;
}

.messages .success-msg {
  color: black;
  margin: 1em auto;
  border: 1px solid #66bb6a;
  padding: 2px 10px;
}

.messages .error-msg {
  background: #ffffff;
  color: black;
  display: flex;
  margin: 1em auto;
  border: 1px solid #c25c5c;
  padding: 2px 10px;
}

@media only screen and (min-width: 1025px) {
  .messages .error-msg::before {
    font-family: "Material Icons";
    text-rendering: optimizelegibility;
    font-feature-settings: "liga";
    font-style: normal;
    text-transform: none;
    line-height: 1;
    display: block;
    overflow: hidden;
    -moz-osx-font-smoothing: grayscale;
    color: #c25c5c;
    content: '\E000';
    width: auto;
    font-size: 35px;
    margin: 0;
  }
}

.messages .error-msg > ul {
  width: auto;
  margin-left: 1rem;
  margin-top: auto;
  margin-bottom: auto;
  font-weight: 400;
}

.messages .notice-msg {
  background: #FDD835;
  color: #424242;
}

.error {
  padding: 1em;
  background: #EF5350;
  color: white;
  display: flex;
  text-align: left;
}

.error span {
  margin: auto 1rem;
  text-align: left;
}

.error a {
  margin: 0 0.5rem;
  color: #EAEAEA;
  text-decoration: underline;
}

.error a:hover {
  color: #f0f0f0;
}

@media only screen and (min-width: 1025px) {
  .error::before {
    font-family: "Material Icons";
    text-rendering: optimizelegibility;
    font-feature-settings: "liga";
    font-style: normal;
    text-transform: none;
    line-height: 1;
    display: block;
    overflow: hidden;
    -moz-osx-font-smoothing: grayscale;
    content: '\E000';
    width: 5rem;
    font-size: 3em;
    margin: 0;
  }
}

.error > ul {
  width: auto;
  margin: 0;
}

.mdl-textfield {
  width: auto;
}

.mdl-checkbox__tick-outline {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  mask: url("../images/tick-mask.svg?embed");
  background: transparent;
  transition-duration: 0.28s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: background;
}

.mdl-checkbox.is-checked .mdl-checkbox__tick-outline {
  background: rgb(34,34,34) url("../images/tick.svg?embed");
}

.mdl-checkbox.is-checked.is-disabled .mdl-checkbox__tick-outline {
  background: rgba(0,0,0, 0.26) url("../images/tick.svg?embed");
}

#map-popup {
  position: absolute;
  z-index: 200;
  width: 30%;
  background: white;
  border: 1px solid #e6e6e6;
  padding: 2rem;
}

#map-popup .btn-cart {
  background: #222;
  padding: 0.5em 1em;
  border: 0;
  width: 100%;
  text-align: center;
  font-size: 1.2rem;
  font-weight: lighter;
  color: white;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

#map-popup .btn-cart i.material-icons {
  position: relative;
  top: 0.25em;
  padding-bottom: 0.25em;
}

#map-popup .btn-cart:hover {
  cursor: pointer;
  background: #1f1f1f;
}

input.mdl-textfield__input {
  appearance: none;
}

@media only screen and (max-width: 767px) {
  .catalogsearch-result-index aside.col-left {
    min-height: 3rem;
  }
  .catalogsearch-result-index .page-title h1 {
    font-size: 1.4rem;
    text-align: center;
    margin-top: 2rem;
  }
  .catalogsearch-result-index .toolbar .sorter .sort-by .mobile-sort {
    width: 100%;
  }
  .catalogsearch-result-index .toolbar .sorter .sort-by .nuva-dropdown-wrapper .nuva-dropdown {
    width: 100%;
  }
}

.mdl-checkbox__box-outline {
  top: 15%;
  left: -1%;
}

.shipping-tracking-popup {
  padding: 3rem;
}

.shipping-tracking-popup .button {
  margin: 1rem 0;
  float: right;
}

.shipping-tracking-popup .button::after {
  clear: both;
  content: "";
  display: table;
}

.shipping-tracking-popup .buttons-set::after {
  clear: both;
  content: "";
  display: table;
}

.tracking-popup-wrapper {
  border: 1px solid #f0f0f0;
  padding: 2rem;
  position: relative;
}

.tracking-popup-wrapper .data-table tbody th {
  background: transparent;
}

.tracking-popup-wrapper .table-padding {
  padding: 1rem;
  background: #fafafa;
  border: 1px solid #eee;
}

.tracking-popup-wrapper .table-padding .odd {
  background: white;
}

.tracking-popup-wrapper [id*=track-history] {
  width: 100%;
}

.tracking-popup-wrapper [id*=track-history] thead tr {
  text-align: left;
}

.tracking-popup-wrapper .page-title::after {
  clear: both;
  content: "";
  display: table;
}

.tracking-popup-wrapper .page-title h1 {
  padding: 0;
  margin-bottom: 2rem;
}

.tracking-popup-wrapper .page-title .button {
  position: absolute;
  top: 1rem;
  right: 2rem;
}

.data-table {
  width: 100%;
  border-spacing: 0;
  margin: 1rem 0;
}

@media only screen and (max-width: 767px) {
  .data-table {
    padding: 1rem 0;
  }
}

.data-table colgroup {
  display: none;
}

.data-table h3 {
  font-size: 1.25rem;
  margin: 0;
}

.data-table tfoot {
  border-top: 2px solid rgba(0, 0, 0, 0.05);
  font-size: 1rem;
}

.data-table td:first-child {
  padding-left: 1rem;
}

.data-table td:last-child {
  padding-right: 1rem;
  text-align: right;
}

.data-table .label {
  font-weight: 400;
}

.data-table th {
  text-transform: uppercase;
  text-align: left;
  font-weight: 100;
}

.data-table tr:hover td, .data-table tr:hover th {
  /*border-bottom: 1px solid rgba($color-polar, 0.2);*/
  /*background: rgba(0,0,0,0.02);*/
}

.data-table td, .data-table th {
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px #f0f0f0 solid;
  transition: border-color 0.1s ease-in-out;
}

.data-table td:first-child, .data-table th:first-child {
  padding-left: 1rem;
  /*border-left: 1px solid $table-border;*/
}

.data-table td:last-child, .data-table th:last-child {
  padding-right: 1rem;
  text-align: right;
  /* border-right: 1px solid $table-border;*/
}

.data-table tbody tr:first-child td, .data-table tbody tr:first-child th, .data-table tfoot tr:first-child td, .data-table tfoot tr:first-child th, .data-table thead tr:first-child td, .data-table thead tr:first-child th {
  /*border-top: 1px solid $table-border;*/
}

.customer-account table.data-table td h3 {
  font-size: 1rem;
}

.customer-account table.data-table tfoot .grand_total td {
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
}

#product-attribute-specs-table {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

/* ANIMATION.scss */
@keyframes showsearch {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes hidesearch {
  0% {
    opacity: 1;
    transform: translateX(0x);
  }
  100% {
    opacity: 0;
    transform: translateX(-100px);
  }
}

/* ACCOUNT - _account.scss */
.customer-account-login h1 {
  margin: 0;
}

.customer-account-login .new-users .buttons-set {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.customer-account-login .new-users, .customer-account-login .registered-users {
  background: #FAFAFA;
  padding: 1rem 2rem;
  position: relative;
  min-height: 100px;
}

.customer-account-login .new-users h2, .customer-account-login .registered-users h2 {
  margin-top: 0;
  margin-bottom: 0;
}

.customer-account-login .new-users a.mdl-button, .customer-account-login .registered-users a.mdl-button {
  color: #000;
}

.customer-account-login ul.benefits {
  padding-left: 2em;
  margin-bottom: 1em;
}

.customer-account-login ul.benefits li {
  list-style: square;
}

.customer-account-login .forgot-password {
  margin-left: 0;
  margin-bottom: 1rem;
}

.customer-account-login .registered-users a.mdl-button {
  float: right;
  clear: left;
}

.customer-account #my-orders-table tr {
  height: 4rem;
}

.customer-account .data-table {
  border-spacing: 0;
}

.customer-account .data-table thead th {
  border-bottom: solid 2px #222;
}

.customer-account .data-table tr:nth-child(even) {
  background: rgba(0, 0, 0, 0.03);
}

.customer-account #my-downloadable-products-table, .customer-account .data-table {
  width: 100%;
}

.customer-account #reorder-validate-detail {
  padding: 1rem 0;
}

.customer-account #reorder-validate-detail li {
  display: flex;
}

.customer-account #reorder-validate-detail li input.checkbox {
  width: 10%;
}

.customer-account #reorder-validate-detail li .product-name {
  width: 90%;
}

.customer-account h2 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.customer-account h3 {
  padding-top: 0.5rem;
  margin: 0;
  font-size: 1.3rem;
}

@media only screen and (min-width: 768px) {
  .customer-account .col-left {
    margin-top: 42px;
  }
}

.remember-me-popup {
  display: none;
}

/* BREADCRUMBS - _breadcrumbs.scss */
.breadcrumbs .mdl-button:hover {
  background: none;
}

@media only screen and (max-width: 767px) {
  .breadcrumbs {
    padding: 0.75rem;
    overflow: hidden;
    height: 3.5rem;
  }
}

@media only screen and (min-width: 768px) {
  .breadcrumbs {
    padding: 0.25rem 1rem;
  }
}

.breadcrumbs ul {
  background: #fff;
  padding: 0;
  margin: 0;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .breadcrumbs ul {
    overflow-x: scroll;
    overflow-y: hidden;
    -webkit-overflow-scolling: touch;
    white-space: nowrap;
  }
}

.breadcrumbs li {
  display: inline-block;
  vertical-align: middle;
  list-style: none;
  margin-bottom: 0rem;
  border: 1px solid #F1F1F1;
  height: 25px;
  line-height: 25px;
}

.breadcrumbs li .mdl-button {
  height: 25px;
  line-height: 25px;
}

@media only screen and (max-width: 767px) {
  .breadcrumbs li {
    padding-left: 0;
    white-space: nowrap;
  }
  .breadcrumbs li:after {
    font-family: "Material Icons";
    text-rendering: optimizelegibility;
    font-feature-settings: "liga";
    font-style: normal;
    text-transform: none;
    line-height: 1;
    display: block;
    overflow: hidden;
    -moz-osx-font-smoothing: grayscale;
    display: inline-block;
    position: relative;
    top: 0.2rem;
    /*content: '\E315';*/
    font-weight: bold;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .breadcrumbs li:last-child {
    background-color: transparent;
    padding-right: 0;
  }
  .breadcrumbs li:last-child strong {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .breadcrumbs li:last-child:after {
    content: '';
  }
  .breadcrumbs li > a, .breadcrumbs li > strong {
    display: inline-block;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.breadcrumbs li strong {
  text-transform: uppercase;
  padding-left: 0;
}

@media only screen and (min-width: 768px) {
  .breadcrumbs li > a {
    font-weight: lighter;
  }
  .breadcrumbs li:last-of-type {
    padding: 0 1rem;
  }
}

.breadcrumbs li .mdl-button {
  min-width: auto;
  font-size: .85rem;
  font-weight: 100;
}

.breadcrumbs li.home {
  padding: 0;
}

@media only screen and (min-width: 768px) {
  .breadcrumbs li.home {
    padding-left: 0;
  }
}

.breadcrumbs li > span {
  color: #BDBDBD;
  margin-left: 0.25rem;
}

@media only screen and (max-width: 767px) {
  .breadcrumbs li > span {
    display: none;
  }
}

.breadcrumbs li > strong {
  color: #2f2f2f;
  color: black;
  font-weight: 100;
  font-size: .85rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

/* 
        JONAS YOU DIRTY BOY 
        Stressa SJHØ!
*/
.catalog-product-view .breadcrumbs ul {
  color: #fff;
}

/* CART - _cart.scss */
.checkout-cart-index main .page-title h1 {
  text-transform: uppercase;
  font-weight: 200;
}

.checkout-cart-index main .products-grid button.btn-cart {
  background: #fff;
  padding: 0.5em 1em;
  border: 0;
  width: 100%;
  text-align: center;
  font-size: 1.2rem;
  font-weight: lighter;
  color: #222;
  margin-bottom: 1rem;
  margin-top: 1rem;
  display: block;
  font-size: 1.1rem;
  /*&:before {
                @include material-icons();
                display: inline;
                vertical-align: bottom;
                content: '\E854';
                margin-right: 1rem
            }*/
}

.checkout-cart-index main .products-grid button.btn-cart i.material-icons {
  position: relative;
  top: 0.25em;
  padding-bottom: 0.25em;
}

.checkout-cart-index main .products-grid button.btn-cart:hover {
  cursor: pointer;
  background: #fcfcfc;
}

.checkout-cart-index main .cart {
  /*.btn-checkout {
        background: $add-to-cart-bg;
        color: $add-to-cart-text-color;
        width: 100%;
        margin-top: 1rem;
        float: right;
        clear: both;
        padding: 1rem 2rem;
        display: flex;
        transition: ease 0.2s background;
        span {
          margin: auto 0;
          font-size: 1.2rem;
          & > :after {
              @include material-icons();
              display: inline;
              margin-left: 0.5rem;
              content: $btn-checkout-unicode-arrow;
              font-size: 1.5rem;
              font-weight: bold;
              vertical-align: bottom;
          }
        }
        &:hover {
          background: darken($add-to-cart-bg, 5%);
        }
      }*/
}

.checkout-cart-index main .cart h1 {
  margin: 0;
  padding: 0;
  margin-top: 0.5rem;
  text-transform: uppercase;
  font-weight: 300;
}

@media only screen and (max-width: 767px) {
  .checkout-cart-index main .cart .checkout-types.top {
    display: none;
  }
}

.checkout-cart-index main .cart .top .btn-checkout {
  display: none;
}

.checkout-cart-index main #shopping-cart-table li {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin: 1em 0em;
  padding: 0.5em;
  background: #FAFAFA;
}

@media only screen and (min-width: 768px) {
  .checkout-cart-index main #shopping-cart-table li {
    flex-wrap: nowrap;
  }
  .checkout-cart-index main #shopping-cart-table li > * {
    margin: auto 1em;
  }
}

.checkout-cart-index main #shopping-cart-table li .downloadable-options {
  padding: 1rem;
}

.checkout-cart-index main #shopping-cart-table li .downloadable-options dt {
  display: block;
}

.checkout-cart-index main #shopping-cart-table li .downloadable-options dd {
  display: inline-block;
}

.checkout-cart-index main #shopping-cart-table li .product-image {
  width: 15%;
  margin: auto 0;
}

@media only screen and (min-width: 768px) {
  .checkout-cart-index main #shopping-cart-table li .product-image img {
    height: auto;
    max-width: 100%;
  }
}

.checkout-cart-index main #shopping-cart-table li .product-info {
  width: 75%;
  padding-left: 0.75rem;
}

.checkout-cart-index main #shopping-cart-table li .product-info .product-name {
  font-size: 1.2rem;
  font-weight: lighter;
  color: #424242;
  line-height: 1.8rem;
}

@media only screen and (max-width: 767px) {
  .checkout-cart-index main #shopping-cart-table li .product-info .product-name {
    padding-right: 2.2rem;
  }
}

.checkout-cart-index main #shopping-cart-table li .product-info .short-description {
  display: none;
}

@media only screen and (min-width: 768px) {
  .checkout-cart-index main #shopping-cart-table li .product-info {
    width: 55%;
    padding-left: 0;
  }
  .checkout-cart-index main #shopping-cart-table li .product-info .product-name {
    font-size: 1.2rem;
    font-weight: lighter;
    color: #424242;
    line-height: 1.8rem;
    margin-top: 0.8em;
    margin-bottom: 0.8em;
  }
  .checkout-cart-index main #shopping-cart-table li .product-info .short-description {
    display: block;
  }
}

.checkout-cart-index main #shopping-cart-table li .price-summary {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.checkout-cart-index main #shopping-cart-table li .price-summary .qty-price-combo {
  display: flex;
  flex-direction: row-reverse;
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}

@media only screen and (max-width: 767px) {
  .checkout-cart-index main #shopping-cart-table li .price-summary .qty-price-combo {
    padding-right: 1rem;
  }
}

.checkout-cart-index main #shopping-cart-table li .price-summary .qty-price-combo .cart-price {
  margin: auto 0;
  padding-left: 1rem;
}

.checkout-cart-index main #shopping-cart-table li .price-summary input.qty {
  width: 3rem;
  text-align: center;
}

.checkout-cart-index main #shopping-cart-table li .price-summary .cart-price:last-child {
  font-weight: bold;
  font-size: 1.4rem;
}

@media only screen and (min-width: 768px) {
  .checkout-cart-index main #shopping-cart-table li .price-summary {
    width: 30%;
    text-align: right;
  }
  .checkout-cart-index main #shopping-cart-table li .price-summary input.qty {
    float: right;
  }
}

.checkout-cart-index main #shopping-cart-table li .actions {
  height: auto;
  width: auto;
}

.checkout-cart-index main #shopping-cart-table li .btn-remove {
  position: absolute;
  top: 0rem;
  right: 0rem;
  padding: 1rem;
}

@media only screen and (min-width: 768px) {
  .checkout-cart-index main #shopping-cart-table li .btn-remove {
    position: static;
    top: 0rem;
    padding: 0.25rem;
    background: #FAFAFA;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}

.checkout-cart-index main #shopping-cart-table .cart-footer {
  padding: 0.5rem 0;
  background: none;
}

.checkout-cart-index main #shopping-cart-table .cart-footer #empty_cart_button, .checkout-cart-index main #shopping-cart-table .cart-footer .btn-update {
  background: #F0F0F0;
  color: #3B3E40;
}

.checkout-cart-index main #shopping-cart-table .cart-footer #empty_cart_button:hover, .checkout-cart-index main #shopping-cart-table .cart-footer .btn-update:hover {
  background: #e3e3e3;
}

.checkout-cart-index main #shopping-cart-table .cart-footer button {
  width: 100%;
}

@media only screen and (min-width: 768px) {
  .checkout-cart-index main #shopping-cart-table .cart-footer button {
    width: auto;
  }
  .checkout-cart-index main #shopping-cart-table .cart-footer button.btn-update {
    /*float: right;*/
    margin: 0;
  }
  .checkout-cart-index main #shopping-cart-table .cart-footer button.btn-continue {
    margin-left: 0;
  }
}

.checkout-cart-index main #shopping-cart-table .link-edit {
  display: none;
}

.checkout-cart-index main #shopping-cart-table .link-wishlist.use-ajax {
  display: none;
}

.checkout-cart-index main .item-options {
  display: flex;
  margin: 0.25rem 0;
}

.checkout-cart-index main .item-options .option {
  display: flex;
  margin-right: 1em;
}

.checkout-cart-index main .item-options .option dt {
  margin-right: 0.2em;
}

.checkout-cart-index main .cart-table {
  width: 100%;
}

.checkout-cart-index main .cart-links a {
  color: white;
  font-size: 0.8rem;
  background: #222;
  padding: 0.5rem;
  margin: 0.3rem;
}

.checkout-cart-index main .btn-remove span {
  display: none;
}

.checkout-cart-index main .btn-remove .material-icons {
  position: relative;
  top: 0.1em;
}

.checkout-cart-index main .checkout-types li {
  float: right;
}

.checkout-cart-index main .cart-total-price-wrapper {
  display: flex;
}

.checkout-cart-index main .cart-total-price-wrapper .cart-totals {
  display: flex;
  flex-direction: column;
  flex: 1;
  float: right;
  text-transform: uppercase;
  padding-bottom: 1.5rem;
}

.checkout-cart-index main .cart-total-price-wrapper .cart-totals .a-right {
  padding: 0.2rem 0em;
  font-size: 1rem;
  font-weight: lighter;
}

.checkout-cart-index main .cart-total-price-wrapper .cart-totals .a-right .price {
  padding-left: 1rem;
  font-weight: lighter;
  display: block;
}

.checkout-cart-index main .cart-total-price-wrapper .cart-totals tfoot .a-right .price {
  font-weight: bold;
}

.checkout-cart-index main .cart-total-price-wrapper #show-discount-form {
  float: right;
  margin-bottom: 1rem;
  display: inline-block;
}

.checkout-cart-index main .cart-total-price-wrapper #show-discount-form:hover {
  cursor: pointer;
}

#shopping-cart-totals-table {
  width: 100%;
}

#discount-coupon-form {
  display: none;
  float: right;
  clear: both;
}

#discount-coupon-form .discount h2 {
  display: none;
  margin: 0;
}

#discount-coupon-form .discount .button-wrapper {
  margin: 0;
}

.crosssell {
  width: 100%;
}

.crosssell .product-name {
  min-height: 4rem;
}

.banner-area .banner-col-two:before {
  content: '';
}

/* CHECKOUT - _checkout_scss */
.checkout-onepage-index button.button.btn-cart {
  display: block;
}

.checkout-onepage-index #checkout-progress-wrapper {
  text-transform: uppercase;
}

.checkout-onepage-index #checkout-progress-wrapper dt.complete, .checkout-onepage-index #checkout-progress-wrapper dt {
  color: #444;
  font-size: 1.2rem;
  font-weight: 300;
}

.checkout-onepage-index #checkout-progress-wrapper dd.complete {
  color: #666;
  margin: 1rem 0;
}

.checkout-onepage-index #checkout-progress-wrapper dd.complete address {
  line-height: 1.5;
}

.checkout-onepage-index h1 {
  text-transform: uppercase;
  font-weight: 300;
}

.checkout-onepage-index .section .step-title {
  display: flex;
  position: relative;
  text-transform: uppercase;
}

.checkout-onepage-index .section .step-title h2 {
  font-weight: 200;
  display: inline;
  margin: 0.5rem 0;
  color: #ccc;
  line-height: 1.2em;
}

.checkout-onepage-index .section .step-title .number {
  display: inline;
  margin: auto 0;
  padding: 1rem;
}

.checkout-onepage-index .section .step-title a {
  display: none;
}

.checkout-onepage-index .section.active {
  padding: 1rem;
  border: 5px solid #F0F0F0;
}

.checkout-onepage-index .section.active h2 {
  color: #444;
}

.checkout-onepage-index .section.active .step {
  display: block;
}

.checkout-onepage-index .section .col2-set {
  display: flex;
  flex-direction: column;
}

.checkout-onepage-index .section .col2-set .col-1, .checkout-onepage-index .section .col2-set .col-2 {
  width: 100%;
  padding: 1rem;
}

.checkout-onepage-index .section .col2-set .col-1 form, .checkout-onepage-index .section .col2-set .col-2 form {
  margin-bottom: 1rem;
}

.checkout-onepage-index .section .col2-set .col-1 h3, .checkout-onepage-index .section .col2-set .col-2 h3 {
  font-size: 1.5rem;
  font-weight: 300;
  text-transform: uppercase;
  line-height: 1.4em;
}

@media only screen and (min-width: 1025px) {
  .checkout-onepage-index .section .col2-set {
    flex-direction: row;
  }
  .checkout-onepage-index .section .col2-set .col-1 {
    width: 50%;
  }
  .checkout-onepage-index .section .col2-set .col-2 {
    width: 50%;
  }
}

.checkout-onepage-index .section.active {
  display: block;
}

.checkout-onepage-index #checkout-review-table {
  width: 100%;
  text-transform: uppercase;
}

.checkout-onepage-index #review-buttons-container .btn-checkout {
  margin: 1rem 0;
}

.checkout-onepage-index .mdl-textfield .validation-advice {
  font-size: 0.7rem;
}

.checkout-onepage-succes .logo {
  max-width: 20rem;
  display: inline-block;
}

.checkout-onepage-succes .main-container {
  padding: 1rem;
}

.checkout-onepage-succes h1, .checkout-onepage-succes h2, .checkout-onepage-succes h3 {
  font-weight: 300;
}

/* CATEGORY - _category.scss */
@media only screen and (min-width: 768px) {
  #narrow-by-list {
    border-right: 2px #fefefe solid;
    padding-right: 1rem;
    margin-top: 0;
  }
}

#narrow-by-list dd {
  margin: 0;
}

#narrow-by-list dd ol {
  padding: 0;
  margin-bottom: 1rem;
  padding-bottom: 0.25rem;
  border-bottom: 1px solid #EEE;
}

#narrow-by-list dd a {
  color: #333;
  text-decoration: none;
}

#narrow-by-list dd a .count {
  font-size: 0.8rem;
  color: #666;
}

#narrow-by-list dd li {
  list-style: none;
}

#narrow-by-list dd li .count {
  color: #bbb;
  font-size: 0.8rem;
  float: right;
}

#narrow-by-list dt {
  color: #555;
  font-size: 1.2rem;
  margin-bottom: 0.25rem;
  text-transform: inherit;
}

.category-title {
  clear: both;
  display: none;
  text-align: center;
  padding-left: 1rem;
  margin-top: 1rem;
}

@media only screen and (min-width: 768px) {
  .category-title {
    text-align: left;
  }
}

.category-title h1 {
  color: #444;
  font-size: 2.4rem;
  margin: 0;
}

.category-description {
  display: none;
  font-size: 1.1em;
  margin-bottom: 1rem;
  padding: 0.5rem 1rem;
}

@media only screen and (min-width: 768px) {
  .category-description {
    display: block;
  }
}

.category-image {
  display: none;
}

@media only screen and (min-width: 768px) {
  .category-image {
    display: block;
  }
}

ul.catblocks {
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
}

ul.catblocks li {
  margin: auto;
  flex-grow: 1;
  flex-basis: 50%;
  text-align: center;
}

ul.catblocks li a {
  text-decoration: none;
  color: #333;
  font-weight: bold;
}

ul.catblocks li a:hover {
  color: #000;
}

ul.catblocks li span {
  display: block;
  color: #333;
  padding-top: 1em;
  padding-bottom: 2em;
}

.toolbar {
  background: #FFF;
  text-transform: inherit;
}

@media only screen and (min-width: 768px) {
  .toolbar .amount::after {
    display: none;
    /* Hidden by default */
    content: "|";
    margin-left: 0.3em;
    margin-right: 0.3em;
  }
  .toolbar .view-mode::after {
    content: "|";
    margin-right: 0.3em;
  }
}

.toolbar .amount {
  order: 10;
  display: inline-block;
  font-weight: lighter;
  color: #333;
}

.toolbar .limiter {
  display: none;
  /* Hidden by default */
  /* display: inline-block; */
}

.toolbar .sorter {
  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: 768px) {
  .toolbar .sorter {
    display: block;
  }
}

.toolbar .sorter .view-mode {
  display: none;
  /* Hidden by default */
  /* display: $display-view-mode; */
  float: left;
}

.toolbar .sorter .view-mode label {
  display: none;
}

.toolbar .sorter .sort-by {
  order: 5;
  float: none;
}

.toolbar .sorter .sort-by select {
  display: none;
  text-transform: inherit;
}

@media only screen and (min-width: 768px) {
  .toolbar .sorter .sort-by label {
    display: inline !important;
    margin-right: 0.5rem;
  }
}

.toolbar .sorter .sort-by .desc, .toolbar .sorter .sort-by .asc {
  display: none;
  /* Hidden by default */
  text-decoration: none;
}

.toolbar .sorter .sort-by .asc::after {
  content: ' \25B4';
}

.toolbar .sorter .sort-by .desc::after {
  content: ' \25BE';
}

@media only screen and (max-width: 767px) {
  .toolbar .sorter .sort-by .nuva-dropdown-wrapper {
    position: static;
  }
}

.toolbar .sorter .sort-by .nuva-dropdown-wrapper .nuva-dropdown {
  width: 150px;
  left: auto;
  right: 8px;
}

@media only screen and (max-width: 767px) {
  .toolbar .sorter .sort-by .nuva-dropdown-wrapper .nuva-dropdown {
    top: 0;
  }
}

@media only screen and (max-width: 767px) {
  .toolbar .sorter .sort-by .nuva-dropdown-wrapper {
    width: 100%;
  }
  .toolbar .sorter .sort-by .nuva-dropdown-wrapper .nuva-dropdown {
    width: 47.5%;
  }
  .toolbar .sorter .sort-by .nuva-dropdown-wrapper .nuva-dropdown li {
    padding: 0.5rem;
  }
}

.toolbar .sorter a.desc span, .toolbar .sorter a.asc span {
  display: none;
}

.toolbar .sorter > div {
  width: 100%;
  text-align: center;
}

@media only screen and (min-width: 768px) {
  .toolbar .sorter > div {
    width: auto;
  }
}

.toolbar .pager {
  clear: both;
  display: none;
}

.toolbar .pager .pages > ol {
  list-style: none;
  text-align: center;
}

.toolbar .pager .pages > ol li {
  margin-right: 1em;
  display: inline-block;
}

.toolbar .pager .pages strong {
  display: none;
}

.toolbar-bottom .toolbar {
  background: none;
  padding: 0 10px;
}

.toolbar-bottom .toolbar .pager {
  display: block;
  background: #FAFAFA;
  padding: 0.5rem;
}

.toolbar-bottom .toolbar .sorter {
  display: none;
}

/* Polarcore SlideToggle */
@media only screen and (max-width: 767px) {
  .col-wrapper aside.sidebar .block-title, .col-wrapper aside.sidebar .block-subtitle--filter {
    height: 3.25rem;
    width: 100%;
    line-height: 1.75rem;
    margin: 0;
    text-align: center;
    background: #222;
    color: #fff;
    padding: 0.75rem 1rem;
    text-transform: uppercase;
    border: 1px solid #222;
  }
  .col-wrapper aside.sidebar .block-title:hover, .col-wrapper aside.sidebar .block-subtitle--filter:hover {
    color: #fff;
  }
  .col-wrapper aside.sidebar .block-title span, .col-wrapper aside.sidebar .block-subtitle--filter span {
    width: 100%;
  }
  .col-wrapper aside.sidebar .block-subtitle--filter {
    width: 47.5%;
  }
  .col-wrapper aside.sidebar .block-subtitle--filter:before {
    content: "\E152";
    font-family: "Material Icons";
    text-rendering: optimizelegibility;
    font-feature-settings: "liga";
    font-style: normal;
    text-transform: none;
    line-height: 1;
    display: block;
    overflow: hidden;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1.25rem;
    margin-right: 0.5rem;
    display: inline-block;
    vertical-align: text-bottom;
    border: 1px solid #222;
  }
}

/* FOOTER - _footer.scss */
.footer-container {
  background: #fff;
  color: #000;
  padding-top: 3rem;
}

.footer-container .back-to-top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  right: 1.25rem;
  cursor: pointer;
  transition: transform 275ms ease-in-out;
  transform: translate3d(0, 4rem, 0);
  will-change: transform;
  backface-visibility: hidden;
}

.footer-container .back-to-top.show {
  transform: translate3d(0, -1.25rem, 0);
}

.footer-container .back-to-top__text {
  display: none;
}

.footer-container .back-to-top__icon {
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 100%;
  width: 44px;
  height: 44px;
  background-color: #222222;
}

.footer {
  width: 75%;
  margin: auto;
  max-width: 100%;
}

.footer .mdl-grid {
  margin-bottom: 2rem;
}

.footer h2 {
  text-transform: uppercase;
  color: #c0c0c0;
  font-size: 1.2rem;
  letter-spacing: 4px;
  font-weight: 300;
}

.footer ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.footer a {
  text-decoration: none;
  color: #000;
  font-weight: 400;
}

.footer a:hover {
  font-weight: 400;
}

.footer .block-title, .footer .col-wrapper aside.sidebar .block-subtitle--filter, .col-wrapper aside.sidebar .footer .block-subtitle--filter {
  color: #5C657D;
  font-size: 1.3rem;
  line-height: 2.5rem;
}

.footer .footer-logos {
  text-align: center;
  padding: 1.5rem 0;
  width: 100%;
}

.footer .footer-logos img {
  height: 25px;
  width: auto;
  min-width: auto;
  padding-right: 0.5rem;
}

.footer .copyright {
  padding: 1rem;
  width: 100%;
  text-align: center;
  color: white;
  background: #222222;
  font-size: 11px;
}

.footer .newsletter {
  background: #fff;
  border-radius: 4px;
}

.footer .newsletter #newsletter {
  color: #000;
}

.footer .newsletter .button {
  padding: 1em;
}

.footer #newsletter-validate-detail {
  display: flex;
}

.footer #newsletter-validate-detail .button {
  margin-left: 0.5rem;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 14px;
}

@media only screen and (min-width: 1025px) {
  .footer .footer-right {
    padding-left: 1rem;
  }
}

.footer .footer-middle {
  padding: 0 1rem;
  text-align: center;
}

.footer .footer-right, .footer .footer-left {
  text-align: center;
}

@media only screen and (min-width: 1025px) {
  .footer .footer-right, .footer .footer-left {
    text-align: left;
  }
}

/* FORM - _form.scss */
input {
  padding: 0.8em;
  border: 1px solid #EEEEEE;
  outline: none;
}

textarea {
  padding: 0.8em;
  border: 1px solid #EEEEEE;
  width: 100%;
  max-width: 32rem;
  min-height: 16rem;
}

.validation-advice {
  position: absolute;
  font-size: 0.8em;
  color: #E57373;
}

/* HEADER NAV - _header-nav.scss */
.logo svg {
  height: 56px;
  width: auto;
}

@media only screen and (max-width: 767px) {
  .logo svg {
    height: 40px;
    width: 100%;
  }
}

@media only screen and (min-width: 768px) {
  .logo {
    text-align: center;
  }
}

.right {
  justify-content: flex-end;
}

.header-nav {
  display: none;
  padding: 0 20px;
  background: rgba(255, 255, 255, 0.8);
  text-transform: uppercase;
}

@media only screen and (min-width: 768px) {
  .header-nav {
    display: block;
  }
}

.header-nav #nav .nav-primary {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}

.header-nav #nav .nav-primary::after {
  clear: both;
  content: "";
  display: table;
}

.header-nav #nav .nav-primary .active {
  background: #101010;
}

.header-nav #nav .nav-primary .level1 a {
  color: #363636;
  height: 0;
}

.header-nav #nav .nav-primary .level1 .has-children:before {
  content: " + ";
  float: right;
}

.header-nav #nav .nav-primary li {
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  white-space: nowrap;
}

.header-nav #nav .nav-primary li.active a {
  color: #fff !important;
}

.header-nav #nav .nav-primary li a {
  padding: 0.75rem 1rem;
  text-align: center;
  color: #363636;
  font-weight: 500;
  text-decoration: none;
  font-size: 1em;
  display: inline-block;
  width: 100%;
  text-align: left;
  height: 45px;
}

.header-nav #nav .nav-primary li a:hover {
  font-weight: 500;
}

.header-nav #nav .nav-primary li a .menu-active a {
  color: #fff;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .header-nav #nav .nav-primary li a {
    padding: 0.75rem;
    font-size: 1rem;
  }
}

.header-nav #nav .nav-primary li.menu-active, .header-nav #nav .nav-primary li:hover {
  font-weight: 300;
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.header-nav #nav .nav-primary li:focus {
  background: black;
}

.header-nav #nav .nav-primary > li ul {
  padding: 0;
  margin: 0;
  display: none;
  position: absolute;
  top: 100%;
  background: #363636;
  border-top: solid 2px #292929;
  border-bottom: solid 2px black;
}

.header-nav #nav .nav-primary li > ul {
  display: none;
  z-index: 3000;
}

.header-nav #nav .nav-primary li > ul ul {
  position: absolute;
  flex: 0 1 auto;
  top: 0;
  left: 100%;
}

.header-nav #nav .nav-primary li.menu-active > ul,
.header-nav #nav .nav-primary li:hover > ul {
  display: block;
}

.search-button i {
  margin-top: 1rem;
  margin-right: 0.5rem;
}

@media only screen and (max-width: 767px) {
  .search-button i {
    margin-top: 0;
    margin-right: 0.5rem;
  }
}

.nav-home-icon {
  vertical-align: middle;
}

/* HEADER - _header.scss */
.head-search-custom {
  display: none;
}

.head-search-custom .search-button {
  display: none;
}

body .header {
  width: 100%;
  position: relative;
  z-index: 105;
}

body .header,
body .mdl-layout__drawer-button {
  will-change: transform, box-shadow;
  backface-visibility: hidden;
}

body.sticky {
  /*.main-container:first-of-type {
      padding-top: map-get($header-height, 'small-up')+25px;

      @media only screen and (min-width:768px) {
        padding-top: map-get($header-height, 'medium-up')+25px;
      }
    }*/
}

body.sticky--up .header,
body.sticky--up .mdl-layout__drawer-button {
  /*transform: translateY(-84px);*/
}

body.sticky--up .header .header-first,
body.sticky--up .mdl-layout__drawer-button .header-first {
  display: none;
}

body.sticky .push-top {
  padding-top: 0 !important;
}

@media only screen and (min-width: 768px) {
  body.sticky .push-top {
    padding-top: 0 !important;
  }
}

body.sticky .header,
body.sticky .mdl-layout__drawer-button {
  will-change: transform, box-shadow;
  backface-visibility: hidden;
  top: 10px;
  width: 100%;
  z-index: 100;
  position: fixed;
  transition: transform 450ms ease-in-out, box-shadow 350ms ease-in-out;
}

body.sticky .header-nav {
  width: 100%;
}

body.sticky .mdl-layout__drawer-button {
  z-index: 120;
  width: auto;
}

body .cms-hjem .main-container:first-of-type {
  padding-top: 0;
}

#header .header-content {
  padding: 1.25rem 0 0.25rem;
  text-align: center;
  background: white;
  min-height: 4rem;
}

#header .header-content .search-button {
  float: left;
  margin-left: 4rem;
  margin-top: -0.1rem;
}

@media only screen and (max-width: 767px) {
  #header .header-content .search-button {
    color: black;
  }
}

@media only screen and (min-width: 768px) {
  #header .header-content {
    padding: 0 1rem;
  }
}

@media only screen and (min-width: 768px) {
  #header .header-content .header-search {
    display: inline-block;
    width: 40%;
    vertical-align: middle;
  }
}

#header .header-content .header-search #search_autocomplete {
  background: white;
  z-index: 20;
  border: 1px solid #F0F0F0;
  padding: 1rem 0;
  color: #333;
  text-align: left;
}

#header .header-content .header-search #search_autocomplete li {
  padding-left: 1rem;
}

#header .header-content .header-search #search_autocomplete .selected {
  background: #F0F0F0;
}

#header .header-content .header-search #search_autocomplete .amount {
  display: none;
}

#header .header-content .header-search .input-box {
  display: flex;
  white-space: nowrap;
  margin-left: -1rem;
}

#header .header-content .header-search .input-box label {
  color: #9E9E9E;
  font-size: 14px;
}

#header .header-content .header-search .input-box input#search {
  width: 100%;
  font-size: 1rem;
}

#header .header-content .header-search .input-box button.search-button {
  vertical-align: middle;
  height: 40px;
  margin-left: -0.25rem;
  font-size: 1.75rem;
  margin-top: 1rem;
  outline: none;
  background: transparent;
  border: 0;
  box-shadow: none;
  color: #222;
}

#header .header-content .header-search .input-box button.search-button i {
  vertical-align: middle;
  font-size: inherit;
  margin-top: -0.5rem;
}

#header .header-content .header-search .input-box button.search-button:hover {
  color: #090909;
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  #header .header-content .header-minicart {
    z-index: 2;
    margin-right: 0;
    margin-top: 0.2rem;
  }
  #header .header-content .header-search {
    position: absolute;
    padding: 1rem 2rem;
    display: none;
    width: 100%;
    background: #fff;
    z-index: 999;
    margin-top: 0.25rem;
  }
  #header .header-content .header-search #search_mini_form {
    width: 100%;
    margin: auto;
  }
  #header .header-content .header-search #search_mini_form .search-wrapper {
    width: 100%;
    margin-left: 0;
  }
}

#header .header-content .show-search {
  display: inline-block;
  animation: showsearch 0.4s ease;
}

.header-search-large {
  position: absolute;
  padding: 1rem 2rem;
  display: none;
  width: 100%;
  background: #fafafa;
  z-index: 999;
  margin-top: 0;
}

.header-search-large #search_mini_form {
  width: 100%;
  margin: auto;
}

.header-search-large #search_mini_form .search-wrapper {
  width: 100%;
  margin-left: 0;
}

.header-search-large #search_autocomplete {
  display: none;
  background: white;
  z-index: 20;
  border: 1px solid #F0F0F0;
  padding: 1rem 0;
  color: #333;
  text-align: left;
}

.header-search-large #search_autocomplete li {
  padding-left: 1rem;
}

.header-search-large #search_autocomplete .selected {
  background: #F0F0F0;
}

.header-search-large #search_autocomplete .amount {
  display: none;
}

.header-search-large .input-box {
  display: flex;
  white-space: nowrap;
  margin-left: -1rem;
}

.header-search-large .input-box label {
  color: #9E9E9E;
  font-size: 14px;
}

.header-search-large .input-box input#search {
  width: 100%;
  font-size: 1rem;
}

.header-search-large .input-box button.search-button {
  vertical-align: middle;
  height: 40px;
  margin-left: -0.25rem;
  font-size: 1.75rem;
  margin-top: 1rem;
  outline: none;
  background: transparent;
  border: 0;
  box-shadow: none;
  color: #222;
}

.header-search-large .input-box button.search-button i {
  vertical-align: middle;
  font-size: inherit;
  margin-top: -0.5rem;
}

.header-search-large .input-box button.search-button:hover {
  color: #090909;
  cursor: pointer;
}

.show-search {
  display: inline-block;
  animation: showsearch 0.4s ease;
}

.header-minicart {
  position: absolute;
  margin: 0;
  text-align: left;
  right: 0;
  top: 0;
  width: 48px;
  height: 48px;
}

.header-minicart .cart-icon {
  position: relative;
  z-index: 2;
  display: inline-block;
  color: #000;
  width: 24px;
  height: 24px;
  margin-right: 0.5rem;
  margin-top: 1.1rem;
}

.header-minicart .cart-icon .count {
  position: absolute;
  color: #FFF;
  background: #000;
  padding: 0rem 0.3rem;
  line-height: 1rem;
  right: 0rem;
  top: 0rem;
  border-radius: 50%;
  font-size: 0.7rem;
  display: flex;
}

.header-minicart .cart-icon .count span {
  margin: auto;
}

.header-minicart .cart-details {
  display: none;
  color: #424242;
  float: left;
}

.header-minicart .cart-details .cart {
  font-size: 1.2em;
}

.header-minicart .cart-details .subtotal {
  font-size: 0.85em;
}

.header-minicart .cart-details .subtotal .label {
  font-weight: 600;
}

@media only screen and (min-width: 768px) {
  .header-minicart {
    position: static;
    top: auto;
    right: auto;
    float: right;
    height: auto;
    width: auto;
  }
  .header-minicart .cart-icon {
    float: left;
    margin-top: 1rem;
  }
  .header-minicart .cart-details {
    display: block;
  }
}

@media screen and (min-width: 767px) and (max-width: 100%) {
  .header-minicart {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
}

.header-minicart::after {
  clear: both;
  content: "";
  display: table;
}

.logo {
  display: block;
  text-align: center;
}

@media only screen and (min-width: 768px) {
  .logo {
    display: inline-block;
    padding: 1rem;
  }
}

.logo img.logo-large {
  display: none;
}

.logo img.logo-small {
  width: 10rem;
  margin-top: -1rem;
}

@media only screen and (min-width: 768px) {
  .logo img.logo-small {
    display: none;
  }
  .logo img.logo-large {
    display: block;
    max-width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  body.sticky .header,
  body.sticky .mdl-layout__drawer-button {
    top: 0;
    position: relative;
  }
  body.sticky .main-container:first-of-type {
    padding-top: 0;
  }
}

/* MOBILE NAV - _mobile-nav.scss */
.mdl-layout__container {
  position: relative;
  width: auto;
  height: auto;
}

.mdl-layout__container .mdl-layout {
  overflow-y: visible;
  overflow-x: visible;
}

.mdl-layout__drawer-button {
  display: block;
  width: auto;
  max-height: 3rem;
  line-height: auto;
  color: black;
  z-index: 110;
}

.mdl-layout__drawer-button .menu-label {
  margin-top: -2.8rem;
  font-size: 0.54rem;
  position: relative;
  display: block;
}

@media only screen and (min-width: 768px) {
  .mdl-layout__drawer-button .menu-label {
    display: none;
  }
}

@media only screen and (min-width: 768px) {
  .mdl-layout__drawer-button {
    display: none;
  }
}

.mdl-layout__drawer-button i.material-icons {
  padding: 0.5rem;
  font-size: 2rem;
}

body.popup-open {
  overflow: hidden;
  position: fixed;
}

body.popup-open .mdl-layout__drawer-button {
  z-index: initial;
}

.mdl-layout__drawer {
  position: fixed;
  overflow-y: auto;
  border-right: none;
  z-index: 160;
  background: #fff;
}

.mdl-layout__drawer .mdl-layout__drawer__home {
  position: absolute;
  top: 0.35rem;
  left: 0.5rem;
  font-size: 2rem;
  padding: 0.25rem;
  color: white;
}

.mdl-layout__drawer .mdl-layout__drawer__title {
  margin: 0;
  padding: 1rem 0;
  font-size: 1.25rem;
  font-weight: 300;
  letter-spacing: 0.5px;
  text-align: center;
  text-transform: uppercase;
  background-color: #222;
  color: white;
}

.mdl-layout__drawer .mdl-layout__drawer__close {
  position: absolute;
  top: 0.35rem;
  right: 0.5rem;
  font-size: 2rem;
  padding: 0.25rem;
  color: white;
}

.mdl-layout__drawer #nav-mobile {
  padding: 0 !important;
  background: #FFF;
}

.mdl-layout__drawer #nav-mobile .has-children:after {
  content: " + ";
  float: right;
}

.mdl-layout__drawer #nav-mobile .open > .has-children:after {
  content: " - ";
  float: right;
}

.mdl-layout__drawer > ul li ul, .mdl-layout__drawer .nav-primary li ul {
  display: none;
  background-color: #f4f4f4;
  /* Level 2 Indentation*/
}

.mdl-layout__drawer > ul li ul > li > a, .mdl-layout__drawer .nav-primary li ul > li > a {
  padding-left: 1.5rem;
}

.mdl-layout__drawer > ul li ul > li > ul > li > a, .mdl-layout__drawer .nav-primary li ul > li > ul > li > a {
  padding-left: 3rem;
}

.mdl-layout__drawer > ul li a, .mdl-layout__drawer .nav-primary li a {
  display: block;
  padding: 1rem;
  font-size: 1.1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.mdl-layout__drawer > ul li.open, .mdl-layout__drawer > ul li.open > a, .mdl-layout__drawer .nav-primary li.open, .mdl-layout__drawer .nav-primary li.open > a {
  border-bottom: 1px solid #222;
}

.mdl-layout__drawer > ul li.nav-home, .mdl-layout__drawer .nav-primary li.nav-home {
  display: none;
}

ul.top-links__mobile {
  background: #2c313e;
}

ul.top-links__mobile:first-of-type {
  border-top: 3px solid #222;
}

ul.top-links__mobile li {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

/* PRODUCT-GRID - _product-grid.scss */
.products-grid {
  display: flex;
  align-items: stretch;
  flex-flow: row wrap;
  padding: 0;
  position: relative;
  clear: both;
}

.products-grid .actions {
  display: none;
  text-align: center;
}

.products-grid .actions .add-to-links {
  list-style: none;
  display: flex;
  padding: 0;
  display: none;
  padding-top: 2rem;
}

.products-grid .actions .add-to-links li {
  padding-right: 1em;
}

@media only screen and (max-width: 767px) {
  .products-grid .actions .add-to-links .inner span {
    display: none;
  }
}

.products-grid .actions .add-to-links li {
  float: left;
}

.products-grid .actions .add-to-links li:last-child {
  padding-right: 0;
}

.products-grid .actions .add-to-links li .inner {
  display: block;
}

.products-grid .actions .add-to-links li .inner span {
  display: none;
}

.products-grid button.btn-cart {
  /* display: none; */
}

.products-grid button.btn-cart .material-icons {
  display: none;
}

.products-grid .combo-box {
  width: 100%;
}

.products-grid .combo-box .review-wrapper {
  width: 100%;
  text-align: center;
}

.products-grid .combo-box .review-wrapper .amount {
  position: relative;
  bottom: 0.1rem;
}

.products-grid .configurable-swatch-list {
  list-style: none;
  padding: 0;
  text-align: center;
}

.products-grid .configurable-swatch-list li {
  display: inline-block;
}

.products-grid .configurable-swatch-list li .swatch-label img {
  border: 1px #EEE solid;
}

.products-grid .item {
  display: flex;
  list-style: none;
  padding: 4px;
  background: transparent;
  will-change: box-shadow;
  transition: box-shadow 0.15s ease;
  /*@media only screen and (min-width:768px){
      &:hover img {
        opacity: .9;
      }
    }*/
}

.products-grid .item .inner-item {
  background: white;
  width: 100%;
}

.products-grid .item .inner-item .image-wrapper {
  position: relative;
  text-align: center;
}

.products-grid .item .product-info {
  display: flex;
  flex-direction: column;
  padding: 1em;
  margin-bottom: 1em;
}

.products-grid .item .product-info .review-wrapper {
  font-size: 0.8em;
}

.products-grid .item .product-info .review-wrapper .rating-box {
  display: inline-block;
}

.products-grid .item .product-info .review-wrapper .rating-box i {
  position: relative;
  top: 0.1em;
  color: #fed227;
  font-size: 1.5em;
}

.products-grid .item .product-info .product-name {
  text-align: center;
  text-decoration: none;
  font-size: 1.2rem;
  line-height: 1.5rem;
  margin: 0;
  padding-bottom: 0.5rem;
}

.products-grid .item .product-info .product-name a {
  color: #424242;
  font-weight: 300;
  text-decoration: none;
}

.products-grid .item .product-info .product-name a:hover {
  color: #828282;
}

.products-grid .item .product-info .product-brand {
  text-align: center;
  text-decoration: none;
  font-size: 1.1rem;
  line-height: 1.5rem;
  margin: 0;
  padding-bottom: 0.5rem;
}

.products-grid .item .product-info .product-brand a {
  color: #424242;
  font-weight: 300;
  text-decoration: none;
}

.products-grid .item .product-info .product-brand a:hover {
  color: #828282;
}

.products-grid .item .price-box {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: auto;
}

@media only screen and (min-width: 1025px) {
  .products-grid .item .price-box {
    height: 1.8rem;
    flex-direction: row;
  }
}

.products-grid .item .price-box .price {
  margin: 0;
  font-size: 1rem;
  font-weight: lighter;
}

.products-grid .item .price-box .price-label {
  display: none;
  color: #757575;
}

.products-grid .item .price-box .regular-price {
  margin: 0;
  color: #757575;
}

.products-grid .item .price-box .old-price {
  color: #333;
  order: 5;
  margin: 0;
}

.products-grid .item .price-box .old-price .price {
  text-decoration: line-through;
  padding-left: 0;
}

@media only screen and (min-width: 1025px) {
  .products-grid .item .price-box .old-price .price {
    padding-left: 0.5rem;
  }
}

.products-grid .item .price-box .special-price {
  margin: 0;
  color: #8e3030;
  font-weight: bold;
  order: 1;
}

.products-grid .item .price-box .price-from {
  margin: 0;
  font-size: 1rem;
  color: #757575;
}

.products-grid .item .price-box .price-from .price-label {
  text-transform: uppercase;
  display: inline;
}

.products-grid .item .price-box .price-to {
  margin: 0;
  display: none;
  color: #757575;
}

.products-grid .item .price-box .minimal-price {
  margin: 0;
  color: #757575;
}

.products-grid .item .price-box .minimal-price-link {
  padding-left: 0;
}

@media only screen and (min-width: 1025px) {
  .products-grid .item .price-box .minimal-price-link {
    padding-left: 0.5rem;
  }
}

.products-grid .item .price-box .minimal-price-link .label {
  display: none;
}

.products-grid .item .price-box .map-link {
  margin: 0;
  font-size: 1rem;
  order: 10;
  padding-left: 0;
}

@media only screen and (min-width: 1025px) {
  .products-grid .item .price-box .map-link {
    padding-left: 0.5rem;
  }
}

.products-grid .item .product-info {
  text-align: left;
}

.products-grid .item .product-info .actions .button.view-details {
  display: none;
}

/* PRODUCT-LIST - _product_list.scss */
.products-list {
  display: flex;
  align-items: stretch;
  flex-flow: column;
  padding: 0;
  position: relative;
  clear: both;
}

.products-list .link-compare .material-icons, .products-list .link-wishlist .material-icons {
  top: 0.25em;
  position: relative;
}

.products-list .add-to-links {
  list-style: none;
  display: flex;
  padding: 0;
}

.products-list .add-to-links li {
  padding-right: 1em;
}

@media only screen and (max-width: 767px) {
  .products-list .add-to-links .inner span {
    display: none;
  }
}

.products-list button.btn-cart {
  background: #222;
  padding: 0.5em 1em;
  border: 0;
  width: 100%;
  text-align: center;
  font-size: 1.2rem;
  font-weight: lighter;
  color: white;
  margin-bottom: 1rem;
  margin-top: 1rem;
  display: inline;
}

.products-list button.btn-cart i.material-icons {
  position: relative;
  top: 0.25em;
  padding-bottom: 0.25em;
}

.products-list button.btn-cart:hover {
  cursor: pointer;
  background: #1f1f1f;
}

@media only screen and (min-width: 768px) {
  .products-list button.btn-cart {
    max-width: 50%;
  }
}

.products-list button.btn-cart span.inner {
  position: relative;
  top: -0.2em;
}

.products-list .configurable-swatch-list {
  list-style: none;
  padding: 0;
  clear: both;
}

.products-list .configurable-swatch-list li {
  float: left;
  display: inline-block;
  padding-left: 0.2rem;
}

.products-list .configurable-swatch-list li .swatch-label img {
  border: 1px #EEE solid;
}

.products-list .item {
  list-style: none;
  flex-grow: 1;
  padding: 1em;
  background: transparent;
  display: flex;
  border-bottom: #EEEEEE solid 1px;
}

@media only screen and (max-width: 767px) {
  .products-list .item {
    padding-left: 0;
  }
}

.products-list .item .image-wrapper {
  position: relative;
  width: 25%;
  order: 1;
}

@media only screen and (min-width: 768px) {
  .products-list .item .image-wrapper {
    order: 0;
  }
}

.products-list .item .product-name {
  text-align: left;
  text-decoration: none;
  font-size: 1.6rem;
  line-height: 2.2rem;
  margin: 0;
  padding-bottom: 0.5rem;
}

@media only screen and (min-width: 768px) {
  .products-list .item .product-name {
    font-size: 2rem;
    line-height: 2.8rem;
  }
}

.products-list .item .product-name a {
  margin: 0;
  color: #424242;
  font-weight: 300;
  text-decoration: none;
}

.products-list .item .product-name a:hover {
  color: #828282;
}

.products-list .item .ratings {
  clear: both;
}

.products-list .item .ratings .rating-links a:first-child:after {
  content: '\a';
  white-space: pre;
}

.products-list .item .ratings .rating-box {
  display: inline-block;
}

.products-list .item .ratings .amount {
  position: absolute;
}

.products-list .item .ratings i {
  color: #fed227;
}

.products-list .item .price-box {
  width: 100%;
  margin-top: 1rem;
  min-height: 3rem;
}

.products-list .item .price-box .price {
  font-size: 1.6rem;
  font-style: lighter;
}

.products-list .item .price-box .regular-price {
  color: #757575;
}

.products-list .item .price-box .old-price {
  text-decoration: line-through;
  margin: 0;
  color: #333;
}

.products-list .item .price-box .old-price .price-label {
  display: none;
}

.products-list .item .price-box .old-price .price {
  font-size: 1.2rem;
}

.products-list .item .price-box .special-price {
  font-weight: bold;
  margin: 0;
  margin-top: 0.8em;
  color: #8e3030;
}

.products-list .item .price-box .special-price .price-label {
  display: none;
}

.products-list .item .price-box .special-price .price {
  font-size: 1.6rem;
}

.products-list .item .price-box .price-to, .products-list .item .price-box .price-from {
  width: 50%;
  font-size: 1.2rem;
  float: left;
}

.products-list .item .price-box .price-to .price, .products-list .item .price-box .price-from .price {
  font-size: 1.2rem;
  font-weight: bold;
}

.products-list .item .product-shop {
  width: 75%;
  padding: 0 1em;
}

.products-list .item p.action {
  margin-bottom: 0;
}

.products-list .item p.action .button.view-details {
  display: none;
}

/* PRODUCT - _product.scss */
.product-view {
  background: #fff;
}

.product-view #product-attribute-specs-table .label {
  text-align: left;
}

.product-view .select.input-box {
  position: relative;
  display: flex;
  max-width: 100%;
  background: white;
  margin-bottom: 1rem;
}

.product-view .select.input-box .validation-failed {
  border-color: #ff4081;
}

.product-view .select.input-box .validation-advice {
  display: none;
}

@media only screen and (min-width: 768px) {
  .product-view .select.input-box {
    max-width: 100%;
  }
}

.product-view .select.input-box:after {
  font-family: "Material Icons";
  text-rendering: optimizelegibility;
  font-feature-settings: "liga";
  font-style: normal;
  text-transform: none;
  line-height: 1;
  display: block;
  overflow: hidden;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  user-select: none;
  pointer-events: none;
  right: 0rem;
  font-size: 1.2rem;
  content: '\E313';
  margin: auto;
  color: #444;
  height: 37px;
  line-height: 37px;
  vertical-align: middle;
  padding: 0rem 0.5rem;
  background: #fff;
}

.product-view .select.input-box .validation-advice {
  right: 0;
  top: -1.5rem;
}

.product-view .select.input-box select {
  position: relative;
  appearance: none;
  outline: none;
  background: transparent;
  width: 100%;
  height: 37px;
  font-size: 0.9rem;
  padding-left: 0.75rem;
}

@media only screen and (min-width: 768px) {
  .product-view .select.input-box select {
    padding: 0.75rem;
  }
}

.product-view .select.input-box select:hover {
  cursor: pointer;
  background: #fcfcfc;
}

.product-view .select.input-box select:disabled {
  background: #c7c7c7;
  color: #444;
}

.product-view .select.input-box select::-ms-expand {
  display: none;
}

.product-view .add-to-cart-wrapper .swatch-attr .input-box {
  background: transparent;
  border: 0;
}

.product-view .add-to-cart-wrapper .swatch-attr .input-box:after {
  content: '';
  display: none;
}

.product-view .add-to-cart-wrapper .swatch-attr .input-box select:hover {
  cursor: pointer;
  background: transparent;
}

.product-view .add-to-cart-wrapper .swatch-attr .input-box select:disabled {
  background: transparent;
}

.product-view .product-shop .inner.grouped {
  background: transparent;
  padding: 0;
}

.product-view .product-shop .inner.grouped .grouped-item {
  display: flex;
  position: relative;
  border-bottom: 1px solid #f0f0f0;
  margin-bottom: 1rem;
}

.product-view .product-shop .inner.grouped .grouped-item div[id*="options_item"] {
  position: relative;
}

.product-view .product-shop .inner.grouped .grouped-item div[id*="options_item"] .validation-advice {
  width: 50%;
  text-align: right;
}

.product-view .product-shop .inner.grouped .grouped-item .product-image {
  width: 25%;
}

.product-view .product-shop .inner.grouped .grouped-item .product-info {
  width: 75%;
  padding: 0.5rem;
}

.product-view .product-shop .inner.grouped .grouped-item .product-info .product-name {
  width: 75%;
  font-size: 1.2rem;
  margin: 0;
  line-height: 1.5rem;
  margin-bottom: 0.5rem;
}

@media only screen and (min-width: 768px) {
  .product-view .product-shop .inner.grouped .grouped-item .product-info .product-name {
    line-height: 1.25rem;
    margin-right: 25%;
    font-weight: 700;
  }
}

.product-view .product-shop .inner.grouped .grouped-item .product-info .product-name a {
  font-weight: inherit;
  color: inherit;
}

.product-view .product-shop .inner.grouped .grouped-item .price-box {
  text-align: left;
  font-size: 1.2rem;
}

@media only screen and (min-width: 768px) {
  .product-view .product-shop .inner.grouped .grouped-item .price-box {
    position: absolute;
    right: 0;
    top: 0.2rem;
    text-align: right;
  }
}

.product-view .product-shop .inner.bundle .mdl-checkbox {
  height: auto;
}

.product-view .product-shop .inner.bundle .price-info .price-box {
  padding: 0;
}

.product-view .product-shop .inner.bundle .price-info .price-box .minimal-price,
.product-view .product-shop .inner.bundle .price-info .price-box .price-from,
.product-view .product-shop .inner.bundle .price-info .price-box .price-to {
  margin-bottom: 0;
}

.product-view .product-shop .inner.bundle .price-info .price-box .price-label {
  display: initial;
}

.product-view .product-shop .inner.bundle .wrapper .extra-info::after {
  clear: both;
  content: "";
  display: table;
}

.product-view .product-shop .inner.bundle .wrapper .extra-info .availability {
  margin-bottom: 0;
}

.product-view .product-shop .inner.bundle .wrapper .add-to-cart-wrapper .price-box-bundle,
.product-view .product-shop .inner.bundle .wrapper .add-to-cart-wrapper .availability {
  display: none;
}

.product-view .product-shop .inner.bundle .wrapper .add-to-cart-wrapper .product-options .mdl-checkbox__label,
.product-view .product-shop .inner.bundle .wrapper .add-to-cart-wrapper .product-options .mdl-radio__label {
  font-size: 1rem;
}

.product-view .product-shop .inner.bundle .wrapper .add-to-cart-wrapper .product-options dl {
  margin-top: 0;
  margin-bottom: 2rem;
}

.product-view .product-shop .inner.bundle .wrapper .add-to-cart-wrapper .product-options select {
  padding-right: calc(2.275rem + 0.75rem);
}

.product-view .product-shop .inner.bundle .wrapper .add-to-cart-wrapper .product-options select[multiple="multiple"] {
  padding-right: 6px;
}

.product-view .product-shop .inner.bundle .wrapper .add-to-cart-wrapper .product-options .qty-holder {
  width: 100%;
  max-width: 75px;
}

.product-view .product-shop .inner.bundle .wrapper .add-to-cart-wrapper .product-options .qty-holder .mdl-textfield__error {
  white-space: nowrap;
}

.product-view .product-shop .inner.bundle .wrapper .add-to-cart-wrapper .product-options .input-box {
  margin-bottom: 0.5rem;
}

.product-view .product-shop .inner.bundle .wrapper .add-to-cart-wrapper .product-options .input-box--single {
  margin-bottom: 0.5rem;
}

.product-view .product-shop .inner.bundle .wrapper .add-to-cart-wrapper .product-options .input-radio {
  margin-bottom: 0.5rem;
}

@media only screen and (max-width: 767px) {
  .product-view .mdl-grid {
    padding: 0;
  }
}

.product-view .mini-products-list {
  display: flex;
}

.product-view .mini-products-list .product {
  padding: 1rem;
}

.product-view .product-name {
  font-weight: lighter;
  line-height: 1.5em;
}

.product-view .short-description {
  margin-bottom: 1em;
}

.product-view .short-description ul {
  margin-left: 15px;
  padding: 15px 0;
}

.product-view .product-image-gallery .gallery-image {
  display: none;
}

.product-view .product-image-gallery .visible {
  display: block;
}

.product-view .product-image-thumbs {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
}

.product-view .mini-description {
  position: relative;
  top: -1rem;
  min-height: 1rem;
}

.product-view .mini-description .sku-wrapper {
  margin-top: 0.4rem;
  float: right;
  display: none;
}

.product-view .mini-description .sku {
  color: #BBB;
}

.product-view .mini-description .article-sku-label {
  padding-left: 1rem;
  padding-right: 0.25rem;
  color: #BBB;
}

.product-view .mini-description .ratings {
  font-size: 1rem;
  display: inline;
}

.product-view .mini-description .ratings .rating-box {
  display: inline-block;
}

.product-view .mini-description .ratings .rating-box i {
  position: relative;
  top: 0.3em;
  color: #fed227;
  font-size: 1.5rem;
}

.product-view .price-info {
  font-size: 1.6rem;
  text-align: left;
  float: left;
  width: 100%;
}

.product-view .price-info .price-label {
  display: none;
}

.product-view .price-info .price {
  font-weight: 800;
}

.product-view .price-info .price-box {
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
}

.product-view .price-info .old-price, .product-view .price-info .special-price, .product-view .price-info .regular-price {
  margin: 0;
  color: #fff;
  padding: 0.2rem;
}

.product-view .price-info .regular-price {
  font-size: 1rem;
}

.product-view .price-info .old-price {
  text-decoration: line-through;
  color: #333;
  order: 3;
}

.product-view .price-info .old-price .price {
  font-size: 1.3rem;
}

.product-view .price-info .special-price {
  color: #8e3030;
  font-size: 2rem;
  font-weight: 800;
  order: 1;
}

.product-view .price-info .tier-prices {
  padding-bottom: 1rem;
}

.product-view .price-info .map-info a {
  font-size: 0.9rem;
}

.product-view .price-info .map-info .msrp-price-hide-message {
  font-size: 0.9rem;
  clear: both;
  float: right;
}

.product-view .price-info .map-info .msrp-price-hide-message::after {
  clear: both;
  content: "";
  display: table;
}

.product-view .price-info .map-info .old-price {
  float: right;
  clear: both;
}

.product-view select {
  display: block;
  padding: 8px 6px;
  width: 100%;
  border: 1px solid #000;
  border-radius: 0;
  background-color: #fff;
  color: #212121;
}

@media only screen and (max-width: 767px) {
  .product-view select {
    font-size: 16px;
  }
}

@media only screen and (min-width: 768px) {
  .product-view select {
    width: auto;
  }
}

.product-view img {
  transition: ease 0.2s;
}

.product-view #color_label, .product-view #size_label {
  font-weight: lighter;
}

.product-view .required {
  color: #424242;
}

.product-view .required em {
  padding-right: 0.25em;
  display: none;
}

.product-view p.required {
  display: none;
}

.product-view dd.swatch-attr {
  padding: 0;
  margin: 0;
}

.product-view .configurable-swatch-list {
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0;
}

.product-view .configurable-swatch-list li {
  width: 2em;
  height: 2em;
  padding: 0em;
  margin-right: 0.3em;
  margin-bottom: 1em;
}

.product-view .configurable-swatch-list li.selected .swatch-label img {
  border-radius: 50%;
}

.product-view .configurable-swatch-list .x {
  display: none;
}

.product-view .configurable-swatch-list .not-available {
  position: relative;
}

.product-view .configurable-swatch-list .not-available::after {
  color: #757575;
  top: -0.1em;
  left: 0.1em;
  font-weight: bold;
  font-size: 2em;
  position: absolute;
  content: '/';
  z-index: -3;
  padding: 0.1em;
}

.product-view .configurable-swatch-list .not-available img {
  opacity: 0.6;
}

.product-view .configurable-swatch-list .not-available a, .product-view .configurable-swatch-list .not-available a:hover {
  color: grey;
}

.product-view #configurable_swatch_size .selected .swatch-label {
  border-bottom: 2px solid black;
}

.product-view .availability .label {
  display: none;
}

.product-view .extra-info .in-stock .value {
  color: #4CAF50;
  font-size: 1.2em;
}

.product-view .extra-info .in-stock .value:before {
  content: '\2713';
  padding-right: 0.2em;
}

.product-view .extra-info .out-of-stock .value {
  color: #F44336;
  font-weight: lighter;
  font-size: 1.2em;
}

.product-view .extra-info .out-of-stock .value:after {
  content: '\2717';
  padding-left: 0.2em;
}

.product-view .product-name .h1 {
  font-size: 1.3em;
  margin: 0 0 0.25em 0;
  font-weight: 400;
}

.product-view .product-shop > .inner {
  background: #fff;
  padding: 0;
  position: relative;
}

@media only screen and (min-width: 768px) {
  .product-view .product-shop > .inner {
    padding: 0;
  }
}

.product-view .sharing-links {
  padding: 0;
  margin: 1em 0;
  list-style: none;
  display: flex;
  flex-direction: column;
}

.product-view .product-essential {
  width: 100%;
}

.product-view .product-collateral {
  width: 100%;
  clear: both;
}

.product-view .product-collateral #tab-description ul, .product-view .product-collateral #tab-additional ul {
  margin-left: 18px;
}

.product-view .product-collateral #tab-description ul li, .product-view .product-collateral #tab-additional ul li {
  list-style-type: disc;
}

.product-view .product-collateral h2 {
  display: none;
}

.product-view .product-collateral h3 {
  text-align: center;
}

.product-view .product-collateral h4 {
  margin-bottom: 6px;
}

.product-view .link-compare .material-icons, .product-view .link-wishlist .material-icons {
  top: 0.25em;
  position: relative;
}

.product-view .add-to-links {
  display: none;
  /* Hidden by default */
  /* @include add-to-links(); */
}

.product-view .product-options dd {
  margin-bottom: 1rem;
}

.product-view .add-to-cart button.btn-cart {
  background: #222;
  padding: 0.5em 1em;
  border: 0;
  width: 100%;
  text-align: center;
  font-size: 1.2rem;
  font-weight: lighter;
  color: white;
  margin-bottom: 1rem;
  margin-top: 1rem;
  max-width: 100%;
  display: block;
  clear: both;
  text-transform: uppercase;
}

.product-view .add-to-cart button.btn-cart i.material-icons {
  position: relative;
  top: 0.25em;
  padding-bottom: 0.25em;
}

.product-view .add-to-cart button.btn-cart:hover {
  cursor: pointer;
  background: #1f1f1f;
}

.product-view .add-to-cart button.btn-cart span.inner {
  position: relative;
}

.product-view .add-to-cart button.btn-cart.out-of-stock {
  color: red;
  background: #E0E0E0;
}

.product-view .add-to-cart .qty,
.product-view .add-to-cart label[for=qty] {
  width: 2.8em;
  display: none;
}

@media only screen and (max-width: 767px) {
  .mdl-tabs__tab-bar {
    border: 0;
  }
}

@media only screen and (max-width: 767px) {
  .mdl-tabs {
    display: flex;
    flex-direction: column;
  }
  .mdl-tabs .mdl-tabs__tab, .mdl-tabs .tab-polarcore {
    width: 100%;
    display: flex;
    border: 1px #f0f0f0 solid;
    border-bottom: 0;
  }
  .mdl-tabs .mdl-tabs__tab:last-of-type, .mdl-tabs .tab-polarcore:last-of-type {
    border: 1px #f0f0f0 solid;
  }
  .mdl-tabs .mdl-tabs__tab span, .mdl-tabs .tab-polarcore span {
    display: inline-flex;
    width: 100%;
  }
  .mdl-tabs .mdl-tabs__tab span:after, .mdl-tabs .tab-polarcore span:after {
    font-family: "Material Icons";
    text-rendering: optimizelegibility;
    font-feature-settings: "liga";
    font-style: normal;
    text-transform: none;
    line-height: 1;
    display: block;
    overflow: hidden;
    -moz-osx-font-smoothing: grayscale;
    display: inline-block;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    content: "\E313";
    transform: rotate(-90deg);
    transition: transform 0.2s ease-in;
  }
  .mdl-tabs .mdl-tabs__tab.is-active span:after, .mdl-tabs .is-active.tab-polarcore span:after {
    transform: rotate(0deg);
  }
  @keyframes tabsAnimation {
    from {
      opacity: 0;
      transform: translate(-50px);
      max-height: 0;
      overflow: hidden;
    }
    to {
      opacity: 1;
      transform: translate(0px);
      max-height: 5000px;
    }
  }
  .mdl-tabs .mdl-tabs__panel.is-active {
    animation: tabsAnimation 0.5s;
  }
}

.mdl-tabs .mdl-tabs__panel {
  margin-top: 1rem;
}

@media only screen and (max-width: 767px) {
  .mdl-tabs .mdl-tabs__panel {
    padding: 1rem;
  }
}

.mdl-tabs .mdl-tabs__tabs:focus {
  outline: none;
}

.mdl-tabs .box-reviews .review-list {
  order: 1;
}

.mdl-tabs .box-reviews .review-list h4 {
  margin-bottom: 0.5rem;
}

.mdl-tabs .box-reviews .review-list .reviews .review span {
  display: inline-block;
  min-width: 4rem;
}

.mdl-tabs .box-reviews .review-list .reviews .review .rating-box {
  display: inline-block;
  position: relative;
  top: 0.5rem;
}

.mdl-tabs .box-reviews .review-form {
  order: 5;
}

.mdl-tabs .mdl-tabs__tab, .mdl-tabs .tab-polarcore {
  padding: 0 1rem;
}

@media only screen and (min-width: 768px) {
  .mdl-tabs .mdl-tabs__tab, .mdl-tabs .tab-polarcore {
    padding: 0 24px;
  }
}

.box-up-sell {
  position: relative;
  padding-bottom: 2rem;
}

.box-up-sell:before {
  content: "";
  position: absolute;
  border-bottom: #E0E0E0 1px solid;
  width: 100%;
  top: 1.7rem;
}

.box-up-sell h2 {
  position: relative;
  text-align: center;
  font-size: 1.2rem;
}

@media only screen and (max-width: 767px) {
  .box-up-sell h2 {
    line-height: 2rem;
  }
}

.box-up-sell h2 .center {
  background: white;
  padding: 0 2rem;
}

@media only screen and (max-width: 767px) {
  .box-up-sell h2 .center {
    padding: 0 2rem;
    display: inline-block;
    margin-top: 0.5rem;
  }
}

.box-up-sell ul li.up-sell-item {
  padding: 1rem;
}

.box-up-sell ul li.up-sell-item .product-name {
  font-size: 1rem;
  margin-bottom: 0.5rem;
  text-align: center;
}

.box-up-sell ul li.up-sell-item .price-box {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: auto;
}

@media only screen and (min-width: 1025px) {
  .box-up-sell ul li.up-sell-item .price-box {
    height: 1.8rem;
    flex-direction: row;
  }
}

.box-up-sell ul li.up-sell-item .price-box .price {
  margin: 0;
  font-size: 1rem;
  font-weight: lighter;
}

.box-up-sell ul li.up-sell-item .price-box .price-label {
  display: none;
  color: #757575;
}

.box-up-sell ul li.up-sell-item .price-box .regular-price {
  margin: 0;
  color: #757575;
}

.box-up-sell ul li.up-sell-item .price-box .old-price {
  color: #333;
  order: 5;
  margin: 0;
}

.box-up-sell ul li.up-sell-item .price-box .old-price .price {
  text-decoration: line-through;
  padding-left: 0;
}

@media only screen and (min-width: 1025px) {
  .box-up-sell ul li.up-sell-item .price-box .old-price .price {
    padding-left: 0.5rem;
  }
}

.box-up-sell ul li.up-sell-item .price-box .special-price {
  margin: 0;
  color: #8e3030;
  font-weight: bold;
  order: 1;
}

.box-up-sell ul li.up-sell-item .price-box .price-from {
  margin: 0;
  font-size: 1rem;
  color: #757575;
}

.box-up-sell ul li.up-sell-item .price-box .price-from .price-label {
  text-transform: uppercase;
  display: inline;
}

.box-up-sell ul li.up-sell-item .price-box .price-to {
  margin: 0;
  display: none;
  color: #757575;
}

.box-up-sell ul li.up-sell-item .price-box .minimal-price {
  margin: 0;
  color: #757575;
}

.box-up-sell ul li.up-sell-item .price-box .minimal-price-link {
  padding-left: 0;
}

@media only screen and (min-width: 1025px) {
  .box-up-sell ul li.up-sell-item .price-box .minimal-price-link {
    padding-left: 0.5rem;
  }
}

.box-up-sell ul li.up-sell-item .price-box .minimal-price-link .label {
  display: none;
}

.box-up-sell ul li.up-sell-item .price-box .map-link {
  margin: 0;
  font-size: 1rem;
  order: 10;
  padding-left: 0;
}

@media only screen and (min-width: 1025px) {
  .box-up-sell ul li.up-sell-item .price-box .map-link {
    padding-left: 0.5rem;
  }
}

.block-related {
  padding-bottom: 2rem;
}

.block-related .block-title, .block-related .col-wrapper aside.sidebar .block-subtitle--filter, .col-wrapper aside.sidebar .block-related .block-subtitle--filter {
  position: relative;
  background: #ebebeb;
}

.block-related .block-title:before, .block-related .col-wrapper aside.sidebar .block-subtitle--filter:before, .col-wrapper aside.sidebar .block-related .block-subtitle--filter:before {
  content: "";
  position: absolute;
  background: #ebebeb;
  width: 1em;
  height: 1em;
  left: calc(50% - 0.5em);
  bottom: -0.5em;
  transform: rotate(45deg);
  z-index: 1;
}

.block-related .block-title h2, .block-related .col-wrapper aside.sidebar .block-subtitle--filter h2, .col-wrapper aside.sidebar .block-related .block-subtitle--filter h2 {
  position: relative;
  text-align: center;
  font-size: 1rem;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
}

.block-related .block-title h2 .center, .block-related .col-wrapper aside.sidebar .block-subtitle--filter h2 .center, .col-wrapper aside.sidebar .block-related .block-subtitle--filter h2 .center {
  background: #ebebeb;
  padding: 0 2rem;
}

.block-related ol {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 auto;
}

.block-related ol li.item {
  padding: 1rem;
  text-align: left;
  position: relative;
  width: 100%;
  flex-basis: 100%;
}

@media only screen and (min-width: 768px) {
  .block-related ol li.item {
    width: 50%;
    flex-basis: 50%;
  }
}

@media only screen and (min-width: 1025px) {
  .block-related ol li.item {
    width: 25%;
    flex-basis: 25%;
  }
}

.block-related ol li.item:hover {
  box-shadow: none;
}

.block-related ol li.item .product-info .product-name {
  text-align: center;
}

.block-related ol li.item .link-wishlist {
  display: none;
}

.block-related ol li.item .product {
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 767px) {
  .block-related ol li.item .product .product-name {
    font-size: 1rem;
  }
}

.block-related ol li.item .price-box {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: auto;
}

@media only screen and (min-width: 1025px) {
  .block-related ol li.item .price-box {
    height: 1.8rem;
    flex-direction: row;
  }
}

.block-related ol li.item .price-box .price {
  margin: 0;
  font-size: 1rem;
  font-weight: lighter;
}

.block-related ol li.item .price-box .price-label {
  display: none;
  color: #757575;
}

.block-related ol li.item .price-box .regular-price {
  margin: 0;
  color: #757575;
}

.block-related ol li.item .price-box .old-price {
  color: #333;
  order: 5;
  margin: 0;
}

.block-related ol li.item .price-box .old-price .price {
  text-decoration: line-through;
  padding-left: 0;
}

@media only screen and (min-width: 1025px) {
  .block-related ol li.item .price-box .old-price .price {
    padding-left: 0.5rem;
  }
}

.block-related ol li.item .price-box .special-price {
  margin: 0;
  color: #8e3030;
  font-weight: bold;
  order: 1;
}

.block-related ol li.item .price-box .price-from {
  margin: 0;
  font-size: 1rem;
  color: #757575;
}

.block-related ol li.item .price-box .price-from .price-label {
  text-transform: uppercase;
  display: inline;
}

.block-related ol li.item .price-box .price-to {
  margin: 0;
  display: none;
  color: #757575;
}

.block-related ol li.item .price-box .minimal-price {
  margin: 0;
  color: #757575;
}

.block-related ol li.item .price-box .minimal-price-link {
  padding-left: 0;
}

@media only screen and (min-width: 1025px) {
  .block-related ol li.item .price-box .minimal-price-link {
    padding-left: 0.5rem;
  }
}

.block-related ol li.item .price-box .minimal-price-link .label {
  display: none;
}

.block-related ol li.item .price-box .map-link {
  margin: 0;
  font-size: 1rem;
  order: 10;
  padding-left: 0;
}

@media only screen and (min-width: 1025px) {
  .block-related ol li.item .price-box .map-link {
    padding-left: 0.5rem;
  }
}

.block-related ol li.item .price-box {
  align-items: initial;
  justify-content: initial;
}

.block-related ol .related-checkbox, .block-related ol .mdl-checkbox.is-upgraded {
  position: absolute;
  top: 1.5rem;
  left: 2rem;
}

.products-grid .slick-track .item:hover {
  box-shadow: none;
}

.products-grid .slick-track .up-sell-item.item {
  width: 200px;
}

.products-grid .slick-track .up-sell-item.item img {
  margin: 0 auto;
}

/* REVIEW - _review.scss */
#customer-reviews .review-list > dl dt {
  font-size: 1.5em;
  margin-top: 1em;
  margin-bottom: 0;
  font-weight: bold;
}

#customer-reviews .review-list > dl dt h4 {
  margin-bottom: 0;
}

#customer-reviews .review-list > dl dd {
  padding: 1em;
  background: #FAFAFA;
}

#customer-reviews .review-list > dl dd ul {
  margin: 1em 0;
  margin-top: 0.175em;
}

#customer-reviews .review-list > dl dd ul li {
  line-height: 1;
}

#customer-reviews .review-list > dl dd ul i {
  color: #fed227;
}

#review-form h3 {
  display: none;
}

#review-form thead {
  display: none;
}

#review-form input[type=radio],
#review-form input[type=checkbox] {
  display: none;
}

#review-form .material-icons:hover {
  cursor: pointer;
}

#review-form input[type=radio]:checked ~ label {
  user-select: none;
  color: #fed227;
  transition: ease 0.5s;
}

#review-form input[type=radio] + label {
  user-select: none;
  color: #fed227;
  transition: ease 0.5s;
}

#review-form .highlight {
  color: #fed227;
  transition: ease 0.5s;
}

#review-form .highlight > i {
  color: #fed227;
  transition: ease 0.5s;
}

/* SIDEBAR - _sidebar.scss */
@media only screen and (min-width: 768px) {
  .sidebar {
    padding: 0;
  }
}

.sidebar .block-viewed {
  text-align: center;
}

.sidebar .block .block-title, .col-wrapper aside.sidebar .block .block-subtitle--filter {
  display: block;
}

/* TOPLINE - _top-line.scss */
.top-line {
  display: none;
  padding: 0.25rem 0;
  border-bottom: 1px solid #EEEEEE;
  background-color: #FAFAFA;
}

@media only screen and (min-width: 768px) {
  .top-line {
    display: block;
  }
}

.top-line .store-language-container {
  float: right;
}

.top-line .store-language-container label {
  display: none;
}

.top-line ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.top-line ul li {
  display: inline;
  padding: 0 0.5rem;
}

.top-line ul li a {
  font-size: 0.9rem;
  color: #9E9E9E;
  text-decoration: none;
}

.top-line ul li a:hover, .top-line ul li a:active {
  text-decoration: underline;
}

.top-line ul.top-links-left {
  float: left;
}

.top-line ul.top-links-right {
  margin-left: 1em;
  float: right;
}

.top-line::after {
  clear: both;
  content: "";
  display: table;
}

/* VISIBILITY - _visibility.scss */
.no-display,
.hidden {
  display: none !important;
}

@media only screen and (min-width: 0px) {
  .show-for-small-only, .show-for-small-up, .hide-for-medium-only, .hide-for-medium-up, .hide-for-large-only, .hide-for-large-up, .hide-for-xlarge-only, .hide-for-xlarge-up, .hide-for-xxlarge-only, .hide-for-xxlarge-up {
    display: inherit !important;
  }
  .hide-for-small-only, .hide-for-small-up, .show-for-medium-only, .show-for-medium-up, .show-for-large-only, .show-for-large-up, .show-for-xlarge-only, .show-for-xlarge-up, .show-for-xxlarge-only, .show-for-xxlarge-up {
    display: none !important;
  }
}

@media only screen and (min-width: 768px) {
  .hide-for-small-only, .show-for-small-up, .show-for-medium-only, .show-for-medium-up, .hide-for-large-only, .hide-for-large-up, .hide-for-xlarge-only, .hide-for-xlarge-up, .hide-for-xxlarge-only, .hide-for-xxlarge-up {
    display: inherit !important;
  }
  .show-for-small-only, .hide-for-small-up, .hide-for-medium-only, .hide-for-medium-up, .show-for-large-only, .show-for-large-up, .show-for-xlarge-only, .show-for-xlarge-up, .show-for-xxlarge-only, .show-for-xxlarge-up {
    display: none !important;
  }
}

@media only screen and (min-width: 1025px) {
  .hide-for-small-only, .show-for-small-up, .hide-for-medium-only, .show-for-medium-up, .show-for-large-only, .show-for-large-up, .hide-for-xlarge-only, .hide-for-xlarge-up, .hide-for-xxlarge-only, .hide-for-xxlarge-up {
    display: inherit !important;
  }
  .show-for-small-only, .hide-for-small-up, .show-for-medium-only, .hide-for-medium-up, .hide-for-large-only, .hide-for-large-up, .show-for-xlarge-only, .show-for-xlarge-up, .show-for-xxlarge-only, .show-for-xxlarge-up {
    display: none !important;
  }
}

@media only screen and (min-width: 1441px) {
  .hide-for-small-only, .show-for-small-up, .hide-for-medium-only, .show-for-medium-up, .hide-for-large-only, .show-for-large-up, .show-for-xlarge-only, .show-for-xlarge-up, .hide-for-xxlarge-only, .hide-for-xxlarge-up {
    display: inherit !important;
  }
  .show-for-small-only, .hide-for-small-up, .show-for-medium-only, .hide-for-medium-up, .show-for-large-only, .hide-for-large-up, .hide-for-xlarge-only, .hide-for-xlarge-up, .show-for-xxlarge-only, .show-for-xxlarge-up {
    display: none !important;
  }
}

@media only screen and (min-width: 1921px) {
  .hide-for-small-only, .show-for-small-up, .hide-for-medium-only, .show-for-medium-up, .hide-for-large-only, .show-for-large-up, .hide-for-xlarge-only, .show-for-xlarge-up, .show-for-xxlarge-only, .show-for-xxlarge-up {
    display: inherit !important;
  }
  .show-for-small-only, .hide-for-small-up, .show-for-medium-only, .hide-for-medium-up, .show-for-large-only, .hide-for-large-up, .show-for-xlarge-only, .hide-for-xlarge-up, .hide-for-xxlarge-only, .hide-for-xxlarge-up {
    display: none !important;
  }
}

/* WISHLIST - _wishlist.scss */
.my-wishlist .page-title {
  text-align: center;
}

.my-wishlist #wishlist-view-form .buttons-set.buttons-set2 > button.button {
  width: 100%;
  margin: 0.25rem 0;
}

.my-wishlist .polarcore-wishlist-table {
  display: flex;
  width: 100%;
}

.my-wishlist .polarcore-wishlist-table ul {
  width: 100%;
}

.my-wishlist .polarcore-wishlist-table .polarcore-wishlist-item {
  display: flex;
  margin: auto;
  flex-direction: column;
}

@media only screen and (min-width: 768px) {
  .my-wishlist .polarcore-wishlist-table .polarcore-wishlist-item {
    flex-direction: row;
    height: 15rem;
  }
}

.my-wishlist .polarcore-wishlist-table .polarcore-wishlist-item .polarcore-wishlist-cell {
  margin: auto;
}

.my-wishlist .polarcore-wishlist-table .polarcore-wishlist-item .customer-wishlist-item-quantity {
  width: 3rem;
  margin: 1rem auto;
}

.my-wishlist .polarcore-wishlist-table .polarcore-wishlist-item .customer-wishlist-item-quantity input {
  width: 100%;
  text-align: center;
}

.my-wishlist .polarcore-wishlist-table .polarcore-wishlist-item .customer-wishlist-item-info {
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .my-wishlist .polarcore-wishlist-table .polarcore-wishlist-item .customer-wishlist-item-info .product-name {
    text-align: center;
  }
}

@media only screen and (min-width: 768px) {
  .my-wishlist .polarcore-wishlist-table .polarcore-wishlist-item .customer-wishlist-item-info {
    width: 45%;
  }
}

.my-wishlist .polarcore-wishlist-table .polarcore-wishlist-item .customer-wishlist-item-info textarea {
  position: relative;
  width: 100%;
  height: 4rem;
  min-width: 0rem;
  min-height: 0rem;
}

.my-wishlist .polarcore-wishlist-table .polarcore-wishlist-item .customer-wishlist-item-info .wishlist-sku {
  display: none;
}

.my-wishlist .polarcore-wishlist-table .polarcore-wishlist-item .customer-wishlist-item-info .item-manage button {
  display: none;
}

.my-wishlist .polarcore-wishlist-table .polarcore-wishlist-item .customer-wishlist-item-cart {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

@media only screen and (min-width: 768px) {
  .my-wishlist .polarcore-wishlist-table .polarcore-wishlist-item .customer-wishlist-item-cart {
    width: 15%;
  }
}

.my-wishlist .polarcore-wishlist-table .polarcore-wishlist-item .customer-wishlist-item-cart .truncated {
  order: 1;
  width: 100%;
  display: none;
}

.my-wishlist .polarcore-wishlist-table .polarcore-wishlist-item .customer-wishlist-item-cart .cart-cell {
  order: 5;
  margin-top: 1rem;
  width: 100%;
}

.my-wishlist .polarcore-wishlist-table .polarcore-wishlist-item .customer-wishlist-item-cart .cart-cell .btn-cart {
  width: 100%;
  margin-bottom: 0.5rem;
}

.my-wishlist .polarcore-wishlist-table .polarcore-wishlist-item .customer-wishlist-item-cart .cart-cell .price-label {
  display: none;
}

.my-wishlist .polarcore-wishlist-table .polarcore-wishlist-item .customer-wishlist-item-cart > p {
  order: 6;
  width: 100%;
  font-size: 0.8rem;
}

.my-wishlist .polarcore-wishlist-table .polarcore-wishlist-item .customer-wishlist-item-cart > p a {
  width: 100%;
  display: inline-block;
  text-align: center;
  padding: 0.5rem;
}

.my-wishlist .polarcore-wishlist-table .polarcore-wishlist-item .customer-wishlist-item-image {
  width: 6rem;
}

@media only screen and (max-width: 767px) {
  .my-wishlist .polarcore-wishlist-table .polarcore-wishlist-item .customer-wishlist-item-image {
    order: 1;
  }
}

.my-wishlist .polarcore-wishlist-table .polarcore-wishlist-item .customer-wishlist-item-remove {
  width: 2rem;
  height: 2rem;
  color: transparent;
}

@media only screen and (max-width: 767px) {
  .my-wishlist .polarcore-wishlist-table .polarcore-wishlist-item .customer-wishlist-item-remove {
    order: 5;
    margin-top: 1rem;
  }
}

.my-wishlist .polarcore-wishlist-table .polarcore-wishlist-item .customer-wishlist-item-remove a span {
  display: none;
}

.my-wishlist .polarcore-wishlist-table .polarcore-wishlist-item .customer-wishlist-item-price .price-box {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: auto;
}

@media only screen and (min-width: 1025px) {
  .my-wishlist .polarcore-wishlist-table .polarcore-wishlist-item .customer-wishlist-item-price .price-box {
    height: 1.8rem;
    flex-direction: column;
  }
}

.my-wishlist .polarcore-wishlist-table .polarcore-wishlist-item .customer-wishlist-item-price .price-box .price {
  margin: 0;
  font-size: 1rem;
  font-weight: lighter;
}

.my-wishlist .polarcore-wishlist-table .polarcore-wishlist-item .customer-wishlist-item-price .price-box .price-label {
  display: none;
  color: #757575;
}

.my-wishlist .polarcore-wishlist-table .polarcore-wishlist-item .customer-wishlist-item-price .price-box .regular-price {
  margin: 0;
  color: #757575;
}

.my-wishlist .polarcore-wishlist-table .polarcore-wishlist-item .customer-wishlist-item-price .price-box .old-price {
  color: #333;
  order: 5;
  margin: 0;
}

.my-wishlist .polarcore-wishlist-table .polarcore-wishlist-item .customer-wishlist-item-price .price-box .old-price .price {
  text-decoration: line-through;
  padding-left: 0;
}

@media only screen and (min-width: 1025px) {
  .my-wishlist .polarcore-wishlist-table .polarcore-wishlist-item .customer-wishlist-item-price .price-box .old-price .price {
    padding-left: 0.5rem;
  }
}

.my-wishlist .polarcore-wishlist-table .polarcore-wishlist-item .customer-wishlist-item-price .price-box .special-price {
  margin: 0;
  color: #8e3030;
  font-weight: bold;
  order: 1;
}

.my-wishlist .polarcore-wishlist-table .polarcore-wishlist-item .customer-wishlist-item-price .price-box .price-from {
  margin: 0;
  font-size: 1rem;
  color: #757575;
}

.my-wishlist .polarcore-wishlist-table .polarcore-wishlist-item .customer-wishlist-item-price .price-box .price-from .price-label {
  text-transform: uppercase;
  display: inline;
}

.my-wishlist .polarcore-wishlist-table .polarcore-wishlist-item .customer-wishlist-item-price .price-box .price-to {
  margin: 0;
  display: none;
  color: #757575;
}

.my-wishlist .polarcore-wishlist-table .polarcore-wishlist-item .customer-wishlist-item-price .price-box .minimal-price {
  margin: 0;
  color: #757575;
}

.my-wishlist .polarcore-wishlist-table .polarcore-wishlist-item .customer-wishlist-item-price .price-box .minimal-price-link {
  padding-left: 0;
}

@media only screen and (min-width: 1025px) {
  .my-wishlist .polarcore-wishlist-table .polarcore-wishlist-item .customer-wishlist-item-price .price-box .minimal-price-link {
    padding-left: 0.5rem;
  }
}

.my-wishlist .polarcore-wishlist-table .polarcore-wishlist-item .customer-wishlist-item-price .price-box .minimal-price-link .label {
  display: none;
}

.my-wishlist .polarcore-wishlist-table .polarcore-wishlist-item .customer-wishlist-item-price .price-box .map-link {
  margin: 0;
  font-size: 1rem;
  order: 10;
  padding-left: 0;
}

@media only screen and (min-width: 1025px) {
  .my-wishlist .polarcore-wishlist-table .polarcore-wishlist-item .customer-wishlist-item-price .price-box .map-link {
    padding-left: 0.5rem;
  }
}

/* POLAR FLEX GRID - _polar-flex-grid.scss */
[class*="polar-flex-grid-"] {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

[class*="polar-flex-grid-"] > li {
  flex-grow: 0;
  flex-shrink: 0;
}

.polar-flex-grid-1 > li {
  width: 100%;
}

.polar-flex-grid-2 > li {
  width: 50%;
}

.polar-flex-grid-3 > li {
  width: 33.33333%;
}

.polar-flex-grid-4 > li {
  width: 25%;
}

.polar-flex-grid-5 > li {
  width: 20%;
}

.polar-flex-grid-6 > li {
  width: 16.66667%;
}

.polar-flex-grid-7 > li {
  width: 14.28571%;
}

.polar-flex-grid-8 > li {
  width: 12.5%;
}

.polar-flex-grid-9 > li {
  width: 11.11111%;
}

.polar-flex-grid-10 > li {
  width: 10%;
}

.polar-flex-grid-11 > li {
  width: 9.09091%;
}

.polar-flex-grid-12 > li {
  width: 8.33333%;
}

@media only screen and (max-width: 767px) {
  .s-polar-flex-grid-1 > li {
    /*width: 100% / $i;*/
    width: 100%;
  }
  .s-polar-flex-grid-2 > li {
    /*width: 100% / $i;*/
    width: 100%;
  }
  .s-polar-flex-grid-3 > li {
    /*width: 100% / $i;*/
    width: 100%;
  }
  .s-polar-flex-grid-4 > li {
    /*width: 100% / $i;*/
    width: 100%;
  }
  .s-polar-flex-grid-5 > li {
    /*width: 100% / $i;*/
    width: 100%;
  }
  .s-polar-flex-grid-6 > li {
    /*width: 100% / $i;*/
    width: 100%;
  }
  .s-polar-flex-grid-7 > li {
    /*width: 100% / $i;*/
    width: 100%;
  }
  .s-polar-flex-grid-8 > li {
    /*width: 100% / $i;*/
    width: 100%;
  }
  .s-polar-flex-grid-9 > li {
    /*width: 100% / $i;*/
    width: 100%;
  }
  .s-polar-flex-grid-10 > li {
    /*width: 100% / $i;*/
    width: 100%;
  }
  .s-polar-flex-grid-11 > li {
    /*width: 100% / $i;*/
    width: 100%;
  }
  .s-polar-flex-grid-12 > li {
    /*width: 100% / $i;*/
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .m-polar-flex-grid-1 > li {
    width: 100%;
  }
  .m-polar-flex-grid-2 > li {
    width: 50%;
  }
  .m-polar-flex-grid-3 > li {
    width: 33.33333%;
  }
  .m-polar-flex-grid-4 > li {
    width: 25%;
  }
  .m-polar-flex-grid-5 > li {
    width: 20%;
  }
  .m-polar-flex-grid-6 > li {
    width: 16.66667%;
  }
  .m-polar-flex-grid-7 > li {
    width: 14.28571%;
  }
  .m-polar-flex-grid-8 > li {
    width: 12.5%;
  }
  .m-polar-flex-grid-9 > li {
    width: 11.11111%;
  }
  .m-polar-flex-grid-10 > li {
    width: 10%;
  }
  .m-polar-flex-grid-11 > li {
    width: 9.09091%;
  }
  .m-polar-flex-grid-12 > li {
    width: 8.33333%;
  }
}

@media only screen and (min-width: 1025px) {
  .l-polar-flex-grid-1 > li {
    width: 100%;
  }
  .l-polar-flex-grid-2 > li {
    width: 50%;
  }
  .l-polar-flex-grid-3 > li {
    width: 33.33333%;
  }
  .l-polar-flex-grid-4 > li {
    width: 25%;
  }
  .l-polar-flex-grid-5 > li {
    width: 20%;
  }
  .l-polar-flex-grid-6 > li {
    width: 16.66667%;
  }
  .l-polar-flex-grid-7 > li {
    width: 14.28571%;
  }
  .l-polar-flex-grid-8 > li {
    width: 12.5%;
  }
  .l-polar-flex-grid-9 > li {
    width: 11.11111%;
  }
  .l-polar-flex-grid-10 > li {
    width: 10%;
  }
  .l-polar-flex-grid-11 > li {
    width: 9.09091%;
  }
  .l-polar-flex-grid-12 > li {
    width: 8.33333%;
  }
}

/* CONTENT / GENERAL - content/_general.scs s*/
/* TYPOGRAPHY - _typography.scss */
h1 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 2.48832rem;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  font-weight: 400;
}

h2 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 2.0736rem;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  font-weight: 400;
}

h3 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 1.728rem;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  font-weight: 400;
}

h4 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 1.44rem;
  font-weight: 400;
}

button {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

p {
  line-height: 1.5rem;
  font-weight: 400;
}

a {
  font-weight: 400;
}

b, strong {
  font-weight: 400;
}

/* WIDGET - _wisget.scss */
.widget-new-products .widget-title {
  position: relative;
  background: #ebebeb;
}

.widget-new-products .widget-title:before {
  content: "";
  position: absolute;
  background: #ebebeb;
  width: 1em;
  height: 1em;
  left: calc(50% - 0.5em);
  bottom: -0.5em;
  transform: rotate(45deg);
  z-index: 1;
}

.widget-new-products .widget-title h2 {
  position: relative;
  text-align: center;
  font-size: 1rem;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
}

.widget-new-products .widget-title h2 .center {
  background: #ebebeb;
  padding: 0 2rem;
}

.widget-new-products .widget-products .products-grid .add-to-links {
  display: flex;
  flex-direction: column;
}

.widget-new-products .widget-products .products-grid .add-to-links li {
  width: 100%;
}

.widget-new-products .widget-products .products-grid .add-to-links li .inner {
  display: block;
}

.widget-viewed .widget-title {
  position: relative;
  background: #ebebeb;
}

.widget-viewed .widget-title:before {
  content: "";
  position: absolute;
  background: #ebebeb;
  width: 1em;
  height: 1em;
  left: calc(50% - 0.5em);
  bottom: -0.5em;
  transform: rotate(45deg);
  z-index: 1;
}

.widget-viewed .widget-title h2 {
  position: relative;
  text-align: center;
  font-size: 1rem;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
}

.widget-viewed .widget-title h2 .center {
  background: #ebebeb;
  padding: 0 2rem;
}

.title-with-line {
  position: relative;
  background: #ebebeb;
}

.title-with-line:before {
  content: "";
  position: absolute;
  background: #ebebeb;
  width: 1em;
  height: 1em;
  left: calc(50% - 0.5em);
  bottom: -0.5em;
  transform: rotate(45deg);
  z-index: 1;
}

.title-with-line h2 {
  position: relative;
  text-align: center;
  font-size: 1rem;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
}

.title-with-line h2 .center {
  background: #ebebeb;
  padding: 0 2rem;
}

.title-with-line h2 {
  margin: 0;
}

.banner-area {
  display: flex;
  flex-wrap: wrap;
}

.banner-area .banner-col-one {
  display: flex;
  width: 100%;
}

.banner-area .banner-col-one .banner-wrapper {
  width: 33.333%;
  padding: 10px;
  position: relative;
}

.banner-area .banner-col-one .banner-wrapper .banner-text {
  color: #fff;
  bottom: 40px;
  left: 40px;
  text-align: center;
  position: absolute;
}

.banner-area .banner-col-one .banner-wrapper .banner-text p {
  font-size: 1.3rem;
  text-transform: uppercase;
}

.banner-area .banner-col-one .banner-wrapper .banner-text a {
  color: #fff;
}

.banner-area .banner-col-one .banner-wrapper .banner-text .button {
  padding: .5em;
  background: rgba(54, 54, 54, 0.63);
}

.banner-area .banner-col-two {
  display: flex;
  width: 100%;
}

.banner-area .banner-col-two .banner-wrapper {
  width: 50%;
  padding: 5px;
  position: relative;
}

.banner-area .banner-col-two .banner-wrapper .banner-text {
  color: #fff;
  /*bottom: 40px;
        left: 40px;*/
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
}

.banner-area .banner-col-two .banner-wrapper .banner-text p {
  font-size: 1.3rem;
  text-transform: uppercase;
}

.banner-area .banner-col-two .banner-wrapper .banner-text a {
  color: #fff;
  text-transform: uppercase;
  font-size: 1.3rem;
  font-weight: 500;
}

.banner-area .banner-col-two .banner-wrapper .banner-text .button {
  padding: .5em;
  background: rgba(54, 54, 54, 0.63);
}

.banner-area .banner-col-three .banner-wrapper .banner-text {
  color: #fff;
  bottom: 40px;
  left: 40px;
  text-align: center;
  position: absolute;
}

.banner-area .banner-col-three .banner-wrapper .banner-text span {
  display: block;
}

.banner-area .banner-col-three .banner-wrapper .banner-text a {
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
}

.banner-area .banner-col-three .banner-wrapper .banner-text p {
  font-size: 1.3rem;
  text-transform: uppercase;
}

ul.category-button li {
  margin: 0.5rem;
  width: calc(50% - 1.1rem);
  list-style: none;
}

ul.category-button {
  text-align: center;
  display: flex;
  flex-diretion: row;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}

ul.category-button li {
  font-family: "Lato", sans-serif !important;
  font-weight: 200;
  height: auto;
  background: #000;
  font-size: 1rem;
  color: white;
}

ul.category-button li a {
  padding: 0.5rem;
  margin: 0;
  width: 100%;
  display: inline-block;
  font-size: 1rem;
  text-transform: uppercase;
  color: white;
}

ul.category-button .full-width {
  width: 100%;
}

.nuva-dropdown-wrapper {
  position: relative;
  display: inline-block;
  user-select: none;
}

.nuva-dropdown-wrapper ul, .nuva-dropdown-wrapper li {
  list-style: none;
  margin: 0;
  padding: 0;
}

.nuva-dropdown-wrapper a {
  width: 100%;
  text-align: center;
  cursor: pointer;
  background: white;
  border: solid 1px #F0F0F0;
  border-radius: 2px;
  padding: 0.2em 25px;
}

.nuva-dropdown-wrapper a:hover {
  background: #F1F1F1;
}

.nuva-dropdown-wrapper a::after {
  display: none;
  content: "<>";
  transform: rotate(90deg);
}

@media only screen and (max-width: 767px) {
  .nuva-dropdown-wrapper {
    /*> a {
      position: absolute;
      top: 16px;
      right: 8px;
      width: 47.5%;
      float: right;
      padding: 0.75rem 1rem;
      margin: 0;
      text-transform: none;
      color: $color-polar;
      border-radius: 0px;
      border: 1px solid $color-polar;
      height: 3.25rem;
      line-height: 1.75rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      &:before {
        content: "\E164";
        @include material-icons();
        font-size: 1.25rem;
        margin-right: 0.5rem;
        display: inline-block;
        vertical-align: text-bottom;
      }
    }*/
  }
}

.nuva-dropdown-wrapper .nuva-dropdown {
  position: absolute;
  top: -0.5em;
  left: 0.2em;
  background: #FFF;
  border: #f0f0f0 1px solid;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.2);
  color: #757575;
  z-index: 30;
  transition: ease 0.2s;
}

.nuva-dropdown-wrapper .nuva-dropdown li {
  z-index: 50;
  position: relative;
  width: 100%;
  padding: 0.5em 1em;
}

.nuva-dropdown-wrapper .nuva-dropdown li:hover {
  background: #F0F0F0;
  cursor: pointer;
}

.nuva-show {
  opacity: 1;
  visibility: visible;
}

.nuva-hide {
  visibility: hidden;
  opacity: 0;
}

#polarcore #bubble-layer-overlay {
  display: flex;
  background: rgba(255, 255, 255, 0.05);
  transition: ease-in-out 0.2s;
}

#polarcore #bubble-layer-overlay .mdl-spinner {
  height: 64px;
  width: 64px;
}

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}

.embed-container iframe, .embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#polarcore .products-grid.slick-slider .slick-dots {
  position: static;
  bottom: initial;
}

#polarcore .products-grid.slick-slider .slick-track:before,
#polarcore .products-grid.slick-slider .slick-track:after {
  font-family: initial;
}

#polarcore .products-grid.slick-slider .slick-slide {
  outline: 0;
}

#polarcore .products-grid.slick-slider .slick-next {
  right: 0;
}

#polarcore .products-grid.slick-slider .slick-prev {
  left: 0;
}

#polarcore .products-grid.slick-slider .slick-next,
#polarcore .products-grid.slick-slider .slick-prev {
  position: absolute;
  z-index: 80;
  width: 3rem;
  margin: auto;
  height: auto;
  top: calc(50% - 50px);
  /*background: rgba(255, 255, 255, 0.45) !important;*/
  display: flex !important;
}

#polarcore .products-grid.slick-slider .slick-next:hover,
#polarcore .products-grid.slick-slider .slick-prev:hover {
  background: rgba(0, 0, 0, 0.05) !important;
}

#polarcore .products-grid.slick-slider .slick-next:before {
  transform: rotate(180deg);
}

#polarcore .products-grid.slick-slider .slick-next:before,
#polarcore .products-grid.slick-slider .slick-prev:before {
  font-family: "Material Icons" !important;
  font-size: 2rem;
  content: '\E314';
  display: inline-block;
  height: 100%;
  margin: auto;
  font-family: initial;
  color: rgba(255, 255, 255, 0.2);
  background: none;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin: 0 auto;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: '';
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir='rtl'] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
  margin: auto;
  width: 100%;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("../images/ajax-loader.gif") center center no-repeat;
}

/* Icons */
@font-face {
  font-family: 'slick';
  font-weight: normal;
  font-style: normal;
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
}

/* Arrows */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  margin-top: -10px\9;
  /*lte IE 8*/
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: transparent;
  outline: none;
  background: transparent;
}

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: .25;
}

.slick-prev:before,
.slick-next:before {
  font-family: 'slick';
  font-size: 20px;
  line-height: 1;
  opacity: .75;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}

[dir='rtl'] .slick-prev {
  right: -25px;
  left: auto;
}

.slick-prev:before {
  content: 'â†';
}

[dir='rtl'] .slick-prev:before {
  content: 'â†’';
}

.slick-next {
  right: -25px;
}

[dir='rtl'] .slick-next {
  right: auto;
  left: -25px;
}

.slick-next:before {
  content: 'â†’';
}

[dir='rtl'] .slick-next:before {
  content: 'â†';
}

/* Dots */
.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: 5px;
  display: block;
  width: 100%;
  padding: 0;
  list-style: none;
  text-align: center;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  font-size: 1rem;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: '\25CF';
  text-align: center;
  opacity: .25;
  color: black;
  text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
  opacity: .75;
  color: black;
}

/* MODULES / AJAXCART - modules/ajaxcart/_ajaxcart.scss */
#polarcore #ajaxcart, #polarcore #ajaxcart-loading {
  top: 50%;
  left: 50%;
  margin-left: auto;
  transform: translate(-50%, -50%);
  font-smoothing: subpixel-antialiased;
  filter: blur(0);
}

#polarcore #ajaxcart h2, #polarcore #ajaxcart-loading h2 {
  font-size: 1.2rem;
}

@media only screen and (min-width: 768px) {
  #polarcore #ajaxcart h2, #polarcore #ajaxcart-loading h2 {
    font-size: 1.575rem;
  }
}

#polarcore #ajaxcart .product, #polarcore #ajaxcart-loading .product {
  padding: 6px 0;
}

#polarcore #ajaxcart .actions, #polarcore #ajaxcart-loading .actions {
  display: flex;
  flex-direction: column;
}

#polarcore #ajaxcart .actions .button, #polarcore #ajaxcart .actions button, #polarcore #ajaxcart-loading .actions .button, #polarcore #ajaxcart-loading .actions button {
  margin-right: 0;
  flex: 1;
}

#polarcore #ajaxcart .actions .button:not(:last-child), #polarcore #ajaxcart .actions button:not(:last-child), #polarcore #ajaxcart-loading .actions .button:not(:last-child), #polarcore #ajaxcart-loading .actions button:not(:last-child) {
  margin-bottom: 0.5rem;
}

@media only screen and (min-width: 768px) {
  #polarcore #ajaxcart .actions .button:not(:last-child), #polarcore #ajaxcart .actions button:not(:last-child), #polarcore #ajaxcart-loading .actions .button:not(:last-child), #polarcore #ajaxcart-loading .actions button:not(:last-child) {
    margin-bottom: 0;
    margin-right: 0.5rem;
  }
}

@media only screen and (min-width: 768px) {
  #polarcore #ajaxcart .actions, #polarcore #ajaxcart-loading .actions {
    flex-direction: row;
  }
}

#polarcore #ajaxcart-loading .close, #polarcore #ajaxcart .close, #polarcore #ajaxcart-error .close {
  padding: 0;
  padding-left: 1px;
  padding-top: 6px;
  top: 0.5rem;
  right: 0.5rem;
  background: transparent;
  border-radius: 0;
  text-indent: -999px;
}

#polarcore #ajaxcart-loading .close:before, #polarcore #ajaxcart .close:before, #polarcore #ajaxcart-error .close:before {
  font-family: "Material Icons";
  text-rendering: optimizelegibility;
  font-feature-settings: "liga";
  font-style: normal;
  text-transform: none;
  line-height: 1;
  display: block;
  overflow: hidden;
  -moz-osx-font-smoothing: grayscale;
  content: "\E5CD";
  position: absolute;
  top: 0;
  right: 0;
  color: #222;
  font-size: 1.5rem;
  text-indent: 0;
}

#polarcore #ajaxcart .actions button, #polarcore #ajaxcart .actions .button {
  font-size: 1rem;
}

/* MODULES / BUBBLE LAYER - modules/bubblelayer/_bubblelayer.scss */
/* PolarCore Bubblelayer Module Style */
@keyframes smoothOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.mdl-js-ensure-loaded {
  transition: opacity 175ms ease-in;
  opacity: 0;
}

@media only screen and (max-width: 767px) {
  .mdl-js-ensure-loaded {
    opacity: 1;
  }
}

@media only screen and (max-width: 767px) {
  .mdl-js-ensure-loaded.mdl-loaded {
    opacity: 1;
  }
}

@media only screen and (min-width: 768px) {
  .mdl-js-ensure-loaded.mdl-loaded {
    -webkit-animation-iteration-count: 1;
    animation: smoothOpacity 0.25s;
    animation-fill-mode: forwards;
  }
}

@media only screen and (max-width: 767px) {
  #polarcore.popup-open .block-layered-nav {
    z-index: 100;
    position: relative;
  }
}

#polarcore .block-layered-nav--no-filters {
  display: block;
}

#polarcore .block-layered-nav {
  /* Mobile Popup Styling*/
}

#polarcore .block-layered-nav .currently .block-subtitle {
  font-weight: 700;
}

#polarcore .block-layered-nav .currently ol li {
  width: 100%;
}

@media only screen and (min-width: 768px) {
  #polarcore .block-layered-nav .currently {
    padding-right: 1rem;
    margin-bottom: 0;
  }
}

#polarcore .block-layered-nav #narrow-by-list dt {
  font-size: 1rem;
  text-transform: uppercase;
  margin-top: 0.5rem;
  margin-bottom: 0;
  background: white;
}

@media only screen and (min-width: 768px) {
  #polarcore .block-layered-nav #narrow-by-list dt.expanded {
    cursor: pointer;
  }
  #polarcore .block-layered-nav #narrow-by-list dt.expanded i {
    font-family: "Material Icons";
    text-rendering: optimizelegibility;
    font-feature-settings: "liga";
    font-style: normal;
    text-transform: none;
    line-height: 1;
    display: block;
    overflow: hidden;
    -moz-osx-font-smoothing: grayscale;
    display: inline-block;
    vertical-align: text-bottom;
    content: "\E315";
    margin-bottom: -0.15rem;
    margin-left: 0.25rem;
    line-height: 20px;
    font-size: 1.5rem;
    transition: transform 0.25s ease;
    float: right;
  }
  #polarcore .block-layered-nav #narrow-by-list dt.collapsed {
    cursor: pointer;
  }
  #polarcore .block-layered-nav #narrow-by-list dt.collapsed i {
    font-family: "Material Icons";
    text-rendering: optimizelegibility;
    font-feature-settings: "liga";
    font-style: normal;
    text-transform: none;
    line-height: 1;
    display: block;
    overflow: hidden;
    -moz-osx-font-smoothing: grayscale;
    display: inline-block;
    vertical-align: text-bottom;
    content: "\E315";
    margin-bottom: -0.15rem;
    margin-left: 0.25rem;
    line-height: 20px;
    font-size: 1.5rem;
    transition: transform 0.25s ease;
    transform: rotate(90deg);
    float: right;
  }
}

#polarcore .block-layered-nav #narrow-by-list dd ol {
  border-bottom: 0;
  margin: 0;
}

#polarcore .block-layered-nav #narrow-by-list dd ol li {
  font-size: 0.9rem;
  margin: 0.5rem 0;
  color: #ccc;
}

#polarcore .block-layered-nav #narrow-by-list dd ol li .mdl-checkbox {
  width: auto;
}

#polarcore .block-layered-nav #narrow-by-list dd ol li .mdl-checkbox .mdl-checkbox__label {
  font-size: 1rem;
}

#polarcore .block-layered-nav #narrow-by-list dd ol li .count {
  color: #bbb;
  font-size: 0.8rem;
  float: right;
}

#polarcore .block-layered-nav #narrow-by-list dd ol li input {
  cursor: pointer;
}

#polarcore .block-layered-nav #narrow-by-list dd .label-filter a:hover, #polarcore .block-layered-nav #narrow-by-list dd .label-filter a:focus {
  background-color: #222;
  color: white;
}

#polarcore .block-layered-nav #narrow-by-list dd.actions {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;
  width: 350px;
  display: none !important;
}

#polarcore .block-layered-nav #narrow-by-list dd.actions .mobil-apply-btn {
  display: block;
  width: 100%;
  color: white;
  font-size: 1.15rm;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 1rem;
  border-top: 4px solid white;
  background-color: #000;
  transform: translateZ(0);
}

@media only screen and (max-width: 767px) {
  #polarcore .block-layered-nav #narrow-by-list dd .layer-slider .price-slider {
    display: none;
  }
}

#polarcore .block-layered-nav #narrow-by-list dd .layer-slider .price-slider .handle {
  width: 16px;
  height: 16px;
  top: 3px;
  background-color: #222;
}

#polarcore .block-layered-nav #narrow-by-list dd .layer-slider .price-slider .span {
  background-color: #222;
}

#polarcore .block-layered-nav #narrow-by-list dd .layer-slider .price-range {
  text-align: center;
  color: #222;
}

#polarcore .block-layered-nav #narrow-by-list dd .layer-slider .price-range::after {
  clear: both;
  content: "";
  display: table;
}

#polarcore .block-layered-nav #narrow-by-list dd .layer-slider .price-range .price {
  float: left;
}

@media only screen and (max-width: 767px) {
  #polarcore .block-layered-nav #narrow-by-list dd .layer-slider .price-range .price {
    margin-left: 0.25rem;
  }
}

#polarcore .block-layered-nav #narrow-by-list dd .layer-slider .price-range .price:last-of-type {
  float: right;
}

@media only screen and (max-width: 767px) {
  #polarcore .block-layered-nav #narrow-by-list dd .layer-slider .price-range .price:last-of-type {
    margin-right: 0.25rem;
  }
}

#polarcore .block-layered-nav #narrow-by-list dd .layer-slider .price-range div {
  display: table;
  width: 100%;
}

#polarcore .block-layered-nav #narrow-by-list dd .layer-slider .price-range div input {
  float: left;
  margin: 0;
}

#polarcore .block-layered-nav #narrow-by-list dd .layer-slider .price-range div input:last-of-type {
  float: right;
}

#polarcore .block-layered-nav #narrow-by-list dd .layer-slider .price-limit {
  display: none;
}

@media only screen and (max-width: 767px) {
  #polarcore .block-layered-nav #narrow-by-list dd ol, #polarcore .block-layered-nav #narrow-by-list dd ul, #polarcore .block-layered-nav #narrow-by-list dd div {
    margin-right: 0.75rem;
  }
}

#polarcore .block-layered-nav #narrow-by-list dd ol {
  border-bottom: 0;
}

#polarcore .block-layered-nav #narrow-by-list dd ol li {
  font-size: 0.9rem;
  margin: 0.5rem 0;
  color: #222;
}

#polarcore .block-layered-nav #narrow-by-list dd ol li .mdl-checkbox {
  width: auto;
}

#polarcore .block-layered-nav #narrow-by-list dd ol li .mdl-checkbox .mdl-checkbox__label {
  font-size: 1rem;
}

#polarcore .block-layered-nav #narrow-by-list dd ol li .count {
  color: #bbb;
  font-size: 0.8rem;
}

#polarcore .block-layered-nav #narrow-by-list dd ol li input {
  cursor: pointer;
}

#polarcore .block-layered-nav #narrow-by-list dd li.is-checked a,
#polarcore .block-layered-nav #narrow-by-list dd li.active a {
  outline: 0;
  text-decoration: none;
  color: #000;
  border-radius: 3px;
}

#polarcore .block-layered-nav #narrow-by-list dd li.is-checked a .count,
#polarcore .block-layered-nav #narrow-by-list dd li.active a .count {
  background-color: white;
}

@media only screen and (max-width: 767px) {
  #polarcore .block-layered-nav #narrow-by-list {
    /*
        .even {
          width: 50%;
        }
        .odd {
          width: 50%;
          float: right;
          clear: both;
          &.last {
            float: left;
          }
        }*/
  }
  #polarcore .block-layered-nav #narrow-by-list::after {
    clear: both;
    content: "";
    display: table;
  }
  #polarcore .block-layered-nav #narrow-by-list dt {
    margin: 0;
    padding: 1rem 1rem;
  }
  #polarcore .block-layered-nav #narrow-by-list dt i {
    float: right;
  }
  #polarcore .block-layered-nav #narrow-by-list dt.open i {
    transform: rotate(90deg);
  }
  #polarcore .block-layered-nav #narrow-by-list dd {
    /*display: none;
          padding: 0.75rem 1rem;
          background-color: #fafafa;*/
  }
  #polarcore .block-layered-nav #narrow-by-list dd .no-display {
    display: list-item !important;
  }
  #polarcore .block-layered-nav #narrow-by-list dd .show-hidden {
    display: none !important;
  }
  #polarcore .block-layered-nav #narrow-by-list h2.headline {
    position: absolute;
    top: 1rem;
    width: 85%;
    margin: 0;
    padding: 0.25rem 0.75rem;
    color: #555;
    text-transform: uppercase;
    font-size: 1.3rem;
    font-weight: bold;
    line-height: 1.75rem;
    left: 4px;
    display: none !important;
  }
}

@media only screen and (max-width: 767px) and (max-width: 767px) {
  #polarcore .block-layered-nav #narrow-by-list {
    /*padding: 4rem 0.75rem;*/
    overflow-y: auto;
    margin-top: 0;
    margin-bottom: 3rem;
  }
}

#polarcore .block-layered-nav .block-content {
  display: flex;
  flex-direction: column;
  background: white;
  padding: 20px;
}

#polarcore .block-layered-nav .block-title, #polarcore .block-layered-nav .col-wrapper aside.sidebar .block-subtitle--filter, .col-wrapper aside.sidebar #polarcore .block-layered-nav .block-subtitle--filter,
#polarcore .block-layered-nav .block-subtitle,
#polarcore .block-layered-nav .actions {
  display: none;
}

#polarcore .block-layered-nav .currently {
  order: 100;
  margin-bottom: 36px;
}

@media only screen and (min-width: 768px) {
  #polarcore .block-layered-nav .currently {
    order: 0;
  }
}

#polarcore .block-layered-nav .currently .block-subtitle {
  margin: 0.75rem 0 0 0;
}

#polarcore .block-layered-nav .currently ol li {
  position: relative;
  display: inline-block;
  margin: 2px 0;
  padding: 4px 24px 4px 12px;
  border: 1px solid #444;
  border-radius: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media only screen and (min-width: 768px) {
  #polarcore .block-layered-nav .currently ol li {
    display: block;
    margin: 6px 0;
  }
}

#polarcore .block-layered-nav .currently .btn-remove {
  position: absolute;
  right: 0.5rem;
  font-size: 11px;
}

#polarcore .block-layered-nav .currently .btn-remove span {
  display: none;
}

#polarcore .block-layered-nav .currently .btn-remove:after {
  font-family: "Material Icons";
  text-rendering: optimizelegibility;
  font-feature-settings: "liga";
  font-style: normal;
  text-transform: none;
  line-height: 1;
  display: block;
  overflow: hidden;
  -moz-osx-font-smoothing: grayscale;
  content: "\E14C";
  position: relative;
  top: 0.2rem;
  margin-left: 0.5rem;
  font-size: 1.25rem;
}

@media only screen and (max-width: 767px) {
  #polarcore .block-layered-nav {
    /*  .block-subtitle {
        display: block;
      }
      .block-subtitle--filter.active {
        position: fixed;
        top: 0.5rem;
        right: 0.5rem;
        z-index: 105;
        width: 3rem;
        height: 3rem;
        text-indent: -999px;
        overflow: hidden;
        &:before {
          content: "\E5CD";
          @include material-icons();
          text-indent: 0;
          position: absolute;
          top: 10px;
          right: 2px;
          font-size: 1.5rem;
        }
      }*/
    /*#narrow-by-list {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 4rem 0rem 3rem;
        background-color: #fff;
      }*/
  }
  #polarcore .block-layered-nav .block-subtitle--filter:not(.active) + #narrow-by-list {
    display: none;
  }
}

#polarcore .bubble-layer-top {
  /*display: none;*/
}

@media only screen and (min-width: 768px) {
  #polarcore .bubble-layer-top {
    display: block;
  }
}

@media only screen and (min-width: 768px) {
  #polarcore .bubble-layer-top .block-layered-nav .block-content {
    max-width: none;
  }
}

.block-layered-nav .mdl-checkbox__box-outline {
  position: absolute;
  top: 6px;
  left: 0;
  display: inline-block;
  box-sizing: border-box;
  width: 12px;
  height: 12px;
  margin: 0;
  cursor: pointer;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.54);
  border-radius: 2px;
  z-index: 2;
}

/* MODULES / SIDENAV - modules/sidenav/_sidenav.scss */
#sidebar-nav {
  margin-bottom: 0px !important;
}

/* MODULES / TCO - modules/tco/_tco.scss */
body.tco.tco-onestep #login-step a.btn-close {
  background: none;
  border: 0;
  color: white;
}

body.tco.tco-onestep #login-step a.btn-close:hover {
  color: #afafaf;
}

body.tco.tco-onestep #login-step-login input {
  min-width: 300px;
}

body.tco.tco-onestep p.login-link {
  margin-bottom: 3rem;
  padding-left: 0.5rem;
}

body.tco.tco-onestep .please-wait {
  display: flex;
}

body.tco.tco-onestep .please-wait img {
  margin: auto;
  padding: 2rem;
  opacity: 0.2;
}

body.tco.tco-onestep .main-container.col1-layout.checkout {
  margin-top: 0;
  margin-bottom: 3rem;
}

body.tco.tco-onestep .checkout .logo {
  max-width: 180px;
}

body.tco.tco-onestep .checkout .back {
  margin-top: 1rem;
}

body.tco.tco-onestep .tco-wrapper .section .step-title, body.tco.tco-onestep .tco-wrapper .step-title {
  background: none;
  border: 0;
  border-bottom: 2px solid #222;
  border-radius: 0;
  box-shadow: none;
}

body.tco.tco-onestep .tco-wrapper .section .step-title h2, body.tco.tco-onestep .tco-wrapper .step-title h2 {
  color: #222;
  text-transform: uppercase;
  font-weight: 300;
}

body.tco.tco-onestep .tco-wrapper #login-step-login > p {
  margin-top: 2rem;
}

body.tco.tco-onestep .tco-wrapper button.btn-checkout {
  background: #222;
  font-size: 1.2rem;
  float: right;
  transistion: background ease-in-out 0.2s;
  border-radius: 0;
}

@media only screen and (max-width: 767px) {
  body.tco.tco-onestep .tco-wrapper button.btn-checkout {
    width: 100%;
  }
}

body.tco.tco-onestep .tco-wrapper button.btn-checkout:hover {
  background: #151515;
}

body.tco.tco-onestep .tco-wrapper button.btn-checkout:after {
  font-family: "Material Icons";
  text-rendering: optimizelegibility;
  font-feature-settings: "liga";
  font-style: normal;
  text-transform: none;
  line-height: 1;
  display: block;
  overflow: hidden;
  -moz-osx-font-smoothing: grayscale;
  content: '\E315';
  display: inline;
  line-height: 1rem;
  font-size: 2rem;
  vertical-align: middle;
  position: relative;
  top: -2px;
}

body.tco.tco-onestep .tco-wrapper .login .form-list button.button {
  background: #222;
  transistion: background ease-in-out 0.2s;
}

body.tco.tco-onestep .tco-wrapper .login .form-list button.button:hover {
  background: #151515;
}

body.tco.tco-onestep .tco-wrapper #checkout-shipping-method-load .radio {
  margin-top: 0px;
  margin-right: 3px;
}

body.tco.tco-onestep .tco-wrapper #checkout-review-table tbody td:first-child {
  width: 64px;
}

body.tco.tco-onestep .tco-wrapper .item-options {
  text-align: left;
}

body.tco.tco-onestep .tco-wrapper .data-table .product-name {
  line-height: 1.4em;
  text-align: left;
}

body.tco.tco-onestep .tco-wrapper label {
  font-weight: 400;
  color: #666;
}

body.tco.tco-onestep .tco-wrapper dd {
  margin-bottom: 1rem;
}

body.tco.tco-onestep .tco-wrapper dd:last-child {
  margin-bottom: 0;
}

body.tco.tco-onestep .tco-wrapper dt {
  color: #666;
  font-weight: 400;
}

body.tco.tco-onestep .tco-wrapper input + label {
  padding: 0;
}

body.tco.tco-onestep .tco-wrapper input.input-text {
  border: 0;
  border-bottom: 2px solid #f0f0f0;
  outline: none;
  box-shadow: none;
}

body.tco.tco-onestep .tco-wrapper input.input-text:focus {
  border: 0;
  border-bottom: solid #222 2px;
}

body.tco.tco-onestep .tco-wrapper input[type="checkbox"]:focus + label {
  color: #090909;
}

body.tco.tco-onestep .tco-wrapper input:checked + label {
  color: #3c3c3c;
}

body.tco.tco-onestep .tco-wrapper .form-list .field {
  width: 100%;
  padding: 0;
}

body.tco.tco-onestep .tco-wrapper .step {
  border: 1px solid #f0f0f0;
}

body.tco.tco-onestep .tco-wrapper .step .number {
  border: 0;
  border-radius: 2px;
  font-style: Verdana;
  line-height: 1.5rem;
  text-align: center;
  box-shadow: none;
}

body.tco.tco-onestep .tco-wrapper .wide {
  margin-bottom: 1.5rem;
}

body.tco .tco-wrapper label[for="shipping:country_id"] + .input-box,
body.tco .tco-wrapper label[for="billing:country_id"] + .input-box,
body.tco .tco-wrapper label[for='billing-address-select'] + .input-box {
  position: relative;
  display: flex;
  border: 1px solid #E0E0E0;
  max-width: 100%;
  background: white;
  margin-bottom: 1rem;
  padding-right: 2rem;
  text-overflow: ellipsis;
}

body.tco .tco-wrapper label[for="shipping:country_id"] + .input-box:after,
body.tco .tco-wrapper label[for="billing:country_id"] + .input-box:after,
body.tco .tco-wrapper label[for='billing-address-select'] + .input-box:after {
  font-family: "Material Icons";
  text-rendering: optimizelegibility;
  font-feature-settings: "liga";
  font-style: normal;
  text-transform: none;
  line-height: 1;
  display: block;
  overflow: hidden;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  user-select: none;
  pointer-events: none;
  right: 0rem;
  font-size: 1.2rem;
  content: '\E313';
  margin: auto;
  color: #444;
  height: 2.5rem;
  line-height: 2.5rem;
  vertical-align: middle;
  padding: 0rem 0.5rem;
  background: rgba(0, 0, 0, 0.05);
}

body.tco .tco-wrapper label[for="shipping:country_id"] + .input-box .validation-advice,
body.tco .tco-wrapper label[for="billing:country_id"] + .input-box .validation-advice,
body.tco .tco-wrapper label[for='billing-address-select'] + .input-box .validation-advice {
  right: 0;
  top: -1.5rem;
}

body.tco .tco-wrapper label[for="shipping:country_id"] + .input-box select,
body.tco .tco-wrapper label[for="billing:country_id"] + .input-box select,
body.tco .tco-wrapper label[for='billing-address-select'] + .input-box select {
  position: relative;
  appearance: none;
  border: 0;
  outline: none;
  padding-right: 2rem;
  background: transparent;
  width: 100%;
  height: 2.5rem;
}

@media only screen and (min-width: 768px) {
  body.tco .tco-wrapper label[for="shipping:country_id"] + .input-box select,
  body.tco .tco-wrapper label[for="billing:country_id"] + .input-box select,
  body.tco .tco-wrapper label[for='billing-address-select'] + .input-box select {
    padding: 0.75rem;
  }
}

body.tco .tco-wrapper label[for="shipping:country_id"] + .input-box select:hover,
body.tco .tco-wrapper label[for="billing:country_id"] + .input-box select:hover,
body.tco .tco-wrapper label[for='billing-address-select'] + .input-box select:hover {
  cursor: pointer;
  background: #fcfcfc;
}

body.tco .tco-wrapper label[for="shipping:country_id"] + .input-box select:disabled,
body.tco .tco-wrapper label[for="billing:country_id"] + .input-box select:disabled,
body.tco .tco-wrapper label[for='billing-address-select'] + .input-box select:disabled {
  background: #c7c7c7;
  color: #444;
}

body.tco.tco-onestep .tco-wrapper table.data-table td.a-right.last {
  text-align: right;
}

body.tco.tco-onestep .data-table td:first-child {
  padding-left: 1rem;
}

body.tco.tco-onestep .data-table td:last-child {
  padding-right: 1rem;
  text-align: right;
}

body.tco.tco-onestep .data-table th {
  text-transform: uppercase;
  text-align: left;
}

body.tco.tco-onestep .data-table td, body.tco.tco-onestep .data-table th {
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px #f0f0f0 solid;
  transition: border-color 0.1s ease-in-out;
}

body.tco.tco-onestep .data-table td:first-child, body.tco.tco-onestep .data-table th:first-child {
  padding-left: 1rem;
  border-left: 1px solid #f0f0f0;
}

body.tco.tco-onestep .data-table td:last-child, body.tco.tco-onestep .data-table th:last-child {
  padding-right: 1rem;
  text-align: right;
  border-right: 1px solid #f0f0f0;
}

body.tco.tco-onestep .data-table tbody tr:first-child td, body.tco.tco-onestep .data-table tbody tr:first-child th, body.tco.tco-onestep .data-table tfoot tr:first-child td, body.tco.tco-onestep .data-table tfoot tr:first-child th, body.tco.tco-onestep .data-table thead tr:first-child td, body.tco.tco-onestep .data-table thead tr:first-child th {
  border-top: 1px solid #f0f0f0;
}

/* MODULES / KCO - modules/kco/_kco.scss */
#polarcore {
  /*  #kco_login .buttons-set button,
  #coupon_code_section #coupon-code-form button,
  #kco_review .cart #shopping-cart-table tr .input-wrapper .qty-change,
  #kco_postal .postal-form button,
  input#kco_newsletter_checkbox:checked + label:before {
    background-color: $kco-button-color;
    &:hover {
      background-color: $kco-button-hover-color;
    }
  }*/
  /* Header colors */
}

#polarcore.klarna-checkout .col-main .page-title h2 {
  color: black;
}

#polarcore.klarna-checkout .col-main .page-title h2:before {
  background-color: black;
}

#polarcore #kco-back-to-store {
  line-height: 45px;
}

#polarcore #kco-logo {
  padding: 0.75rem 0;
}

#polarcore .page-title {
  margin-bottom: 1rem;
}

#polarcore .page-title h2 {
  margin: 0;
}

#polarcore #login-popup {
  margin: 1rem 0;
}

#polarcore #kco_login h2 {
  margin: 0;
}

#polarcore #kco_login #login-form label {
  font-size: 1.1rem;
  margin-bottom: 0.25rem;
}

#polarcore #kco_login #login-form input {
  border: 1px solid #e0e0e0;
}

#polarcore #kco_login .buttons-set a {
  margin-right: 0.5rem;
}

#polarcore #kco_review {
  overflow-x: auto;
}

@media only screen and (min-width: 768px) {
  #polarcore #kco_review {
    overflow-x: visible;
  }
}

#polarcore #kco_review #shopping-cart-table {
  border-spacing: 0px;
}

#polarcore #kco_review #shopping-cart-table tr {
  margin-bottom: 0;
}

@media only screen and (min-width: 768px) {
  #polarcore #kco_review #shopping-cart-table tr td {
    border-bottom: 1px solid #e0e0e0;
  }
  #polarcore #kco_review #shopping-cart-table tr td:nth-of-type(3) a {
    display: none;
  }
  #polarcore #kco_review #shopping-cart-table tr td:first-of-type {
    width: 20%;
    /*border-left: 1px solid #e0e0e0;*/
  }
  #polarcore #kco_review #shopping-cart-table tr td:last-of-type {
    /*border-right: 1px solid #e0e0e0;*/
  }
  #polarcore #kco_review #shopping-cart-table tr:last-of-type td {
    border-bottom: none;
  }
}

#polarcore #kco_review #shopping-cart-table .product-image img {
  max-width: none;
  width: auto;
}

#polarcore #kco_review #shopping-cart-table .product-name {
  line-height: 1.5rem;
  font-size: 1rem;
  margin-left: 0.5rem;
}

#polarcore #kco_review #shopping-cart-table .item-options {
  margin-left: 0.5rem;
}

#polarcore #kco_review #shopping-cart-table input.qty {
  max-width: 2.5rem;
}

#polarcore #kco_review #shopping-cart-table a.btn-remove {
  display: inline-block;
  text-indent: -999px;
  text-align: left;
  overflow: hidden;
}

@media only screen and (min-width: 768px) {
  #polarcore #kco_review #shopping-cart-table a.btn-remove {
    position: relative;
  }
}

#polarcore #kco_review #shopping-cart-table a.btn-remove:after {
  font-family: "Material Icons";
  text-rendering: optimizelegibility;
  font-feature-settings: "liga";
  font-style: normal;
  text-transform: none;
  line-height: 1;
  display: block;
  overflow: hidden;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
  content: "\E5CD";
  text-indent: 0;
  font-size: 1.25rem;
}

@media only screen and (max-width: 767px) {
  #polarcore #kco_review #shopping-cart-table tr {
    display: block;
    position: relative;
    border: 1px solid #e0e0e0;
    border-bottom: none;
  }
  #polarcore #kco_review #shopping-cart-table tr .product-image {
    margin-right: 0.75rem;
  }
  #polarcore #kco_review #shopping-cart-table tr .product-image img {
    max-height: 75px;
    margin-top: 0.25rem;
  }
  #polarcore #kco_review #shopping-cart-table tr .product-name {
    font-size: 1.25rem;
  }
  #polarcore #kco_review #shopping-cart-table tr .input-wrapper {
    text-align: center;
  }
  #polarcore #kco_review #shopping-cart-table tr .input-wrapper .qty-change {
    font-weight: normal;
    font-size: 1.25rem;
    width: 28px;
    height: 28px;
    line-height: 28px;
  }
  #polarcore #kco_review #shopping-cart-table tr .cart-price {
    padding: 1rem 0;
    font-size: 1.25rem;
  }
  #polarcore #kco_review #shopping-cart-table tr .btn-remove {
    right: 0;
  }
}

#polarcore #kco_newsletter label input {
  margin-bottom: 3px;
}

#polarcore #coupon_code_section #coupon-code-form {
  margin-top: 0.5rem;
}

@media only screen and (max-width: 767px) {
  #polarcore #payment_kco #payment_select li {
    width: 100%;
    text-align: center;
  }
}

/* MODULES / LABELS - modules/labels/_labels.scss */
#polarcore .product-img-box, #polarcore .image-wrapper {
  position: relative;
}

#polarcore .product-img-box .trollweb-labels, #polarcore .image-wrapper .trollweb-labels {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
}

#polarcore .product-img-box .trollweb-labels .trollweb-label, #polarcore .image-wrapper .trollweb-labels .trollweb-label {
  color: #fff;
  margin: 0.25rem;
}

#polarcore .product-img-box .trollweb-labels .trollweb-label .discount, #polarcore .product-img-box .trollweb-labels .trollweb-label .new, #polarcore .image-wrapper .trollweb-labels .trollweb-label .discount, #polarcore .image-wrapper .trollweb-labels .trollweb-label .new {
  display: block;
  padding: 0.375rem 1.425rem;
  background-color: #EF5350;
  text-align: center;
  font-size: 1.275rem;
}

/* MODULES / QUICKSHOP - modules/quickshop/_quickshop.scss */
@media only screen and (max-width: 767px) {
  #polarcore #quickshop.quickshop-container {
    padding: 1rem;
  }
}

/* CUSTOM - Sample custom scss file */
/* To import, rename or copy to <anything>.scss and keep it in the scss/components/custom/after folder */
/* (all scss files in the custom folder will be imported after all other scss files) */
/*.header-nav #nav .nav-primary .level1 a:after, a:after {
    display:block;
    content:attr(title);
    font-weight:bold;
    height:1px;
    color:transparent;
    overflow:hidden;
    visibility:hidden;
    margin-bottom:-1px;
}*/
h1, h2 {
  text-transform: uppercase;
}

.gallery-image {
  width: 100%;
}

.main {
  padding: 160px 15px 1em 15px;
}

/*.checkout-onepage-index{
  .main{
    padding-top: 160px;
  }
}*/
.cms-home .main {
  padding: 0;
}

.instagram-grid {
  background: #fff;
  margin-top: 5em;
}

.instagram-grid .h4-wrapper {
  text-align: center;
}

.instagram-grid .h4-wrapper h4 {
  display: inline-block;
  text-align: center;
  color: #000;
  padding: 0 5px;
  letter-spacing: 1px;
  text-shadow: 1px 1px 4px #000;
  font-size: 2rem;
  color: white;
  font-size: 1.7rem;
  font-weight: 400;
}

.instagram-grid #instafeed {
  width: 75%;
  /*box-shadow: 0px 0px 10px 0px black;*/
}

@media only screen and (max-width: 767px) {
  .main {
    padding-top: 0;
  }
}

.catalog-product-view .main {
  padding-left: 0;
}

#instafeed > * {
  padding: 5px;
  box-shadow: 0px 0px 10px 0px black;
  margin: 2px;
  width: calc(16.66667% - 4px);
}

@media only screen and (max-width: 767px) {
  #instafeed > * {
    width: calc(33.33333% - 4px);
  }
}

#polarcore {
  /* Meganav */
}

#polarcore .header-nav #nav .nav-primary li {
  position: static;
}

#polarcore .header-nav #nav .nav-primary > li > div {
  padding: 0;
  margin: 0;
  display: none;
  position: absolute;
  top: 100%;
}

#polarcore .header-nav #nav .nav-primary > li .meganav {
  z-index: 1000;
  width: auto;
  background-color: rgba(234, 255, 0, 0.5);
}

#polarcore .header-nav #nav .nav-primary > li .meganav .view-all {
  font-size: 1.1rem;
  padding: 0.25rem 0.5rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
}

#polarcore .header-nav #nav .nav-primary > li .meganav .view-all:hover {
  font-weight: 400;
}

#polarcore .header-nav #nav .nav-primary > li .meganav ul {
  position: static;
  padding: 0.5rem 0;
  border-top: none;
  border-bottom: none;
  background-color: transparent;
}

#polarcore .header-nav #nav .nav-primary > li .meganav ul li {
  background-color: transparent;
}

#polarcore .header-nav #nav .nav-primary > li .meganav ul li:hover {
  font-weight: 400;
}

#polarcore .header-nav #nav .nav-primary > li .meganav ul li:hover a {
  background-color: transparent;
}

#polarcore .header-nav #nav .nav-primary > li .meganav ul li a {
  text-transform: initial;
  padding: 0.25rem 0.5rem;
  background-color: transparent;
}

#polarcore .header-nav #nav .nav-primary > li .meganav ul.col-2 {
  column-count: 2;
}

#polarcore .header-nav #nav .nav-primary > li .meganav ul.col-3 {
  column-count: 3;
}

#polarcore .header-nav #nav .nav-primary > li .meganav .mdl-cell {
  padding: 1rem;
  width: 100%;
}

#polarcore .header-nav #nav .nav-primary > li.menu-active > div.meganav {
  display: flex;
}

#polarcore .header-nav #nav .nav-primary > li.menu-active > div.meganav > div > ul {
  display: block;
}

#polarcore .header-nav #nav .nav-primary > li.menu-active > div.meganav .banner {
  margin: 0;
  padding: 0;
  display: flex;
}

#polarcore .header-nav #nav .nav-primary > li.menu-active > div.meganav .banner a {
  position: relative;
  padding: 2px;
  margin: auto 0;
}

#polarcore .header-nav #nav .nav-primary > li.menu-active > div.meganav .banner img {
  width: 100%;
}

#polarcore .header-nav #nav .nav-primary > li.menu-active > div.meganav .banner.mdl-cell--9-col {
  width: 75%;
}

#polarcore .header-nav #nav .nav-primary > li.menu-active > div.meganav .banner.mdl-cell--8-col {
  width: 66.666%;
}

#polarcore .header-nav #nav .nav-primary > li.menu-active > div.meganav .banner.mdl-cell--6-col {
  width: 50%;
}

#polarcore .header-nav #nav .nav-primary > li.menu-active > div.meganav .banner .mdl-cell--4-col {
  width: 33.333%;
}

#polarcore .header-nav #nav .nav-primary > li.menu-active > div.meganav .banner .mdl-cell--3-col {
  width: 25%;
}

/*.product-view{
 	.product-name{
 		.h1{
 			color:#fff;
 			font-size: 1.6em;
 		}
 	}
 	.add-to-cart button.btn-cart{
 		i{
 			display:none;
 		}
 	}
 	.product-view{
 		 .price-info{
 		 	.price{
 		 		color:#222;
 		 	}
 		 }
 	}
	.product-shop{
		position:absolute;
		right:3%;
		top:3%;
		width: 20%;
		> .inner{
			padding:1rem;
			background-color:$nav-background;
		}
		.sku-wrapper{
			display:none;
		}
	}
}
*/
.footer {
  padding-top: 3rem;
  /*.newsletter{
		background:#fff;
		padding:0;
	}
	.mdl-textfield__label{
		color:#fafafa;
	}
	.mdl-textfield--floating-label.is-focused .mdl-textfield__label, .mdl-textfield--floating-label.is-dirty .mdl-textfield__label{
		color:#fafafa;
	}
	.mdl-textfield--floating-label.is-focused .mdl-textfield__label:after{
		background:#222;
	}
	.mdl-textfield__input{
		background:#222;
		border-color:#fafafa;
	}
  .button{
    border-color:#fafafa;
    color:#fafafa;
  }*/
}

.footer #newsletter {
  color: #fafafa !important;
  /* IMPORTANT RULES - DirtyBoy */
}

.copyright svg {
  width: 70px;
  height: auto;
  fill: white;
  padding-bottom: 5px;
}

/*! Flickity v1.2.1
http://flickity.metafizzy.co
---------------------------------------------- */
.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus {
  outline: none;
}

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* draggable */
.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

/* ---- previous/next buttons ---- */
.flickity-prev-next-button {
  position: absolute;
  top: 50%;
  width: 44px;
  height: 44px;
  z-index: 9;
  border: none;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.3);
  cursor: pointer;
  /* vertically center */
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.flickity-prev-next-button:hover {
  background: rgba(255, 255, 255, 0.3);
}

.flickity-prev-next-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #09F;
}

.flickity-prev-next-button:active {
  filter: alpha(opacity=60);
  /* IE8 */
  opacity: 0.6;
}

.flickity-prev-next-button.previous {
  left: 10px;
}

.flickity-prev-next-button.next {
  right: 10px;
}

/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px;
}

.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px;
}

.flickity-prev-next-button:disabled {
  filter: alpha(opacity=30);
  /* IE8 */
  opacity: 0.3;
  cursor: auto;
}

.flickity-prev-next-button svg {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%;
}

.flickity-prev-next-button .arrow {
  fill: rgba(255, 255, 255, 0.2);
}

/* color & size if no SVG - IE8 and Android 2.3 */
.flickity-prev-next-button.no-svg {
  color: #333;
  font-size: 26px;
}

/* ---- page dots ---- */
.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1;
}

.flickity-rtl .flickity-page-dots {
  direction: rtl;
}

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  filter: alpha(opacity=25);
  /* IE8 */
  opacity: 0.25;
  cursor: pointer;
}

.flickity-page-dots .dot.is-selected {
  filter: alpha(opacity=100);
  /* IE8 */
  opacity: 1;
}

@media only screen and (max-width: 767px) {
  .flickity-prev-next-button:hover, .flickity-prev-next-button {
    background: none;
  }
  .flickity-prev-next-button.previous {
    left: 0;
    padding: 0;
    width: 22px;
    height: 22px;
  }
  .flickity-prev-next-button.next {
    right: 0;
    padding: 0;
    width: 22px;
    height: 22px;
  }
}

.mdl-textfield {
  width: 100%;
}

.polarslide .slick-prev:before, .polarslide .slick-next:before {
  color: #fff !important;
}

.• {
  margin-top: 0;
}

/*.newsletter a{
  display:inline-block;
  background:#222;
  border-radius:50%;
  width:30px;
  height:30px;
  text-align:center;
  svg{
    fill:#fff;
    width:25px;
    height:30px;
  }
  .instagram-svg{
    width:20px;
  }
}*/
.checkout-cart-index .impulseproducts .item {
  width: 25%;
}

@media only screen and (max-width: 767px) {
  /*.cms-home{
    .products-grid{
      display: none;
    }
  }*/
  .impulseproducts .page-title {
    margin-top: 1em;
  }
  .impulseproducts .item {
    width: 100% !important;
  }
  .impulseproducts .btn-cart {
    font-size: 0.8em !important;
  }
}

.cart-content {
  position: fixed;
  right: 20px;
  top: 60px;
  z-index: 9;
  display: none;
}

.cart-content img {
  width: 80px;
  display: inline-block;
}

.cart-content p {
  display: inline;
}

.header-minicart:hover .cart-content {
  display: block;
}

#ajaxcart-error, #polarcore #ajaxcart, #polarcore #ajaxcart-loading {
  display: none !important;
}

.page-title {
  text-align: center;
}

.account-create {
  margin: auto;
}

.swatch-link {
  padding: 0;
  background: #F1F1F1;
}

.soldout {
  position: absolute;
  background: rgba(255, 255, 255, 0.4);
  color: white;
  transform: rotate(-45deg);
  text-transform: uppercase;
  text-align: center;
  top: 20px;
  left: -70px;
  width: 200px;
  z-index: 2;
}

.soldout p {
  margin: 0;
}

.salebadge {
  position: absolute;
  background: rgba(255, 255, 255, 0.2);
  right: 1em;
  top: 1em;
  width: 5rem;
  color: #fafafa;
  z-index: 2;
}

.salebadge p {
  margin: 0;
  line-height: 2em;
  text-transform: uppercase;
}

.blackfriday {
  position: absolute;
  background: rgba(0, 0, 0, 0.6);
  right: 0;
  top: 0;
  width: 10rem;
  color: #fafafa;
  z-index: 2;
}

.blackfriday:after {
  content: 'Black Friday';
  font-weight: 400;
  text-transform: uppercase;
  line-height: 2em;
}

.blackfriday p {
  display: none;
}

.image-wrapper {
  overflow: hidden;
}

.filter-title {
  display: none;
}

.filter-content .category-filter-tree ul {
  display: inline;
}

#polarcore .bubble-layer-top {
  display: none;
  padding: 0;
}

.toolbar-bottom .toolbar-inline {
  display: blocK;
}

#polarcore .bubble-layer-top .block-layered-nav .block-content {
  display: inline-block;
  padding: 15px 0;
}

.show-when-sticky {
  display: none;
}

.sticky--up .show-when-sticky {
  display: block;
}

.short-description ul {
  /*padding: 15px 0;*/
}

.short-description ul li {
  font-weight: 400;
}

.short-description ul li:before {
  content: '›';
  /* background: url(../images/finger.svg) no-repeat; */
  color: #000;
  background-size: contain;
  vertical-align: middle;
  margin-right: 5px;
  width: 20px;
  height: 20px;
  display: inline-block;
  line-height: 15px;
  text-align: center;
  font-weight: 700;
  font-size: 1.5em;
}

.product-options .mdl-cell {
  margin-bottom: 0;
}

.product-options .button {
  width: 100%;
}

/*.product-view .add-to-cart button.btn-cart{
  margin-top:0;
  color: #dc37c0;
  background: white;
  border: 2px solid black;
  font-weight: 500;
}*/
.product-view .add-to-cart button.btn-cart {
  box-shadow: 0 8px 0 #000, 0 15px 20px rgba(0, 0, 0, 0.35);
  transition: box-shadow .1s ease-in-out;
  padding: 0;
  background: transparent !important;
  color: #000;
  font-weight: 500;
  margin-top: 0;
}

.product-view .add-to-cart button.btn-cart .inner {
  border: 1px solid black;
  padding: 0.5em 1em;
  display: block;
  background: #eaff00;
  transition: transform .1s ease-in-out;
}

.product-view .add-to-cart button.btn-cart:active {
  box-shadow: 0 8px 0 #000, 0 12px 10px rgba(0, 0, 0, 0.3);
}

.product-view .add-to-cart button.btn-cart:active span {
  transform: translate(0, 4px);
}

.product-view .select.input-box select {
  background: white;
  color: black;
  font-weight: 400;
}

.product-view .select.input-box:after {
  background: transparent;
  color: black;
}

.product-view .select.input-box select:hover {
  background: black;
  color: white;
}

.product-view .select.input-box:hover:after {
  background: black;
  color: white;
}

.cms-sneaker-history .page-title {
  display: none;
}

.history-holder {
  position: relative;
  margin-bottom: 16px;
  overflow: hidden;
}

.history-holder img {
  width: 100%;
}

.history-holder .scale-transition {
  transition: .3s;
}

.history-holder .scale-transition:hover {
  transform: scale(1.02);
}

.history-holder h2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-transform: uppercase;
  color: #fff;
}

.history-holder h2:before, .history-holder h2:after {
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  display: block;
}

.history-holder h2 a {
  color: #fff;
}

.block-related ol li.item .price-box .regular-price {
  text-align: center;
}

#header-cart {
  position: fixed;
  z-index: 99999;
  background: #FAFAFA;
  width: 25%;
  right: 0;
  top: 0;
}

#header-cart #cart-sidebar {
  padding-bottom: 10px;
  padding: 0 10px;
}

#header-cart .product-name, #header-cart .product-options {
  margin: 0;
}

.ajax-loading {
  text-align: center;
  display: none;
}

.loading .ajax-loading {
  display: block;
}

.header-minicart:hover #header-cart {
  display: block !important;
}

.banner-area .banner-col-one:after {
  content: '';
}

/* DIRTY */
.banner-area .banner-col-three {
  width: 100%;
}

.banner-area .banner-col-three img {
  width: 100%;
}

.banner-area .banner-col-three .banner-wrapper {
  padding: 5px;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .banner-area .banner-col-one {
    flex-direction: column;
  }
  .banner-area .banner-col-one, .banner-area .banner-col-two {
    flex-direction: column;
  }
  .banner-area .banner-wrapper {
    width: 100% !important;
  }
}

.minicart-actions {
  height: 30px;
  width: 100%;
  text-align: center;
  padding: 10px;
  margin-bottom: 20px;
}

.empty-cart > * {
  display: none;
}

.hide-chart, .size-chart {
  opacity: 0;
  visibility: hidden;
  height: 0;
  transform: scaleY(0);
  transform-origin: top;
  transition: transform 0.3s ease;
}

.hide-chart table, .size-chart table {
  width: 100%;
}

.show-size {
  opacity: 1;
  visibility: visible;
  height: auto;
  transform: scaleY(1);
  transition: transform 0.3s ease;
}

.mdl-dialog {
  width: 40%;
}

.mdl-dialog .mdl-dialog__actions {
  position: absolute;
  top: 0;
  right: 0;
}

.mdl-dialog__content {
  min-width: 280px;
}

.mdl-data-table {
  width: 100%;
}

.mdl-data-table tbody tr {
  transition: none;
}

.product-essential .product-shop .product-shop {
  /*background:#ECECEC;*/
  padding: 0 0 15px 0;
  margin: 0 15px;
}

@media only screen and (max-width: 767px) {
  .product-essential .product-shop .product-shop {
    display: flex;
    flex-direction: column;
  }
  .product-essential .order-mobile-1 {
    order: 1;
    padding-top: 1em;
  }
  .product-essential .order-mobile-2 {
    order: 2;
  }
  .product-view .short-description ul {
    margin-left: 0;
  }
  .short-description {
    padding: 0 !important;
  }
  .product-view .price-info .price {
    padding-left: 0 !important;
  }
  .product-view .product-name .h1 {
    padding-left: 0 !important;
  }
  .order-mobile-1-nopad {
    order: 1;
  }
  .order-mobile-2-nopad {
    order: 2;
  }
  .order-mobile-2 {
    order: 2;
  }
}

.minicart-actions a {
  color: #000;
  line-height: 20px;
  padding: 5px;
  float: left;
  font-weight: 400;
  font-size: .9rem;
}

.minicart-actions a:last-of-type {
  float: right;
}

.mdl-dialog, dialog {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  z-index: 999;
  box-sizing: content-box;
  /* THA FIXES BE REAL! */
  left: 0;
  right: 0;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  margin: auto;
  border: solid;
  padding: 1em;
  background: white;
  color: black;
  display: none;
  box-shadow: 0 0 0 10000px rgba(3, 169, 244, 0.3);
}

@media only screen and (max-width: 767px) {
  .mdl-dialog, dialog {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.mdl-dialog[open], dialog[open] {
  display: block;
}

dialog::backdrop, dialog + .backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /*background: rgba(0,0,0,0.1);*/
  background: rgba(3, 169, 244, 0.3);
}

/* for small devices, modal dialogs go full-screen */
@media screen and (max-width: 540px) {
  dialog[_polyfill_modal] {
    /* TODO: implement */
    top: 0;
    width: auto;
    margin: 1em;
  }
}

._dialog_overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.product-view .product-name .h1 {
  padding: 15px 20px 0 20px;
  font-weight: 600;
  background: transparent;
  margin: 0;
  font-size: 2rem;
  color: black;
}

.product-view .price-info {
  background: #fff;
  padding-bottom: 15px;
  color: black;
}

.product-view .price-info .price {
  font-weight: 600;
  font-size: 2rem;
  padding: 0 20px;
  background: #fff;
  color: black;
}

.short-description {
  padding: 0 15px 20px;
  background: #fff;
}

.mdl-data-table tbody tr {
  height: 0;
}

.mdl-data-table th, .mdl-data-table td {
  padding: 5px;
  height: 0;
  text-align: center;
}

.mdl-data-table td:first-of-type, .mdl-data-table th:first-of-type {
  padding-left: 5px;
}

.mdl-data-table td:last-of-type, .mdl-data-table th:last-of-type {
  padding-right: 5px;
}

#shopping-cart-table .product-name {
  line-height: 1.5rem;
  font-size: 1rem;
  margin-left: 0.5rem;
}

#header-cart .subtotal {
  padding: 0 10px;
  margin-top: 10px;
  text-align: right;
}

#header-cart .subtotal .label {
  font-size: .8rem;
  font-weight: 300;
}

.qty {
  text-align: center;
}

.mdl-data-table tbody tr:nth-child(2n) {
  background: #eeeeee;
}

.mdl-dialog__title {
  text-align: center;
  margin-top: 0;
}

@media screen and (max-width: 1024px) {
  .cart-total-price-wrapper {
    position: static;
  }
}

.sidebar .block .block-title, .col-wrapper aside.sidebar .block .block-subtitle--filter, .col-wrapper aside.sidebar .block .block-subtitle--filter, .customer-account h2 {
  border-bottom: 1px solid #e0e0e0;
  text-transform: uppercase;
}

.customer-account .sidebar ul {
  padding: 15px 0;
}

.customer-account .sidebar ul li {
  font-weight: 400;
}

.customer-account .sidebar ul li:before {
  content: "›";
  margin-right: 5px;
  background: white;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  display: inline-block;
  line-height: 15px;
  text-align: center;
}

.grey {
  background: #fff;
  color: #222;
  padding: 2rem;
}

.grey .mdl-grid {
  width: 90%;
  margin: auto;
}

.grey .mdl-grid p {
  margin: 0;
}

#cart-sidebar .push-right {
  padding-right: 10px;
}

#cart-sidebar .product-options {
  font-size: .8rem;
}

#cart-sidebar .price {
  font-weight: 100;
}

.mini-products-list li {
  padding-bottom: 2px;
}

.mini-products-list li p {
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cart-empty {
  text-align: center;
}

.cart-total-price-wrapper {
  align-items: flex-start;
  align-content: flex-start;
}

.cart-totals {
  transition: .2s;
  padding: 1.5em;
  margin: 5px;
}

@media only screen and (max-width: 767px) {
  .cart-totals {
    background: #fff;
  }
}

.mdl-textfield__label {
  font-weight: 100;
}

ul.top-links__mobile {
  background: #fff;
}

.mdl-layout__drawer > ul li ul, .mdl-layout__drawer .nav-primary li ul, ul.top-links__mobile, .mdl-layout__drawer #nav-mobile {
  background: rgba(255, 255, 255, 0.6);
}

.mdl-layout__drawer {
  background: transparent;
}

ul.top-links__mobile:first-of-type {
  border: none;
}

.mdl-layout__drawer .mdl-layout__drawer__title {
  font-size: 0;
  background-color: rgba(255, 255, 255, 0.6);
}

.mdl-layout__drawer .mdl-layout__drawer__close {
  color: #000;
}

.mdl-layout__drawer .mdl-layout__drawer__home {
  display: none;
}

@media only screen and (max-width: 767px) {
  .footer .mdl-textfield {
    width: 100%;
  }
}

#header .header-content .show-search {
  left: 0;
}

@media only screen and (max-width: 767px) {
  #shopping-cart-table {
    display: none;
  }
  #narrow-by-list dd li {
    padding: 0 2vw;
    margin: 0 4px 8px 0;
  }
  .toolbar {
    margin: 0;
    padding: 0;
  }
  .toolbar .sorter .sort-by .nuva-dropdown-wrapper {
    float: none;
    position: relative;
  }
  .toolbar .sorter .sort-by .nuva-dropdown-wrapper .nuva-dropdown {
    width: 100%;
    right: 0;
  }
  #polarcore .bubble-layer-top .block-layered-nav .block-content {
    display: block;
    padding: 0;
  }
  .product-essential .product-shop .product-shop, .catalog-product-view .main {
    padding: 0;
  }
  .product-shop {
    padding: 0 15px;
  }
  .mobile ul {
    margin: 1.5em 0;
  }
  .mobile .cart-icon {
    position: relative;
    width: 24px;
    display: inline-block;
    vertical-align: top;
  }
  .mobile .cart-icon .count {
    position: absolute;
    color: #FFF;
    background: #000;
    padding: 0rem 0.3rem;
    line-height: 1rem;
    right: -5px;
    top: -3px;
    border-radius: 50%;
    font-size: 0.7rem;
    display: flex;
  }
  .mobile .cart-icon .count span {
    margin: auto;
  }
  .mobile .cart-icon svg {
    vertical-align: top;
  }
  .mobile li {
    display: inline-block;
    padding: 0 25px;
  }
  .mobile li .material-icons {
    vertical-align: middle;
  }
  .mobile #header .header-content .header-search {
    background: #fff;
  }
}

.mobile-menu {
  padding: 0 10px;
  min-width: 20em;
  margin: auto;
  display: none;
}

.mobile-menu .top-links__mobile, .mobile-menu .nav-primary {
  width: 100%;
}

.mobile-menu .top-links__mobile li, .mobile-menu .nav-primary li {
  width: 100%;
}

.mobile-menu .top-links__mobile li a, .mobile-menu .nav-primary li a {
  padding: 20px 10px;
  display: block;
}

.mobile-menu .top-links__mobile .parent > a:after, .mobile-menu .nav-primary .parent > a:after {
  content: '+';
  float: right;
  color: #333;
  font-weight: 400;
  transition: 0.4s ease;
}

.mobile-menu .top-links__mobile .open > a:after, .mobile-menu .nav-primary .open > a:after {
  transform: rotate(45deg);
}

.mobile-menu .top-links__mobile .parent > ul, .mobile-menu .top-links__mobile .parent > .meganav, .mobile-menu .nav-primary .parent > ul, .mobile-menu .nav-primary .parent > .meganav {
  display: none;
  border-left: 1px solid #eaeaea;
  padding-left: 5px;
  margin-left: 30px;
}

.is-visible {
  display: block;
  animation: showsearch 0.4s ease;
}

.is-visible-no-anim {
  display: block;
}

.cart-item {
  justify-content: space-between;
  border-bottom: 1px solid #e0e0e0;
  padding: 1rem 0;
}

.cart-item > .cart-item-col:first-of-type {
  flex: 0 1 auto;
}

.cart-item:last-of-type {
  border: none;
}

.cart-item > .cart-item-col {
  justify-content: space-between;
}

.cart-item > * {
  flex: auto;
}

.cart-item-col + .cart-item-col > .cart-item-col:first-of-type {
  width: 40%;
  padding-left: 2em;
}

.cart-item-col + .cart-item-col > .cart-item-col:first-of-type h4 {
  margin: 0;
}

.cart-item-col + .cart-item-col > .cart-item-col:first-of-type h4 a {
  font-weight: 500;
  font-size: 1rem;
}

.cart-item .price, .cart-item dd {
  font-weight: 500;
  font-size: 1rem;
}

.cart-item .btn-remove {
  font-weight: 500;
  font-size: 1rem;
}

.cart-item .qty {
  border-color: #000;
}

.cart-item-col + .cart-item-col > .cart-item-col:last-of-type {
  width: 10%;
}

/*.cart-item-col:nth-child(1){
  width: auto;
}
.cart-item-col:nth-child(3){
  justify-content: space-between;
}*/
@media only screen and (max-width: 767px) {
  .page-title h1 {
    font-size: 1.6rem;
  }
  .page-title h2 {
    font-size: 1.4rem;
    line-height: 1.5em;
  }
  .polarslide {
    padding: 0;
  }
  .cart-item-col + .cart-item-col > .cart-item-col:first-of-type {
    width: auto;
    padding-left: 0;
  }
  .cart-item {
    display: inline-block;
    width: 100%;
    position: relative;
  }
  .cart-item .price {
    position: absolute;
    bottom: 7%;
  }
  .cart-item .product-name {
    margin: 0;
    font-size: 1.15em;
    line-height: 1.15em;
  }
  .cart-item a.btn-remove {
    display: inline-block;
    text-indent: -999px;
    text-align: left;
    overflow: hidden;
  }
}

@media only screen and (max-width: 767px) and (min-width: 768px) {
  .cart-item a.btn-remove {
    position: relative;
  }
}

@media only screen and (max-width: 767px) {
  .cart-item a.btn-remove:after {
    font-family: "Material Icons";
    text-rendering: optimizelegibility;
    font-feature-settings: "liga";
    font-style: normal;
    text-transform: none;
    line-height: 1;
    display: block;
    overflow: hidden;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    top: 1.5rem;
    right: 0.25rem;
    content: "\E5CD";
    text-indent: 0;
    font-size: 1.25rem;
  }
  .cart-item > * {
    display: inline-block;
    vertical-align: top;
  }
  .cart-item > .cart-item-col {
    width: calc(50% - 10px);
    padding-right: 10px;
    vertical-align: middle;
  }
  .cart-item-col:nth-child(3) {
    position: absolute;
    right: 0.25em;
    bottom: 11%;
  }
  .cart-item-col:nth-child(3) input {
    padding: 8px 0;
  }
}

.soldout-text {
  text-align: center;
  padding: 1em;
  border: 1px solid;
  margin: 15px 0;
}

.soldout-text p {
  margin: 0;
}

.page-text {
  padding-bottom: 3em;
  max-width: 50rem;
  margin: auto;
}

.page-text h4 {
  margin: 0;
}

.col-main:first-of-type .page-text {
  padding-top: 3em;
}

.page-text-two-col {
  display: flex;
  align-items: center;
}

.page-text-two-col .col-2 {
  width: 50%;
}

.stories .price-box {
  margin-bottom: 15px;
}

.feit-strek:after {
  content: '';
  position: relative;
  width: 20%;
  height: 1px;
  background: #222;
  margin: auto;
  display: block;
  margin-top: 25px;
}

.page-banner {
  position: relative;
}

.page-banner img {
  width: 100% !important;
}

.page-banner .page-banner-head {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.page-banner .white {
  color: #fff;
}

.page-banner .black {
  color: #000;
}

@media only screen and (min-width: 768px) {
  .pad-large {
    padding-top: 3em;
    padding-bottom: 3em;
  }
}

@media only screen and (max-width: 767px) {
  .pad-large {
    padding-top: 1.4em;
  }
  .page-text {
    padding-bottom: 1.4em !important;
    padding-top: 1.4em !important;
    padding: 0 2em;
  }
  .page-text h4 {
    margin: 0;
    line-height: 1.5em;
    font-size: 1em;
  }
  .page-text h4 strong {
    font-weight: 200;
  }
  .page-text-two-col h4 {
    margin: 0;
    line-height: 1.5em;
    font-size: 1em;
  }
  .page-text-two-col h4 strong {
    font-weight: 200;
  }
  .page-text-two-col {
    flex-direction: column;
  }
  .page-text-two-col .col-2 {
    width: 100%;
  }
  .page-banner h1 {
    width: 100%;
    font-size: 1rem;
    text-align: center;
  }
  .stories .product-name {
    font-size: 1.5rem;
  }
  .stories .page-title {
    margin: 0 !important;
  }
  .stories .item {
    padding: 0;
  }
}

.slick-slide .button {
  position: absolute;
  padding: 1em 5em;
  font-weight: 400;
}

.slick-slide .middle {
  top: 85%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.slick-slide .right {
  top: 26%;
  right: 5%;
  transform: none;
}

.slick-slide .left {
  top: 75%;
  left: 20%;
  transform: none;
}

/*.slick-slide:nth-child(4) .button{
  top: 58%;
  right: 77%;
}*/
.slick-slide {
  position: relative;
}

@media only screen and (max-width: 767px) {
  .slick-slide .button {
    position: absolute;
    padding: 0.2em 1em;
  }
  .slick-slide .middle {
    top: 70%;
    left: 50%;
    transform: translateX(50%);
  }
  .slick-slide .right {
    top: 10%;
    right: 5%;
    transform: none;
  }
  .slick-slide .left {
    top: 70%;
    left: 50%;
    transform: translateX(50%);
  }
  .slick-slide .white {
    color: #171717;
    border-color: #fff;
    font-weight: 300;
  }
  .slick-slide .black {
    color: #000;
    border-color: #000;
  }
}

.nopad > * {
  padding: 0 !important;
}

.checkout-cart-index main .products-grid button.btn-cart {
  margin: 0;
  padding-top: 15px;
  font-weight: 500;
  background: transparent;
}

.checkout-cart-index main .products-grid button.btn-cart:hover {
  background: transparent;
}

@media only screen and (max-width: 767px) {
  .search-autocomplete ul.bubble-elasticsearch li {
    display: block;
  }
  .search-autocomplete ul.bubble-elasticsearch li.selected a {
    color: #222;
  }
}

.ajaxcart-error-msg {
  border: 1px solid #c25c5c;
  padding: 5px;
  text-align: center;
  margin-bottom: 1rem;
}

.ajaxcart-success-msg {
  border: 1px solid #000000;
  padding: 5px;
  text-align: center;
  margin-bottom: 1rem;
  display: block;
  text-align: center;
  width: 80%;
  margin: auto;
}

.cms-kontakt #contactForm {
  width: 50em;
  margin: auto;
}

.cms-kontakt #contactForm .mdl-textfield {
  width: 100%;
}

.cms-kontakt #contactForm textarea {
  max-width: 100%;
}

address {
  font-weight: 100;
}

.stories .products-grid {
  flex-flow: row;
  justify-content: center !important;
}

.stories button {
  display: block;
  width: 100px;
  margin: auto;
}

@media only screen and (max-width: 767px) {
  .stories .products-grid {
    flex-flow: column;
  }
}

@media only screen and (max-width: 767px) {
  .polarslide .slick-slider {
    margin-bottom: 10px !important;
  }
  .instagram-grid {
    margin-top: 10px !important;
  }
}

.banner-area .banner-col-three .banner-wrapper .banner-text {
  color: #fff;
  bottom: 50%;
  left: 50%;
  text-align: center;
  position: absolute;
  transform: translate(-50%, 50%);
  font-size: 1.5em;
}

.tynn-strek {
  margin-bottom: 30px;
}

.tynn-strek:after {
  content: '';
  height: 1px;
  display: block;
  width: 5%;
  margin: auto;
  background: #474747;
}

.tynn-v {
  margin-bottom: 30px;
}

.tynn-v:after {
  content: 'v';
  font-size: 1rem;
  line-height: 1;
  display: block;
  width: 5%;
  margin: auto;
  background: #474747;
}

.polarslide-text {
  position: absolute;
  color: white;
}

.slick-slide .white {
  color: white;
}

.slick-slide .black {
  color: black;
}

.polarslide-text h1 {
  font-weight: 600;
  margin: 0;
  line-height: 1;
}

.polarslide-text h2 {
  margin: 0;
  margin-bottom: 20px;
}

.slider-align-0 {
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
}

.slider-align-1 {
  top: 50%;
  left: 25%;
  text-align: center;
  transform: translate(-50%, -50%);
}

.slider-align-2 {
  top: 50%;
  left: 75%;
  text-align: center;
  transform: translate(-50%, -50%);
}

.polarslide-text .button {
  position: relative;
  background: #000;
  color: #fff;
}

.polarslide-text .button:hover {
  background: transparent;
  color: #000;
}

@media only screen and (max-width: 767px) {
  .slider-align-1, .slider-align-2 {
    top: 50%;
    left: 50%;
    text-align: center;
    transform: translate(-50%, -50%);
    width: 100%;
  }
  .slider-align-0 {
    width: 100%;
  }
  .polarslide-text h1 {
    font-size: 1.3rem;
  }
  .polarslide-text h2 {
    font-size: 1.1rem;
    line-height: 1;
    font-weight: 300;
  }
}

@media only screen and (min-width: 768px) {
  .products-grid .item .inner-item {
    position: relative;
  }
  .products-grid .item .product-info {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    visibility: hidden;
    z-index: 9;
    width: 90%;
    transition: 0;
    transition-delay: 0;
    margin: 0;
    padding: 0;
  }
  .products-grid .item .product-info .product-name a {
    font-weight: 600;
    display: block;
  }
  .products-grid .item .product-info .price {
    font-size: 1.1rem;
    font-weight: 500;
    color: #424242;
  }
  .products-grid .item:hover .product-info {
    opacity: 1;
    visibility: visible;
    transition: .1s;
    transition-delay: .2s;
  }
  .products-grid .item:hover .inner-item img {
    opacity: .3;
  }
}

.products-grid .item .inner-item {
  position: relative;
}

.products-grid .item .inner-item .product-image {
  display: block;
}

.products-grid .item .product-info .product-name a {
  font-weight: 600;
}

.products-grid .item .product-info .price {
  font-size: 1.1rem;
  font-weight: 500;
  color: #424242;
}

.filter-drawer {
  width: 25rem;
  height: 100%;
  position: fixed;
  background: rgba(255, 214, 241, 0.95);
  box-shadow: 5px 0px 8px 0px #868686;
  z-index: 999;
  overflow: scroll;
  top: 120%;
  left: 0;
  transition: 0.3s;
}

@media only screen and (max-width: 767px) {
  .filter-drawer {
    width: 19rem;
  }
}

.filter-drawer .filter-close {
  position: fixed;
  left: 22.6rem;
  top: 0px;
  margin-left: 1rem;
  font-size: 2rem;
  z-index: 1000;
  color: black;
  display: none;
}

@media only screen and (max-width: 767px) {
  .filter-drawer .filter-close {
    left: 17.5rem;
    margin-left: 0;
  }
}

.drawer-overlay {
  position: fixed;
  z-index: 998;
  top: 0;
  left: 350px;
  display: none;
  width: 100%;
  height: 100%;
  background-color: transparent;
}

.filter-open {
  overflow: hidden !important;
}

.filter-open .filter-drawer {
  top: 0;
}

.filter-open .filter-drawer .filter-close {
  display: block;
}

.filter-open .drawer-overlay {
  display: block;
}

#algolia-searchbox {
  margin: 0 !important;
}

#algolia-searchbox label {
  display: none;
}

.magnifying-glass {
  display: none;
}

.autocomplete-wrapper .col-3 {
  display: none;
}

#algolia-autocomplete-container .col9 {
  width: 100%;
}

.filter-wrapper a {
  /*width: 100%;
    border: 1px solid #606060;
    color: #606060;*/
  border-radius: 0;
}

.filter-wrapper svg {
  width: 2rem;
  height: 17px;
  fill: rgba(97, 97, 97, 0.81);
  margin-right: 5px;
  vertical-align: initial;
}

.nuva-dropdown-wrapper {
  display: block;
}

.nuva-dropdown-wrapper a {
  display: block;
  height: 36px;
  line-height: 36px;
  border: none;
  color: #606060;
  padding: 0;
  text-transform: uppercase;
  border-radius: 0;
  font-weight: 500;
}

.toolbar .sorter .sort-by .nuva-dropdown-wrapper .nuva-dropdown {
  /*width:100%;*/
  width: 150px;
  left: auto;
  right: 0;
  top: 36px;
  background: #fff;
  color: #000;
  font-family: 'Comfortaa';
  text-shadow: aqua;
  /* background: wheat; */
  border: none;
  box-shadow: 5px 0 20px 3px rgba(0, 0, 0, 0.8);
}

@media only screen and (max-width: 767px) {
  .toolbar .sorter .sort-by .nuva-dropdown-wrapper .nuva-dropdown {
    background: #fff;
    opacity: 1;
  }
}

.nuva-dropdown-wrapper .nuva-dropdown li:hover {
  background: none;
}

.toolbar .sorter {
  display: inline-block;
  float: right;
}

#polarcore .block-layered-nav .block-content {
  background: transparent;
}

#polarcore .block-layered-nav #narrow-by-list dd ol li .mdl-checkbox .mdl-checkbox__label {
  color: black;
  font-weight: 500;
  display: block;
}

.block-layered-nav .mdl-checkbox__box-outline {
  border: 1px solid rgba(0, 0, 0, 0.54);
}

#narrow-by-list {
  border-right: none;
}

.mdl-checkbox.is-checked .mdl-checkbox__box-outline {
  border: 1px solid black;
  background: white;
}

#polarcore .block-layered-nav #narrow-by-list dt {
  color: white;
  background: none;
  cursor: pointer;
  font-family: 'Comfortaa';
  color: #444;
  font-weight: 800;
  font-size: 0.9rem;
  padding: 1rem 0;
}

@media only screen and (max-width: 767px) {
  #polarcore .block-layered-nav #narrow-by-list dt {
    color: #444;
  }
}

@-webkit-keyframes turnonlights {
  0% {
    color: #6d5669;
    text-shadow: 0 0 3px #635861, 0 0 5px #635861;
  }
  1% {
    color: #ff55de;
    text-shadow: 0 0 5px #ff99de, 0 0 10px #ff99de, 0 0 17px #ff00de, 0 0 38px #ff00de;
  }
  4% {
    color: #ff55de;
    text-shadow: 0 0 5px #ff99de, 0 0 10px #ff99de, 0 0 17px #ff00de, 0 0 38px #ff00de;
  }
  7% {
    color: #6d5669;
    text-shadow: 0 0 3px #635861, 0 0 5px #635861;
  }
  8% {
    color: #ff55de;
    text-shadow: 0 0 5px #ff99de, 0 0 10px #ff99de, 0 0 17px #ff00de, 0 0 38px #ff00de;
  }
  12% {
    color: #6d5669;
    text-shadow: 0 0 3px #635861, 0 0 5px #635861;
  }
  22% {
    color: #6d5669;
    text-shadow: 0 0 3px #635861, 0 0 5px #635861;
  }
  23% {
    color: #ff55de;
    text-shadow: 0 0 5px #ff99de, 0 0 10px #ff99de, 0 0 15px #ff99de, 0 0 20px #ff00de, 0 0 30px #ff00de, 0 0 40px #ff00de;
  }
  26% {
    color: #6d5669;
    text-shadow: 0 0 3px #635861, 0 0 5px #635861;
  }
  28% {
    color: #ff55de;
    text-shadow: 0 0 5px #ff99de, 0 0 10px #ff99de, 0 0 17px #ff00de, 0 0 38px #ff00de;
  }
  34% {
    color: #ff55de;
    text-shadow: 0 0 5px #ff99de, 0 0 10px #ff99de, 0 0 15px #ff99de, 0 0 20px #ff00de, 0 0 30px #ff00de, 0 0 40px #ff00de;
  }
  36% {
    color: #6d5669;
    text-shadow: 0 0 3px #635861, 0 0 5px #635861;
  }
  42% {
    color: #ff55de;
    text-shadow: 0 0 5px #ff99de, 0 0 10px #ff99de, 0 0 17px #ff00de, 0 0 38px #ff00de;
  }
  55% {
    color: #ff55de;
    text-shadow: 0 0 5px #ff99de, 0 0 10px #ff99de, 0 0 15px #ff99de, 0 0 20px #ff00de, 0 0 30px #ff00de, 0 0 40px #ff00de;
  }
  60% {
    color: #6d5669;
    text-shadow: 0 0 3px #635861, 0 0 5px #635861;
  }
  63% {
    color: #ff55de;
    text-shadow: 0 0 5px #ff99de, 0 0 10px #ff99de, 0 0 15px #ff99de, 0 0 20px #ff00de, 0 0 30px #ff00de, 0 0 40px #ff00de;
  }
  67% {
    color: #6d5669;
    text-shadow: 0 0 3px #635861, 0 0 5px #635861;
  }
  72% {
    color: #6d5669;
    text-shadow: 0 0 3px #635861, 0 0 5px #635861;
  }
  73% {
    color: #ff55de;
    text-shadow: 0 0 5px #ff99de, 0 0 10px #ff99de, 0 0 17px #ff00de, 0 0 38px #ff00de;
  }
  76% {
    color: #6d5669;
    text-shadow: 0 0 3px #635861, 0 0 5px #635861;
  }
  78% {
    color: #ff55de;
    text-shadow: 0 0 5px #ff99de, 0 0 10px #ff99de, 0 0 17px #ff00de, 0 0 38px #ff00de;
  }
  84% {
    color: #ff55de;
    text-shadow: 0 0 5px #ff99de, 0 0 10px #ff99de, 0 0 15px #ff99de, 0 0 20px #ff00de, 0 0 30px #ff00de, 0 0 40px #ff00de;
  }
  86% {
    color: #6d5669;
    text-shadow: 0 0 3px #635861, 0 0 5px #635861;
  }
  96% {
    color: #ff55de;
    text-shadow: 0 0 5px #ff99de, 0 0 10px #ff99de, 0 0 17px #ff00de, 0 0 38px #ff00de;
  }
  100% {
    color: #ff55de;
    text-shadow: 0 0 5px #ff99de, 0 0 10px #ff99de, 0 0 15px #ff99de, 0 0 20px #ff00de, 0 0 30px #ff00de, 0 0 40px #ff00de;
  }
}

@-moz-keyframes turnonlights {
  0% {
    color: #6d5669;
    text-shadow: 0 0 3px #635861, 0 0 5px #635861;
  }
  1% {
    color: #ff55de;
    text-shadow: 0 0 5px #ff99de, 0 0 10px #ff99de, 0 0 17px #ff00de, 0 0 38px #ff00de;
  }
  4% {
    color: #ff55de;
    text-shadow: 0 0 5px #ff99de, 0 0 10px #ff99de, 0 0 17px #ff00de, 0 0 38px #ff00de;
  }
  7% {
    color: #6d5669;
    text-shadow: 0 0 3px #635861, 0 0 5px #635861;
  }
  8% {
    color: #ff55de;
    text-shadow: 0 0 5px #ff99de, 0 0 10px #ff99de, 0 0 17px #ff00de, 0 0 38px #ff00de;
  }
  12% {
    color: #6d5669;
    text-shadow: 0 0 3px #635861, 0 0 5px #635861;
  }
  22% {
    color: #6d5669;
    text-shadow: 0 0 3px #635861, 0 0 5px #635861;
  }
  23% {
    color: #ff55de;
    text-shadow: 0 0 5px #ff99de, 0 0 10px #ff99de, 0 0 15px #ff99de, 0 0 20px #ff00de, 0 0 30px #ff00de, 0 0 40px #ff00de;
  }
  26% {
    color: #6d5669;
    text-shadow: 0 0 3px #635861, 0 0 5px #635861;
  }
  28% {
    color: #ff55de;
    text-shadow: 0 0 5px #ff99de, 0 0 10px #ff99de, 0 0 17px #ff00de, 0 0 38px #ff00de;
  }
  34% {
    color: #ff55de;
    text-shadow: 0 0 5px #ff99de, 0 0 10px #ff99de, 0 0 15px #ff99de, 0 0 20px #ff00de, 0 0 30px #ff00de, 0 0 40px #ff00de;
  }
  36% {
    color: #6d5669;
    text-shadow: 0 0 3px #635861, 0 0 5px #635861;
  }
  42% {
    color: #ff55de;
    text-shadow: 0 0 5px #ff99de, 0 0 10px #ff99de, 0 0 17px #ff00de, 0 0 38px #ff00de;
  }
  55% {
    color: #ff55de;
    text-shadow: 0 0 5px #ff99de, 0 0 10px #ff99de, 0 0 15px #ff99de, 0 0 20px #ff00de, 0 0 30px #ff00de, 0 0 40px #ff00de;
  }
  60% {
    color: #6d5669;
    text-shadow: 0 0 3px #635861, 0 0 5px #635861;
  }
  63% {
    color: #ff55de;
    text-shadow: 0 0 5px #ff99de, 0 0 10px #ff99de, 0 0 15px #ff99de, 0 0 20px #ff00de, 0 0 30px #ff00de, 0 0 40px #ff00de;
  }
  67% {
    color: #6d5669;
    text-shadow: 0 0 3px #635861, 0 0 5px #635861;
  }
  72% {
    color: #6d5669;
    text-shadow: 0 0 3px #635861, 0 0 5px #635861;
  }
  73% {
    color: #ff55de;
    text-shadow: 0 0 5px #ff99de, 0 0 10px #ff99de, 0 0 17px #ff00de, 0 0 38px #ff00de;
  }
  76% {
    color: #6d5669;
    text-shadow: 0 0 3px #635861, 0 0 5px #635861;
  }
  78% {
    color: #ff55de;
    text-shadow: 0 0 5px #ff99de, 0 0 10px #ff99de, 0 0 17px #ff00de, 0 0 38px #ff00de;
  }
  84% {
    color: #ff55de;
    text-shadow: 0 0 5px #ff99de, 0 0 10px #ff99de, 0 0 15px #ff99de, 0 0 20px #ff00de, 0 0 30px #ff00de, 0 0 40px #ff00de;
  }
  86% {
    color: #6d5669;
    text-shadow: 0 0 3px #635861, 0 0 5px #635861;
  }
  96% {
    color: #ff55de;
    text-shadow: 0 0 5px #ff99de, 0 0 10px #ff99de, 0 0 17px #ff00de, 0 0 38px #ff00de;
  }
  100% {
    color: #ff55de;
    text-shadow: 0 0 5px #ff99de, 0 0 10px #ff99de, 0 0 15px #ff99de, 0 0 20px #ff00de, 0 0 30px #ff00de, 0 0 40px #ff00de;
  }
}

.history-holder h2 {
  animation: turnonlights infinite;
  animation-duration: 3.0s;
  animation-fill-mode: both;
  animation-timing-function: steps(2, start);
  font-size: 3.5rem;
  font-weight: 400;
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .history-holder h2 {
    font-size: 2rem;
    width: 90%;
    margin: 0;
    text-align: center;
  }
}

.animate {
  -webkit-animation-name: turnonlights;
  -webkit-animation-duration: 3.0s;
  -webkit-animation-fill-mode: both;
  -webkit-animation-timing-function: steps(2, start);
  -moz-animation-name: turnonlights;
  -moz-animation-duration: 3.0s;
  -moz-animation-fill-mode: both;
  -moz-animation-timing-function: steps(2, start);
}

.grey .mdl-grid img {
  width: 50px;
  vertical-align: middle;
  display: inline-block;
}

.header-first .mdl-cell {
  align-items: center;
  justify-content: center;
  display: flex;
}

.header-first .header-holder {
  position: absolute;
  right: 0;
  top: 0;
  line-height: 52px;
}

.absolute-right {
  position: absolute;
  right: 0;
}

.header-nav .center .mdl-cell {
  align-items: center;
  justify-content: center;
  display: flex;
}

.search-button i {
  line-height: 84px;
  margin-top: 0;
}

.product-view .price-info .special-price {
  color: #fff;
}

.customer-account-login .registered-users h2, .page-title h1 {
  color: #000;
  font-weight: 500 !important;
  font-size: 1.5rem;
}

.customer-account-login .registered-users h2 {
  font-size: 1.5rem;
}

.cart-empty p {
  text-decoration: underline;
}

.account-create .buttons-set, .account-create .form-list {
  width: 50%;
  margin: auto;
}

.account-create button {
  width: 100%;
}

.account-create .mdl-checkbox__label {
  font-size: 1rem;
  font-weight: 200;
}

@media only screen and (max-width: 767px) {
  .account-create .buttons-set, .account-create .form-list {
    width: 100%;
    margin: auto;
  }
}

.customer-account-forgotpassword form {
  width: 35%;
  margin: auto;
}

@media only screen and (max-width: 767px) {
  .customer-account-forgotpassword form {
    width: 100%;
  }
}

.customer-account-login .mdl-js-textfield {
  width: 50%;
}

.account-login .new-users:after {
  content: '';
  position: absolute;
  display: block;
  top: 10%;
  left: 100%;
  bottom: 10%;
  width: 1px;
  background: #d0d0d0;
  z-index: 999999;
}

.customer-account-login .registered-users a.mdl-button {
  font-size: 0.8rem;
}

.box-up-sell ul li.up-sell-item {
  padding: 0;
  box-shadow: 5px 4px 8px 0px black;
  margin: .5rem;
}

.box-up-sell ul li.up-sell-item .product-name {
  font-size: 0.9rem !important;
}

.products-grid .slick-track .item:hover {
  box-shadow: 5px 4px 8px 0px black;
}

.products-grid .slick-track .item:hover img {
  opacity: .3;
}

@media only screen and (max-width: 767px) {
  .box-up-sell ul li.up-sell-item {
    box-shadow: none;
  }
  .products-grid .slick-track .item:hover {
    box-shadow: none;
  }
}

.checkout-cart-index main .cart-total-price-wrapper #show-discount-form {
  text-decoration: underline;
}

.impulseproducts h2 {
  font-size: 1rem;
  font-weight: 500;
  text-decoration: underline;
}

#polarcore .block-layered-nav .currently {
  order: 0;
}

#narrow-by-list {
  display: block !important;
}

#algolia-searchbox .algolia-search-input {
  width: 100% !important;
  float: none !important;
}

#algolia-searchbox .magnifying-glass {
  top: 7px !important;
}

#algolia-searchbox .algolia-search-input:focus {
  border: solid 1px #eaff00 !important;
}

#algolia-searchbox .clear-query-autocomplete {
  top: 9px !important;
}

@media only screen and (max-width: 767px) {
  .box-up-sell {
    padding-bottom: 0;
  }
  .footer-container {
    padding-top: 0 !important;
  }
}

.kco_hide_payment {
  display: none !important;
}

.newsletter img {
  width: 22px;
}
