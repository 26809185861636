/* PRODUCT-LIST - _product_list.scss */

.products-list {
  display: flex;
  align-items: stretch;
  flex-flow: column;
  padding: 0;
  position: relative;
  clear: both;
  .link-compare .material-icons, .link-wishlist .material-icons {
    top: 0.25em;
    position: relative;
  }
  .add-to-links {
    @include add-to-links();
  }
  button.btn-cart {
    @include btn-cart($add-to-cart-bg, $add-to-cart-text-color);
    display: inline;
    @media #{$medium-up} {
      max-width: 50%;
    }
    span.inner {
      position: relative;
      top: -0.2em;
    }
  }
  .configurable-swatch-list {
    list-style: none;
    padding: 0;
    clear: both;
    li {
      float: left;
      display: inline-block;
      padding-left: 0.2rem;
      .swatch-label {
        img {
          border: 1px $configurable-swatch-list-border-color solid;
        }
      }
    }
  }
  .item {
    list-style: none;
    flex-grow: 1;
    padding: 1em;
    background: transparent;
    display: flex;
    border-bottom: $products-list-border-bottom-color solid 1px;
    @media #{$small-only} {
        padding-left: 0;
    }
    .image-wrapper {
      position: relative;
      width: 25%;
      order: 1;
      @media #{$medium-up} {
        order: 0;
      }
      .product-image img {
      }
    }
    .product-name {
      text-align: left;
      text-decoration: none;
      font-size: 1.6rem;
      line-height: 2.2rem;
      margin: 0;
      //text-transform: uppercase;
      padding-bottom: 0.5rem;
      @media #{$medium-up} {
        font-size: 2rem;
        line-height: 2.8rem;
      }
      a {
        margin: 0;
        color: $product-name-link-color;
        font-weight: $product-name-font-weight;
        text-decoration: none;
        &:hover {
          color: lighten($product-name-link-color, 25%);
        }
      }
    }
    .ratings {
      clear:both;
      .rating-links a:first-child:after {
        content: '\a'; // Adds linebreak
        white-space: pre;
      }
      .rating-box {
        display: inline-block;
      }
      .amount {
        position: absolute;
      }
      i {
        color: $review-color;
      }
    }
    .price-box {
      width: 100%;
      margin-top: 1rem;
      min-height: 3rem;
      .price {
        font-size: 1.6rem;
        font-style: lighter;
      }
      .regular-price {
        color: $price-color;
      }
      .old-price {
        text-decoration: line-through;
        margin: 0;
        color: $old-price-color;
        .price-label {
          display: none;
        }
        .price {
          font-size: 1.2rem;
        }
      }
      .special-price {
        font-weight: bold;
        margin: 0;
        margin-top: 0.8em;
        color: $special-price-color;
        .price-label {
          display: none;
        }
        .price {
          font-size: 1.6rem;
        }
      }
      .price-to, .price-from {
        width: 50%;
        font-size: 1.2rem;
        float: left;
        .price {
          font-size: 1.2rem;
          font-weight: bold;
        }
      }
    }
    .product-shop {
      width: 75%;
      padding: 0 1em;
    }
    p.action {
      margin-bottom: 0;
      .button.view-details {
        display: none;
      }
    }
  }
}
