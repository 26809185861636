// Media query rarnges
$small-range: (0px, 767px) !default;
$medium-range: (768px, 1024px) !default;
$large-range: (1025px, 1440px) !default;
$xlarge-range: (1441px, 1920px) !default;
$xxlarge-range: (1921px, 99999999px) !default;
$grid-tablet-breakpoint: lower-bound($medium-range) !default;
$grid-desktop-breakpoint: lower-bound($large-range) !default;

$screen: "only screen" !default;
$landscape: "#{$screen} and (orientation: landscape)" !default;
$portrait: "#{$screen} and (orientation: portrait)" !default;
$small-up: "#{$screen} and (min-width: #{lower-bound($small-range)})" !default;
$small-only: "#{$screen} and (max-width: #{upper-bound($small-range)})" !default;
$medium-up: "#{$screen} and (min-width:#{lower-bound($medium-range)})" !default;
$medium-only: "#{$screen} and (min-width:#{lower-bound($medium-range)}) and (max-width:#{upper-bound($medium-range)})" !default;
$large-up: "#{$screen} and (min-width:#{lower-bound($large-range)})" !default;
$large-only: "#{$screen} and (min-width:#{lower-bound($large-range)}) and (max-width:#{upper-bound($large-range)})" !default;
$xlarge-up: "#{$screen} and (min-width:#{lower-bound($xlarge-range)})" !default;
$xlarge-only: "#{$screen} and (min-width:#{lower-bound($xlarge-range)}) and (max-width:#{upper-bound($xlarge-range)})" !default;
$xxlarge-up: "#{$screen} and (min-width:#{lower-bound($xxlarge-range)})" !default;
$xxlarge-only: "#{$screen} and (min-width:#{lower-bound($xxlarge-range)}) and (max-width:#{upper-bound($xxlarge-range)})" !default;

// Max width of content
$content-width: 100% !default;

// Grid
$grid-phone-columns: 12 !default;
$grid-phone-gutter: 0px !default;
$grid-phone-margin: -10px !default;
$grid-tablet-columns: 12 !default;
$grid-tablet-gutter: 0px !default;
$grid-tablet-margin: -10px !default;
$grid-desktop-columns: 12 !default;
$grid-desktop-gutter: 0px !default;
$grid-desktop-margin: -10px !default;

// Fonts
$preferred_font: 'Roboto', 'Helvetica', 'Arial', sans-serif !default;
$performance_font: $preferred_font !default;
$heading_font: $preferred_font !default;

// Colors

// -- Basic
$color-blue: $mdl-blue-500 !default;
$color-grey-lighter: $mdl-grey-50 !default;
$color-grey-light: $mdl-grey-200 !default;
$color-grey-medium: $mdl-grey-400 !default;
$color-grey-dark: $mdl-grey-500 !default;
$color-grey-darker: $mdl-grey-800 !default;

$black: #000 !default;

// -- MDL & Polarcore Primary Colors
$color-polar: #222 !default;
$color-primary: to-mdl($color-polar) !default;

$cart-icon-color: #000 !default;
$cart-count-color: #000 !default;
$cart-details-color: $color-grey-darker !default;

$nav-background: rgba(255,255,255,.8) !default;
$nav-background-link-color: #363636 !default;
$add-to-cart-bg: #222 !default;
$add-to-cart-text-color: white !default;
$select-regular-color: $color-polar !default;
$select-hover-color: darken($color-polar, 25%) !default;

// -- General Colors
$body-bg-color: #FFF !default;
$button-bg-color: #000 !default;
$button-text-color: white !default;
$table-border: #f0f0f0 !default;

$title-bg-color: #ebebeb !default;

// -- Main Container
$main-container-bg-color: white !default;
$h2-with-line-color: $mdl-grey-300 !default;
$category-button-bg: $button-bg-color !default;
$category-button-bg-hover: $select-hover-color !default;
$category-button-color: white !default;

// -- Footer
$footer-container-bg-color: #2c313e !default;
$footer-copyright-bg-color: #222222 !default;
$footer-copyright-text-color: white !default;

// -- Mobile Nav
$mobile-nav-top-links-bg: #2c313e !default;

// -- Sidebar
$sidebar-bg-color: #F5F5F5 !default;

// -- Product List
$product-list-hover-color: #f0f0f0 !default;

// -- Products View
$article-sku-color: #BBB !default;
$product-name-font-weight: 300 !default;
$product-name-link-color: $color-grey-darker !default;
$products-list-border-bottom-color: $color-grey-light !default;
$configurable-swatch-list-border-color: #EEE !default;

// -- Polar Language List
$polarcore-language-bg-color: white !default;

// -- Messages Colors
$error-msg-text-color: white !default;
$error-msg-bg-color: $mdl-red-400 !default;

$success-msg-text-color: white !default;
$success-msg-bg-color: $mdl-green-400 !default;

$notice-msg-text-color: $mdl-grey-800 !default;
$notice-msg-bg-color: $mdl-yellow-600 !default;

$messages-bg-color: #FAFAFA !default;
$messages-text-color: $mdl-grey-800 !default;
$messages-border-color: #F0F0F0 !default;

// -- Category Settings
$display-asc-desc: none !default; // inline or none
$display-sort-by-label: inline !default; // inline or none
$display-view-mode: inline !default; // inline or none !default;

// -- Category/Products Colors
$special-price-color: #8e3030 !default;
$old-price-color: #333 !default;
$price-color: $mdl-grey-600 !default;

// -- General Elements
$text-link-color: $color-polar !default;
$search-border-color: $color-grey-light !default;
$review-color: #fed227 !default;
$review-list-bg-color: #FAFAFA !default;

// -- Top Line
$top-line-background: $color-grey-lighter !default;
$top-line-link-color: $color-grey-dark !default;
$top-line-border: $color-grey-light !default;

$basic-link-color: #222 !default;
$basic-link-hover-percentage: 25% !default;

// -- CART
$btn-checkout-unicode-arrow: '\E315' !default;
$cart-bg-color: #FAFAFA !default;

// -- NAVIGATION
$nav-child-arrow: ' + ' !default;
$nav-child-arrow-open: ' - ' !default;

// -- TYPOGRAPHY
$category-sidebar-title-text-transform: inherit !default;
$toolbar-text-transform: inherit !default;

$base-size: 1rem !default;
$ratio: 1.2 !default;

$heading-h5: $base-size * $ratio !default;
$heading-h4: $heading-h5 * $ratio !default;
$heading-h3: $heading-h4 * $ratio !default;
$heading-h2: $heading-h3 * $ratio !default;
$heading-h1: $heading-h2 * $ratio !default;


// -- Modules
$import-ajaxcart: true !default;
$import-algolia: false !default;
$import-bubblelayer: true !default;
$import-sidenav: true !default;
$import-tco: true !default;
$import-kco: true !default;
$import-cac: false !default;
$import-labels: true !default;
$import-quickshop: true !default;
$import-favoriteproducts: false !default;
