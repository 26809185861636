/* TYPOGRAPHY - _typography.scss */

h1 {
  font-family: $heading_font;
  font-size: $heading-h1;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  font-weight: 400;
}

h2 {
  font-family: $heading_font;
  font-size: $heading-h2;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  font-weight: 400;
}

h3 {
  font-family: $heading_font;
  font-size: $heading-h3;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  font-weight: 400;
}

h4 {
  font-family: $heading_font;
  font-size: $heading-h4;
  font-weight: 400;
}

button {
  font-family: $preferred_font;
}
p {
  line-height: 1.5rem;
  font-weight: 400;
}

a{
  font-weight: 400;
}

b, strong{
  font-weight: 400;
}
