/* MODULES / LABELS - modules/labels/_labels.scss */

#polarcore {
 .product-img-box, .image-wrapper {
    position: relative;
    .trollweb-labels {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
      display: flex;
      flex-direction: column;
      .trollweb-label {
         color: #fff;
         margin: 0.25rem;

        .discount, .new {
          display: block;
          padding: 0.375rem 1.425rem;
          background-color: $mdl-red-400;
          text-align: center;
          font-size: 1.275rem;
        }
      }
    }
  }
}

