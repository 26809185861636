/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

@import "../variables";

.mdl-badge {
  position : relative;
  white-space: nowrap;
  margin-right: ($badge-size + $badge-padding);

  &:not([data-badge]) {
    margin-right: auto;
  }

  &[data-badge]:after {
    content: attr(data-badge);

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    align-items: center;

    position: absolute;
    top: -($badge-size / 2);
    right: -($badge-size + $badge-padding);

    .mdl-button & {
      top: -10px;
      right: -5px;
    }

    font-family: $preferred_font;
    font-weight: 600;
    font-size: $badge-font-size;
    width: $badge-size;
    height: $badge-size;
    border-radius : 50%;

    background: $badge-background;
    color: $badge-color;
  }

  &.mdl-badge--no-background {
    &[data-badge]:after {
      color: $badge-color-inverse;
      background: $badge-background-inverse;

      box-shadow: 0 0 1px gray;
    }
  }
}
