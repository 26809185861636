/* HEADER NAV - _header-nav.scss */

.logo{
  svg{
    height:56px;
    width: auto;
  }
}
@media #{$small-only} {
  .logo{
    svg{
      height:40px;
      width: 100%;
    }
  }
}


@media #{$medium-up} {
  .logo{
      text-align: center;
  }
}
.right{
  justify-content: flex-end;
}

.header-nav {
  display: none;
  padding:0 20px;
  background: $nav-background;
  text-transform: uppercase;
  @media #{$medium-up}{
    display: block;
  }
  #nav .nav-primary {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    @include clearfix();
    .active {
      background: darken($nav-background-link-color, 15%);
    }
    .level1 a {
        color:#363636;
        height:0;
      }
    .level1 .has-children {
      &:before {
        content: $nav-child-arrow;
        float: right;
      }
    }
    li {
      margin: 0;
      padding: 0;
      list-style: none;
      position: relative;
      white-space: nowrap;
       &.active a{
          color:#fff!important;
       }
      a {
        padding: 0.75rem 1rem;
        text-align: center;
        color: $nav-background-link-color;
        font-weight: 500;
        text-decoration: none;
        font-size: 1em;
        display: inline-block;
        width: 100%;
        text-align: left;
        height: 45px;
        &:hover{
          font-weight: 500;
        }
        .menu-active{
          a{
            color:#fff;
          }
        }
      }
      @media #{$medium-only}{
        a {
          padding: 0.75rem;
          font-size: 1rem;
        }
      }
      &.menu-active,
      &:hover {
        font-weight: 300;
        @include material-animation-default();
      }
      &:focus {
        background: darken($nav-background-link-color, 25%);
      }
    }
    & > li ul {
      padding: 0;
      margin: 0;
      display: none;
      position: absolute;
      top: 100%;
      background: $nav-background-link-color;
      border-top: solid 2px darken($nav-background-link-color, 5%);
      border-bottom: solid 2px darken($nav-background-link-color, 25%);
      li:hover {

      }
    }
    li > ul {
      display: none;
      z-index: 3000;
      ul {
        position: absolute;
        flex: 0 1 auto;
        top: 0;
        left: 100%;
      }
    }
    li.menu-active > ul,
    li:hover > ul {
      display: block;
    }
  }
}

.search-button{
  i{
    margin-top:1rem;
    margin-right: 0.5rem
  }
}
@media #{$small-only} {
 .search-button{
  i{
    margin-top:0;
    margin-right: 0.5rem
  }
}
}

.nav-home-icon {
  vertical-align: middle;
}
