/* VISIBILITY - _visibility.scss */

.no-display,
.hidden {
  display: none !important;
}

$breakpoints:
  small,
  medium,
  large,
  xlarge,
  xxlarge;

$breakpoint-queries:
  unquote($small-up),
  unquote($medium-up),
  unquote($large-up),
  unquote($xlarge-up),
  unquote($xxlarge-up);

@mixin visibility-generator(){
  @each $current-breakpoint in $breakpoints {
    $inherit-list: ();
    $hide-list: ();
    @each $breakpoint in $breakpoints {
      @if index($breakpoints, $breakpoint) < index($breakpoints, $current-breakpoint) {

        $inherit-list: append($inherit-list, unquote(
          '.hide-for-#{$breakpoint}-only, .show-for-#{$breakpoint}-up'
        ),comma);
      
        $hide-list: append($hide-list, unquote(
          '.show-for-#{$breakpoint}-only, .hide-for-#{$breakpoint}-up'
        ), comma);

      } @else if index($breakpoints, $breakpoint) > index($breakpoints, $current-breakpoint) {

        $inherit-list: append($inherit-list, unquote(
          '.hide-for-#{$breakpoint}-only, .hide-for-#{$breakpoint}-up'
        ), comma);
      
        $hide-list: append($hide-list, unquote(
          '.show-for-#{$breakpoint}-only, .show-for-#{$breakpoint}-up'
        ), comma);

      } @else {
        
        $inherit-list: append($inherit-list, unquote(
          '.show-for-#{$breakpoint}-only, .show-for-#{$breakpoint}-up'
        ), comma);
      
        $hide-list: append($hide-list, unquote(
          '.hide-for-#{$breakpoint}-only, .hide-for-#{$breakpoint}-up'
        ), comma);
        
      }
    }
    
    @media #{nth($breakpoint-queries, index($breakpoints, $current-breakpoint))} {
      #{$inherit-list} {
        display: inherit !important;
      }
      #{$hide-list} {
        display: none !important;
      }
    }
  }
}

@include visibility-generator();
