/* MODULES / BUBBLE LAYER - modules/bubblelayer/_bubblelayer.scss */
/* PolarCore Bubblelayer Module Style */

// Safari Ajax render fix and looks nice
@keyframes smoothOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.mdl-js-ensure-loaded {
  transition: opacity 175ms ease-in;
  opacity: 0;
  @media #{$small-only} {
    opacity: 1;
  }
  &.mdl-loaded {
    @media #{$small-only} {
        opacity: 1;
    }
    @media #{$medium-up} {
        -webkit-animation-iteration-count: 1;
        animation: smoothOpacity 0.25s;
        animation-fill-mode: forwards;
    }
  }
}

#polarcore {
  @media #{$small-only} {
    &.popup-open {
      .block-layered-nav {
        z-index: 100;
        position: relative;
      }
    }
  }
  .block-layered-nav--no-filters {
    display: block;
  }
  .block-layered-nav {
    .currently {
      .block-subtitle {
        font-weight: 700;
      }
      ol {
        li {
          width: 100%;
        }
      }
    }
    @media #{$medium-up}{
      .currently {
        padding-right: 1rem;
        margin-bottom: 0;
      }
    }
    #narrow-by-list dt {
      font-size: 1rem;
      text-transform: uppercase;
      margin-top: 0.5rem;
      margin-bottom: 0;
      background:white;



      @media #{$medium-up}{
        &.expanded {
          cursor: pointer;
          i {
            @include material-icons();
            display: inline-block;
            vertical-align: text-bottom;
            content: "\E315";
            margin-bottom: -0.15rem;
            margin-left: 0.25rem;
            line-height: 20px;
            font-size: 1.5rem;
            transition: transform 0.25s ease;
            float:right;
          }
        }
        &.collapsed {
          cursor: pointer;
          i {
            @include material-icons();
            display: inline-block;
            vertical-align: text-bottom;
            content: "\E315";
            margin-bottom: -0.15rem;
            margin-left: 0.25rem;
            line-height: 20px;
            font-size: 1.5rem;
            transition: transform 0.25s ease;
            transform: rotate(90deg);
            float:right;
          }
        }
      }
    }
    #narrow-by-list dd {
      ol {
        border-bottom: 0;
        margin: 0;
          li {
            font-size: 0.9rem;
            margin: 0.5rem 0;
            color: #ccc;
            .mdl-checkbox {
                width: auto;
                .mdl-checkbox__label {
                    font-size: 1rem;
                }
            }
            .count {
                color: #bbb;
                font-size: 0.8rem;
                float:right;
            }
            input {
              cursor: pointer;
            }
         }
      }
      .label-filter a:hover, .label-filter a:focus {
        background-color: $color-polar;
        color: white;
      }
      &.actions {
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 100;
        width: 350px;
        display: none!important;
        .mobil-apply-btn {
          display: block;
          width: 100%;
          color: white;
          font-size: 1.15rm;
          text-align: center;
          text-transform: uppercase;
          letter-spacing: 1px;
          padding: 1rem;
          border-top: 4px solid white;
          background-color: $button-bg-color;
          transform: translateZ(0);
        }
      }

      .layer-slider {
        .price-slider {
          @media #{$small-only}{
            display: none;
          }
          .handle {
            width: 16px;
            height: 16px;
            top: 3px;
            background-color: $color-polar;
          }
          .span {
            background-color: $color-polar;
          }
        }
        .price-range {
          text-align: center;
          color: $color-polar;
          @include clearfix();
          .price {
            float: left;
            @media #{$small-only}{
              margin-left: 0.25rem;
            }
          }
          .price:last-of-type {
            float: right;
            @media #{$small-only}{
              margin-right: 0.25rem;
            }
          }
          div {
            display: table;
            width: 100%;
            input{
              float: left;
              margin: 0;
            }
            input:last-of-type {
              float: right;
            }
          }
        }
        .price-limit {
          display: none;
        }
      }
      @media #{$small-only}{
        ol, ul, div {
          margin-right: 0.75rem;
        }
      }
      ol {
        border-bottom: 0;
          li {
            font-size: 0.9rem;
            margin: 0.5rem 0;
            color: #222;
            .mdl-checkbox {
              width: auto;
              .mdl-checkbox__label {
                 font-size: 1rem;
              }
            }
            .count {
               color: #bbb;
               font-size: 0.8rem;
            }
            input {
              cursor: pointer;
            }
         }
      }
      li.is-checked a,
      li.active a {
        outline: 0;
        text-decoration: none;
        color: #000;
        border-radius: 3px;
        .count {
          background-color: white;
        }
      }
    }
    @media #{$small-only} {
      #narrow-by-list {
        @include clearfix();
        /*
        .even {
          width: 50%;
        }
        .odd {
          width: 50%;
          float: right;
          clear: both;
          &.last {
            float: left;
          }
        }*/
        //New Mobile Layout
        dt {
          margin: 0;
          padding: 1rem 1rem;
          i {
            float: right;
          }
          &.open i {
            transform: rotate(90deg);
          }
        }
        dd {
          /*display: none;
          padding: 0.75rem 1rem;
          background-color: #fafafa;*/
          .no-display {
            display: list-item !important;
          }
          .show-hidden {
            display: none !important;
          }
        }
        h2.headline {
          position: absolute;
          top: 1rem;
          width: 85%;
          margin: 0;
          padding: 0.25rem 0.75rem;
          color: #555;
          text-transform: uppercase;
          font-size: 1.3rem;
          font-weight: bold;
          line-height: 1.75rem;
          left: 4px;
          display: none!important;
        }
        @media #{$small-only}{
          /*padding: 4rem 0.75rem;*/
          overflow-y: auto;
          margin-top: 0;
          margin-bottom: 3rem;
        }
      }
    }
    #narrow-by-list dd li:hover {
      //background-color: #f0f0f0;
    }
    .block-content {
      display: flex;
      flex-direction: column;
      background: white;
      padding: 20px;
    }
    .block-title,
    .block-subtitle,
    .actions {
      display: none;
    }
    .currently {
      order: 100;
      margin-bottom: 36px;
      @media #{$medium-up}{
        order: 0;
      }
      .block-subtitle {
        margin: 0.75rem 0 0 0;
      }
      ol li {
        position: relative;
        display: inline-block;
        margin: 2px 0;
        padding: 4px 24px 4px 12px;
        border: 1px solid #444;
        border-radius: 6px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        @media #{$medium-up}{
          display: block;
          margin: 6px 0;
        }
      }
      .btn-remove {
        position: absolute;
        right: 0.5rem;
        font-size: 11px;
        span {
          display: none;
        }
        &:after {
          @include material-icons();
          content: "\E14C";
          position: relative;
          top: 0.2rem;
          margin-left: 0.5rem;
          font-size: 1.25rem;
        }
      }
    }
    /* Mobile Popup Styling*/
    @media #{$small-only}{
    /*  .block-subtitle {
        display: block;
      }
      .block-subtitle--filter.active {
        position: fixed;
        top: 0.5rem;
        right: 0.5rem;
        z-index: 105;
        width: 3rem;
        height: 3rem;
        text-indent: -999px;
        overflow: hidden;
        &:before {
          content: "\E5CD";
          @include material-icons();
          text-indent: 0;
          position: absolute;
          top: 10px;
          right: 2px;
          font-size: 1.5rem;
        }
      }*/
      .block-subtitle--filter:not(.active) + #narrow-by-list {
        display: none;
      }
      /*#narrow-by-list {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 4rem 0rem 3rem;
        background-color: #fff;
      }*/
    }
  }
  .bubble-layer-top {
  /*display: none;*/
    @media #{$medium-up}{
      display: block;
    }
    .block-layered-nav {
      .block-content {
        @media #{$medium-up}{
          max-width: none;
        }
      }
    }
  }
}

.block-layered-nav{
  .mdl-checkbox__box-outline {
    position: absolute;
    top: 6px;
    left: 0;
    display: inline-block;
    box-sizing: border-box;
    width: 12px;
    height: 12px;
    margin: 0;
    cursor: pointer;
    overflow: hidden;
    border: 1px solid rgba(0,0,0, 0.54);
    border-radius: 2px;
    z-index: 2;
  }
}
