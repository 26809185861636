/* MOBILE NAV - _mobile-nav.scss */

.mdl-layout__container {
  position: relative;
  width: auto;
  height: auto;
  .mdl-layout {
    overflow-y: visible;
    overflow-x: visible;
  }
}

.mdl-layout__drawer-button {
  display: block;
  width: auto;
  max-height: 3rem;
  line-height: auto;
  color: black;
  z-index: 110;
  .menu-label {
      margin-top: -2.8rem;
      font-size: 0.54rem;
      position: relative;
      display: block;
      @media #{$medium-up} {
          display: none;
      }
  }
  @media #{$medium-up}{
    display: none;
  }
  i.material-icons {
    padding: 0.5rem;
    font-size: 2rem;
  }
}
body.popup-open {
  overflow: hidden;
  position: fixed;
  .mdl-layout__drawer-button {
    z-index: initial;
  }
}

.mdl-layout__drawer {
  position: fixed;
  overflow-y: auto;
  border-right: none;
  z-index: 160;
  background: #fff;
  .mdl-layout__drawer__home {
    position: absolute;
    top: 0.35rem;
    left: 0.5rem;
    font-size: 2rem;
    padding: 0.25rem;
    color: white;
  }
  .mdl-layout__drawer__title {
    margin: 0;
    padding: 1rem 0;
    font-size: 1.25rem;
    font-weight: 300;
    letter-spacing: 0.5px;
    text-align: center;
    text-transform: uppercase;
    background-color: $color-polar;
    color: white;
  }
  .mdl-layout__drawer__close {
    position: absolute;
    top: 0.35rem;
    right: 0.5rem;
    font-size: 2rem;
    padding: 0.25rem;
    color: white;
  }
  #nav-mobile {
    padding: 0 !important;
    background: #FFF;
    .has-children {
      &:after {
        content: $nav-child-arrow;
        float: right;
      }
    }
    .open > .has-children {
      &:after {
        content: $nav-child-arrow-open;
        float: right;
      }
    }
  }
  > ul, .nav-primary {
    li {
      ul {
        display: none;
        background-color: #f4f4f4;
        /* Level 2 Indentation*/
        > li {
          > a {
            padding-left: 1.5rem;
          }
          > ul {
            > li > a {
              padding-left: 3rem;
            }
          }
        }
      }
      a {
        display: block;
        padding: 1rem;
        font-size: 1.1rem;
        border-bottom: 1px solid rgba(0,0,0, 0.05);
      }
      &.open, &.open > a {
        border-bottom: 1px solid $color-polar;
      }
      &.nav-home {
        display: none;
      }
    }
  }
}

ul.top-links__mobile {
    background: $mobile-nav-top-links-bg;
    &:first-of-type {
        border-top: 3px solid $color-polar;
    }
    &:last-of-type {
    }
    li {
        border-bottom: 1px solid rgba(0,0,0, 0.2);
    }
}
