/* CUSTOM - Sample custom scss file */
/* To import, rename or copy to <anything>.scss and keep it in the scss/components/custom/after folder */
/* (all scss files in the custom folder will be imported after all other scss files) */

/*.header-nav #nav .nav-primary .level1 a:after, a:after {
    display:block;
    content:attr(title);
    font-weight:bold;
    height:1px;
    color:transparent;
    overflow:hidden;
    visibility:hidden;
    margin-bottom:-1px;
}*/


h1, h2{
  text-transform:uppercase;
}


.gallery-image{
  width:100%;
}

.main{
  //padding: 160px 15px 5em 15px;
  padding: 160px 15px 1em 15px;
}
/*.checkout-onepage-index{
  .main{
    padding-top: 160px;
  }
}*/

.cms-home .main{
  padding:0;
}

.instagram-grid{
  background:#fff;
  margin-top:5em;
  .h4-wrapper{
    text-align:center;
    h4{
      display: inline-block;
      text-align: center;
      color: #000;
      padding: 0 5px;
      letter-spacing: 1px;

    text-shadow: 1px 1px 4px #000;
    font-size: 2rem;
    color: white;

      font-size: 1.7rem;
      font-weight: 400;
    }
  }
  #instafeed{
    width: 75%;
    /*box-shadow: 0px 0px 10px 0px black;*/
  }
}

@media #{$small-only} {
  .main{
    padding-top:0
  }
}

.catalog-product-view .main{
  padding-left:0;
}

#instafeed > * {
    padding: 5px;
    box-shadow: 0px 0px 10px 0px black;
    margin: 2px;
    width: calc(16.66667% - 4px);
    @media #{$small-only} {
      width: calc(33.33333% - 4px);
    }
}

#polarcore {
  .header-nav #nav .nav-primary {
    li {
      position: static;
    }
  }
  /* Meganav */
  .header-nav #nav .nav-primary > li > div {
    padding: 0;
    margin: 0;
    display: none;
    position: absolute;
    top: 100%;
  }
  .header-nav #nav .nav-primary > li .meganav {
    z-index: 1000;
    width: auto;
    background-color: rgba(234, 255, 0, 0.5);
    .view-all {
      font-size: 1.1rem;
      padding: 0.25rem 0.5rem;
      border-bottom: 1px solid rgba(255,255,255,0.4);
      &:hover {
        font-weight: 400;
      }
    }
    ul {
      position: static;
      padding: 0.5rem 0;
      border-top: none;
      border-bottom: none;
      background-color: transparent;
      li {
        background-color: transparent;
        &:hover {
          font-weight: 400;
          a {
            background-color: transparent;
          }
        }
        a {
          text-transform: initial;
          padding: 0.25rem 0.5rem;
          background-color: transparent;
        }
      }
      &.col-2 {
        column-count: 2;
      }
      &.col-3 {
        column-count: 3;
      }
    }
    .mdl-cell {
      padding: 1rem;
      width:100%;
    }
  }
  .header-nav #nav .nav-primary > li.menu-active > div.meganav {
    display: flex;
    & > div > ul {
      display: block;
    }
    .banner {
      margin: 0;
      padding: 0;
      display: flex;
      a {
        position: relative;
        padding: 2px;
        margin: auto 0;
      }
      img {
        width: 100%;
      }
      &.mdl-cell--9-col {
        width: 75%;
      }
      &.mdl-cell--8-col {
        width: 66.666%;
      }
      &.mdl-cell--6-col {
        width: 50%;
      }
      .mdl-cell--4-col {
        width: 33.333%;
      }
      .mdl-cell--3-col {
        width: 25%;
      }
    }
  }
}

/*.product-view{
 	.product-name{
 		.h1{
 			color:#fff;
 			font-size: 1.6em;
 		}
 	}
 	.add-to-cart button.btn-cart{
 		i{
 			display:none;
 		}
 	}
 	.product-view{
 		 .price-info{
 		 	.price{
 		 		color:#222;
 		 	}
 		 }
 	}
	.product-shop{
		position:absolute;
		right:3%;
		top:3%;
		width: 20%;
		> .inner{
			padding:1rem;
			background-color:$nav-background;
		}
		.sku-wrapper{
			display:none;
		}
	}
}
*/
.footer{
  padding-top: 3rem;
	/*.newsletter{
		background:#fff;
		padding:0;
	}
	.mdl-textfield__label{
		color:#fafafa;
	}
	.mdl-textfield--floating-label.is-focused .mdl-textfield__label, .mdl-textfield--floating-label.is-dirty .mdl-textfield__label{
		color:#fafafa;
	}
	.mdl-textfield--floating-label.is-focused .mdl-textfield__label:after{
		background:#222;
	}
	.mdl-textfield__input{
		background:#222;
		border-color:#fafafa;
	}
  .button{
    border-color:#fafafa;
    color:#fafafa;
  }*/
	#newsletter{
			color:#fafafa!important;
			/* IMPORTANT RULES - DirtyBoy */
		}
}


.copyright{
	svg{
	    width: 70px;
	    height: auto;
	    fill: white;
	    padding-bottom: 5px;
	}
}

.mdl-grid{
  .mdl-cell{

  }
}

/*! Flickity v1.2.1
http://flickity.metafizzy.co
---------------------------------------------- */

.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus { outline: none; }

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* draggable */

.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
          tap-highlight-color: transparent;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

/* ---- previous/next buttons ---- */

.flickity-prev-next-button {
  position: absolute;
  top: 50%;
  width: 44px;
  height: 44px;
  z-index:9;
  border: none;
  border-radius: 50%;
  background: rgba(255,255,255,0.3);
  cursor: pointer;
  /* vertically center */
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
}




.flickity-prev-next-button:hover { background: rgba(255,255,255,0.3); }

.flickity-prev-next-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #09F;
}

.flickity-prev-next-button:active {
  filter: alpha(opacity=60); /* IE8 */
  opacity: 0.6;
}

.flickity-prev-next-button.previous { left: 10px; }
.flickity-prev-next-button.next { right: 10px; }
/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px;
}
.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px;
}

.flickity-prev-next-button:disabled {
  filter: alpha(opacity=30); /* IE8 */
  opacity: 0.3;
  cursor: auto;
}

.flickity-prev-next-button svg {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%;
}

.flickity-prev-next-button .arrow {
  fill: rgba(255,255,255,.2);
}

/* color & size if no SVG - IE8 and Android 2.3 */
.flickity-prev-next-button.no-svg {
  color: #333;
  font-size: 26px;
}

/* ---- page dots ---- */

.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1;
}

.flickity-rtl .flickity-page-dots { direction: rtl; }

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  filter: alpha(opacity=25); /* IE8 */
  opacity: 0.25;
  cursor: pointer;
}

.flickity-page-dots .dot.is-selected {
  filter: alpha(opacity=100); /* IE8 */
  opacity: 1;
}

@media #{$small-only} {
  .flickity-prev-next-button:hover, .flickity-prev-next-button {
    background:none;
  }
  .flickity-prev-next-button.previous { left: 0; padding: 0; width: 22px; height: 22px; }
  .flickity-prev-next-button.next { right: 0; padding: 0; width: 22px; height: 22px;}
}

.mdl-textfield{
  width:100%;
}

.polarslide .slick-prev:before, .polarslide .slick-next:before{color:#fff!important;}
.•{
  margin-top: 0;
}

.newsletter a{
}
/*.newsletter a{
  display:inline-block;
  background:#222;
  border-radius:50%;
  width:30px;
  height:30px;
  text-align:center;
  svg{
    fill:#fff;
    width:25px;
    height:30px;
  }
  .instagram-svg{
    width:20px;
  }
}*/

.checkout-cart-index .impulseproducts{
  .item{
    width:25%;
  }
}
@media #{$small-only} {

  /*.cms-home{
    .products-grid{
      display: none;
    }
  }*/

  .impulseproducts{
    .page-title{
      margin-top:1em;
    }
    .item{
      width:100%!important;
    }
    .btn-cart{
      font-size: .8em!important;
    }
  }
}


.cart-content{
  position:fixed;
  right:20px;
  top:60px;
  z-index: 9;
  display:none;
  img{
      width: 80px;
      display: inline-block;
  }
  p{
    display:inline;
  }
}
.header-minicart:hover .cart-content{
  display:block;
}

#ajaxcart-error, #polarcore #ajaxcart, #polarcore #ajaxcart-loading{
  display:none!important;
}

.page-title{
  text-align:center;
}

.account-create{
  margin:auto;
}

.swatch-link{
    padding: 0;
    background: #F1F1F1;
}

.soldout{
  position: absolute;
  background: rgba(255, 255, 255, 0.4);
  color: white;
  transform: rotate(-45deg);
  text-transform: uppercase;
  text-align: center;
  top: 20px;
  left: -70px;
  width: 200px;
  z-index: 2;
  p{
    margin:0;
  }
}

.salebadge{
  position: absolute;
  background: rgba(255, 255, 255, 0.2);
  right: 1em;
  top: 1em;
  width: 5rem;
  color: #fafafa;
  z-index: 2;
  p{
    margin: 0;
    line-height: 2em;
    text-transform: uppercase;
  }
}

.blackfriday{
  position: absolute;
  background: rgba(0, 0, 0, 0.6);
  right: 0;
  top: 0;
  width: 10rem;
  color: #fafafa;
  z-index: 2;
  &:after{
    content: 'Black Friday';
    font-weight: 400;
    text-transform: uppercase;
    line-height: 2em;
  }
  p{
    display: none;
  }
}

.image-wrapper{
  overflow:hidden;
}


  .filter-title{
    display:none;
  }

  .filter-content{
    .category-filter-tree ul{
      display:inline;
    }
  }


  #polarcore .bubble-layer-top{
    display:none;
    padding:0;
  }
  .toolbar-bottom .toolbar-inline{
    display:blocK;
  }

  #polarcore .bubble-layer-top .block-layered-nav .block-content{
    display:inline-block;
    padding: 15px 0;
  }

@media #{$medium-up} {

}
.show-when-sticky{
  display:none;
}

.sticky--up .show-when-sticky{
  display:block;
}

  .short-description {
    ul{
        /*padding: 15px 0;*/
      li{
        font-weight: 400;
        &:before {
          content: '›';
          /* background: url(../images/finger.svg) no-repeat; */
          color: #000;
          background-size: contain;
          vertical-align: middle;
          margin-right: 5px;
          width: 20px;
          height: 20px;
          display: inline-block;
          line-height: 15px;
          text-align: center;
          font-weight: 700;
          font-size: 1.5em;
        }

      }
    }
  }

.product-options{
  .mdl-cell{
    margin-bottom:0;
  }
  .button{
    width:100%
  }
}

/*.product-view .add-to-cart button.btn-cart{
  margin-top:0;
  color: #dc37c0;
  background: white;
  border: 2px solid black;
  font-weight: 500;
}*/

.product-view .add-to-cart button.btn-cart{
  box-shadow:0 8px 0 #000, 0 15px 20px rgba(0, 0, 0, .35);
  transition: box-shadow .1s ease-in-out;
  padding:0;
  background:transparent!important;
  color: #000;
  font-weight: 500;
  margin-top: 0;
  .inner{
    border: 1px solid black;
    padding: 0.5em 1em;
    display: block;
    background:rgb(255, 214, 241);
    transition:  transform .1s ease-in-out;
  }
  &:active{
    box-shadow: 0 8px 0 #000, 0 12px 10px rgba(0, 0, 0, .3);
    span{
      transform: translate(0, 4px);

    }
  }
}


.product-view .select.input-box select{
  background:white;
  color:black;
  font-weight: 400;
}
.product-view .select.input-box:after{
  background:transparent;
  color:black;
}

.product-view .select.input-box select:hover{
  background:black;
  color:white;
}

.product-view .select.input-box:hover:after{
  background:black;
  color:white;
}

.cms-sneaker-history .page-title{
  display:none;
}
.history-holder{
  position: relative;
  margin-bottom: 16px;
  overflow: hidden;
  img{
    width:100%;
    }
  .scale-transition{
    transition:.3s;
    &:hover{
        transform: scale(1.02);
    }
  }
  h2{
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    text-transform:uppercase;
    color:#fff;
    &:before,&:after{
      //content:'';
      border-top: 1px solid white;
      border-bottom: 1px solid white;
      display:block;
    }
    a{
      color:#fff;
    }
  }
}



.block-related ol li.item .price-box .regular-price{
  text-align:center;
}

#header-cart{
    position: fixed;
    z-index: 99999;
    background: #FAFAFA;
    width: 25%;
    right: 0;
    top: 0;

    #cart-sidebar{
      padding-bottom: 10px;
      padding: 0 10px;
    }
    .product-name,.product-options{
      margin:0;
    }
}

.ajax-loading{
  text-align:center;
  display:none;
}

.loading{
  .ajax-loading{
    display: block;
  }
}

.header-minicart:hover #header-cart{
  display:block!important;
}

.sticky--up #header-cart{
}


.banner-area .banner-col-one{
  &:after{
    content:'';
  }
}

/* DIRTY */
.banner-area{
  .banner-col-three{
    width:100%;
    img{
      width:100%;
    }
    .banner-wrapper{
      padding: 5px;
      position: relative;
    }
  }
}

@media #{$small-only} {
  .banner-area{
    .banner-col-one{
      flex-direction: column;
    }
    .banner-col-one, .banner-col-two{
      flex-direction: column;
    }
    .banner-wrapper{
      width:100%!important;
    }
  }
}


.minicart-actions{
    height: 30px;
    width: 100%;
    text-align: center;
    padding: 10px;
    margin-bottom: 20px;
}

.empty-cart > *{
  display:none;
}


.hide-chart,.size-chart{
  opacity:0;
  visibility: hidden;
  height:0;
  transform: scaleY(0);
  transform-origin: top;
  transition: transform 0.3s ease;
  table{
    width:100%;
  }
}

.show-size{
  opacity:1;
  visibility:visible;
  height:auto;
  transform: scaleY(1);
  transition: transform 0.3s ease;
}

.mdl-dialog{
  width:40%;
  .mdl-dialog__actions{
    position: absolute;
    top: 0;
    right: 0;
  }
}

.mdl-dialog__content{
  min-width:280px;
}
.mdl-data-table{
  width:100%
}
.mdl-data-table tbody tr{
  transition:none;
}


.product-essential{
  .product-shop .product-shop{
    /*background:#ECECEC;*/
    padding: 0 0 15px 0;
    margin:0 15px;
  }
}

@media #{$small-only} {
  .product-essential{
    .product-shop .product-shop{
      display: flex;
      flex-direction:column;
    }
    .order-mobile-1{order:1;padding-top:1em;}
    .order-mobile-2{order:2;}
  }
  .product-view .short-description ul{margin-left:0;}
  .short-description{padding:0!important;}
  .product-view .price-info .price{padding-left:0!important;}
  .product-view .product-name .h1{padding-left:0!important;}

  .order-mobile-1-nopad{order:1;}
  .order-mobile-2-nopad{order:2;}
  .order-mobile-2{order:2;}

}

.minicart-actions{
  a{
    color: #000;
    line-height: 20px;
    padding: 5px;
    float:left;
    font-weight: 400;
    font-size: .9rem;
  }
  a:last-of-type{
    float:right;
  }
}

.mdl-dialog, dialog {
  position: fixed;
  top:50%;
  transform:translateY(-50%);
  z-index: 999;
  box-sizing:content-box; /* THA FIXES BE REAL! */
  left: 0; right: 0;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  margin: auto;
  border: solid;
  padding: 1em;
  background: white;
  color: black;
  display: none;
  box-shadow: 0 0 0 10000px rgba(3,169,244,.30);
}
@media #{$small-only} {
  .mdl-dialog, dialog {
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
  }
}

.mdl-dialog[open], dialog[open] {
  display: block;
}

dialog::backdrop, dialog + .backdrop {
  position: fixed;
  top: 0; right: 0; bottom: 0; left: 0;
  /*background: rgba(0,0,0,0.1);*/
  background: rgba(3,169,244,.30);
}

/* for small devices, modal dialogs go full-screen */
@media screen and (max-width: 540px) {
  dialog[_polyfill_modal] {  /* TODO: implement */
    top: 0;
    width: auto;
    margin: 1em;
  }
}

._dialog_overlay {
  position: fixed;
  top: 0; right: 0; bottom: 0; left: 0;
}

.product-view .product-name .h1{
  padding:15px 20px 0 20px;
  font-weight:600;
  background: transparent;
  margin:0;
  font-size: 2rem;
  color: black;
}
.product-view .price-info{
  background: #fff;
  padding-bottom:15px;
  color:black;
}

.product-view .price-info .price{
  font-weight: 600;
  font-size: 2rem;
  padding:0 20px;
  background: #fff;
  color:black;
}


.short-description{
  padding:0 15px 20px;
  background: #fff;
}

.mdl-data-table tbody tr{
  height:0;
}
.mdl-data-table th, .mdl-data-table td{
  padding:5px;
  height:0;
  text-align:center;
}
.mdl-data-table td:first-of-type, .mdl-data-table th:first-of-type{
  padding-left:5px;
}
.mdl-data-table td:last-of-type, .mdl-data-table th:last-of-type{
  padding-right:5px;
}

#shopping-cart-table .product-name{
    line-height: 1.5rem;
    font-size: 1rem;
    margin-left: 0.5rem;
}
#header-cart .subtotal{
  padding:0 10px;
  margin-top: 10px;
  text-align:right;
  .label{
    font-size:.8rem;
    font-weight: 300;
  }
}

.qty{
  text-align:center;
}

.mdl-data-table tbody tr:nth-child(2n){
      background: #eeeeee;
}


.mdl-dialog__title{
  text-align:center;
  margin-top: 0;
}

@media screen and (max-width: 1024px) {
 .cart-total-price-wrapper{
    position:static;
  }
}

.sidebar .block .block-title, .col-wrapper aside.sidebar .block .block-subtitle--filter, .customer-account h2 {
    border-bottom: 1px solid #e0e0e0;
    text-transform: uppercase;
}

.customer-account .sidebar{
  ul{
    padding: 15px 0;
  li{
    font-weight: 400;
    &:before {
      content: "›";
      margin-right:5px;
      background: white;
      border-radius: 50%;
      width: 15px;
      height: 15px;
      display: inline-block;
      line-height: 15px;
      text-align: center;
     }
    }
  }
}

.grey{
  background:#fff;
  color:#222;
  padding: 2rem;
  .mdl-grid{
    width: 90%;
    margin: auto;
    p{
      margin:0;
    }
  }
}

#cart-sidebar .push-right{
  padding-right:10px;
}

#cart-sidebar{
  .product-options{
    font-size:.8rem;
  }
  .price{
    font-weight:100;
  }
}

.mini-products-list{
  li{
    padding-bottom: 2px;
    p{
      font-size:12px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.cart-empty{
  text-align:center;
}

.cart-total-price-wrapper{
  align-items: flex-start;
  align-content: flex-start;
}

.cart-totals{
  transition:.2s;
  padding: 1.5em;
  margin: 5px;
}

@media #{$small-only} {
  .cart-totals{
    background:#fff;
  }
}

  .mdl-textfield__label{
    font-weight:100;
  }

  ul.top-links__mobile{
    background:#fff;
  }
   .mdl-layout__drawer > ul li ul, .mdl-layout__drawer .nav-primary li ul, ul.top-links__mobile, .mdl-layout__drawer #nav-mobile{
    background: rgba(255, 255, 255, 0.6);
  }
  .mdl-layout__drawer{
    background:transparent;
  }

  ul.top-links__mobile:first-of-type {
    border:none;
}
.mdl-layout__drawer .mdl-layout__drawer__title{
  font-size:0;
  background-color: rgba(255, 255, 255, 0.6);
}
.mdl-layout__drawer .mdl-layout__drawer__close{
  color:#000;
}
.mdl-layout__drawer .mdl-layout__drawer__home{
  display:none;
}


.footer .mdl-cell{
}
@media #{$small-only} {
  .footer .mdl-textfield{
    width:100%;
  }
}

#header .header-content .show-search{
  left:0;
}

@media #{$small-only} {

  #shopping-cart-table{
    display: none;
  }

  #narrow-by-list dd li{
    padding: 0 2vw;
    margin:0 4px 8px 0;
  }
  .toolbar{
    margin:0;
    padding: 0;
  }
  .toolbar .sorter .sort-by .nuva-dropdown-wrapper{
    float:none;
    position: relative;
  }
  .toolbar .sorter .sort-by .nuva-dropdown-wrapper .nuva-dropdown{
    width:100%;
    right:0;
  }

#polarcore .bubble-layer-top .block-layered-nav .block-content{
  display: block;
  padding: 0;
}

  .product-essential .product-shop .product-shop, .catalog-product-view .main{
    padding:0;
  }

  .product-shop {
    padding: 0 15px;
  }
  .mobile{
    ul{
      margin: 1.5em 0;
    }
    .cart-icon{
      position: relative;
      width: 24px;
      display: inline-block;
      vertical-align: top;
      .count {
        position: absolute;
        color: #FFF;
        background: $cart-count-color;
        padding: 0rem 0.3rem;
        line-height: 1rem;
        right: -5px;
        top: -3px;
        border-radius: 50%;
        font-size: 0.7rem;
        display: flex;
        span {
          margin: auto;
        }
      }
      svg{
        vertical-align: top;
      }
    }
    li{
      display: inline-block;
      padding: 0 25px;
      .material-icons{
        vertical-align: middle;
      }
    }
    #header .header-content .header-search{
      background:#fff;
    }
  }
}

.mobile-menu{
  padding: 0 10px;
  min-width: 20em;
  margin: auto;
  display: none;
  .top-links__mobile, .nav-primary{
    width: 100%;
    li{
      width: 100%;
      a{
        padding: 20px 10px;
        display: block;
      }
    }
    .parent > a:after{
      content: '+';
      float: right;
      color: #333;
      font-weight: 400;
      transition: 0.4s ease;
    }
    .open > a:after{
      transform:rotate(45deg);
    }
    .parent > ul, .parent > .meganav{
      display: none;
      border-left: 1px solid #eaeaea;
      padding-left: 5px;
      margin-left: 30px;
    }
  }
}

.is-visible{
  display: block;
  animation: showsearch 0.4s ease;
}

.is-visible-no-anim{
  display: block;
}


.cart-item{
  justify-content: space-between;
  border-bottom: 1px solid #e0e0e0;
  padding: 1rem 0;
}
.cart-item > .cart-item-col:first-of-type{
  flex: 0 1 auto;
}
.cart-item:last-of-type{
  border:none;
}
.cart-item > .cart-item-col{
  justify-content: space-between;
}
.cart-item > *{
  flex:auto;
}
.cart-item-col + .cart-item-col > .cart-item-col:first-of-type{
  width: 40%;
  padding-left: 2em;
  h4{
    margin: 0;
    a{
      font-weight: 500;
      font-size: 1rem;
    }
  }
}
.cart-item{
  .price, dd{
    font-weight: 500;
    font-size: 1rem;
  }
  .btn-remove{
    font-weight: 500;
    font-size: 1rem;
  }
  .qty{
    border-color:#000;
  }
}

.cart-item-col + .cart-item-col > .cart-item-col:last-of-type{
  width:10%;
}

/*.cart-item-col:nth-child(1){
  width: auto;
}
.cart-item-col:nth-child(3){
  justify-content: space-between;
}*/

@media #{$small-only} {
  .page-title h1{
    font-size:1.6rem;
  }
  .page-title h2{
    font-size: 1.4rem;
	line-height:1.5em;
  }
  .polarslide{
    padding:0;
  }
  .cart-item-col + .cart-item-col > .cart-item-col:first-of-type{
    width: auto;
    padding-left: 0;
  }
  .cart-item{
    display: inline-block;
    width: 100%;
    position: relative;
    .price{
      position: absolute;
      bottom: 7%;
    }
    .product-name{
      margin:0;
      font-size: 1.15em;
      line-height: 1.15em;
    }
    a.btn-remove {
      display: inline-block;
      text-indent: -999px;
      text-align: left;
      overflow: hidden;
      @media #{$medium-up}{
        position: relative;
      }
      &:after {
        @include material-icons();
        position: absolute;
        top: 1.5rem;
        right: 0.25rem;
        content: "\E5CD";
        text-indent: 0;
        font-size: 1.25rem;
      }
    }
  }
  .cart-item > *{
    display: inline-block;;
    vertical-align: top;
  }
  .cart-item > .cart-item-col{
    width: calc(50% - 10px);
    padding-right: 10px;
    vertical-align: middle;
  }
  .cart-item-col:nth-child(3){
    position: absolute;
    right:0.25em;
    bottom: 11%;
    input{
      padding:8px 0;
    }
  }
}

.soldout-text{
  text-align: center;
  padding: 1em;
  border: 1px solid;
  margin: 15px 0;
  p{
    margin:0;
  }
}

.page-text{
  padding-bottom: 3em;
  max-width: 50rem;
  margin: auto;
	h4{
		margin:0;
	}
}
.col-main:first-of-type .page-text{
  padding-top:3em;
}

.page-text-two-col{
  display:flex;
  align-items: center;
  .col-2{
    width:50%;
  }
}

.stories{
  .price-box{
    margin-bottom: 15px;
  }
}

.feit-strek:after {
    content: '';
    position: relative;
    width: 20%;
    height: 1px;
    background: #222;
    margin: auto;
    display: block;
    margin-top: 25px;
}

.page-banner{
  position: relative;
    img{
      width:100%!important;
    }
    .page-banner-head{
      position:absolute;
      top:50%;
      left:50%;
      transform:translate(-50%,-50%);
    }
    .white{
      color:#fff;
    }
    .black{
      color:#000;
    }
}
@media #{$medium-up} {
  .pad-large{
    padding-top:3em;
    padding-bottom:3em;
  }
}


@media #{$small-only} {
  .pad-large{
    padding-top:1.4em;
  }
  .page-text{
    padding-bottom: 1.4em!important;
    padding-top: 1.4em!important;
    padding:0 2em;
    h4{
      margin:0;
	  line-height: 1.5em;
      strong{
        font-weight:200;
      }
      font-size:1em;

    }
  }
.page-text-two-col{
    h4{
      margin:0;
	  line-height: 1.5em;
      strong{
        font-weight:200;
      }
      font-size:1em;
    }
}

  .page-text-two-col{
    flex-direction: column;
	  .col-2{
		    width:100%;
  		}
	}
  .page-banner{
    	h1{
      width:100%;
      font-size: 1rem;
      text-align: center;
      }
    }

    .stories{
      .product-name{
        font-size: 1.5rem;
      }
      .page-title{
        margin:0!important;
      }
	  .item{
	  	padding:0;
	  }
    }
}



.slick-slide{
  .button{
    position: absolute;
    //padding:1.6em 7.5em;
    padding: 1em 5em;
    font-weight:400;
  }
  .middle{
    top: 85%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .right{
    top: 26%;
    right: 5%;
    transform:none;
  }
  .left{
    top: 75%;
    left: 20%;
    transform:none;
  }
}

/*.slick-slide:nth-child(4) .button{
  top: 58%;
  right: 77%;
}*/

.slick-slide{
	position:relative;
}

@media #{$small-only} {

.slick-slide{
  .button{
    position: absolute;
    padding:0.2em 1em;
  }
  .middle{
    top: 70%;
    left: 50%;
    transform: translateX(50%);
  }
  .right{
    top: 10%;
    right: 5%;
    transform: none;
  }
  .left{
    top: 70%;
    left: 50%;
    transform: translateX(50%);
  }
  .white{
    color:#171717;
    border-color:#fff;
	  font-weight: 300;
  }

  .black{
    color:#000;
    border-color:#000;
  }
}

}

.nopad > *{
  padding:0!important;
}

.checkout-cart-index main .products-grid button.btn-cart {
    margin: 0;
    padding-top: 15px;
    font-weight: 500;
    background:transparent;
}

.checkout-cart-index main .products-grid button.btn-cart:hover{
  background:transparent;
}


@media #{$small-only} {
  .search-autocomplete ul.bubble-elasticsearch li{
    display: block;
  }
  .search-autocomplete ul.bubble-elasticsearch li.selected a{
    color:#222;
  }
}


.ajaxcart-error-msg {
    border: 1px solid #c25c5c;
    padding: 5px;
    text-align: center;
    margin-bottom: 1rem;
}

.ajaxcart-success-msg {
  border: 1px solid #000000;
  padding: 5px;
  text-align: center;
  margin-bottom: 1rem;
  display: block;
  text-align: center;
  width: 80%;
  margin: auto;
}




.cms-kontakt{
  #contactForm{
    width:50em;
    margin:auto;
    .mdl-textfield{
      width:100%;
    }
    textarea{
      max-width:100%;
    }
  }
}

address{
  font-weight:100;
}



.stories{
  .products-grid{
    flex-flow: row;
    justify-content: center!important;
  }
  button{
    display: block;
    width: 100px;
    margin: auto;
  }
}

@media #{$small-only} {
  .stories{
    .products-grid{
      flex-flow: column;

    }
  }
}
@media #{$small-only} {
  .polarslide .slick-slider{
      margin-bottom:10px!important;
  }
  .instagram-grid{
    margin-top:10px!important;
  }
}

.banner-area{
  .banner-col-three{
    .banner-wrapper {
      .banner-text{
        color: #fff;
        bottom: 50%;
        left: 50%;
        text-align: center;
        position: absolute;
        transform: translate(-50%,50%);
        font-size: 1.5em;
      }
    }
  }
}

.tynn-strek{
	margin-bottom:30px;
}

.tynn-strek:after{
    content: '';
    height: 1px;
    display: block;
    width: 5%;
    margin: auto;
    background: #474747;
}

.tynn-v{
	margin-bottom:30px;
}

.tynn-v:after{
    	content: 'v';
	font-size:1rem;
    line-height: 1;
    display: block;
    width: 5%;
    margin: auto;
    background: #474747;
}



.polarslide-text{
  position: absolute;
  color: white;
}
  .slick-slide{
    .white{color:white;}
    .black{color:black;}
  }
  .polarslide-text h1{
    font-weight: 600;
    margin:0;
    line-height: 1;
  }
  .polarslide-text h2{
    margin:0;
    margin-bottom: 20px;
  }
  .slider-align-0{
    top: 50%;
    left: 50%;
    text-align: center;
    transform: translate(-50%,-50%);
  }
  .slider-align-1{
    top: 50%;
    left: 25%;
    text-align: center;
    transform: translate(-50%,-50%);
  }
  .slider-align-2{
    top: 50%;
    left: 75%;
    text-align: center;
    transform: translate(-50%,-50%);
  }
  .polarslide-text .button{
    position: relative;
    background: #000;
    color: #fff;
  }
  .polarslide-text .button:hover{
    background: transparent;
    color: #000;
  }

@media #{$small-only} {
  .slider-align-1, .slider-align-2{
    top: 50%;
    left: 50%;
    text-align: center;
    transform: translate(-50%,-50%);
    width: 100%;
  }
	.slider-align-0{
		width: 100%;
	}
	.polarslide-text h1{
    	font-size: 1.3rem;
	}
	.polarslide-text h2{
	    font-size: 1.1rem;
	    line-height: 1;
	    font-weight: 300;
	}
}



@media #{$medium-up}{
  .products-grid .item {
    .inner-item{
      position: relative;

    }
    .product-info{
      position: absolute;
      top: 50%;
      left: 50%;
      transform:translate(-50% , -50%);
      opacity: 0;
      visibility: hidden;
      z-index: 9;
      width: 90%;
      transition:0;
      transition-delay:0;
      margin:0;
      padding:0;
      .product-name{
        a{
          font-weight: 600;
          display: block;
        }
      }
      .price{
        font-size: 1.1rem;
        font-weight: 500;
        color: #424242;
      }
    }
    &:hover .product-info{
      opacity: 1;
      visibility: visible;
      transition:.1s;
      transition-delay:.2s;
    }
    &:hover .inner-item img{
      opacity: .3;
    }
  }
}

.products-grid .item {
  .inner-item{
    position: relative;
    .product-image{
      display: block;
    }
  }
  .product-info{
    .product-name{
      a{
        font-weight: 600;
      }
    }
    .price{
      font-size: 1.1rem;
      font-weight: 500;
      color: #424242;
    }
  }
}

.filter-drawer{
    width: 25rem;
    height: 100%;
    position: fixed;
    background: rgba(255, 214, 241, 0.95);
    box-shadow: 5px 0px 8px 0px rgb(134, 134, 134);
    z-index: 999;
    overflow: scroll;
    top: 120%;
    left: 0;
    transition: 0.3s;

    @media #{$small-only} {
      width: 19rem;
    }

    .filter-close {
      position: fixed;
      left: 22.6rem;
      top: 0px;
      margin-left: 1rem;
      font-size: 2rem;
      z-index: 1000;
      color: black;
      display: none;

      @media #{$small-only} {
        left: 17.5rem;
        margin-left: 0;
      }
    }



}

.drawer-overlay {
    position: fixed;
    z-index: 998;
    top: 0;
    left: 350px;
    display: none;
    width: 100%;
    height: 100%;
    background-color: transparent;
}

.filter-open{
  overflow: hidden!important;

  .filter-drawer{
    top:0;

    .filter-close{
      display: block;
    }
  }

  .drawer-overlay{
    display: block;
  }
}


#algolia-searchbox{
  margin:0 !important;
  label{
    display: none;
  }
}

#algolia-searchbox{

}

.magnifying-glass{
  display: none;
}

.autocomplete-wrapper{
  .col-3{
    display: none;
  }
}

#algolia-autocomplete-container .col9{
  width:100%;
}


.filter-wrapper{
  a{
    /*width: 100%;
    border: 1px solid #606060;
    color: #606060;*/
    border-radius: 0;
  }
  svg{
    width: 2rem;
    height: 17px;
    fill: rgba(97, 97, 97, 0.81);
    margin-right: 5px;
    vertical-align: initial;
  }
}

.nuva-dropdown-wrapper{
  display: block;
  a{
    display: block;
    height: 36px;
    line-height:36px;
    border: none;
    color: #606060;
    padding: 0;
    text-transform: uppercase;
    border-radius: 0;
    font-weight: 500;
  }
}

.toolbar .sorter .sort-by .nuva-dropdown-wrapper .nuva-dropdown{
  /*width:100%;*/
  width: 150px;
  left: auto;
  right: 0;
  top: 36px;
  background: #fff;
  color: #000;
  font-family: 'Comfortaa';
  text-shadow: aqua;
  @media #{$small-only} {
    background: #fff;
    opacity: 1;
  }
  /* background: wheat; */

  border: none;
  box-shadow: 5px 0 20px 3px rgba(0, 0, 0, 0.80);
}

.nuva-dropdown-wrapper .nuva-dropdown li:hover{
  background:none;
}

.toolbar .sorter{
  display: inline-block;
  float: right;
}

#polarcore .block-layered-nav .block-content{
  background:transparent;
}

#polarcore .block-layered-nav #narrow-by-list dd ol li .mdl-checkbox .mdl-checkbox__label{
  color: black;
  font-weight: 500;
  display: block;
}

.block-layered-nav .mdl-checkbox__box-outline{
  border:1px solid rgba(0, 0, 0, 0.54);
}

#narrow-by-list{
  border-right: none;
}

.mdl-checkbox.is-checked .mdl-checkbox__box-outline {
    border: 1px solid rgb(0, 0, 0);
    background: white;
}

#polarcore .block-layered-nav #narrow-by-list dt{
  color:white;
  background:none;
  cursor: pointer;
  font-family: 'Comfortaa';
  color: #444;
  font-weight: 800;
  font-size: 0.9rem;
  padding: 1rem 0;
  @media #{$small-only} {
    color: #444;
  }
}

#narrow-by-list dd a{
  //color:white;
}




//@import url(https://fonts.googleapis.com/css?family=Advent+Pro:700);
@-webkit-keyframes turnonlights {
    0%         { color: #6d5669; text-shadow: 0 0 3px #635861, 0 0 5px #635861; }
    1%         { color: #ff55de; text-shadow: 0 0 5px #ff99de, 0 0 10px #ff99de, 0 0 17px #ff00de, 0 0 38px #ff00de}
    4%         { color: #ff55de; text-shadow: 0 0 5px #ff99de, 0 0 10px #ff99de, 0 0 17px #ff00de, 0 0 38px #ff00de}
    7%         { color: #6d5669; text-shadow: 0 0 3px #635861, 0 0 5px #635861; }
    8%         { color: #ff55de; text-shadow: 0 0 5px #ff99de, 0 0 10px #ff99de, 0 0 17px #ff00de, 0 0 38px #ff00de}
    12%        { color: #6d5669; text-shadow: 0 0 3px #635861, 0 0 5px #635861; }
    22%        { color: #6d5669; text-shadow: 0 0 3px #635861, 0 0 5px #635861; }
    23%        { color: #ff55de; text-shadow: 0 0 5px #ff99de, 0 0 10px #ff99de, 0 0 15px #ff99de, 0 0 20px #ff00de, 0 0 30px #ff00de, 0 0 40px #ff00de; }
    26%        { color: #6d5669; text-shadow: 0 0 3px #635861, 0 0 5px #635861; }
    28%        { color: #ff55de; text-shadow: 0 0 5px #ff99de, 0 0 10px #ff99de, 0 0 17px #ff00de, 0 0 38px #ff00de}
    34%        { color: #ff55de; text-shadow: 0 0 5px #ff99de, 0 0 10px #ff99de, 0 0 15px #ff99de, 0 0 20px #ff00de, 0 0 30px #ff00de, 0 0 40px #ff00de; }
    36%        { color: #6d5669; text-shadow: 0 0 3px #635861, 0 0 5px #635861; }
    42%        { color: #ff55de; text-shadow: 0 0 5px #ff99de, 0 0 10px #ff99de, 0 0 17px #ff00de, 0 0 38px #ff00de}
    55%        { color: #ff55de; text-shadow: 0 0 5px #ff99de, 0 0 10px #ff99de, 0 0 15px #ff99de, 0 0 20px #ff00de, 0 0 30px #ff00de, 0 0 40px #ff00de; }
    60%        { color: #6d5669; text-shadow: 0 0 3px #635861, 0 0 5px #635861; }
    63%        { color: #ff55de; text-shadow: 0 0 5px #ff99de, 0 0 10px #ff99de, 0 0 15px #ff99de, 0 0 20px #ff00de, 0 0 30px #ff00de, 0 0 40px #ff00de; }
    67%        { color: #6d5669; text-shadow: 0 0 3px #635861, 0 0 5px #635861; }
    72%        { color: #6d5669; text-shadow: 0 0 3px #635861, 0 0 5px #635861; }
    73%        { color: #ff55de; text-shadow: 0 0 5px #ff99de, 0 0 10px #ff99de, 0 0 17px #ff00de, 0 0 38px #ff00de}
    76%        { color: #6d5669; text-shadow: 0 0 3px #635861, 0 0 5px #635861; }
    78%        { color: #ff55de; text-shadow: 0 0 5px #ff99de, 0 0 10px #ff99de, 0 0 17px #ff00de, 0 0 38px #ff00de}
    84%        { color: #ff55de; text-shadow: 0 0 5px #ff99de, 0 0 10px #ff99de, 0 0 15px #ff99de, 0 0 20px #ff00de, 0 0 30px #ff00de, 0 0 40px #ff00de; }
    86%        { color: #6d5669; text-shadow: 0 0 3px #635861, 0 0 5px #635861; }
    96%        { color: #ff55de; text-shadow: 0 0 5px #ff99de, 0 0 10px #ff99de, 0 0 17px #ff00de, 0 0 38px #ff00de}
    100%       { color: #ff55de; text-shadow: 0 0 5px #ff99de, 0 0 10px #ff99de, 0 0 15px #ff99de, 0 0 20px #ff00de, 0 0 30px #ff00de, 0 0 40px #ff00de; }
}
@-moz-keyframes turnonlights {
    0%        { color: #6d5669; text-shadow: 0 0 3px #635861, 0 0 5px #635861; }
    1%        { color: #ff55de; text-shadow: 0 0 5px #ff99de, 0 0 10px #ff99de, 0 0 17px #ff00de, 0 0 38px #ff00de}
    4%        { color: #ff55de; text-shadow: 0 0 5px #ff99de, 0 0 10px #ff99de, 0 0 17px #ff00de, 0 0 38px #ff00de}
    7%        { color: #6d5669; text-shadow: 0 0 3px #635861, 0 0 5px #635861; }
    8%        { color: #ff55de; text-shadow: 0 0 5px #ff99de, 0 0 10px #ff99de, 0 0 17px #ff00de, 0 0 38px #ff00de}
    12%        { color: #6d5669; text-shadow: 0 0 3px #635861, 0 0 5px #635861; }
    22%        { color: #6d5669; text-shadow: 0 0 3px #635861, 0 0 5px #635861; }
    23%        { color: #ff55de; text-shadow: 0 0 5px #ff99de, 0 0 10px #ff99de, 0 0 15px #ff99de, 0 0 20px #ff00de, 0 0 30px #ff00de, 0 0 40px #ff00de; }
    26%        { color: #6d5669; text-shadow: 0 0 3px #635861, 0 0 5px #635861; }
    28%        { color: #ff55de; text-shadow: 0 0 5px #ff99de, 0 0 10px #ff99de, 0 0 17px #ff00de, 0 0 38px #ff00de}
    34%        { color: #ff55de; text-shadow: 0 0 5px #ff99de, 0 0 10px #ff99de, 0 0 15px #ff99de, 0 0 20px #ff00de, 0 0 30px #ff00de, 0 0 40px #ff00de; }
    36%        { color: #6d5669; text-shadow: 0 0 3px #635861, 0 0 5px #635861; }
    42%        { color: #ff55de; text-shadow: 0 0 5px #ff99de, 0 0 10px #ff99de, 0 0 17px #ff00de, 0 0 38px #ff00de}
    55%        { color: #ff55de; text-shadow: 0 0 5px #ff99de, 0 0 10px #ff99de, 0 0 15px #ff99de, 0 0 20px #ff00de, 0 0 30px #ff00de, 0 0 40px #ff00de; }
    60%        { color: #6d5669; text-shadow: 0 0 3px #635861, 0 0 5px #635861; }
    63%        { color: #ff55de; text-shadow: 0 0 5px #ff99de, 0 0 10px #ff99de, 0 0 15px #ff99de, 0 0 20px #ff00de, 0 0 30px #ff00de, 0 0 40px #ff00de; }
    67%        { color: #6d5669; text-shadow: 0 0 3px #635861, 0 0 5px #635861; }
    72%        { color: #6d5669; text-shadow: 0 0 3px #635861, 0 0 5px #635861; }
    73%        { color: #ff55de; text-shadow: 0 0 5px #ff99de, 0 0 10px #ff99de, 0 0 17px #ff00de, 0 0 38px #ff00de}
    76%        { color: #6d5669; text-shadow: 0 0 3px #635861, 0 0 5px #635861; }
    78%        { color: #ff55de; text-shadow: 0 0 5px #ff99de, 0 0 10px #ff99de, 0 0 17px #ff00de, 0 0 38px #ff00de}
    84%        { color: #ff55de; text-shadow: 0 0 5px #ff99de, 0 0 10px #ff99de, 0 0 15px #ff99de, 0 0 20px #ff00de, 0 0 30px #ff00de, 0 0 40px #ff00de; }
    86%        { color: #6d5669; text-shadow: 0 0 3px #635861, 0 0 5px #635861; }
    96%        { color: #ff55de; text-shadow: 0 0 5px #ff99de, 0 0 10px #ff99de, 0 0 17px #ff00de, 0 0 38px #ff00de}
    100%       { color: #ff55de; text-shadow: 0 0 5px #ff99de, 0 0 10px #ff99de, 0 0 15px #ff99de, 0 0 20px #ff00de, 0 0 30px #ff00de, 0 0 40px #ff00de; }
}

.history-holder h2{
  animation: turnonlights infinite;
  animation-duration: 3.0s;
  animation-fill-mode: both;
  animation-timing-function: steps(2, start);
  font-size: 3.5rem;
  font-weight: 400;
  margin: 0;
}

@media #{$small-only} {
  .history-holder{
    h2{
      font-size: 2rem;
      width:90%;
      margin: 0;
      text-align: center;
    }
  }
}

.animate {
    -webkit-animation-name: turnonlights;
    -webkit-animation-duration: 3.0s;
    -webkit-animation-fill-mode: both;
    -webkit-animation-timing-function: steps(2, start);
    -moz-animation-name: turnonlights;
    -moz-animation-duration: 3.0s;
    -moz-animation-fill-mode: both;
    -moz-animation-timing-function: steps(2, start);
}

//@import url('https://fonts.googleapis.com/css?family=Press+Start+2P');
@import url('https://fonts.googleapis.com/css?family=Comfortaa');


.grey .mdl-grid img {
    width: 50px;
    vertical-align: middle;
    display: inline-block;
}



.header-first{
  .mdl-cell{
    align-items: center;
    justify-content: center;
    display: flex;
  }
  .header-holder{
    position: absolute;
    right: 0;
    top: 0;
    line-height: 52px;
  }
}

.absolute-right{
  position: absolute;
  right:0;
}

.header-nav{
  .center{
    .mdl-cell{
      align-items: center;
      justify-content: center;
      display: flex;
    }
  }
}


.search-button i{
  line-height: 84px;
  margin-top: 0;
}


.product-view .price-info .special-price{
  color:#fff;
}


.customer-account-login .registered-users h2, .page-title h1{
  color: #000;
  font-weight: 500!important;
  font-size: 1.5rem;
}
.customer-account-login .registered-users h2{
  font-size: 1.5rem;
}

.cart-empty p{
  text-decoration: underline;
}

.account-create{
  .buttons-set, .form-list{
    width: 50%;
    margin: auto;
  }
  button{
    width: 100%;
  }
  .mdl-checkbox__label{
    font-size: 1rem;
    font-weight: 200;
  }
  @media #{$small-only} {
    .buttons-set, .form-list{
      width: 100%;
      margin: auto;
    }
  }
}

.customer-account-forgotpassword{
  form{
    width: 35%;
    margin: auto;
    @media #{$small-only} {
      width: 100%
    }
  }
}

.customer-account-login .mdl-js-textfield{
  width: 50%;
}


.account-login .new-users:after{
  content: '';
  position: absolute;
  display: block;
  top: 10%;
  left: 100%;
  bottom: 10%;
  width: 1px;
  background: #d0d0d0;
  z-index: 999999;
}


.customer-account-login .registered-users a.mdl-button {
  font-size: 0.8rem;
}


.box-up-sell ul li.up-sell-item{
  padding:0;
  box-shadow: 5px 4px 8px 0px black;
  margin:.5rem;
  .product-name{
    font-size: .9rem!important;
  }
}

.products-grid .slick-track .item:hover{
  box-shadow: 5px 4px 8px 0px black;
  img{
    opacity: .3;
  }
}

  @media #{$small-only} {
    .box-up-sell ul li.up-sell-item{
      box-shadow: none;
    }
    .products-grid .slick-track .item:hover{
      box-shadow: none;
    }
  }


.checkout-cart-index main .cart-total-price-wrapper #show-discount-form{
  text-decoration: underline;
}


.impulseproducts{
  h2{
    font-size:1rem;
    font-weight:500;
    text-decoration:underline;
  }
}


#polarcore .block-layered-nav .currently{
  order: 0;
}


#narrow-by-list{
  display: block!important;
}


#algolia-searchbox .algolia-search-input {
    width: 100%!important;
    float: none!important;
}

#algolia-searchbox .magnifying-glass{
  top:7px!important;
}

#algolia-searchbox .algolia-search-input:focus{
  border: solid 1px #eaff00!important;
}

#algolia-searchbox .clear-query-autocomplete {
    top: 9px!important;
}


@media #{$small-only} {
  .box-up-sell{
    padding-bottom: 0;
  }
  .footer-container{
    padding-top: 0!important;
  }
}

.kco_hide_payment {
    display: none!important;
}


.newsletter img {
    width: 22px;
}
