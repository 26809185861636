// Compitability fix for Compass vs mdl
@function radial-gradient($args...) {
  @return unquote('radial-gradient(#{$args})');
}
@function linear-gradient($args...) {
  @return unquote('linear-gradient(#{$args})');
}

// Ranges
@function lower-bound($range){
  @if length($range) <= 0 {
    @return 0;
  }
  @return nth($range,1);
}
@function upper-bound($range) {
  @if length($range) < 2 {
    @return 999999999999;
  }
  @return nth($range, 2);
}

@function to-number($value) {
  @if type-of($value) == 'number' {
    @return $value;
  } @else if type-of($value) != 'string' {
    $_: log('Value for `to-number` should be a number or a string.');
  }
  
  $result: 0;
  $digits: 0;
  $minus: str-slice($value, 1, 1) == '-';
  $numbers: ('0': 0, '1': 1, '2': 2, '3': 3, '4': 4, '5': 5, '6': 6, '7': 7, '8': 8, '9': 9);
  
  @for $i from if($minus, 2, 1) through str-length($value) {
    $character: str-slice($value, $i, $i);
    
    @if not (index(map-keys($numbers), $character) or $character == '.') {
      @return to-length(if($minus, -$result, $result), str-slice($value, $i))
    }
    
    @if $character == '.' {
      $digits: 1; 
    } @else if $digits == 0 {
      $result: $result * 10 + map-get($numbers, $character);  
    } @else {
      $digits: $digits * 10;
      $result: $result + map-get($numbers, $character) / $digits;
    }
  }
  
  @return if($minus, -$result, $result);;
}

@function to-rgb($string) {
  $comma: str_index($string, ",");
  $first: str-slice($string, 0, $comma - 1);
  $string: str-slice($string, $comma + 1);
  $comma: str_index($string, ",");
  $second: str-slice($string, 0, $comma - 1);
  $third: str-slice($string, $comma + 1);
  @return rgb(to-number($first), to-number($second), to-number($third));
}

@function to-mdl($color) {
  @return red($color) + ',' + green($color) + ',' + blue($color);
}
