/* BREADCRUMBS - _breadcrumbs.scss */

.breadcrumbs {

  .mdl-button:hover{
    background:none;
  }

  @media #{$small-only} {
    padding: 0.75rem;
    overflow: hidden;
    height: 3.5rem;
  }
  @media #{$medium-up} {
    padding: 0.25rem 1rem;
  }
  ul {
    //display: flex;
    background:#fff;
    padding: 0;
    margin: 0;
    width: 100%;
    @media #{$small-only} {
        overflow-x: scroll;
        overflow-y: hidden;
        -webkit-overflow-scolling: touch;
        white-space: nowrap;
    }
  }
  li {
    display: inline-block;
    vertical-align: middle;
    list-style: none;
    margin-bottom: 0rem;
    border:1px solid #F1F1F1;
    height: 25px;
    line-height: 25px;
    .mdl-button{
      height: 25px;
      line-height: 25px;
    }
    @media #{$small-only} {
      padding-left: 0;
      white-space: nowrap;
      &:after {
        @include material-icons();
        display: inline-block;
        position: relative;
        top: 0.2rem;
        /*content: '\E315';*/
        font-weight: bold;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
      }
      &:last-child {
        background-color: transparent;
        padding-right: 0;
        strong {
	        padding-left: 1rem;
    	    padding-right: 1rem;
        }
        &:after {
            content: '';
        }
      }
      & > a, & > strong {
        display: inline-block;
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }
    strong {
        text-transform: uppercase;
        padding-left: 0;
    }
    @media #{$medium-up} {
        & > a {
            font-weight: lighter;
        }
        &:last-of-type {
            padding: 0 1rem;
        }
    }
    .mdl-button {
        min-width: auto;
        font-size: .85rem;
		font-weight: 100;
    }
    &.home {
        padding: 0;
      @media #{$medium-up} {
        padding-left: 0;
      }
    }
    & > span {
      @media #{$small-only } {
        display: none;
      }
      color: $color-grey-medium;
      margin-left: 0.25rem;
    }
    & > strong {
      color: lighten($basic-link-color, 5%);
      color: black;
      font-weight: 100;
      font-size: .85rem;
        padding-left: 1rem;
        padding-right: 1rem;
    }
  }
}
/* 
        JONAS YOU DIRTY BOY 
        Stressa SJHØ!
*/
.catalog-product-view{
  .breadcrumbs{
    ul{
      color:#fff;
    }
  }
}
