/* FORM - _form.scss */

input {
  padding: 0.8em;
  border: 1px solid $color-grey-light;
  outline: none;
}

textarea {
  padding: 0.8em;
  border: 1px solid $color-grey-light;
  width: 100%;
  max-width: 32rem;
  min-height: 16rem;
}

.validation-advice {
  // MDL Input fix
  position: absolute;
  font-size: 0.8em;
  color: $mdl-red-300;
}
