/* FOOTER - _footer.scss */

.footer-container {
  background: #fff;
  color: #000;
  padding-top: 3rem;

  .back-to-top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0;
    right: 1.25rem;
    cursor: pointer;
    transition: transform 275ms ease-in-out;
    transform: translate3d(0, 4rem, 0);
    will-change: transform;
    backface-visibility: hidden;

    &.show {
      transform: translate3d(0, -1.25rem, 0);
    }

    &__text {
      display: none;
    }

    &__icon {
      justify-content: center;
      align-items: center;
      display: flex;
      border-radius: 100%;
      width: 44px;
      height: 44px;
      background-color: #222222;
      @extend .mdl-shadow--3dp;
    }
  }
}

.footer {
  width: 75%;
  margin:auto;
  max-width: 100%;
  .mdl-grid {
    margin-bottom: 2rem;
  }
  h2 {
    text-transform: uppercase;
    color: #c0c0c0;
    font-size: 1.2rem;
    letter-spacing: 4px;
    font-weight: 300;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  a {
    text-decoration: none;
    color: #000;
    font-weight: 400;
    &:hover {
          font-weight: 400;
    }
  }
  .block-title {
    color: #5C657D;
    font-size: 1.3rem;
    line-height: 2.5rem;
  }
  .footer-logos {
    text-align: center;
    padding: 1.5rem 0;
    width: 100%;
    img {
      height: 25px;
      width: auto;
      min-width: auto;
      padding-right: 0.5rem;
    }
  }
  .copyright {
    padding: 1rem;
    width: 100%;
    text-align: center;
    color: $footer-copyright-text-color;;
    background: $footer-copyright-bg-color;
    font-size: 11px;
  }
  .newsletter {
    background: #fff;
    border-radius: 4px;
    #newsletter {
        color: #000;
    }
  }
  .newsletter .button {
    padding: 1em;
  }
  #newsletter-validate-detail {
    display: flex;
    .button {
        margin-left: 0.5rem;
        margin-top: auto;
        margin-bottom: auto;
        line-height: 14px;
    }
  }
  .footer-right {
      @media #{$large-up} {
        padding-left: 1rem;
      }
  }
  .footer-middle {
    padding: 0 1rem;
    text-align: center;
  }
  .footer-right, .footer-left {
      text-align: center;
      @media #{$large-up} {
        text-align: left;
      }
  }
}
